import React,{ useState } from "react"
import CardProgressBar from "./CardProgressBar/CardProgressBar";

// import eye1 from "../../../../images/cardIcons/eyePurple.svg"
// import eye2 from "../../../../images/cardIcons/eyeBrown.svg"
// import eye3 from "../../../../images/cardIcons/eyeGreen.svg"
// import eye4 from "../../../../images/cardIcons/eyeBlue.svg"
// import eye5 from "../../../../images/cardIcons/eyeRed.svg"

import ActivationCard from "./ActivationCard/ActivationCard";
import HeadersSection from "../../../components/book/headersSection/HeadersSection";
import {info} from "../../../../mockups/dashboard";
import './Activations.scss'
import Graph from "./Graph/Graph";
import Table from "./Table/Table";
import PieGraph from "./PieGraph/PieGraph";
import Cancellation from "./Cancellation/Cancellation";


const Activations=()=>{

    // var fake = [
    //      {title:'Riesgo', number:245, img:eye1, color:'#a02af5'},
    //      {title:'Cajas GPON', number:245, img:eye2, color:'#925f00'},
    //      {title:'Nuevos planes', number:245, img:eye3, color:'#036402'},
    //      {title:'Nuevos POS', number:245, img:eye4, color:'#308ac9'},
    //      {title:'PP SIN DATOS', number:245, img:eye5, color:'#ff2631'},
    //     ]

    var fake = [
        {title:'Riesgo', number:245,  color:'#a02af5'},
        {title:'Cajas GPON', number:245,  color:'#925f00'},
        {title:'Nuevos planes', number:245,  color:'#036402'},
        {title:'Nuevos POS', number:245,  color:'#308ac9'},
        {title:'PP SIN DATOS', number:245,  color:'#ff2631'},
       ]

    var cards = fake.map(item=>{
        return(
            <ActivationCard
                name={item.title}
                number={item.number}
                color={item.color}
                image={item.img}
            />
        )
    })

    return(
        <div>
            <HeadersSection
                title={'Activaciones'}
                exeptions={""}
                date={info.headerInfo.date}
            />
             <div className="sectionCards">
                 {cards}
             </div>

            <div className="sectionSecondColumn">
                <CardProgressBar/>
                <Graph/>
                <PieGraph />
            </div>

            <Table />

            <Cancellation/>

        </div>
    )
}
export default Activations
