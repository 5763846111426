import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import csv from "../../../../../images/tableIcons/csv.png";
import excel from "../../../../../images/tableIcons/excel.png";
import { classNames } from 'primereact/utils'
import { Ripple } from 'primereact/ripple';



const DataReports = () => {

  const defaultPaginatorTemplate = {
    layout: 'PrevPageLink PageLinks NextPageLink',
    'PrevPageLink': (options) => {
        return (
            <button type="button" className='paginator-before greenBtn ' onClick={options.onClick} disabled={options.disabled}>
                Anterior
                <Ripple />
            </button>
        )
    },
    'NextPageLink': (options) => {
        return (
            <button type="button" className='paginator-after greenBtn' onClick={options.onClick} disabled={options.disabled}>
                Siguiente
                <Ripple />
            </button>
        )
    },
    'PageLinks': (options) => {
        if ((options.view.startPage === options.page && options.view.startPage !== 0) || (options.view.endPage === options.page && options.page + 1 !== options.totalPages)) {
            const className = classNames(options.className, { 'p-disabled': true });

            return <span className={className} style={{ userSelect: 'none' }}>...</span>;
        }

        return (
            <button type="button" className={options.className} onClick={options.onClick}>
                {options.page + 1}
                <Ripple />
            </button>
        )
    },
};
  const column = [
    {
      field: "fecha",
      header: "Fecha de registro",
      order: false,
    },
    {
      field: "foto",
      header: "Foto",
      order: false,
    },
    {
      field: "nombre",
      header: "Nombre",
      order: false,
    },
    {
      field: "sucursal",
      header: "Sucursal",
      order: false,
    },
    {
      field: "supervisor",
      header: "Supervisor",
      order: false,
    },
    {
      field: "ci",
      header: "Ci",
      order: false,
    },
    {
      field: "perfil",
      header: "Perfil",
      order: false,
    },
    {
      field: "canal",
      header: "Canal comercial",
      order: false,
    },
    {
      field: "pos",
      header: "POS",
      order: false,
    },
    {
      field: "tipo",
      header: "Tipo",
      order: false,
    },
    {
      field: "estado",
      header: "Estado",
      order: false,
    },
    {
      field: "acciones",
      header: "Acciones",
      order: false,
    },
  ];
  const rows = [
    {
      fecha: "21 - Abril - 2021",
      foto: "-",
      nombre: "Joel Trincado",
      sucursal: "34",
      ci: "34df23",
      accion: "Acciones",
    },
  ];
  return (
    <div className="tableDownload">
      <DataTable
      value={rows}
        paginator
        paginatorTemplate={defaultPaginatorTemplate}
        rows={10}
        className="parameters-table"
      >
        {column.map((e) => {
          return (
            <Column
              field={e.field}
              header={e.header}
              body={e.body ? e.body : null}
            />
          );
        })}
      </DataTable>
      <div style={{ display: "flex", gap: "11px", alignItems: "center" }}>
              <p style={{ fontSize: "12px", margin: "0" }}>Descargar como</p>
              <div style={{ display: "flex", gap: "22px" }}>
                <div
                  style={{
                    width: "40px",
                    height: "40px",
                    display: "grid",
                    placeItems: "center",
                    borderRadius: "5px",
                    backgroundColor: "#ffffff",
                    border: "1px solid  #e3e3e3",
                  }}
                >
                  <img style={{ width: "20px", height: "20px" }} src={csv} />
                </div>
                <div
                  style={{
                    width: "40px",
                    height: "40px",
                    display: "grid",
                    placeItems: "center",
                    borderRadius: "5px",
                    backgroundColor: "#ffffff",
                    border: "1px solid  #e3e3e3",
                  }}
                >
                  <img style={{ width: "20px", height: "20px" }} src={excel} />
                </div>
              </div>
            </div>
    </div>
  );
};

export default DataReports;
