import React, { useState } from 'react'
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import Column from 'antd/lib/table/Column';
import { Row } from 'primereact/row';
import ColumnGroup from 'antd/lib/table/ColumnGroup';
import Xicon from '../../../../../img/icons/xicon.svg'
import { DataTable } from 'primereact/datatable';

import './Modal2.scss'

const imgTemplate = () =>
{
    return(
        <img src={Xicon}></img>
    )
    
}
const Modal2 = ({setOpenModalEdit}) => {

    const close = () =>
{
    setOpenModalEdit(false);
    document.getElementById('fondo').style.display ="block";
    document.getElementById('contenido').style.display ="block"
}
    var arrayItems = [
        {
            field1: "Automoviles",
            field2: "34",
            field3: "Nissan Sentra 2021",
            field4: "ewqe4321ewqe231",
            field5: "Vehículo",
            field6: "Lorem ipsum dolor sit amet, fweaw",
            field7: "",


        },
        {
            field1: "Automoviles",
            field2: "34",
            field3: "Nissan Sentra 2021",
            field4: "ewqe4321ewqe231",
            field5: "Vehículo",
            field6: "Lorem ipsum dolor sit amet, fweaw",
            field7: "",
        },
        {
            field1: "Automoviles",
            field2: "34",
            field3: "Nissan Sentra 2021",
            field4: "ewqe4321ewqe231",
            field5: "Vehículo",
            field6: "Lorem ipsum dolor sit amet, fweaw",
            field7: "",
           
        },
    ]
    var arrayHeader = [
        { field: "field1" },
        { field: "field2" },
        { field: "field3" },
        { field: "field4" },
        { field: "field5" },
        { field: "field6" },
        { field: "field7" },
    ]

    let headerGroup =
    <ColumnGroup>
        <Row>
            <Column header="Familia" colSpan={1} className="header-color border-radius-left header-table" />
            <Column header="Sección" colSpan={1} className="header-color header-table" />
            <Column header="Producto" colSpan={1} className="header-color header-table" />
            <Column header="Nro de serie." colSpan={1} className="header-color header-table" />
            <Column header="Tipo de recurso" colSpan={1} className="header-color header-table" />
            <Column header="Observaciones" colSpan={1} className="header-color header-table" />
            <Column header="Acciones" colSpan={1} className="header-color header-table border-radius-right" />
        </Row>
    </ColumnGroup>

  return (
    <>
    <div className='scroll'>
    <div className='Modal_container2'> 
        <div className='title-container'>
            <div className='title'>Entregar recurso</div>
        </div>
            <div className='dt-modal2'>
                <div></div>
                <div className='form'>
                   <div className='sub-title'>Persona que entrega</div>
                   <input className='input' disabled value='JORGE ANTONIO FUERTE DIAZ'></input>
                   <div className='sub-title'>Recurso a entregar</div>
                   <input className='input' disabled value='Nissan Sentra FEWF231'></input>
                   <div className='sub-title'>Fecha prevista de devolución</div>
                   <input className='input' disabled value='dd/mm/aaaa'></input>
                   <div className='sub-title'>Fecha prevista de devolución</div>
                   <input className='input-date' type='date'></input>
                   <div className='sub-title'>Observaciones</div>
                   <textarea className='text-area' type='date'></textarea>
                   <div className='btn-container'>
                    <button className='btn' onClick={()=>{close()}}>Cancelar</button>
                    <button className='btn-entregar' onClick={()=>{close()}}>Entregar</button>
                   </div>

                </div>
            </div>
           
    </div> 
    </div> 
    <div className='Modal__background' onClick={()=>{close()}}></div>
    </>
)}

export default Modal2