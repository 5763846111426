import React, { useState , useEffect} from "react";
import ReactDOM from "react-dom";
import HeadersSection from "../../../../../components/book/headersSection/HeadersSection";
import AbsoluteSelect from "../../../../../components/book/absoluteSelect/AbsoluteSelect";
import Table from "../../../../../components/activations/table/Table_hf_r";
import TableAgo from "../../../../../components/activations/table/TableAgo";
import FooterSimple from "../../../../../components/book/footerSimple/FooterSimple";
import ThirdTable from "../../../../../components/activations/table/ThirdTable"
import { info } from '../../../../../../mockups/dashboard';
import { infoAct } from '../../../../../../mockups/activations';

import { faFileExcel, faFileWord } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import './HomeFiles.scss';
import 'primeflex/primeflex.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import axios from "axios";

const Receive = ({routes}) => {
    const [openSelect, setOpenSelect] = useState(false);
    const [showSubSelects, setShowSubSelects] = useState(false);

    const [dataSelect, setDataSelect] = useState([{}])

    const [idSelect, setIdSelect] = useState('')
    const [firstTable, setFirstTable] = useState([{}])
    const [secondTable, setSecondTable] = useState([{}])
    const [thirdTable, setThirdTable] = useState([{}])

    const [clean, setClean] = useState([{}])

    var Routes
    var listJsonTest = [
        "19090",
        "2324234",
        "34534",
        "56756",
        "3453",
        "4567567",
        "98797",
        "34556",
        "8966",
    ]
    useEffect(() => {

        const getSelect = async () => {

            let getData = Routes.getSelect;
            const result = await axios(getData,);
            setDataSelect(result.data.data.period)

        }

        const getTable = async () => {

            let getData = Routes.dataTable;
            const result = await axios(getData,);
            setFirstTable(result.data.data.first_table)
            setSecondTable(result.data.data.second_table)
            setThirdTable(result.data.data.third_table)

        }
        getTable()

        const getDataRelated  = async() => {
            let mainRoute = document.getElementsByName('routes')[0].getAttribute('content');
            const result = await axios(mainRoute,);
            Routes = result.data
            getSelect()
            getTable()
        }

        getDataRelated()



    },[]);

   //  useEffect(()=>{
   //
   //      //var Routes2
   //      if(idSelect !== ''){
   //          const getTable = async () => {
   //
   //              let getData = routes.dataTable;
   //              const result = await axios(getData,);
   //              setFirstTable(result.data.data.first_table)
   //              setSecondTable(result.data.data.second_table)
   //              setThirdTable(result.data.data.third_table)
   //
   //          }
   //          getTable()
   //
   //      }
   //
   // },[idSelect])
   //


   const exportExcel = () => {
    //    const ws = XLSX.utils.json_to_sheet(arrayItems);
    //    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    //    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    //    const data = new Blob([excelBuffer], { type: fileType });
    //    FileSaver.saveAs(data, 'table_' + new Date().getTime() + fileExtension);
   }


    return (
        <div className="container-activations">
            <HeadersSection
                title={<div>PREPAGO Legajos pendientes <span className="colorRed">A VENCER</span> agencia</div>}
                exeptions={{}}
                date={info.headerInfo.date}
            />


            <div className="div-selects-activations homeFiles">
                <div className="div-container-stand">
                    <div className="p-grid">
                        <div className="p-col-3 p-md-6 p-lg-3 p-sm-12">
                            <AbsoluteSelect
                                inputName={'Región'}
                                //list={info.selectRangoInfo.list}
                                list={listJsonTest}
                                setOpenSelect={setOpenSelect}
                                openSelect={openSelect}
                                setIdSelect={setIdSelect}
                            />
                        </div> 
                        <div className="p-col-3 p-md-6 p-lg-3 p-sm-12">
                            <AbsoluteSelect
                                inputName={'Sucursal'}
                                //list={info.selectRangoInfo.list}
                                list={listJsonTest}
                                setOpenSelect={setOpenSelect}
                                openSelect={openSelect}
                                setIdSelect={setIdSelect}
                            />
                        </div> 
                        <div className="p-col-3 p-md-6 p-lg-3 p-sm-12">
                            <AbsoluteSelect
                                inputName={'Canal'}
                                //list={info.selectRangoInfo.list}
                                list={listJsonTest}
                                setOpenSelect={setOpenSelect}
                                openSelect={openSelect}
                                setIdSelect={setIdSelect}
                            />
                        </div> 
                        <div className="p-col-3 p-md-6 p-lg-3 p-sm-12">
                            <AbsoluteSelect
                                inputName={'Sub canal comercial'}
                                //list={info.selectRangoInfo.list}
                                list={listJsonTest}
                                setOpenSelect={setOpenSelect}
                                openSelect={openSelect}
                                setIdSelect={setIdSelect}
                            />
                        </div> 
                        <div className="p-col-3 p-md-6 p-lg-3 p-sm-12">
                            <AbsoluteSelect
                                inputName={'Supervisor'}
                                //list={info.selectRangoInfo.list}
                                list={listJsonTest}
                                setOpenSelect={setOpenSelect}
                                openSelect={openSelect}
                                setIdSelect={setIdSelect}
                            />
                        </div> 
                        <div className="p-col-3 p-md-6 p-lg-3 p-sm-12">
                            <AbsoluteSelect
                                inputName={'Vendedor'}
                                //list={info.selectRangoInfo.list}
                                list={listJsonTest}
                                setOpenSelect={setOpenSelect}
                                openSelect={openSelect}
                                setIdSelect={setIdSelect}
                            />
                        </div> 
                        <div className="p-col-3 p-md-6 p-lg-3 p-sm-12">
                            <AbsoluteSelect
                                inputName={'POS'}
                                //list={info.selectRangoInfo.list}
                                list={listJsonTest}
                                setOpenSelect={setOpenSelect}
                                openSelect={openSelect}
                                setIdSelect={setIdSelect}
                            />
                        </div> 
                        <div className="p-col-3 p-md-6 p-lg-3 p-sm-12">
                            <AbsoluteSelect
                                inputName={'Estado Rendición Legajos Agente'}
                                //list={info.selectRangoInfo.list}
                                list={listJsonTest}
                                setOpenSelect={setOpenSelect}
                                openSelect={openSelect}
                                setIdSelect={setIdSelect}
                            />
                        </div> 
                    </div>
                </div>
            </div>

            {/* <div className="p-grid mb-3">
                <div className="p-col-6 p-md-6 p-lg-3 p-sm-12">
                </div> 
                <div className="p-col-6 p-md-6 p-lg-9 p-sm-12">
                    <div className="div-details-activations">
                        <button className="buttons btn-big btn-blue mr-3" onClick={() =>{ setShowSubSelects(!showSubSelects);setClean(true)}}>Limpiar</button>
                        <button className="buttons btn-big btn-green" onClick={() => setShowSubSelects(!showSubSelects)}>Aplicar</button>
                    </div>
                </div>
            </div> */}
            <div className="buttons-container" style={{marginBottom:"30px"}}>
                    <div className="div-details-activations">
                        <button className="btn-blue btn-jf mr-3 " style={{width:"100px"}} onClick={() => setShowSubSelects(!showSubSelects)}>Limpiar</button>
                        <button className="btn-jf btn-green" onClick={() => setShowSubSelects(!showSubSelects)}>Aplicar</button>
                    </div>
                </div>

            <div className="div-container-stand homeFiles">
                <div className="div-downloads-dashboard">
                    <span className="text-download-dashboard">Descargar como</span>
                    <FontAwesomeIcon onClick={exportExcel} icon={faFileWord} className="icon-word-dashboard" />
                    <FontAwesomeIcon onClick={exportExcel} icon={faFileExcel} className="icon-excel-dashboard" />
                </div>
                <Table
                    arrayHeader={[
                        {field:"field1", colspan:2, className:"bold-600"},
                        {field:"field2"},
                        {field:"field3"},
                        {field:"field4"},
                        {field:"field5"},
                        {field:"field6"},
                        {field:"field7"},
                        {field:"field8"},
                        {field:"field9"},
                        {field:"field10"},
                        {field:"field11"},
                        {field:"field12"},
                        {field:"field13"},
                        {field:"field14"},
                    ]}
                    arrayItems={[
                        {   
                            field1:"",
                            field2:"15 - julio - 21",
                            field3:"",
                            field4:"",
                            field5:"",
                            field6:(<><input type="checkbox" id="vehicle1"></input><label for="vehicle1"></label></>),
                            field7:"",
                            field8:(<div className="colorGreen">PORTA</div>),
                            field9:"",
                            field10:"Central",
                            field11:"",
                            field12:"",
                            field13:"Carloz Gómez",
                            field14:"",
                        },
                        {   
                            field1:"",
                            field2:"15 - julio - 21",
                            field3:"",
                            field4:"",
                            field5:"",
                            field6:(<><input type="checkbox" id="vehicle2"></input><label for="vehicle2"></label></>),
                            field7:"",
                            field8:(<div className="colorGreen">PORTA</div>),
                            field9:"",
                            field10:"Central",
                            field11:"",
                            field12:"",
                            field13:"Carloz Gómez",
                            field14:"",
                        },
                        {   
                            field1:"",
                            field2:"15 - julio - 21",
                            field3:"",
                            field4:"",
                            field5:"",
                            field6:(<><input type="checkbox" id="vehicle3"></input><label for="vehicle3"></label></>),
                            field7:"",
                            field8:(<div className="colorGreen">PORTA</div>),
                            field9:"",
                            field10:"Central",
                            field11:"",
                            field12:"",
                            field13:"Carloz Gómez",
                            field14:"",
                        },
                        {   
                            field1:"",
                            field2:"15 - julio - 21",
                            field3:"",
                            field4:"",
                            field5:"",
                            field6:(<><input type="checkbox" id="vehicle4"></input><label for="vehicle4"></label></>),
                            field7:"",
                            field8:(<div className="colorGreen">PORTA</div>),
                            field9:"",
                            field10:"Central",
                            field11:"",
                            field12:"",
                            field13:"Carloz Gómez",
                            field14:"",
                        },
                        {   
                            field1:"",
                            field2:"15 - julio - 21",
                            field3:"",
                            field4:"",
                            field5:"",
                            field6:(<><input type="checkbox" id="vehicle5"></input><label for="vehicle5"></label></>),
                            field7:"",
                            field8:(<div className="colorGreen">PORTA</div>),
                            field9:"",
                            field10:"Central",
                            field11:"",
                            field12:"",
                            field13:"Carloz Gómez",
                            field14:"",
                        },
                        {   
                            field1:"",
                            field2:"15 - julio - 21",
                            field3:"",
                            field4:"",
                            field5:"",
                            field6:(<><input type="checkbox" id="vehicle6"></input><label for="vehicle6"></label></>),
                            field7:"",
                            field8:(<div className="colorGreen">PORTA</div>),
                            field9:"",
                            field10:"Central",
                            field11:"",
                            field12:"",
                            field13:"Carloz Gómez",
                            field14:"",
                        },
                        {   
                            field1:"",
                            field2:"15 - julio - 21",
                            field3:"",
                            field4:"",
                            field5:"",
                            field6:(<><input type="checkbox" id="vehicl7"></input><label for="vehicl7"></label></>),
                            field7:"",
                            field8:(<div className="colorGreen">PORTA</div>),
                            field9:"",
                            field10:"Central",
                            field11:"",
                            field12:"",
                            field13:"Carloz Gómez",
                            field14:"",
                        },
                        {   
                            field1:"",
                            field2:"15 - julio - 21",
                            field3:"",
                            field4:"",
                            field5:"",
                            field6:(<><input type="checkbox" id="vehicle8"></input><label for="vehicle8"></label></>),
                            field7:"",
                            field8:(<div className="colorGreen">PORTA</div>),
                            field9:"",
                            field10:"Central",
                            field11:"",
                            field12:"",
                            field13:"Carloz Gómez",
                            field14:"",
                        },
                        {   
                            field1:"",
                            field2:"15 - julio - 21",
                            field3:"",
                            field4:"",
                            field5:"",
                            field6:(<><input type="checkbox" id="vehicle9"></input><label for="vehicle9"></label></>),
                            field7:"",
                            field8:(<div className="colorGreen">PORTA</div>),
                            field9:"",
                            field10:"Central",
                            field11:"",
                            field12:"",
                            field13:"Carloz Gómez",
                            field14:"",
                        },
                        {   
                            field1:"",
                            field2:"15 - julio - 21",
                            field3:"",
                            field4:"",
                            field5:"",
                            field6:(<><input type="checkbox" id="vehicle10"></input><label for="vehicle10"></label></>),
                            field7:"",
                            field8:(<div className="colorGreen">PORTA</div>),
                            field9:"",
                            field10:"Central",
                            field11:"",
                            field12:"",
                            field13:"Carloz Gómez",
                            field14:"",
                        },
                        {   
                            field1:"",
                            field2:"15 - julio - 21",
                            field3:"",
                            field4:"",
                            field5:"",
                            field6:(<><input type="checkbox" id="vehicle11"></input><label for="vehicle11"></label></>),
                            field7:"",
                            field8:(<div className="colorGreen">PORTA</div>),
                            field9:"",
                            field10:"Central",
                            field11:"",
                            field12:"",
                            field13:"Carloz Gómez",
                            field14:"",
                        },
                        {   
                            field1:"",
                            field2:"15 - julio - 21",
                            field3:"",
                            field4:"",
                            field5:"",
                            field6:(<><input type="checkbox" id="vehicle12"></input><label for="vehicle12"></label></>),
                            field7:"",
                            field8:(<div className="colorGreen">PORTA</div>),
                            field9:"",
                            field10:"Central",
                            field11:"",
                            field12:"",
                            field13:"Carloz Gómez",
                            field14:"",
                        },
                        {   
                            field1:"",
                            field2:"15 - julio - 21",
                            field3:"",
                            field4:"",
                            field5:"",
                            field6:(<><input type="checkbox" id="vehicle13"></input><label for="vehicle13"></label></>),
                            field7:"",
                            field8:(<div className="colorGreen">PORTA</div>),
                            field9:"",
                            field10:"Central",
                            field11:"",
                            field12:"",
                            field13:"Carloz Gómez",
                            field14:"",
                        },
                    ]}
                    dates={["total a la fecha"]}
                />
            </div>
            <FooterSimple />
        </div>

    );
};

export default Receive;

