import React, { useState } from "react";
import { Link, Navigate, useParams } from 'react-router-dom';
import '../../Plans/CreatePlan/CreatePlan.scss';
import 'primeflex/primeflex.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './createPos.scss'
import check from "../../../../../images/dashboard/checkbox.svg"


const Form = ({ closeModule, items = {} }) => {
    const {id} = useParams()
    console.log(items, 'xx')
    const [date, setDate] = useState('21/12/21');
    const [plan, setPlan] = useState('1');
    const [city, setCity] = useState('PP BH TPS - SMS');
    const [descprom, setdescprom] = useState(items.descprom || '');
    const [dpto, setDpto] = useState('Portabilidad')
    const [typePos, setTypePos] = useState('Genérico')
    const [asigned, setAsigned] = useState('Comercial')
    const [liquidation, setLiquidation] = useState('100')
    const[asignadoA , setAsignadoA] = useState('seleccionar');

    const [status, setStatus] = useState(0)
    const [selectedCheck, setSelectedCheck] = useState(false)

    // var history=Navigate()
    
    return (
        <div className="p-grid">
            <div className="p-col-12 p-md-6 p-lg-3">
                <div className="group-label">
                    <label className="input-label">FECHA INSERCIÓN:</label>
                    <input  onChange={(e) => setDate(e.target.value)} type="text" className="input" />
                </div>
                <div className="group-label">
                    <label className="input-label">NRO. POS:</label>
                    <input  onChange={(e) => setPlan(e.target.value)} type="text" className="input" />
                </div>
                <div className="group-label">
                    <label className="input-label">CIUDAD POS:</label>
                    <input type="text"  onChange={(e) => setCity(e.target.value)} className="input" />
                </div>

                <div className="group-label">
                    <label className="input-label">DPTO POS:</label>
                    <input type="text" onChange={(e) => setDpto(e.target.value)} className="input" />
                </div>

                <div className="group-label">
                    <label className="input-label">Estado:</label>
                    <div className='statusSection'>
                        <button className={`btnLeftStatus ${status === 0 ? 'statusSelected': ''}`} onClick={()=>setStatus(0)}>Activo</button>
                        <button className={`btnRightStatus ${status === 1 ? 'statusSelected': ''}`} onClick={()=>setStatus(1)}>Inactivo</button>
                    </div>

                </div>



            </div>
            <div className="p-col-12 p-md-6 p-lg-3">

                <div className="group-label">
                    <label className="input-label">TIPO DE POS:</label>
                    <select className="select-basic" value={id ? typePos : ''}>
                        <option>Seleccionar</option>
                        <option value="generico">Genérico</option>
                        <option value="comercial">Comercial</option>
                        <option value="distribucion">Distribución</option>
                    </select>
                </div>

                <div className="group-label">
                    <label className="input-label">ASIGNADO A:</label>
                    <select className="select-basic" onChange={(e)=>{ setAsignadoA(e.target.value);console.log(e.target.value)}}>
                        <option value='seleccionar'>Seleccionar</option>
                        <option value="generico">Genérico</option>
                        <option value="comercial">Comercial</option>
                        <option value="distribucion">Distribución</option>
                    </select>
                    
                    
                    
                </div>
                {asignadoA !== 'seleccionar'?
                    <>
                        <p className="green-text"> 685621, Mirna rulli, Sucursal, Canal, Sub canal, Supervisor</p>
                    </>
                    :
                    null
                    }
                <div className="group-label">
                    <label className="input-label">POS LIQUIDACIÓN:</label>
                    <input type="text" value={liquidation} onChange={(e) => setLiquidation(e.target.value)} className="input" />
                </div>

                <div className="group-label">
                    <div className='fakeCheck' onClick={()=>setSelectedCheck(!selectedCheck)}>
                        {selectedCheck && (
                            <img src={check}/>
                            )}
                        <div className="input-label">IMPULSADORA</div>
                    </div>
                </div>

                <div className="div-buttons-createplan2">
                    {/* <button className="buttons btn-red" onClick={() => closeModule(false)}>Cancelar</button> */}

                    <Link to='/pos'>
                        <button className="buttons btn-red" >Cancelar</button>
                    </Link>
                   

                    <button className="buttons btn-green" style={{marginLeft:'30px'}}>Guardar</button>
                </div>
            </div>
        </div>
    );
};

export default Form;
