import React from 'react'
import Commissions from './Commissions'

const HumanResources = () => {
  return (
    <>
    <Commissions title="Recursos Humanos comisiones"/>
    </>
  )
}

export default HumanResources