import React, { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "./DashboardRRHH.scss";
import { useNavigate } from "react-router";
import data2 from "../Data/dataRRHH.json";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import pastelIcon from "../../../../../images/dashboardRRHH/pastelicon.png";
import festivoIcon from "../../../../../images/dashboardRRHH/festivoicon.png";
import profileImg from "../../../../../images/dashboardRRHH/profileImg.svg";
import csvIcon from "../../../../../images/FileManagement/FilesForm/CSVicon.png";
import excelIcon from "../../../../../images/FileManagement/FilesForm/EXCELicon.png";
import HeadersSection from "../../../../components/book/headersSection/HeadersSection";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Ripple } from "primereact/ripple";
import { classNames } from "primereact/utils";
import { Link } from "react-router-dom";

export default function DashboardRRHH() {
  const data = [
    {
      state: "Usuarios bloqueados por legajos",
      quantity: 500,
      status: "A bloquear",
      quantity2: 500,
      link:"/users-bloked"
    },
    {
      state: "Usuarios bloqueados por stock",
      quantity: 250,
      status: "A bloquear",
      quantity2: 250,
      link:"/users-bloked-stook"
    },
    {
      state: "Usuarios con contraseña vencida",
      quantity: 1000,
      status: "",
      quantity2: "",
      link:"/expired-password"
    },
  ];
  // commercials
  const dataCommercials = [
    {
      state: "Comerciales sin POS Asignados",
      quantity: 1000,
      link:"/pos-unassigned"
    },
    {
      state: "Comerciales sin venta",
      quantity: 1000,
      
      link:"/no-sales"
    },
    {
      state: "Comerciales sin meta asignada",
      quantity: 1000,
      link:'/meta-unassigned',
      
    },
    {
      state: "Devolución de recursos",
      quantity: 1000,
      link:'/return-products',
    },
  ];

  const requestPermission = [
    {
      state: "Solicitud de permisos",
      quantity: 1000,
      link:"/request-permission"
    },
    {
      state: "Solicitud de vacaciones",
      quantity: 1000,
      
      link:"/request-vacation"
    },
  ];


  const PermissionActive = (row) => {
    return row.permissionActive === "active" ? (
      <div style={{ fontSize: "11px", fontWeight: "bold", color: "#04d400" }}>
        Vacaciones
      </div>
    ) : (
      ""
    );
  };

  const RemainingDay = (row) => {
    return row.permissionActive === "active" ? (
      <div
        style={{
          background: "#04d400",
          color: "#fff",
          height: "25px",
          borderRadius: "10px",
          padding: "5px 0",
        }}
      >
        {row.remainingDay}
      </div>
    ) : (
      ""
    );
  };

  const ProfileImg = () => {
    return (
      <div
        style={{
          width: "30px",
          height: "30px",
          borderRadius: "50%",
          objectFit: "cover",
          objectPosition: "center",
          marginLeft: "20px",
        }}
      >
        <img src={profileImg} alt="profile" />
      </div>
    );
  };

  const State = (row) => {
    return <div style={{ fontWeight: "bold" }}>{row.state}</div>;
  };

  const Status = (row) => {
    return <div style={{ color: "#ff2631" }}>{row.status}</div>;
  };

  const Quantity2 = (row) => {
    return (
      <div style={{ color: "#ff2631", fontWeight: "bold" }}>
        {row.quantity2}
      </div>
    );
  };
  const Quantity = (row) => {
    return (
      <div onClick={()=>navigate(row.link)} className="cp">
        {row.quantity}
      </div>
    );
  };
  const QuantityCommercials = (row) => {
    return (
      <div onClick={()=>navigate(row.link)} className="cp">
        {row.quantity}
      </div>
    );
  };
  const QuantityPermission = (row) => {
    return (
      <div onClick={()=>navigate(row.link)} className="cp">
        {row.quantity}
      </div>
    );
  };


   const FooterTable=()=>  {
    return (
      <div className="contain-footer-table">
        <div className="title">Descargar como</div>
        <div className="contain-icon">
          <img src={csvIcon} alt="CSV" className="icon" />
        </div>
        <div className="contain-icon">
          <img src={excelIcon} alt="EXCEL" className="icon" />
        </div>
      </div>
    );
  };

  const defaultPaginatorTemplate = {
    layout: "PrevPageLink PageLinks NextPageLink",
    PrevPageLink: (options) => {
      return (
        <button
          type="button"
          className="paginator-before greenBtn "
          onClick={options.onClick}
          disabled={options.disabled}
        >
          Anterior
          <Ripple />
        </button>
      );
    },
    NextPageLink: (options) => {
      return (
        <button
          type="button"
          className="paginator-after greenBtn"
          onClick={options.onClick}
          disabled={options.disabled}
        >
          Siguiente
          <Ripple />
        </button>
      );
    },
    PageLinks: (options) => {
      if (
        (options.view.startPage === options.page &&
          options.view.startPage !== 0) ||
        (options.view.endPage === options.page &&
          options.page + 1 !== options.totalPages)
      ) {
        const className = classNames(options.className, { "p-disabled": true });

        return (
          <span className={className} style={{ userSelect: "none" }}>
            ...
          </span>
        );
      }

      return (
        <button
          type="button"
          className={options.className}
          onClick={options.onClick}
        >
          {options.page + 1}
          <Ripple />
        </button>
      );
    },
  };

  const events = [
    { id: "BD", title: "1", start: "2022-07-02" },
    { id: "DF", title: "Dia de la independencia", start: "2022-07-02" },
    { id: "DF", title: "Dia de la independencia", start: "2022-07-07" },
    { id: "BD", title: "1", start: "2022-07-18" },
    { id: "DF", title: "Año nuevo", start: "2022-07-25" },
  ];

  const imgIcon = (e) => {
    const date = new Date(e.event._instance.range.start)
      .toLocaleDateString()
      .split("/")
      .reverse()
      .join("-");

    const birthday = [
      { name: "Julio Cesar Chaves", img: "", fecha: "2022-7-18" },
    ];
    const fecha = birthday.map((e) => e.fecha).toString();
    console.log(birthday.map((e) => e.name));
    return (
      <>
        <OverlayTrigger
          delay={{ hide: 450, show: 300 }}
          overlay={(props) => (
            <Tooltip {...props}>
              <div className="imgIconCalendarTooltip">
                {e.event.id == "BD" ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <img
                        src={pastelIcon}
                        style={{ width: "15px", height: "15px" }}
                      />
                      <span style={{ fontWeight: "bold", fontSize: "12px" }}>
                        Cumpleaños del día
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <img
                        src={profileImg}
                        style={{ width: "15px", height: "15px" }}
                      />
                      <span style={{ fontSize: "12px" }}>
                        {"Jorge Alejandro Pérez"}
                      </span>
                    </div>
                  </>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <img
                      src={festivoIcon}
                      style={{ width: "15px", height: "15px" }}
                    />
                    <span style={{ fontSize: "11px" }}>{e.event.title}</span>
                  </div>
                )}
              </div>
            </Tooltip>
          )}
          placement="bottom"
        >
          <div className="imgIconCalendar">
            {e.event.id == "BD" ? (
              <img src={pastelIcon} style={{ width: "15px", height: "15px" }} />
            ) : (
              <img
                src={festivoIcon}
                style={{ width: "15px", height: "15px" }}
              />
            )}
            <span style={{ color: "#000" }}>{e.event.title}</span>
          </div>
        </OverlayTrigger>
      </>
    );
  };

  const [searchName, setSearchName] = useState();
  const [departament, setDepartament] = useState();
  const [position, setPosition] = useState();
  const navigate = useNavigate()
  const link = ()=>
  {
    alert("yes");
  }
  return (
    <div>
      <HeadersSection
        title={"Dashboard RRHH"}
        exeptions={""}
        date={"Viernes 30 de Julio del 2021 | Semana: 18 | Cierre de mes: 1"}
      />
      <div className="body">
      <div className=" mt-5 f-container">
        <div>
          <div className="dashboardrrhh-contain-table-user">
            <h1 className="title">Gestión de usuarios</h1>
            <DataTable value={data} stripedRows className="table-rrhh" responsiveLayout="scroll">

              <Column field="state" header="Estado" body={State}></Column>
              <Column field="quantity" header="Cantidad" body={Quantity}></Column>
              <Column field="status" header="" body={Status}></Column>
              <Column
                field="quantity2"
                header="Cantidad"
                body={Quantity2}
              ></Column>
            </DataTable>
          </div>
          <div className="dashboardrrhh-contain-table-commercial">
            <h1 className="title">Gestión de comerciales</h1>
            <DataTable value={dataCommercials} stripedRows className="table-rrhh" responsiveLayout="scroll">
              <Column field="state" header="Estado" body={State}></Column>
              <Column field="quantity" header="Cantidad" body={QuantityCommercials}></Column>
            </DataTable>
          </div>
          <div className="dashboardrrhh-contain-table-request">
            <h1 className="title">Gestión de solicitudes</h1>
            <DataTable value={requestPermission} stripedRows className="table-rrhh" responsiveLayout="scroll">
              <Column field="state" header="Estado" body={State}></Column>
              <Column field="quantity" header="Cantidad" body={QuantityPermission}></Column>
            </DataTable>
          </div>
        </div>
        <div>
          <div className="dashboardrrhh-contain-calendar">
            <div className="contain-title">
              <div className="title">Calendario</div>
              <div className="contain-icons">
                <div className="contain-icons">
                  <img src={festivoIcon} alt="festivo" className="icon" />
                  <div>Días feriados</div>
                </div>
                <div className="contain-icons">
                  <img src={pastelIcon} alt="cumpleaños" className="icon" />
                  <div>Cumpleaños</div>
                </div>
              </div>
            </div>
            <FullCalendar
              plugins={[dayGridPlugin, interactionPlugin]}
              initialView="dayGridMonth"
              height={"450px"}
              locale="es"
              headerToolbar={{ start: "title", center: "", end: "prev,next" }}
              events={events}
              eventContent={imgIcon}
              eventColor="#fff"
            />
          </div>
          <div className="dashboardrrhh-contain-table-rrhh">
            <h1 className="title">Recursos humanos</h1>
            <DataTable value={data} stripedRows className="table-rrhh" responsiveLayout="scroll">
              <Column field="state" header="Estado" body={State}></Column>
              <Column field="quantity" header="Cantidad"></Column>
            </DataTable>
          </div>
        </div>
      </div>


      <div className="dashboardrrhh-contain-table-vacations">
        <div className="contain-title">
          <div className="title">Vacaciones y permisos</div>
          <div className="contain-selects">
            <input
              type="search"
              value={searchName}
              onChange={(e) => setSearchName(e.target.value)}
              placeholder="Buscar por nombre"
              className="selectVacations"
            />
            <select
              value={position}
              options={""}
              onChange={(e) => setPosition(e.value)}
              className="selectVacations"
            >
              <option>Seleccionar departamento</option>
            </select>
            <select
              value={position}
              options={""}
              onChange={(e) => setPosition(e.value)}
              className="selectVacations"
            >
              <option>Seleccionar cargo</option>
            </select>
          </div>
        </div>
        <DataTable
          value={data2}
          rows={10}
          stripedRows
          className="table-rrhh"
          globalFilter={searchName}
          paginator
          paginatorTemplate={defaultPaginatorTemplate}
          emptyMessage="Sin resultados"
          responsiveLayout="scroll"
          footer={FooterTable}
        >
          <Column field="image" header="Fotografía" body={ProfileImg}></Column>
          <Column field="name" header="Nombre"></Column>
          <Column
            field="permissionActive"
            header="Permiso activo"
            body={PermissionActive}
          ></Column>
          <Column field="dateVacation" header="Fecha de vacaciones"></Column>
          <Column
            field="remainingDay"
            header="Días restantes"
            body={RemainingDay}
          ></Column>
          <Column field="daysPerYear" header="Días por año"></Column>
          <Column field="daysAcumulated" header="Días acumulados"></Column>
          <Column
            field="daysPerMonthTake"
            header="Días tomados en el mes"
          ></Column>
          <Column
            field="daysPerYearsTake"
            header="Días tomados en el año"
          ></Column>
          <Column field="daysPendings" header="Días pendientes"></Column>
          <Column field="e" header="E"></Column>
          <Column field="v" header="V"></Column>
          <Column field="m" header="M"></Column>
          <Column field="t" header="T"></Column>
          <Column field="md" header="MD"></Column>
        </DataTable>
      </div>
    </div>
    </div>
  );
}

