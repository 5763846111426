import React, { useState, useEffect } from "react";
import './LethargyManagement.scss'
import 'primeflex/primeflex.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

//components
import HeadersSection from "../../components/book/headersSection/HeadersSection";
import Table from "../../components/activations/table/Table_hfNotExpand";
import FooterSimple from "../../components/book/footerSimple/FooterSimple";
import { info } from '../../../mockups/dashboard';
const LethargyManagement = () => {
    return (
        <div className="container-activations">
            <HeadersSection
                title={'Gestión Legajos Claros'}
                exeptions={{}}

            />


            <div className="div-selects-activations homeFiles">
                <div className="div-container-stand">
                    <div className="button-header pl-3 pr-3">
                        <div className="pb-3 btn-header-responcive">
                        </div>
                    </div>

                    <Table
                        arrayHeader={[
                            { field: "field1", colspan: 2, className: "bold-600" },
                            { field: "field2", colspan: 2, className: "bold-600" },
                            { field: "field3", colspan: 2, className: "bold-600" },
                            { field: "field4" },
                            { field: "field5" },
                            { field: "field6" },
                            { field: "field7" },
                            { field: "field8" },
                            { field: "field9" },
                            { field: "field10" },
                            { field: "field11" },
                            { field: "field12" },
                            { field: "field13" }
                        ]}
                        arrayItems={[
                            {
                                position: {
                                    title: "central",
                                    sumatory: [
                                        "1000",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "1000",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",

                                    ],
                                    branchOffice1: {
                                        title: "Sucursal 1",
                                        subtitles: {
                                            sub1: {
                                                title: "4x4",
                                                data: [
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",

                                                ],
                                            },
                                            sub2: {
                                                title: "PDA",
                                                data: [
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",

                                                ]
                                            },
                                            sub3: {
                                                title: "LYG",
                                                data: [
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",

                                                ]
                                            },
                                        },
                                    },
                                    branchOffice2: {
                                        title: "Sucursal 1",
                                        subtitles: {
                                            sub1: {
                                                title: "4x4",
                                                data: [
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",

                                                ]
                                            },
                                            sub2: {
                                                title: "PDA",
                                                data: [
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",

                                                ]
                                            },
                                            sub3: {
                                                title: "LYG",
                                                data: [
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",

                                                ]
                                            },
                                        },
                                    },
                                },
                            },
                            {
                                position: {
                                    sumatory: [
                                        "",
                                        "",
                                        "",
                                        "1000",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "1000",
                                        "1000",
                                        "1000",

                                    ],
                                    title: "Centro",
                                    branchOffice1: {
                                        title: "Sucursal 1",
                                        subtitles: {
                                            sub1: {
                                                title: "4x4",
                                                data: [
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",

                                                ]
                                            },
                                            sub2: {
                                                title: "PDA",
                                                data: [
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",

                                                ]
                                            },
                                            sub3: {
                                                title: "LYG",
                                                data: [
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",

                                                ]
                                            },
                                        },
                                    },
                                    branchOffice2: {
                                        title: "Sucursal 1",
                                        subtitles: {
                                            sub1: {
                                                title: "4x4",
                                                data: [
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",

                                                ]
                                            },
                                            sub2: {
                                                title: "PDA",
                                                data: [
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",

                                                ]
                                            },
                                            sub3: {
                                                title: "LYG",
                                                data: [
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",

                                                ]
                                            },
                                        },
                                    },
                                },
                            },
                            {
                                position: {
                                    sumatory: [
                                        "1000",
                                        "",
                                        "",
                                        "1000",
                                        "",
                                        "",
                                        "1000",
                                        "",
                                        "",
                                        "1000",
                                        "1000",
                                        "1000",

                                    ],
                                    title: "Este",
                                    branchOffice1: {
                                        title: "Sucursal 1",
                                        subtitles: {
                                            sub1: {
                                                title: "4x4",
                                                data: [
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",

                                                ]
                                            },
                                            sub2: {
                                                title: "PDA",
                                                data: [
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",

                                                ]
                                            },
                                            sub3: {
                                                title: "LYG",
                                                data: [
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",

                                                ]
                                            },
                                        },
                                    },
                                    branchOffice2: {
                                        title: "Sucursal 1",
                                        subtitles: {
                                            sub1: {
                                                title: "4x4",
                                                data: [
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",

                                                ]
                                            },
                                            sub2: {
                                                title: "PDA",
                                                data: [
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",

                                                ]
                                            },
                                            sub3: {
                                                title: "LYG",
                                                data: [
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",

                                                ]
                                            },
                                        },
                                    },
                                },
                            },
                            {
                                position: {
                                    sumatory: [
                                        "1000",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "1000",
                                        "1000",
                                        "",
                                        "",
                                        "",
                                        "",

                                    ],
                                    title: "Sur",
                                    branchOffice1: {
                                        title: "Sucursal 1",
                                        subtitles: {
                                            sub1: {
                                                title: "4x4",
                                                data: [
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",

                                                ]
                                            },
                                            sub2: {
                                                title: "PDA",
                                                data: [
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",

                                                ]
                                            },
                                            sub3: {
                                                title: "LYG",
                                                data: [
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",

                                                ]
                                            },
                                        },
                                    },
                                    branchOffice2: {
                                        title: "Sucursal 1",
                                        subtitles: {
                                            sub1: {
                                                title: "4x4",
                                                data: [
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",

                                                ]
                                            },
                                            sub2: {
                                                title: "PDA",
                                                data: [
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",

                                                ]
                                            },
                                            sub3: {
                                                title: "LYG",
                                                data: [
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",

                                                ]
                                            },
                                        },
                                    },
                                },
                            },
                            {
                                position: {
                                    title: "Norte",
                                    sumatory: [
                                        "",
                                        "",
                                        "",
                                        "1000",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "1000",
                                        "1000",
                                        "1000",

                                    ],
                                    branchOffice1: {
                                        title: "Sucursal 1",
                                        subtitles: {
                                            sub1: {
                                                title: "4x4",
                                                data: [
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",

                                                ]
                                            },
                                            sub2: {
                                                title: "PDA",
                                                data: [
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",

                                                ]
                                            },
                                            sub3: {
                                                title: "LYG",
                                                data: [
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",

                                                ]
                                            },
                                        },
                                    },
                                    branchOffice2: {
                                        title: "Sucursal 1",
                                        subtitles: {
                                            sub1: {
                                                title: "4x4",
                                                data: [
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",

                                                ]
                                            },
                                            sub2: {
                                                title: "PDA",
                                                data: [
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",

                                                ]
                                            },
                                            sub3: {
                                                title: "LYG",
                                                data: [
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",
                                                    "1000",

                                                ]
                                            },
                                        },
                                    },
                                },
                            },
                        ]}
                        dates={["total a la fecha"]}
                    />
                </div>
            </div>


            <div className="div-container-stand homeFiles">
                <div className="p-grid">
                    <div className="p-col p-md-6 p-lg-3-5-col p-sm-12 pl-3 pr-3">
                        <div className="bot-button botcolor-de9000">
                            Consultar Legajos
                        </div>
                    </div>
                    <div className="p-col p-md-6 p-lg-3-5-col p-sm-12 pl-3 pr-3">
                        <div className="bot-button botcolor-036402">
                        Generar rendición Claro
                        </div>
                    </div>
                    <div className="p-col p-md-6 p-lg-3-5-col p-sm-12 pl-3 pr-3">
                        <div className="bot-button botcolor-308ac9">
                        Subir legajos escaneados
                        </div>
                    </div>
                    <div className="p-col p-md-6 p-lg-3-5-col p-sm-12 pl-3 pr-3">
                        <div className="bot-button botcolor-0600a8">
                        Confirmar rendición Claro
                        </div>
                    </div>
                    <div className="p-col p-md-6 p-lg-3-5-col p-sm-12 pl-3 pr-3">
                        <div className="bot-button" style={{background:"#ff2631"}}>
                        Subir legajos STEALTH
                        </div>
                    </div>
                </div>
            </div>
            <FooterSimple />
        </div>
    );
}
export default LethargyManagement;