import React from "react";
import Personnel from "../Personnel";
import "./NewPersonnelTwo.scss";
import BranchOffice from "./BranchOffice";
import { infoParameters } from "./newPersonnelTwoData";
import { useEffect, useState } from "react";
import ListaRegistro from "./ListaRegistro";

const NewPersonnelTwo = () => {
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [dataParameters, setDataParameters] = useState([]);
  const [column, setColumn] = useState([]);
  const [modal, setModal] = useState(false);
  const [cargarTabla, setCargarTabla] = useState(false);
  const [registro, setRegistro] = useState([]);
  const [estadoActualizado, setEstadoActualizado] = useState([]);
  const [suma, setSuma] = useState(0);

  useEffect(() => {
    const getData = async () => {
      setColumn(infoParameters.tableInfo.headerList);
      setDataParameters(infoParameters.tableInfo);
    };
    getData();
  }, []);

  useEffect(() => {
    let descripcion3 = 0;
    registro.forEach((e) => {
      const descripcion = e.descripcion.slice(1);
      const descripcion2 = parseFloat(descripcion.replaceAll(",", ""));
      descripcion3 = descripcion3 + parseFloat(descripcion2);
      setSuma(descripcion3);
    });
  }, [registro]);

  const eliminarRegistro = (id) => {
    const registroActualizado = registro.filter(
      (contacto) => contacto.id !== id
    );
    setRegistro(registroActualizado);
    setSuma(0);
  };

  const actualizarEstado = (id) => {
    registro.forEach((elemento) => {
      if (elemento.id === id && elemento.estado == "Activo") {
        elemento.estado = "Inactivo";
      } else if (elemento.id === id && elemento.estado == "Inactivo") {
        elemento.estado = "Activo";
      }
      setEstadoActualizado([...estadoActualizado, elemento]);
    });
  };

  const cerrarModal = () => {
    const modal = document.getElementById("overlay");
    modal.remove();
    setModal(false);
    setCargarTabla(true);
    const body = document.getElementById("root");
    body.classList.remove("fijar-body");
  };

  return (
    <div
      className=" personnel"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: "20px",
      }}
    >
      <div>
        <div>
          <Personnel caption="Personal" captionData="Nuevo Personal" />
        </div>
        <div className="personnel-container">
          <p className="personnel-container-text">Datos de asignación</p>
          <div className="personnel-container-data">
            <div className="start-date">
              <label>Fecha de inicio</label>
              <input type="date" />
            </div>
            <div className="data-one">
              <div className="start-date">
                <label>Tipo de relación</label>
                <select>
                  <option disabled>Selecciona una opción</option>
                  <option>Pasantia</option>
                  <option>Tercerizado</option>
                  <option>Interno</option>
                  <option>IPS</option>
                </select>
              </div>
              <div className="start-date">
                <label>Inicio de contrato</label>
                <input type="date" />
              </div>
              <div className="start-date">
                <label>Fin de contrato</label>
                <input type="date" />
              </div>
              <div className="start-date">
                <label style={{ width: "300px" }}>Antigüedad del agente</label>
                <p>2 años, 3 meses, 14 días</p>
              </div>
            </div>

            <div className="data-one">
              <div className="start-date">
                <label>Tipo de puesto</label>
                <select>
                  <option disabled>Selecciona una opción</option>
                  <option>Administrativo</option>
                  <option>Comercial</option>
                  <option>BackOficce</option>
                  <option>Servicio técnico</option>
                  <option>Tercerizado</option>
                  <option>Courier</option>
                </select>
              </div>
              <div className="start-date">
                <label>Correo electrónico asignado</label>
                <input type="email" />
              </div>
              <div className="start-date">
                <label>Nro de teléfono asignado</label>
                <div className="phone-number">
                  <input className="lada" type="number" placeholder="+595" />
                  <input type="number" placeholder="Número de teléfono" />
                </div>
              </div>
            </div>
          </div>
          <BranchOffice
            cerrarModal={cerrarModal}
            modal={modal}
            setModal={setModal}
            registro={registro}
            setRegistro={setRegistro}
          />
          <ListaRegistro
            actualizarEstado={actualizarEstado}
            eliminarRegistro={eliminarRegistro}
            registro={registro}
            suma={suma}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "20px",
          }}
        >
          <button style={{ backgroundColor: "#e3e3e3", color: "black" }}>
            Anterior
          </button>
          <button style={{ backgroundColor: "#308ac9", color: "white" }}>
            Siguiente
          </button>
        </div>
      </div>
      <div>
        <footer style={{ display: "flex", justifyContent: "center" }}>
          <p>2022 GestiClaro. Todos los derechos reservados</p>
        </footer>
      </div>
    </div>
  );
};

export default NewPersonnelTwo;
