import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel, faFileWord } from "@fortawesome/free-solid-svg-icons";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import "./Table.scss";

const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

const TableAgo = ({ arrayItems, dataDateNow, download = false }) => {
  const exportExcel = () => {
    const ws = XLSX.utils.json_to_sheet(arrayItems);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "table_" + new Date().getTime() + fileExtension);
  };

  return (
    <div>
      {dataDateNow && (
        <>
          <div className="table-activations">
            <div
              style={{ width: "100%", height: 40, backgroundColor: "#4a4f54", padding: '10px 0 0 25px', fontWeight: 'bold', fontSize: '12px'  }}
            >
              <label style={{ color: "#fff" }}>Mes anterior</label>
            </div>

            {dataDateNow &&
              dataDateNow.length &&
              dataDateNow.map((itemdate, key) => {
                return (
                  <div style={{ width: "100%", height: 40 }}>
                    <div key={key}>
                      <div
                        className="widthtable row-1 rowInfo"
                        style={{
                          backgroundColor: itemdate.gray
                            ? "#969696"
                            : "#e3e3e3",
                        }}
                      >
                        <label style={{color: '#000', fontWeight: 'bold', padding: '3px 0 0 0'}}>{itemdate.total}</label>
                      </div>
                      {itemdate.rows.map((item, key) => {
                        console.log(item)
                        return (
                          <div
                            className={`widthtable row-${key + 2} rowInfo`}
                            style={{
                              backgroundColor: item.color
                                ? item.color
                                : "transparent",
                              borderRight: "1px solid #eee",
                              borderBottom: "1px solid #eee",
                            }}
                          >
                            <div className="w100">
                              {item.row.map((subitem, subkey) => {
                                return (
                                  <div
                                    className={`div-label-row row-${
                                      key + 2
                                    }-item${subkey + 1}`}
                                  >
                                    <label key={subkey}>{subitem}</label>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}

            <div style={{ width: "100%", height: 40 }}></div>

            <div style={{ width: "100%", height: 40 }}>
            {dataDateNow &&
              dataDateNow.length &&
              dataDateNow.map((itemdate, key) => {
                return (
                  <div style={{ width: "100%", height: 40 }}>
                    <div key={key}>
                      <div
                        className="widthtable row-1 rowInfo"
                        style={{
                          backgroundColor: itemdate.gray
                            ? "#969696"
                            : "#e3e3e3",
                        }}
                      >
                        <label style={{color: '#000', fontWeight: 'bold', padding: '3px 0 0 0'}}>{itemdate.total2}</label>
                      </div>
                      {itemdate.rows.map((item, key) => {
                        return (
                          <div
                            className={`widthtable row-${key + 2} rowInfo`}
                            style={{
                              backgroundColor: item.color
                                ? item.color
                                : "transparent",
                              borderRight: "1px solid #eee",
                              borderBottom: "1px solid #eee",
                            }}
                          >
                            <div className="w100">
                              {item.row.map((subitem, subkey) => {
                                return (
                                  <div
                                    className={`div-label-row row-${
                                      key + 2
                                    }-item${subkey + 1}`}
                                  >
                                    <label key={subkey}>{subitem}</label>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>

            {/*<div style={{ width: '100%', height: 40 }}>*/}
            {/*    /!*<div className="widthtable row-1 rowInfo" style={{ backgroundColor: '#e3e3e3' }}>*!/*/}
            {/*    /!*    <label className="month-ago-table">Total mes</label>*!/*/}
            {/*    /!*</div>*!/*/}

            {/*    {arrayItems.map((item, key)=>{*/}
            {/*        if(key === 0){*/}
            {/*            return(*/}
            {/*                <div className="widthtable row-1 rowInfo" style={{ backgroundColor: '#e3e3e3' }}>*/}
            {/*                    {item.data && (*/}
            {/*                        <label className="month-ago-table">{item.data[1]}</label>*/}
            {/*                    )}*/}
            {/*                </div>*/}
            {/*            )*/}
            {/*        }else{*/}
            {/*            return (*/}
            {/*                <div className={`widthtable row-${key + 1} rowInfo bgrow${key + 1}`} key={key}>*/}
            {/*                    <div className="w100">*/}
            {/*                        {item.subchannel && (*/}
            {/*                            <>*/}
            {/*                                {item.subchannel.map((subitem, subkey) => {*/}
            {/*                                    return (*/}
            {/*                                        <div className={`div-label-row row-${key + 1}-item${subkey + 1}`} key={subkey}>*/}
            {/*                                            <label key={subkey}>{subitem.data[1]}</label>*/}
            {/*                                        </div>*/}
            {/*                                    )*/}
            {/*                                })}*/}

            {/*                            </>*/}
            {/*                        )}*/}

            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            )*/}
            {/*        }*/}
            {/*    })}*/}

            {/*{dataDateNow && dataDateNow.length && dataDateNow.map((item, key) => {*/}

            {/*    return (*/}
            {/*        <div className={`widthtable row-${key + 2} rowInfo bgrow${key + 2}`} key={key}>*/}
            {/*            <div className="w100">*/}
            {/*                {item.row.map((subitem, subkey) => {*/}
            {/*                    return (*/}
            {/*                        <div className={`div-label-row row-${key + 2}-item${subkey + 1}`} key={subkey}>*/}
            {/*                            <label key={subkey}>{subitem}</label>*/}
            {/*                        </div>*/}
            {/*                    )*/}
            {/*                })}*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    )*/}

            {/*})}*/}
            {/*</div>*/}
          </div>
          {download && (
            <div className="div-downloads-dashboard">
              <span className="text-download-dashboard">Descargar como</span>
              <FontAwesomeIcon
                onClick={exportExcel}
                icon={faFileWord}
                className="icon-word-dashboard"
              />
              <FontAwesomeIcon
                onClick={exportExcel}
                icon={faFileExcel}
                className="icon-excel-dashboard"
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default TableAgo;
