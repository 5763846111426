import React, { useState,useEffect } from 'react'
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import './PdfModal.scss'
import pdfDocument from '../../../img/icons/pdf-document.svg'
import printIcon from '../../../img/icons/print.svg'
import exitIcon from '../../../img/icons/close-white.svg'

const PdfModal = ({modalState,state,content}) => {
    const close = () =>
{
    state(false);
}
useEffect(()=>{
    const bloked = document.getElementById('body_class')
    if(modalState === true)
    {
        bloked.style.overflow="hidden";
    }
    return()=>{bloked.style.overflow="scroll";} 
    
},[modalState])
  return (
    <>
    <div className="img-container-pdf-modal">
        <div className="cirle-pdf-modal">
            <img src={pdfDocument} ></img>
        </div>
        <div className="cirle-pdf-modal">
            <img src={printIcon}></img>
        </div>
        <div className="cirle-pdf-modal-red">
            <img src={exitIcon} onClick={()=>close()} style={{cursor:"pointer"}}></img>
        </div>
     </div>
    <div className='PdfModal'>
    <div className='Pdf-Modal_container'> 
            <div className='dt-modalPdf'>
                <div></div>
                <div className='formPdf'>
                   {content}

                </div>
            </div>
           
    </div> 
    </div> 
    <div className='PdfModal-background' onClick={()=>{close()}}></div>
    </>
)}

export default PdfModal