const tableInfo = {
  headerList: [
    {
      field: "banco",
      header: "Banco",
      order: false,
    },
    {
      field: "date",
      header: "Fecha de registro",
      order: false,
    },
    {
      field: "num",
      header: "Numero de Usuarios vinculados",
      order: false,
    },
    {
      field: "update",
      header: "Última actualización",
      order: false,
    },
    {
      field: "accion",
      header: "Acciones",
      order: false,
    },
  ],
  rows: [
    {
      banco: "Efectivo de ventanilla",
      date: "21 - Abril - 2021",
      num: "34",
      update: "21 - Abril - 2021",
      accion: "Acciones",
    },
    {
      banco: "Transparencia de cuenta",
      date: "",
      num: "34",
      update: "21 - Abril - 2021",
      accion: "Acciones",
    },
    {
      banco: "Efectivo de ventanilla",
      date: "21 - Abril - 2021",
      num: "34",
      update: "21 - Abril - 2021",
      accion: "Acciones",
    },
    {
      banco: "Sub-agente",
      date: "21 - Abril - 2021",
      num: "34",
      update: "21 - Abril - 2021",
      accion: "Acciones",
    },
  ],
};

export const infoParameters = {
  tableInfo,
};
