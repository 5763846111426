import React, { useState } from 'react'
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import { Dropdown } from 'primereact/dropdown';



import './ModalEdit.scss'


const ModalEdit = ({setOpenModalEdit}) => {


    const [selectedCity1, setSelectedCity1] = useState(null);

    const onCityChange = (e) => {
        setSelectedCity1(e.value);
    }

    const cities = [
        { name: 'Falta Firma', code: 'FF' },
        { name: 'Frima no coincide', code: 'NC' },
        { name: 'Tachaduras', code: 'TA' },
    ];

  return (
    <>
    <div className='Modal_container'>   
        <h3 className='mt-4'>Observaciones</h3>
        <Dropdown className='mt-5 w-80' value={selectedCity1} options={cities} width='21370px' onChange={onCityChange} optionLabel="name" placeholder="Seleccione una opción" />
        <div className='mt-5'>
            
            <button className='btn mr-5 pl-5 pr-5' onClick={()=>setOpenModalEdit(false)}>Cancelar</button>
            <button className='btn btn-primary pl-5 pr-5' onClick={()=>setOpenModalEdit(false)}>Guardar</button>
        </div>
    </div>
    <div className='Modal__background'></div>
    </>
)}

export default ModalEdit