const headerInfo = {
    title: 'PREPAGO Legajos pendientes A RECIBIR agencia',
    exeptions: {
        text: 'A RECIBIR',
        color: 'rgb(255, 38, 49)'
    },
    date: 'Viernes 30 de Julio del 2021  |  Semana: 18  |  Cierre de mes: 1'
};

const arrayListMock = [
    '201901',
    '201902',
    '201903',
    '201904',
    '201905',
    '201906',
    '201907',
    '201908',
    '201909',
    '201910'
];

const selectRangoInfo = {
    inputName: 'Región',
    list: arrayListMock
};

const selectBranchInfo = {
    inputName: 'Sucursal',
    list: arrayListMock
};

const selectChannelInfo = {
    inputName: 'Canal',
    list: arrayListMock
};

const selectSubChannelInfo = {
    inputName: 'Sub Canal comercial',
    list: arrayListMock
};

const selectSupervisorInfo = {
    inputName: 'Supervisor',
    list: arrayListMock
};

const selectSellerInfo = {
    inputName: 'Vendedor',
    list: arrayListMock
};

const selectPOSInfo = {
    inputName: 'POS',
    list: arrayListMock
};

const selectAgentsInfo = {
    inputName: 'Estado Rendición Legajos Agente',
    list: arrayListMock
};

const tableInfo = {
    headerList: [
        {
            field: 'timeago',
            header: 'Antiguedad',
            order: true
        },
        {
            field: 'activationdate',
            header: 'Fecha Activación',
            order: false
        },
        {
            field: 'sds',
            header: 'SDS',
            order: false
        },
        {
            field: 'postype',
            header: 'Tipo POS',
            order: true
        },
        {
            field: 'pos',
            header: 'POS',
            order: false
        },
        {
            field: 'force',
            header: 'Impulsadora',
            order: false
        },
        {
            field: 'nim',
            header: 'NIM',
            order: false
        },
        {
            field: 'simcard',
            header: 'SIMCARD',
            order: false
        },
        {
            field: 'simcardcellar',
            header: 'Bodega SIMCARD',
            order: false
        },
        {
            field: 'region',
            header: 'Región',
            order: false
        },
        {
            field: 'subchannel',
            header: 'Sub Canal',
            order: false
        },
        {
            field: 'supervisor',
            header: 'Supervisor',
            order: false
        },
        {
            field: 'staterl',
            header: 'Estado Rendición Legajos',
            order: false
        }
    ],
    rows: [
        {
            timeago: '',
            activationdate: '15 - Julio -21',
            sds: '',
            postype: '',
            pos: <div><input type="checkbox" className="checkbox-table-dashboard" id="dash-check1" /><label for="dash-check1" /></div>,
            force: '',
            nim: <span style={{ color: '#036402' }}>PORTA</span>,
            simcard: '',
            simcardcellar: '',
            region: 'CENTRAL',
            subchannel: '',
            supervisor: 'Carlos Gómez',
            staterl: ''
        },
        {
            timeago: '',
            activationdate: '15 - Julio -21',
            sds: '',
            postype: '',
            pos: <div><input type="checkbox" className="checkbox-table-dashboard" id="dash-check2" /><label for="dash-check2" /></div>,
            force: '',
            nim: <span style={{ color: '#036402' }}>PORTA</span>,
            simcard: '',
            simcardcellar: '',
            region: 'CENTRAL',
            subchannel: '',
            supervisor: 'Carlos Gómez',
            staterl: ''
        },
        {
            timeago: '',
            activationdate: '15 - Julio -21',
            sds: '',
            postype: '',
            pos: <div><input type="checkbox" className="checkbox-table-dashboard" id="dash-check3" /><label for="dash-check3" /></div>,
            force: '',
            nim: <span style={{ color: '#036402' }}>PORTA</span>,
            simcard: '',
            simcardcellar: '',
            region: 'CENTRAL',
            subchannel: '',
            supervisor: 'Carlos Gómez',
            staterl: ''
        },
        {
            timeago: '',
            activationdate: '15 - Julio -21',
            sds: '',
            postype: '',
            pos: <div><input type="checkbox" className="checkbox-table-dashboard" id="dash-check1" /><label for="dash-check1" /></div>,
            force: '',
            nim: <span style={{ color: '#036402' }}>PORTA</span>,
            simcard: '',
            simcardcellar: '',
            region: 'CENTRAL',
            subchannel: '',
            supervisor: 'Carlos Gómez',
            staterl: ''
        },
        {
            timeago: '',
            activationdate: '15 - Julio -21',
            sds: '',
            postype: '',
            pos: <div><input type="checkbox" className="checkbox-table-dashboard" id="dash-check2" /><label for="dash-check2" /></div>,
            force: '',
            nim: <span style={{ color: '#036402' }}>PORTA</span>,
            simcard: '',
            simcardcellar: '',
            region: 'CENTRAL',
            subchannel: '',
            supervisor: 'Carlos Gómez',
            staterl: ''
        },
        {
            timeago: '',
            activationdate: '15 - Julio -21',
            sds: '',
            postype: '',
            pos: <div><input type="checkbox" className="checkbox-table-dashboard" id="dash-check3" /><label for="dash-check3" /></div>,
            force: '',
            nim: <span style={{ color: '#036402' }}>PORTA</span>,
            simcard: '',
            simcardcellar: '',
            region: 'CENTRAL',
            subchannel: '',
            supervisor: 'Carlos Gómez',
            staterl: ''
        },
        {
            timeago: '',
            activationdate: '15 - Julio -21',
            sds: '',
            postype: '',
            pos: <div><input type="checkbox" className="checkbox-table-dashboard" id="dash-check1" /><label for="dash-check1" /></div>,
            force: '',
            nim: <span style={{ color: '#036402' }}>PORTA</span>,
            simcard: '',
            simcardcellar: '',
            region: 'CENTRAL',
            subchannel: '',
            supervisor: 'Carlos Gómez',
            staterl: ''
        },
        {
            timeago: '',
            activationdate: '15 - Julio -21',
            sds: '',
            postype: '',
            pos: <div><input type="checkbox" className="checkbox-table-dashboard" id="dash-check2" /><label for="dash-check2" /></div>,
            force: '',
            nim: <span style={{ color: '#036402' }}>PORTA</span>,
            simcard: '',
            simcardcellar: '',
            region: 'CENTRAL',
            subchannel: '',
            supervisor: 'Carlos Gómez',
            staterl: ''
        },
        {
            timeago: '',
            activationdate: '15 - Julio -21',
            sds: '',
            postype: '',
            pos: <div><input type="checkbox" className="checkbox-table-dashboard" id="dash-check3" /><label for="dash-check3" /></div>,
            force: '',
            nim: <span style={{ color: '#036402' }}>PORTA</span>,
            simcard: '',
            simcardcellar: '',
            region: 'CENTRAL',
            subchannel: '',
            supervisor: 'Carlos Gómez',
            staterl: ''
        },
        {
            timeago: '',
            activationdate: '15 - Julio -21',
            sds: '',
            postype: '',
            pos: <div><input type="checkbox" className="checkbox-table-dashboard" id="dash-check1" /><label for="dash-check1" /></div>,
            force: '',
            nim: <span style={{ color: '#036402' }}>PORTA</span>,
            simcard: '',
            simcardcellar: '',
            region: 'CENTRAL',
            subchannel: '',
            supervisor: 'Carlos Gómez',
            staterl: ''
        },
        {
            timeago: '',
            activationdate: '15 - Julio -21',
            sds: '',
            postype: '',
            pos: <div><input type="checkbox" className="checkbox-table-dashboard" id="dash-check2" /><label for="dash-check2" /></div>,
            force: '',
            nim: <span style={{ color: '#036402' }}>PORTA</span>,
            simcard: '',
            simcardcellar: '',
            region: 'CENTRAL',
            subchannel: '',
            supervisor: 'Carlos Gómez',
            staterl: ''
        },
        {
            timeago: '',
            activationdate: '15 - Julio -21',
            sds: '',
            postype: '',
            pos: <div><input type="checkbox" className="checkbox-table-dashboard" id="dash-check3" /><label for="dash-check3" /></div>,
            force: '',
            nim: <span style={{ color: '#036402' }}>PORTA</span>,
            simcard: '',
            simcardcellar: '',
            region: 'CENTRAL',
            subchannel: '',
            supervisor: 'Carlos Gómez',
            staterl: ''
        },
        {
            timeago: '',
            activationdate: '15 - Julio -21',
            sds: '',
            postype: '',
            pos: <div><input type="checkbox" className="checkbox-table-dashboard" id="dash-check1" /><label for="dash-check1" /></div>,
            force: '',
            nim: <span style={{ color: '#036402' }}>PORTA</span>,
            simcard: '',
            simcardcellar: '',
            region: 'CENTRAL',
            subchannel: '',
            supervisor: 'Carlos Gómez',
            staterl: ''
        },
        {
            timeago: '',
            activationdate: '15 - Julio -21',
            sds: '',
            postype: '',
            pos: <div><input type="checkbox" className="checkbox-table-dashboard" id="dash-check2" /><label for="dash-check2" /></div>,
            force: '',
            nim: <span style={{ color: '#036402' }}>PORTA</span>,
            simcard: '',
            simcardcellar: '',
            region: 'CENTRAL',
            subchannel: '',
            supervisor: 'Carlos Gómez',
            staterl: ''
        },
        {
            timeago: '',
            activationdate: '15 - Julio -21',
            sds: '',
            postype: '',
            pos: <div><input type="checkbox" className="checkbox-table-dashboard" id="dash-check3" /><label for="dash-check3" /></div>,
            force: '',
            nim: <span style={{ color: '#036402' }}>PORTA</span>,
            simcard: '',
            simcardcellar: '',
            region: 'CENTRAL',
            subchannel: '',
            supervisor: 'Carlos Gómez',
            staterl: ''
        },
        {
            timeago: '',
            activationdate: '15 - Julio -21',
            sds: '',
            postype: '',
            pos: <div><input type="checkbox" className="checkbox-table-dashboard" id="dash-check1" /><label for="dash-check1" /></div>,
            force: '',
            nim: <span style={{ color: '#036402' }}>PORTA</span>,
            simcard: '',
            simcardcellar: '',
            region: 'CENTRAL',
            subchannel: '',
            supervisor: 'Carlos Gómez',
            staterl: ''
        },
        {
            timeago: '',
            activationdate: '15 - Julio -21',
            sds: '',
            postype: '',
            pos: <div><input type="checkbox" className="checkbox-table-dashboard" id="dash-check2" /><label for="dash-check2" /></div>,
            force: '',
            nim: <span style={{ color: '#036402' }}>PORTA</span>,
            simcard: '',
            simcardcellar: '',
            region: 'CENTRAL',
            subchannel: '',
            supervisor: 'Carlos Gómez',
            staterl: ''
        },
        {
            timeago: '',
            activationdate: '15 - Julio -21',
            sds: '',
            postype: '',
            pos: <div><input type="checkbox" className="checkbox-table-dashboard" id="dash-check3" /><label for="dash-check3" /></div>,
            force: '',
            nim: <span style={{ color: '#036402' }}>PORTA</span>,
            simcard: '',
            simcardcellar: '',
            region: 'CENTRAL',
            subchannel: '',
            supervisor: 'Carlos Gómez',
            staterl: ''
        },
        {
            timeago: '',
            activationdate: '15 - Julio -21',
            sds: '',
            postype: '',
            pos: <div><input type="checkbox" className="checkbox-table-dashboard" id="dash-check1" /><label for="dash-check1" /></div>,
            force: '',
            nim: <span style={{ color: '#036402' }}>PORTA</span>,
            simcard: '',
            simcardcellar: '',
            region: 'CENTRAL',
            subchannel: '',
            supervisor: 'Carlos Gómez',
            staterl: ''
        },
        {
            timeago: '',
            activationdate: '15 - Julio -21',
            sds: '',
            postype: '',
            pos: <div><input type="checkbox" className="checkbox-table-dashboard" id="dash-check2" /><label for="dash-check2" /></div>,
            force: '',
            nim: <span style={{ color: '#036402' }}>PORTA</span>,
            simcard: '',
            simcardcellar: '',
            region: 'CENTRAL',
            subchannel: '',
            supervisor: 'Carlos Gómez',
            staterl: ''
        },
        {
            timeago: '',
            activationdate: '15 - Julio -21',
            sds: '',
            postype: '',
            pos: <div><input type="checkbox" className="checkbox-table-dashboard" id="dash-check3" /><label for="dash-check3" /></div>,
            force: '',
            nim: <span style={{ color: '#036402' }}>PORTA</span>,
            simcard: '',
            simcardcellar: '',
            region: 'CENTRAL',
            subchannel: '',
            supervisor: 'Carlos Gómez',
            staterl: ''
        },
        {
            timeago: '',
            activationdate: '15 - Julio -21',
            sds: '',
            postype: '',
            pos: <div><input type="checkbox" className="checkbox-table-dashboard" id="dash-check1" /><label for="dash-check1" /></div>,
            force: '',
            nim: <span style={{ color: '#036402' }}>PORTA</span>,
            simcard: '',
            simcardcellar: '',
            region: 'CENTRAL',
            subchannel: '',
            supervisor: 'Carlos Gómez',
            staterl: ''
        },
        {
            timeago: '',
            activationdate: '15 - Julio -21',
            sds: '',
            postype: '',
            pos: <div><input type="checkbox" className="checkbox-table-dashboard" id="dash-check2" /><label for="dash-check2" /></div>,
            force: '',
            nim: <span style={{ color: '#036402' }}>PORTA</span>,
            simcard: '',
            simcardcellar: '',
            region: 'CENTRAL',
            subchannel: '',
            supervisor: 'Carlos Gómez',
            staterl: ''
        },
        {
            timeago: '',
            activationdate: '15 - Julio -21',
            sds: '',
            postype: '',
            pos: <div><input type="checkbox" className="checkbox-table-dashboard" id="dash-check3" /><label for="dash-check3" /></div>,
            force: '',
            nim: <span style={{ color: '#036402' }}>PORTA</span>,
            simcard: '',
            simcardcellar: '',
            region: 'CENTRAL',
            subchannel: '',
            supervisor: 'Carlos Gómez',
            staterl: ''
        },
        {
            timeago: '',
            activationdate: '15 - Julio -21',
            sds: '',
            postype: '',
            pos: <div><input type="checkbox" className="checkbox-table-dashboard" id="dash-check1" /><label for="dash-check1" /></div>,
            force: '',
            nim: <span style={{ color: '#036402' }}>PORTA</span>,
            simcard: '',
            simcardcellar: '',
            region: 'CENTRAL',
            subchannel: '',
            supervisor: 'Carlos Gómez',
            staterl: ''
        },
        {
            timeago: '',
            activationdate: '15 - Julio -21',
            sds: '',
            postype: '',
            pos: <div><input type="checkbox" className="checkbox-table-dashboard" id="dash-check2" /><label for="dash-check2" /></div>,
            force: '',
            nim: <span style={{ color: '#036402' }}>PORTA</span>,
            simcard: '',
            simcardcellar: '',
            region: 'CENTRAL',
            subchannel: '',
            supervisor: 'Carlos Gómez',
            staterl: ''
        },
        {
            timeago: '',
            activationdate: '15 - Julio -21',
            sds: '',
            postype: '',
            pos: <div><input type="checkbox" className="checkbox-table-dashboard" id="dash-check3" /><label for="dash-check3" /></div>,
            force: '',
            nim: <span style={{ color: '#036402' }}>PORTA</span>,
            simcard: '',
            simcardcellar: '',
            region: 'CENTRAL',
            subchannel: '',
            supervisor: 'Carlos Gómez',
            staterl: ''
        },
        {
            timeago: '',
            activationdate: '15 - Julio -21',
            sds: '',
            postype: '',
            pos: <div><input type="checkbox" className="checkbox-table-dashboard" id="dash-check1" /><label for="dash-check1" /></div>,
            force: '',
            nim: <span style={{ color: '#036402' }}>PORTA</span>,
            simcard: '',
            simcardcellar: '',
            region: 'CENTRAL',
            subchannel: '',
            supervisor: 'Carlos Gómez',
            staterl: ''
        },
        {
            timeago: '',
            activationdate: '15 - Julio -21',
            sds: '',
            postype: '',
            pos: <div><input type="checkbox" className="checkbox-table-dashboard" id="dash-check2" /><label for="dash-check2" /></div>,
            force: '',
            nim: <span style={{ color: '#036402' }}>PORTA</span>,
            simcard: '',
            simcardcellar: '',
            region: 'CENTRAL',
            subchannel: '',
            supervisor: 'Carlos Gómez',
            staterl: ''
        },
        {
            timeago: '',
            activationdate: '15 - Julio -21',
            sds: '',
            postype: '',
            pos: <div><input type="checkbox" className="checkbox-table-dashboard" id="dash-check3" /><label for="dash-check3" /></div>,
            force: '',
            nim: <span style={{ color: '#036402' }}>PORTA</span>,
            simcard: '',
            simcardcellar: '',
            region: 'CENTRAL',
            subchannel: '',
            supervisor: 'Carlos Gómez',
            staterl: ''
        },
    ]
}

export const info = {
    headerInfo,
    selectRangoInfo,
    selectBranchInfo,
    selectChannelInfo,
    selectSubChannelInfo,
    selectSupervisorInfo,
    selectSellerInfo,
    selectPOSInfo,
    selectAgentsInfo,
    tableInfo
};