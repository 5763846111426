import React,{useState,useRef} from "react";
import './UploadDocuments.scss'

//components
import InputSearch from "../../../components/InputSearch/InputSearch";
import HeadersSection from "../../../components/book/headersSection/HeadersSection";
import Swal from "sweetalert2";
//assets
import AlertIcon from '../../../img/icons/alert.svg' 
import searchIcon from '../../../img/icons/search.svg';

const UploadDocuments = () => {
    const [viewSearch, setViewSearch] = useState(false)
    const [urlOrFile, setUrlOrFile] = useState(null);
    const [urlOrFile2, setUrlOrFile2] = useState(null);
    const [fileImage, setFileImage] = useState(null)
    const [urlImage,setUrlImage] = useState(null)
    const [modal,setModal] = useState(false);
    const inputFile = useRef();
    const inputFile2 = useRef();
    // cargar imagen
    const onFileChange = (event) => {
        let url = URL.createObjectURL(event.target.files[0]);
        setUrlOrFile(url);
        setFileImage(event.target.files[0]);
        setUrlImage(event.target.files[0]);
    };
    const onFileChange2 = (event) => {
        let url = URL.createObjectURL(event.target.files[0]);
        setUrlOrFile2(url);
        setFileImage(event.target.files[0]);
        setUrlImage(event.target.files[0]);
    };
    const clean =()=>
    {
        setUrlOrFile(null);
        setUrlOrFile2(null);
    }
    const swallAlertButton =()=>
    {
        alert(document.getElementById('btnWith').value);

    }
    const swallAlert=()=>
    {
          const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
              confirmButton: 'btn-right-upload-documents',
              cancelButton: 'btn-left-upload-documents'
            },
            buttonsStyling: false
          })
          
          swalWithBootstrapButtons.fire({
            title: '<div class="title-swal-upload-documents">¿Imprimir CI con datos?</div>',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Imprimir con datos',
            cancelButtonText: 'Imprimir sin datos por defecto',
            reverseButtons: true
          }).then((result) => {
            if (result.isConfirmed) {
              swalWithBootstrapButtons.fire(
                'Imprimir con datos',
              )
            } else if (
              /* Read more about handling dismissals below */
              result.dismiss === Swal.DismissReason.cancel
            ) {
              swalWithBootstrapButtons.fire(
                'Imprimir sin datos por defecto',
                
              )
            }
          })
    }
    
    return (<>
        <div className="UploadDocuments">
                <HeadersSection
                    title={<div>Subir / Imprimir documentos</div>}
                    exeptions={{}}
                />
                <div className="div-container-stand">
                    <div className="top-container">
                        <div className="input-search">
                            <InputSearch placeholder={"NRO RENDICIÓN"} ></InputSearch>
                            <div className="button-container">
                                <div>
                                    <button className="btn-search" onClick={() => setViewSearch(true)}><img src={searchIcon}></img></button>
                                </div>
                                <div className="btn-clean-container">
                                    <button className="btn-clean" onClick={() => {setViewSearch(false);clean()}}>Limpiar busqueda</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {viewSearch?
                        <>
                            <div className="data-container">
                                <div className="element">
                                    <div className="title">DOCUMENTO:</div>
                                    <div className="info">XXXXXXXX</div>
                                </div>
                                <div className="element">
                                    <div className="title">FECHA DE NACIMIENTO:</div>
                                    <div className="info">XXXXXXXX</div>
                                </div>
                                <div className="element">
                                    <div className="title">TITULAR:</div>
                                    <div className="info">XXXXXXXX</div>
                                </div>
                                <div className="element">
                                    <div className="title">EDAD:</div>
                                    <div className="info">XXXXXXXX</div>
                                </div>
                            </div>
                            {/* body */}
                            <div className="header-body">
                                Cedula de identidad: 652549
                            </div>
                            {/* subir imagen */}
                            <div className="img-upload-container">
                                <div>
                                    {urlOrFile===null?
                                    <>
                                
                                        
                                            <div className="border-container-image">
                                                <div>
                                                    <div className="title">Seleccionar la imagen del frente de CI</div>
                                                    <div className="container-btn-upload">
                                                        <button className="btn-upload" onClick={() => {inputFile.current.click();}}>Seleccionar archivo</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="">
                                                <img src={urlOrFile} />
                                                <input
                                                type={"file"}
                                                accept="image/*"
                                                ref={inputFile}
                                                className="hidden"
                                                onChange={onFileChange}
                                                />
                                            </div>
                                        
                                    </>
                                    :
                                    <>
                                        <div className="charge-img">
                                            <img src={urlOrFile}  />
                                            <input
                                            type={"file"}
                                            accept="image/*"
                                            ref={inputFile}
                                            className="hidden"
                                            onChange={onFileChange}
                                            />
                                        </div>
                                    </>
                                    }
                                </div>
                            {/* imagen 2 */}
                                <div>
                                    {urlOrFile2===null?
                                    <>
                                        <div className="border-container-image">
                                            <div>
                                                <div className="title">Seleccionar la imagen del frente de CI</div>
                                                <div className="container-btn-upload">
                                                    <button className="btn-upload" onClick={() => {inputFile2.current.click();}}>Seleccionar archivo</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="">
                                            <img src={urlOrFile2} />
                                            <input
                                            type={"file"}
                                            accept="image/*"
                                            ref={inputFile2}
                                            className="hidden"
                                            onChange={onFileChange2}
                                            />
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className="charge-img">
                                            <img src={urlOrFile2}  />
                                            <input
                                            type={"file"}
                                            accept="image/*"
                                            ref={inputFile2}
                                            className="hidden"
                                            onChange={onFileChange2}
                                            />
                                        </div>
                                    </>
                                    }
                                </div>
                                {/* botones para imprimir o cambiar */}
                                <div>
                                    <div className="btns-container">
                                        {urlOrFile&&urlOrFile2 !== null?
                                            <button className="btn" style={{background:"#036402"}} onClick={()=>{setModal(true);swallAlert()}}>Imprimir</button>
                                         :
                                            <button className="btn" style={{background:"#969696"}}>Imprimir</button>
                                        }
                                       
                                        <button className="btn" style={{background:"#308ac9"}} onClick={()=>clean()}>Cambiar</button>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        null
                    }
                </div>
                
                
        </div>
        
    </>);
}
export default UploadDocuments;