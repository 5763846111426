import React, { useState , useEffect} from "react";
import ReactDOM from "react-dom";
import HeadersSection from "../../../../components/book/headersSection/HeadersSection";
import AbsoluteSelect from "../../../../components/book/absoluteSelect/AbsoluteSelect";
import Table from "../../../../components/activations/table/Table_hf";
import TableAgo from "../../../../components/activations/table/TableAgo";
import FooterSimple from "../../../../components/book/footerSimple/FooterSimple";
import ThirdTable from "../../../../components/activations/table/ThirdTable"
import { info } from '../../../../../mockups/dashboard';
import { infoAct } from '../../../../../mockups/activations';

import './HomeFiles.scss';
import 'primeflex/primeflex.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import axios from "axios";

const Management = ({routes}) => {
    const [openSelect, setOpenSelect] = useState(false);
    const [showSubSelects, setShowSubSelects] = useState(false);

    const [dataSelect, setDataSelect] = useState([{}])

    const [idSelect, setIdSelect] = useState('')
    const [firstTable, setFirstTable] = useState([{}])
    const [secondTable, setSecondTable] = useState([{}])
    const [thirdTable, setThirdTable] = useState([{}])
    const [openClose, setOpenClose] = useState(true);

    var Routes
    useEffect(() => {

        const getSelect = async () => {

            let getData = Routes.getSelect;
            const result = await axios(getData,);
            setDataSelect(result.data.data.period)

        }

        const getTable = async () => {

            let getData = Routes.dataTable;
            const result = await axios(getData,);
            setFirstTable(result.data.data.first_table)
            setSecondTable(result.data.data.second_table)
            setThirdTable(result.data.data.third_table)

        }
        getTable()

        const getDataRelated  = async() => {
            let mainRoute = document.getElementsByName('routes')[0].getAttribute('content');
            const result = await axios(mainRoute,);
            Routes = result.data
            getSelect()
            getTable()
        }

        getDataRelated()



    },[]);

   //  useEffect(()=>{
   //
   //      //var Routes2
   //      if(idSelect !== ''){
   //          const getTable = async () => {
   //
   //              let getData = routes.dataTable;
   //              const result = await axios(getData,);
   //              setFirstTable(result.data.data.first_table)
   //              setSecondTable(result.data.data.second_table)
   //              setThirdTable(result.data.data.third_table)
   //
   //          }
   //          getTable()
   //
   //      }
   //
   // },[idSelect])
   //
  


    return (
        <div className="container-activations">
            <HeadersSection
                title={'Gerencía y reportería'}
                exeptions={{}}
                date={info.headerInfo.date}
            />


            <div className="div-selects-activations homeFiles">
                <div className="div-container-stand">
                        <div className="button-header pl-3 pr-3">
                            <div className="pb-3 btn-header-responcive">
                                {/* <div className="div-details-activations"> */}
                                {openClose?<>
                                    <button className="buttons btn-big btn-blue" onClick={() => setOpenClose(false)}>Explayar</button>
                                </>:<>
                                    <button className="buttons btn-big btn-blue" onClick={() => setOpenClose(true)}>Minimizar</button>
                                </>}
                                    
                                {/* </div> */}
                            </div>
                        </div>
                    
                    <Table
                        state = {openClose}
                        arrayHeader={[
                            {field:"field1", colspan:2, className:"bold-600"},
                            {field:"field2", colspan:2, className:"bold-600"},
                            {field:"field3", colspan:2, className:"bold-600"},
                            {field:"field4"},
                            {field:"field5"},
                            {field:"field6"},
                            {field:"field7"},
                            {field:"field8"},
                            {field:"field9"},
                            {field:"field10"},
                            {field:"field11"},
                            {field:"field12"},
                            {field:"field13"}
                        ]}
                        arrayItems={[
                            {   
                               position:{
                                title:"central",
                                sumatory:[
                                    "6000",
                                    "6000",
                                    "6000",
                                    "6000",
                                    "6000",
                                    "6000",
                                    "6000",
                                    "6000",
                                    "6000",
                                    "6000",
                                    "6000",
                                    "6000",
                                  
                                ],
                                        branchOffice1:{
                                            title:"Sucursal 1",
                                                subtitles:{
                                                    sub1:{
                                                        title:"4x4",
                                                        data:[
                                                            "1000",
                                                            "1000",
                                                            "1000",
                                                            "1000",
                                                            "1000",
                                                            "1000",
                                                            "1000",
                                                            "1000",
                                                            "1000",
                                                            "1000",
                                                            "1000",
                                                            "1000",
                                                           
                                                        ],
                                                    },
                                                    sub2:{
                                                        title:"PDA",
                                                        data:[
                                                            "1000",
                                                            "1000",
                                                            "1000",
                                                            "1000",
                                                            "1000",
                                                            "1000",
                                                            "1000",
                                                            "1000",
                                                            "1000",
                                                            "1000",
                                                            "1000",
                                                            "1000",
                                                            
                                                        ]
                                                    },
                                                    sub3:{
                                                        title:"LYG",
                                                        data:[
                                                            "1000",
                                                            "1000",
                                                            "1000",
                                                            "1000",
                                                            "1000",
                                                            "1000",
                                                            "1000",
                                                            "1000",
                                                            "1000",
                                                            "1000",
                                                            "1000",
                                                            "1000",
                                                           
                                                        ]
                                                    },
                                                },
                                    },
                                    branchOffice2:{
                                        title:"Sucursal 1",
                                            subtitles:{
                                                sub1:{
                                                    title:"4x4",
                                                    data:[
                                                        "1000",
                                                        "1000",
                                                        "1000",
                                                        "1000",
                                                        "1000",
                                                        "1000",
                                                        "1000",
                                                        "1000",
                                                        "1000",
                                                        "1000",
                                                        "1000",
                                                        "1000",
                                                       
                                                    ]
                                                },
                                                sub2:{
                                                    title:"PDA",
                                                    data:[
                                                        "1000",
                                                        "1000",
                                                        "1000",
                                                        "1000",
                                                        "1000",
                                                        "1000",
                                                        "1000",
                                                        "1000",
                                                        "1000",
                                                        "1000",
                                                        "1000",
                                                        "1000",
                                                        
                                                    ]
                                                },
                                                sub3:{
                                                    title:"LYG",
                                                    data:[
                                                        "1000",
                                                        "1000",
                                                        "1000",
                                                        "1000",
                                                        "1000",
                                                        "1000",
                                                        "1000",
                                                        "1000",
                                                        "1000",
                                                        "1000",
                                                        "1000",
                                                        "1000",
                                                        
                                                    ]
                                                },
                                            },
                                    },
                               },
                            },
                            {   
                                position:{
                                sumatory:[
                                    "6000",
                                    "6000",
                                    "6000",
                                    "6000",
                                    "6000",
                                    "6000",
                                    "6000",
                                    "6000",
                                    "6000",
                                    "6000",
                                    "6000",
                                    "6000",
                                    
                                ],
                                 title:"Centro",
                                         branchOffice1:{
                                             title:"Sucursal 1",
                                                 subtitles:{
                                                     sub1:{
                                                         title:"4x4",
                                                         data:[
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                            
                                                         ]
                                                     },
                                                     sub2:{
                                                         title:"PDA",
                                                         data:[
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             
                                                         ]
                                                     },
                                                     sub3:{
                                                         title:"LYG",
                                                         data:[
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                            
                                                         ]
                                                     },
                                                 },
                                     },
                                     branchOffice2:{
                                         title:"Sucursal 1",
                                             subtitles:{
                                                 sub1:{
                                                     title:"4x4",
                                                     data:[
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                        
                                                     ]
                                                 },
                                                 sub2:{
                                                     title:"PDA",
                                                     data:[
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         
                                                     ]
                                                 },
                                                 sub3:{
                                                     title:"LYG",
                                                     data:[
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         
                                                     ]
                                                 },
                                             },
                                     },
                                },
                             },
                             {   
                                position:{
                                sumatory:[
                                    "6000",
                                    "6000",
                                    "6000",
                                    "6000",
                                    "6000",
                                    "6000",
                                    "6000",
                                    "6000",
                                    "6000",
                                    "6000",
                                    "6000",
                                    "6000",
                                    
                                ],
                                 title:"Este",
                                         branchOffice1:{
                                             title:"Sucursal 1",
                                                 subtitles:{
                                                     sub1:{
                                                         title:"4x4",
                                                         data:[
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                            
                                                         ]
                                                     },
                                                     sub2:{
                                                         title:"PDA",
                                                         data:[
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             
                                                         ]
                                                     },
                                                     sub3:{
                                                         title:"LYG",
                                                         data:[
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                            
                                                         ]
                                                     },
                                                 },
                                     },
                                     branchOffice2:{
                                         title:"Sucursal 1",
                                             subtitles:{
                                                 sub1:{
                                                     title:"4x4",
                                                     data:[
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                        
                                                     ]
                                                 },
                                                 sub2:{
                                                     title:"PDA",
                                                     data:[
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         
                                                     ]
                                                 },
                                                 sub3:{
                                                     title:"LYG",
                                                     data:[
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         
                                                     ]
                                                 },
                                             },
                                     },
                                },
                             },
                             {   
                                position:{
                                sumatory:[
                                    "6000",
                                    "6000",
                                    "6000",
                                    "6000",
                                    "6000",
                                    "6000",
                                    "6000",
                                    "6000",
                                    "6000",
                                    "6000",
                                    "6000",
                                    "6000",
                                    
                                ],
                                 title:"Sur",
                                         branchOffice1:{
                                             title:"Sucursal 1",
                                                 subtitles:{
                                                     sub1:{
                                                         title:"4x4",
                                                         data:[
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                            
                                                         ]
                                                     },
                                                     sub2:{
                                                         title:"PDA",
                                                         data:[
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             
                                                         ]
                                                     },
                                                     sub3:{
                                                         title:"LYG",
                                                         data:[
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                            
                                                         ]
                                                     },
                                                 },
                                     },
                                     branchOffice2:{
                                         title:"Sucursal 1",
                                             subtitles:{
                                                 sub1:{
                                                     title:"4x4",
                                                     data:[
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                        
                                                     ]
                                                 },
                                                 sub2:{
                                                     title:"PDA",
                                                     data:[
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         
                                                     ]
                                                 },
                                                 sub3:{
                                                     title:"LYG",
                                                     data:[
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         
                                                     ]
                                                 },
                                             },
                                     },
                                },
                             },
                             {   
                                position:{
                                 title:"Norte",
                                 sumatory:[
                                    "6000",
                                    "6000",
                                    "6000",
                                    "6000",
                                    "6000",
                                    "6000",
                                    "6000",
                                    "6000",
                                    "6000",
                                    "6000",
                                    "6000",
                                    "6000",
                                    
                                ],
                                         branchOffice1:{
                                             title:"Sucursal 1",
                                                 subtitles:{
                                                     sub1:{
                                                         title:"4x4",
                                                         data:[
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                            
                                                         ]
                                                     },
                                                     sub2:{
                                                         title:"PDA",
                                                         data:[
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             
                                                         ]
                                                     },
                                                     sub3:{
                                                         title:"LYG",
                                                         data:[
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                             "1000",
                                                            
                                                         ]
                                                     },
                                                 },
                                     },
                                     branchOffice2:{
                                         title:"Sucursal 1",
                                             subtitles:{
                                                 sub1:{
                                                     title:"4x4",
                                                     data:[
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                        
                                                     ]
                                                 },
                                                 sub2:{
                                                     title:"PDA",
                                                     data:[
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         
                                                     ]
                                                 },
                                                 sub3:{
                                                     title:"LYG",
                                                     data:[
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         "1000",
                                                         
                                                     ]
                                                 },
                                             },
                                     },
                                },
                             },
                        ]}
                        dates={["total a la fecha"]}
                    />
                    {/* <table>
                       
                        
                        <tr className="header">
                            <td>Región</td>
                            <td className="title"><div>PREPAGO</div></td>
                            <td className="title"><div>POSPAGO</div></td>
                            <td className="title"><div>DTH</div></td>
                            <td className="title"><div>GPON</div></td>
                        </tr>
                    </table> */}
                </div>
            </div>


            <div className="div-container-stand homeFiles">
                <div className="p-grid">
                    <div className="p-col p-md-6 p-lg-3 p-sm-12 pl-3 pr-3">
                        <div className="bot-button botcolor-de9000">
                            Consultar Legajos
                        </div>
                    </div> 
                    <div className="p-col p-md-6 p-lg-3 p-sm-12 pl-3 pr-3">
                        <div className="bot-button botcolor-036402">
                            Recibir Legajos
                        </div>
                    </div> 
                    <div className="p-col p-md-6 p-lg-3 p-sm-12 pl-3 pr-3">
                        <div className="bot-button botcolor-308ac9">
                            Procesar Legajos
                        </div>
                    </div> 
                    <div className="p-col p-md-6 p-lg-3 p-sm-12 pl-3 pr-3">
                        <div className="bot-button botcolor-0600a8">
                            Reportes
                        </div>
                    </div>
                </div>
            </div>
            <FooterSimple />
        </div>

    );
};

export default Management;

