import React, { useState } from "react";
import "./SearchFiles.scss";
import check from "../../../../img/icons/check.svg";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

export default function Observations() {
  return (
    <div className="observations-contain">
      <ContainCboxs />
      <ContainCboxs />
    </div>
  );
}

const Cbox = ({ title, cboxNum, onClick }) => {
  return (
    <div className="contain">
      <div className="cbox" onClick={onClick}>
        {cboxNum === true ? <img src={check} /> : ""}
      </div>
      <div>{title}</div>
    </div>
  );
};

const ContainCboxs = () => {
  const [cbox1, setCbox1] = useState(false);
  const [cbox2, setCbox2] = useState(false);
  const [cbox3, setCbox3] = useState(false);
  const [cbox4, setCbox4] = useState(false);
  const [cbox5, setCbox5] = useState(false);
  const [cbox6, setCbox6] = useState(false);
  const [cbox7, setCbox7] = useState(false);
  const [cbox8, setCbox8] = useState(false);
  const [cbox9, setCbox9] = useState(false);
  const [cbox10, setCbox10] = useState(false);
  const [cbox11, setCbox11] = useState(false);
  const [cbox12, setCbox12] = useState(false);
  const [cbox13, setCbox13] = useState(false);
  const [cbox14, setCbox14] = useState(false);
  const [cbox15, setCbox15] = useState(false);
  const [cbox16, setCbox16] = useState(false);
  const [cbox17, setCbox17] = useState(false);
  const [cbox18, setCbox18] = useState(false);
  const [cbox19, setCbox19] = useState(false);

  const handleClick = () => {
    MySwal.fire({
      width: "500px",
      height: "350px",
      title: (
        <p style={{ fontWeight: "bold", fontSize: "20px" }}>
          Se guardo la observación
        </p>
      ),
      icon: "success",
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonColor: "#036402",
      cancelButtonText: "Aceptar",
    });
  };

  return (
    <div className="contain-cbox">
      <div className="title">OBSERVACIONES LEGAJOS CLAROS</div>
      <div className="contain-cboxs">
        <div className="contain-cbox1">
          <Cbox
            title="Firma no coinciden"
            cboxNum={cbox1}
            onClick={(e) => setCbox1(!cbox1)}
          />
          <Cbox
            title="Falta firma o firma incompleta cliente"
            cboxNum={cbox2}
            onClick={(e) => setCbox2(!cbox2)}
          />
          <Cbox
            title="Falta firma vendedor"
            cboxNum={cbox3}
            onClick={(e) => setCbox3(!cbox3)}
          />
          <Cbox
            title="Cliente no firma falta firma de testigo"
            cboxNum={cbox4}
            onClick={(e) => setCbox4(!cbox4)}
          />
          <Cbox
            title="diferente color bolígrafo"
            cboxNum={cbox5}
            onClick={(e) => setCbox5(!cbox5)}
          />
          <Cbox
            title="Cliente menor de edad"
            cboxNum={cbox6}
            onClick={(e) => setCbox6(!cbox6)}
          />
          <Cbox
            title="Tachaduras / Borrones y corrección"
            cboxNum={cbox7}
            onClick={(e) => setCbox7(!cbox7)}
          />
          <Cbox
            title="Rechazado"
            cboxNum={cbox8}
            onClick={(e) => setCbox8(!cbox8)}
          />
          <Cbox
            title="Línea no correspondiente a titular"
            cboxNum={cbox9}
            onClick={(e) => setCbox9(!cbox9)}
          />
          <Cbox
            title="LLenado Legajo incorrecto"
            cboxNum={cbox10}
            onClick={(e) => setCbox10(!cbox10)}
          />
          
        </div>
        <div className="contain-cbox1">
          <Cbox
            title="CI Legible"
            cboxNum={cbox11}
            onClick={(e) => setCbox11(!cbox11)}
          />
          <Cbox
            title="CI Adulterado"
            cboxNum={cbox12}
            onClick={(e) => setCbox12(!cbox12)}
          />
          <Cbox
            title="Frente o dorso de CI no corresponde"
            cboxNum={cbox13}
            onClick={(e) => setCbox13(!cbox13)}
          />
          <Cbox
            title="CI Vencida mayor a 6 meses"
            cboxNum={cbox14}
            onClick={(e) => setCbox14(!cbox14)}
          />
          <Cbox
            title="Copia de copoa documento"
            cboxNum={cbox15}
            onClick={(e) => setCbox15(!cbox15)}
          />
          <Cbox
            title="NRO de CI adulterado"
            cboxNum={cbox16}
            onClick={(e) => setCbox16(!cbox16)}
          />
          <Cbox
            title="Legajo incompleto"
            cboxNum={cbox17}
            onClick={(e) => setCbox17(!cbox17)}
          />
          <Cbox
            title="Línea no portada"
            cboxNum={cbox18}
            onClick={(e) => setCbox18(!cbox18)}
          />
          <Cbox
            title="Reconstrucción de legajo"
            cboxNum={cbox19}
            onClick={(e) => setCbox19(!cbox19)}
          />
        </div>
      </div>
      <>
            <div className="contain-textarea">
              <div className="title-observation">Observaciones:</div>
              <textarea className="textarea" />
            </div>
            <div className="observations-contain-buttons">
              <button className="button-blue">Cancelar</button>
              <button className="button-green" onClick={handleClick}>
                Guardar
              </button>
            </div>
          </>
    </div>
  );
};
