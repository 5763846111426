import React, { useState } from "react";
import "./FilesReceive.scss";
import HeadersSection from "../../../../components/book/headersSection/HeadersSection";
import InputSearch from "../../../../components/InputSearch/InputSearch";
import searchIcon from "../../../../img/icons/search.svg";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import data from "./data.json";
import show from "../../../../img/icons/eye.png";
import pencil from "../../../../img/icons/pencil.svg";
import trash from "../../../../img/icons/trash.svg";
import deleteIcon from "../../../../img/icons/delete.png";
import FilesReceiveBody from './FilesReceiveAnexo'
import Cis from './FilesReceiveCi'

const FilesReceive = () =>{

  const NIM = (row) => {
    return (
      <div>
        {row.nim == 1 ? (
          <div style={{ color: "#036402", fontWeight: "bold" }}>PORTA</div>
        ) : (
          ""
        )}
      </div>
    );
  };

  const Ci = (row) => {
    return (<Cis/>)
  };

  const Anexos =  () => {
 
    return (<FilesReceiveBody/>);
  };

 

  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const Actions = (row) => {
    return (
      <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
        <img
          src={show}
          alt="eye"
          style={{ width: "20px", height: "20px" }}
          onClick={(e) => setIsOpen(!isOpen)}
        />
        <img
          onClick={(e) => setIsOpen2(!isOpen2)}
          src={pencil}
          alt="pencil"
          style={{ width: "15px", height: "15px" }}
        />
        <img
          onClick={(e) => setIsOpen3(!isOpen3)}
          src={trash}
          alt="trash"
          style={{ width: "15px", height: "15px" }}
        />
      </div>
    );
  };

  return (
    <div>
      <HeadersSection
        title={"Recibir Legajos"}
        exeptions={{}}
        date={"Viernes 30 de Julio del 2021 | Semana: 18 | Cierre de mes: 1"}
      />
      <div className="files-receive-contain">
        <div className="contain-header">
          <SelectReceive name="RECIBE" title="title" />
          <SelectReceive name="POS/ RESPONSABLE/ ENTREGA" title="title2" />
          <div className="contain-input">
            <span className="title">SUPERVISOR</span>
            <input
              type={"search"}
              placeholder="XXXXXXXXX"
              className="inputReceive"
            />
          </div>
        </div>
        <div className="contain-header">
          <div className="input-search">
            <InputSearch placeholder="NIM, SDS o SIMCARD"/>
            <div className="button-container">
              <button className="btn-search">
                <img src={searchIcon}></img>
              </button>
            </div>
          </div>
        </div>
        <div className="contain-datatable">
          <DataTable
            value={data}
            scrollable
            scrollHeight="400px"
            className="table-files-receive"
          >
            <Column field="nrofiles" header="NRO LEGAJOS"></Column>
            <Column field="sds" header="SDS"></Column>
            <Column field="canal" header="CANAL"></Column>
            <Column field="pos" header="POS"></Column>
            <Column field="nim" header="NIM" body={NIM}></Column>
            <Column field="plan" header="PLAN"></Column>
            <Column field="simcard" header="SIMCARD"></Column>
            <Column field="document" header="DOCUMENTO"></Column>
            <Column field="titular" header="TITULAR"></Column>
            <Column
              field="dateactivation"
              header="FECHA DE ACTIVACIÓN"
            ></Column>
            <Column field="state" header="ESTADO"></Column>
            <Column field="antiguedad" header="ANTIGUEDAD LEGAJO"></Column>
            <Column field="ci" header="CI" body={Ci}></Column>
            <Column field="riesgo" header="RIESGO"></Column>
            <Column field="maverick" header="MAVERICK"></Column>
            <Column field="anexos" header="ANEXOS" body={Anexos}></Column>
            <Column field="observations" header="OBSERVACIONES"></Column>
            <Column field="actions" header="ACCIONES" body={Actions}></Column>
          </DataTable>
        </div>
        <div className="contain-buttons">
          <button className="button-blue">Limpiar</button>
          <button className="button-green">Recepcionar</button>
        </div>
      </div>
      {isOpen === true ? (
        <>
          <div className="contain-modal"></div>
          <div className="modal">
            <div className="close" onClick={(e) => setIsOpen(!isOpen)}>
              X
            </div>
            <div className="title">Ver legajo</div>
            <div className="contain">
              <div className="contain-info">
                <span>NRO LEGAJO: 50</span>
                <span>CANAL: PREPAGO</span>
                <span>NIM: XXXXXX XXXXXX XXXXXX XXXXXX</span>
                <span>SIMCARD: XXXXXXXXXXXX</span>
                <span>TITULAR: XXXXXXXX</span>
                <span>ESTADO: NO</span>
                <span>CI: {Ci}</span>
                <span>MAVERICK: XXXXX</span>
                <span>OBSERVACIONES: Firma no coincide.</span>
              </div>
              <div className="contain-info">
                <span>SDS: 123456789</span>
                <span>POS: 234567</span>
                <span>PLAN: XXXXXX</span>
                <span>DOCUMENTO: XXXXX</span>
                <span>FECHA DE ACTIVACION: XX/XX/XXXX</span>
                <span>ANTIGUEDAD: XXXX</span>
                <span>RIESGO: XXXXXX</span>
                <span>ANEXOS: {Anexos}</span>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {isOpen2 === true ? (
        <>
          <div className="contain-modal"></div>
          <div className="modal2">
            <div className="title2">Observaciones</div>
            <div className="contain2">
                <select className="select">
                    <option>Selecciona una opción</option>
                    <option>Falta firma</option>
                    <option>Firma no coincide</option>
                    <option>Tachaduras</option>
                </select>
                <div className="contain-button">
                    <button className="button-white" onClick={(e) => setIsOpen2(!isOpen2)}>Cancelar</button>
                    <button className="button-blue"onClick={(e) => setIsOpen2(!isOpen2)}>Guardar</button>
                </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {isOpen3 === true ? (
        <>
          <div className="contain-modal"></div>
          <div className="modal2">
            <div><img src={deleteIcon} alt='' /></div>
            <div className="contain2">
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <span style={{fontWeight:'bold'}}>Se eliminara este legajo</span>
                    <span>"PREPAGO 985564122211"</span>
                </div>
                <div className="contain-button">
                    <button className="button-white" onClick={(e) => setIsOpen3(!isOpen3)}>Cancelar</button>
                    <button className="button-blue"onClick={(e) => setIsOpen3(!isOpen3)}>Eliminar</button>
                </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );

}

export default FilesReceive


const SelectReceive = ({ name, title }) => {
  return (
    <div className="contain-select">
      <span className={title}>{name}</span>
      <select className="select">
        <option>Seleccion</option>
      </select>
    </div>
  );
};
