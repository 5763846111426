import React from "react";
import './FooterTable.scss'
import csvIcon from "../../../../images/FileManagement/FilesForm/CSVicon.png";
import excelIcon from "../../../../images/FileManagement/FilesForm/EXCELicon.png";

 const FooterTableW=()=>  {
    return (
        <>
            <div className="footer-tableW">
                <div className="contain-footer-tableW">
                    <div className="titleW">Descargar como</div>
                    <div className="contain-iconW">
                    <img src={csvIcon} alt="CSV" className="iconW" />
                    </div>
                    <div className="contain-iconW">
                    <img src={excelIcon} alt="EXCEL" className="iconW" />
                    </div>
                </div>
            </div>
        </>
    );
  };
  export default FooterTableW;