import React from "react";
import Registro from "../NewPersonnel/Registro";
const ListaRegistro = ({ registro, eliminarContacto }) => {
  return (
    <div className="contactos">
      {registro.map((contacto) => {
        return (
          <Registro
            ID={contacto.id}
            tipo={contacto.tipo}
            numero={contacto.numero}
            eliminarContacto={eliminarContacto}
          />
        );
      })}
    </div>
  );
};

export default ListaRegistro;
