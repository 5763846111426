import React, { useState,useEffect } from 'react'
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import './GeneralModal.scss'


const GeneralModal = ({modalState,state,content}) => {
const close = () =>
{
    state(false);
}

useEffect(()=>{
    const bloked = document.getElementById('body_class')
    if(modalState === true)
    {
        bloked.style.overflow="hidden";
    }
    return()=>{bloked.style.overflow="scroll";} 
    
},[modalState])
  return (
    <>
    <div className='GeneralModal'>
    <div className='General-Modal_container'> 
            <div className='dt-modal2'>
                <div></div>
                <div className='form'>
                   {content}

                </div>
            </div>
           
    </div> 
    </div> 
    <div className='Modal__background' onClick={()=>{close()}}></div>
    </>
)}

export default GeneralModal