import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel, faFileWord } from "@fortawesome/free-solid-svg-icons";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import "./TableEditModal.scss";
const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

const TableEditModal= ({
  arrayItems,
  dataDateNow,
  porcent,
  download = false,
  dataDates,
}) => {
  const exportExcel = () => {
    const ws = XLSX.utils.json_to_sheet(arrayItems);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "table_" + new Date().getTime() + fileExtension);
  };

  // var info

  arrayItems.map((item) => {
    if (item.data) {
      item.data.map((item2) => {
        console.log(item2);
      });
    }
    if (item.subchannel) {
      item.subchannel.map((item3) => {
        console.log(item3.data);
      });
    }
  });

  var channels = arrayItems.map((item, index) => {
    let subchannel;

    if (item.subchannel) {
      subchannel = item.subchannel.map((item2, row) => {
        return (
          <>
            <div className={`rdtable ptn row-${index + 1}-item${row + 1}`}>
              <label>{item2.name}</label>
            </div>
          </>
        );
      });
    }

    if (index === 0) {
      return (
        <div className="widthtable row-1">
          <div className="date-main fatherstable-date">
            <label>{item.name}</label>
          </div>
        </div>
      );
    }

    if (!item[0]) {
      return (
        <div className={`widthtable row-${index + 1}`}>
          <div
            className="opacityColor fatherstable"
            style={{ backgroundColor: `${item.background_color}` }}
          >
            <label>{item.name}</label>
          </div>
          <div
            className="w100 divSUBTH"
            style={{ backgroundColor: `${item.background_color}` }}
          >
            {subchannel}
          </div>
        </div>
      );
    }
  });

  return (
    <div>
      <div className="table-activations">
        <div style={{ width: "100%", height: 80 }}>
          <div className="widthtable row-1">
            <div className="date-main fatherstable-date">
              <label>Fecha</label>
            </div>
          </div>

          <div className="widthtable row-2">
            <div className="prep-father fatherstable">
              <label>PREPAGO</label>
            </div>
            <div className="prep-child w100 divSUBTH">
              <div className="rdtable ptn row-2-item1">
                <label>PP</label>
              </div>
              <div className="rdtable ptn row-2-item2">
                <label>PORTA</label>
              </div>
              <div className="rdtable ptn row-2-item3">
                <label>BAM</label>
              </div>
              <div className="rdtable ptn row-2-item4">
                <label>T.Gral</label>
              </div>
            </div>
          </div>
          <div className="widthtable row-3">
            <div className="pos-father fatherstable">
              <label>POSTPAGO</label>
            </div>
            <div className="pos-child w100 divSUBTH">
              <div className="rdtable ptn row-3-item1">
                <label>Pospago</label>
              </div>
              <div className=" rdtable ptn row-3-item2">
                <label>PORTA</label>
              </div>
              <div className=" rdtable ptn row-3-item3">
                <label>BAM</label>
              </div>
              <div className=" rdtable ptn row-3-item4">
                <label>CORPO</label>
              </div>
              <div className=" rdtable ptn row-3-item5">
                <label>T.Gral</label>
              </div>
            </div>
          </div>
          <div className="widthtable row-4">
            <div className="dth-father rdtable fatherstable">
              <label>DTH</label>
            </div>
            <div className="dth-child w100 divSUBTH">
              <div className="rdtable row-4-item1">
                <label>Principal</label>
              </div>
              <div className=" rdtable row-4-item2">
                <label>ADIC</label>
              </div>
              <div className=" rdtable row-4-item3">
                <label>Recupero</label>
              </div>
              <div className=" rdtable ptup row-4-item4">
                <label className="doubleLetter">Provisionados</label>
                <label className="doubleLetter">principal</label>
              </div>
            </div>
          </div>
          <div className="widthtable row-5">
            <div className="gpon-father rdtable fatherstable">
              <label>GPON</label>
            </div>
            <div className="gpon-child w100 divSUBTH">
              <div className="rdtable row-5-item1">
                <label>GPON</label>
              </div>
              <div className="rdtable row-5-item2">
                <label>GCORPO</label>
              </div>
              <div className="rdtable row-5-item3">
                <label>IPTV</label>
              </div>
              <div className="rdtable ptup row-5-item4">
                <label className="doubleLetter">IPTV</label>
                <label className="doubleLetter">ADIC</label>
              </div>
              <div className="rdtable">
                <label>2P</label>
              </div>
              <div className="rdtable">
                <label>3P</label>
              </div>
              <div className="rdtable ptup">
                <label className="doubleLetter">Provisionados</label>
                <label className="doubleLetter">principal</label>
              </div>
            </div>
          </div>
          <div className="widthtable row-6">
            <div className="p-grid nomargin">
              <div className="gray-father rdtable fatherstable lastfathertbl">
                <label></label>
              </div>
              <div className="gray-child w100 divSUBTH">
                <div className="rdtable graylb">
                  <label>SIN CANAL</label>
                </div>
              </div>
            </div>
          </div>
        </div>

        {arrayItems &&
          arrayItems.length &&
          arrayItems.map((itemdate, key) => {
            return (
              <div style={{ width: "100%", height: 40 }}>
                <div key={key}>
                  <div
                    className="widthtable row-1 rowInfo"
                    style={{
                      backgroundColor: itemdate.gray ? "#969696" : "#246796",
                    }}
                  >
                    <label>{itemdate.total}</label>
                  </div>
                  {itemdate.rows.map((item, key) => {
                    return (
                      <div
                        className={`widthtable row-${key + 2} rowInfo`}
                        style={{
                          backgroundColor: item.color
                            ? item.color
                            : "transparent",
                          borderRight: "1px solid #eee",
                          borderBottom: "1px solid #eee",
                        }}
                      >
                        <div className="w100">
                          {item.row.map((subitem, subkey) => {
                            return (
                              <div
                                className={`div-label-row row-${key + 2}-item${
                                  subkey + 1
                                }`}
                              >
                                <label key={subkey}>{subitem}</label>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}

        {/*{arrayItems &&*/}
        {/*    arrayItems.map((item,key)=>{*/}
        {/*        if(item.data){*/}
        {/*            item.data.map((item2, key2)=>{*/}
        {/*                console.log('item2', item2)*/}
        {/*                return(*/}
        {/*                    <div style={{ width: '100%', height: 40 }}>*/}
        {/*                        <div key={key2}>*/}
        {/*                            <div className="widthtable row-1 rowInfo" style={{ backgroundColor: item2 ? '#969696' : '#308ac9' }}>*/}
        {/*                                <label>{item2}</label>*/}
        {/*                            </div>*/}
        {/*                        </div>*/}
        {/*                    </div>*/}
        {/*                )*/}
        {/*            })*/}
        {/*        }*/}

        {/*    })*/}
        {/*}*/}

        {dataDates &&
          dataDates.length &&
          dataDates.map((itemdate, key) => {
            return (
              <div style={{ width: "100%", height: 40 }}>
                <div key={key}>
                  <div
                    className="widthtable row-1 rowInfo"
                    style={{
                      backgroundColor: itemdate.gray ? "#969696" : "#308ac9",
                    }}
                  >
                    <label>{itemdate.date}</label>
                  </div>
                  {itemdate.rows.map((item, key) => {
                    return (
                      <div
                        className={`widthtable row-${key + 2} rowInfo`}
                        style={{
                          backgroundColor: itemdate.gray
                            ? "rgb(74 79 84 / 15%)"
                            : "transparent",
                          borderRight: "1px solid #eee",
                          borderBottom: "1px solid #eee",
                        }}
                      >
                        <div className="w100">
                          {item.row.map((subitem, subkey) => {
                            return (
                              <div
                                className={`div-label-row row-${key + 2}-item${
                                  subkey + 1
                                }`}
                              >
                                <input type={"number"} style={{width:"100%"}} className="input-table-edit-modal" key={subkey} placeholder={subitem}/>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}

        {porcent && (
          <div style={{ width: "100%", height: 40 }}>
            <div
              className="widthtable row-1 rowInfo"
              style={{ backgroundColor: "#e3e3e3", padding: "5px 2px" }}
            >
              <label style={{ color: "black", fontSize: 10 }}>
                % cumplimiento meta
              </label>
            </div>
            {dataDateNow &&
              dataDateNow.length &&
              dataDateNow.map((item, key) => {
                return (
                  <div
                    className={`widthtable row-${key + 2} rowInfo`}
                    style={{
                      borderRight: "1px solid #eee",
                      borderBottom: "1px solid #eee",
                    }}
                    key={key}
                  />
                );
              })}
          </div>
        )}
      </div>
      {download && (
        <div className="div-downloads-dashboard">
          <span className="text-download-dashboard">Descargar como</span>
          <FontAwesomeIcon
            onClick={exportExcel}
            icon={faFileWord}
            className="icon-word-dashboard"
          />
          <FontAwesomeIcon
            onClick={exportExcel}
            icon={faFileExcel}
            className="icon-excel-dashboard"
          />
        </div>
      )}
    </div>
  );
};

export default TableEditModal;
