import React from 'react'
import HeadersSection from '../../../../components/book/headersSection/HeadersSection'
import { info } from '../../../../../mockups/dashboard';
import './PendingBox.scss'
import CardRisk from '../../../../components/activations/cardsRisk/CardRisk';
import Tables from '../../../../components/PendingBox/Tables/Tables';

// import boxGreen4 from '../../../../../images/cardIcons/boxGreen.svg'
// import boxRed4 from '../../../../../images/cardIcons/boxRed.svg'
// import boxYellow4 from '../../../../../images/cardIcons/boxYellow.svg'

const PendingBox = () => {
    return (
        <div>
            <HeadersSection
                title={'Gestión información cajas GPON'}
                exeptions={''}
                date={info.headerInfo.date}

            />

            <CardRisk
                text1={"Solicitar cajas"}
                // img1={boxYellow4}
                text2={"Pendiente de verificar"}
                number2={"245"}
                // img2={boxRed4}
                downText2={"Último pedido:"}
                downNumber2={"2021-08-08 12:00:00"}
                text3={"Riesgos verificados"}
                number3={"245"}
                // img3={boxGreen4}
                downText3={"Última información de caja actualizada: "}
                downNumber3={"2021-08-08 12:00:00"}
            />
            <Tables />

        </div>


    )
}

export default PendingBox
