import React, { useState } from "react";
import "./BranchOffice.scss";
import ModalAdd from "./ModalAdd";

const BranchOffice = ({
  cerrarModal,
  modal,
  setModal,
  registro,
  setRegistro,
}) => {
  return (
    <div className="branch-office">
      <p className="branch-office-text">Sucursal asignada</p>
      <div className="branch-container-data">
        <div className="data-two">
          <label>Sucursal</label>
          <select>
            <option disabled>Selecciona una opción</option>
            <option>Norte</option>
            <option>Sur</option>
            <option>Central</option>
            <option>Centro</option>
            <option>Oeste</option>
            <option>Este</option>
          </select>
        </div>
        <div className="data-two">
          <label>Departamento</label>
          <select>
            <option disabled>Selecciona una opción</option>
            <option>Administración</option>
            <option>Tesorería</option>
            <option>Análisis</option>
            <option>Legajos</option>
          </select>
        </div>
        <div className="data-two">
          <label>Cargo</label>
          <select>
            <option disabled>Selecciona una opción</option>
          </select>
        </div>
        <div className="data-two">
          <label>Canal comercial</label>
          <select>
            <option disabled>Selecciona una opción</option>
            <option>4x4</option>
            <option>PDA</option>
            <option>Estacionaria</option>
            <option>FDV</option>
            <option>POS directa </option>
            <option>GPON</option>
            <option>Televenta Gondola & Locales </option>
            <option>DTH</option>
          </select>
        </div>
      </div>

      <div className="branch-container-data">
        <div className="data-two">
          <label>Supervisor asignado</label>
          <select>
            <option disabled>Selecciona una opción</option>
            <option>Norte</option>
            <option>Sur</option>
            <option>Central</option>
            <option>Centro</option>
            <option>Oeste</option>
            <option>Este</option>
          </select>
        </div>
        <div className="data-two">
          <label>TeamLeader</label>
          <select>
            <option disabled>Selecciona una opción</option>
            <option>Norte</option>
            <option>Sur</option>
            <option>Central</option>
            <option>Centro</option>
            <option>Oeste</option>
            <option>Este</option>
          </select>
        </div>
        <div className="data-two">
          <label>Asignar código de ruta</label>
          <input
            className="input-style"
            type="text"
            placeholder="Ingresa código de ruta"
          />
        </div>
        <div className="data-two">
          <label>Asignar ID MOMO</label>
          <input
            className="input-style"
            type="text"
            placeholder="Ingresa ID MOMO"
          />
        </div>
      </div>
      <div className="option-checkbox">
        <div>
          <div>
            <input type="checkbox" id="cellar" name="cellar" />
            <label for="cellar">Bodega virtual</label>
          </div>
          <div>
            <input type="checkbox" id="contact" name="contact" />
            <label for="contact">Impulsadora</label>
          </div>
        </div>

        <div>
          <p style={{ fontWeight: "900", fontSize: "12px" }}>
            Forma de asistencia
          </p>
          <div>
            <input type="radio" id="clock" name="clock" />
            <label for="clock" style={{ fontWeight: "normal" }}>
              Marcar reloj
            </label>
          </div>
          <div>
            <input type="radio" id="attendance" />
            <label for="attendance" style={{ fontWeight: "normal" }}>
              Supervisor marca asistencia
            </label>
          </div>
        </div>
      </div>
      <div>
        <p className="branch-office-text">Seguro social</p>

        <div>
          <div>
            <input type="checkbox" id="ips" name="ips" />
            <label for="ips">Registro de IPS</label>
          </div>
          <div>
            <input type="checkbox" id="smp" name="smp" />
            <label for="smp">Registro seguro médico privado</label>
          </div>
        </div>
      </div>

      <div>
        <p className="branch-office-text">Liquidación de haberes</p>

        <div className="start-date">
          <label>Razón social a asignar</label>
          <select>
            <option disabled>Selecciona una opción</option>
          </select>
        </div>
        <div className="data-one">
          <div className="start-date date-ruc">
            <input type="checkbox" id="invoice" name="invoice" />
            <label for="invoice">Emite factura</label>
          </div>
          <div className="start-date">
            <label>RUC</label>
            <input type="text" disabled />
          </div>
          <div className="start-date">
            <input type="checkbox" id="accreditation" name="accreditation" />
            <label for="accreditation">Realiza pago por acreditación</label>
          </div>
        </div>
      </div>

      <div className="branch-container-data">
        <div className="data-two">
          <label>Periodo de liquidación</label>
          <select>
            <option disabled>Selecciona una opción</option>
            <option>Norte</option>
            <option>Sur</option>
            <option>Central</option>
            <option>Centro</option>
            <option>Oeste</option>
            <option>Este</option>
          </select>
        </div>
        <div className="data-two">
          <label>Forma de pago</label>
          <select>
            <option disabled>Selecciona una opción</option>
            <option>Norte</option>
            <option>Sur</option>
            <option>Central</option>
            <option>Centro</option>
            <option>Oeste</option>
            <option>Este</option>
          </select>
        </div>
      </div>

      <div>
        <p className="branch-office-text">Condiciones</p>
        <p className="branch-office-textarea">
          Descripción y regístro de condiciones laborales, comerciales y forma
          de pago
        </p>
        <div className="container-textarea">
          <textarea>Escribe aquí</textarea>
        </div>
        <div>
          <p className="branch-office-text">Asignación de haberes</p>
        </div>
        {modal && (
          <ModalAdd
            cerrarModal={cerrarModal}
            registro={registro}
            setRegistro={setRegistro}
          />
        )}
        <div className="button-direction buttom-branch">
          <button
            onClick={(e) => {
              setModal(true);
              const body = document.getElementById("root");
              const overlay = document.createElement("div");
              overlay.classList.add("overlay");
              overlay.setAttribute("id", "overlay");
              body.appendChild(overlay);
            }}
          >
            <p className="button-direction-text buttom-branch-text">+</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default BranchOffice;
