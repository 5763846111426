import React from "react"
import './graph.scss'
import ReactEChartsCore from "echarts-for-react/lib/core";
import * as echarts from "echarts/core";
import {LineChart, BarChart,PieChart} from "echarts/charts";
import {GridComponent, TooltipComponent, TitleComponent, DatasetComponent,  LegendComponent,} from 'echarts/components';
import {
    CanvasRenderer,
    // SVGRenderer,
} from 'echarts/renderers';

echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    LineChart,
    BarChart,
    CanvasRenderer,
    LegendComponent,
]);

const Graph=()=>{



    const countryColors = {
        Australia: '#ff2631',
        Canada: '#f8a100',
        China: '#0271c1',
        Cuba: '#036402',

    };

    const options = {
        grid: { top: 8, right: 8, bottom: 24, left: 36 },
        xAxis: {
            type: 'category',
            //data: ['PIN GENERADO', 'PIN RECIBIDO', 'APROBADO Y AGENDADO', 'RECHAZADOS EAPN'],
            data:['1', '2','3','4']
        },
        yAxis: {
            type: 'value',
        },
        series: [
            {
                data:[
                    {value:457,itemStyle:{ color: '#ff2631'}},
                    {value:257,itemStyle:{ color: '#f8a100'}},
                    {value:57,itemStyle:{ color: '#0271c1'}},
                    {value:257,itemStyle:{ color: '#036402'}},
                ],
                barWidth:'40%',
                itemStyle: {
                    normal: {
                        barBorderRadius:  [50, 50, 0, 0],

                    }
                },

                //data: [457, 257, 57, 257],
                type: 'bar',
                smooth: true,
            },
        ],
        tooltip: {
            trigger: 'axis',
        },
    };

    const onChartReadyCallback = () => {
        // alert("OKOK")
    };

    return(
        <div className='graphContainer'>
            <p>Gestión de PORTABILIDAD</p>

            <ReactEChartsCore
                echarts={echarts}
                option={options}
                notMerge={true}
                lazyUpdate={true}
                theme={"theme_name"}
                onChartReady={onChartReadyCallback}
                //onEvents={EventsDict}
                //opts={}
            />

        </div>
    )
}
export default Graph
