import React from "react";
import './InputSearch.scss'

const InputSearch=({placeholder})=>
{
    return(
        <>
            <div className="cj-input-search">
                <div className={`printL__input-container`}>
                    <button className={`printL__btn`}>Buscar:</button>
                    <input className='printL__input' type='text' placeholder={placeholder} />
                </div>
            </div>
        </>
    );
}
export default InputSearch;