const tableInfo = {
  headerList: [
    {
      field: "banco",
      header: "Fecha de registro",
      order: false,
    },
    {
      field: "date",
      header: "Foto",
      order: false,
    },
    {
      field: "num",
      header: "Nombre",
      order: false,
    },
    {
      field: "update",
      header: "Sucursal",
      order: false,
    },
    {
      field: "supervisor",
      header: "Supervisor",
      order: false,
    },
    {
      field: "ci",
      header: "CI",
      order: false,
    },
    {
      field: "perfil",
      header: "Perfil",
      order: false,
    },
    {
      field: "comercial",
      header: "Canal comercial",
      order: false,
    },
    {
      field: "pos",
      header: "POS",
      order: false,
    },
    {
      field: "tipo",
      header: "Tipo",
      order: false,
    },
    {
      field: "estado",
      header: "Estado",
      order: false,
    },
    {
      field: "accion",
      header: "Acciones",
      order: false,
    },
  ],
  rows: [
    {
      banco: "05 - Enero - 2020",
      date: "Foto",
      num: "ARTURO ALEJANDRO LÓPEZ MARTÍNEZ",
      update: "ARTURO ALEJANDRO LÓPEZ MARTÍNEZ",
      supervisor: "ALBA GRICELDA CABAÑAS PALACIOS",
      ci: "5619751",
      perfil: "Ventas",
      comercial: "GPON",
      pos: "12456456",
      tipo: "Baja",
      estado: "Bloqueado",
      accion: "Acciones",
    },
    {
      banco: "05 - Enero - 2020",
      date: "Foto",
      num: "ARTURO ALEJANDRO LÓPEZ MARTÍNEZ",
      update: "ARTURO ALEJANDRO LÓPEZ MARTÍNEZ",
      supervisor: "ALBA GRICELDA CABAÑAS PALACIOS",
      ci: "5619751",
      perfil: "Ventas",
      comercial: "GPON",
      pos: "12456456",
      tipo: "Alta",
      estado: "Inactivo",
      accion: "Acciones",
    },
    {
      banco: "05 - Enero - 2020",
      date: "Foto",
      num: "ARTURO ALEJANDRO LÓPEZ MARTÍNEZ",
      update: "ARTURO ALEJANDRO LÓPEZ MARTÍNEZ",
      supervisor: "ALBA GRICELDA CABAÑAS PALACIOS",
      ci: "5619751",
      perfil: "Ventas",
      comercial: "GPON",
      pos: "12456456",
      tipo: "Baja",
      estado: "Inactivo",
      accion: "Acciones",
    },
    {
      banco: "05 - Enero - 2020",
      date: "Foto",
      num: "ARTURO ALEJANDRO LÓPEZ MARTÍNEZ",
      update: "ARTURO ALEJANDRO LÓPEZ MARTÍNEZ",
      supervisor: "ALBA GRICELDA CABAÑAS PALACIOS",
      ci: "5619751",
      perfil: "Ventas",
      comercial: "GPON",
      pos: "12456456",
      tipo: "Alta",
      estado: "Activo",
      accion: "Acciones",
    },
    {
      banco: "05 - Enero - 2020",
      date: "Foto",
      num: "ARTURO ALEJANDRO LÓPEZ MARTÍNEZ",
      update: "ARTURO ALEJANDRO LÓPEZ MARTÍNEZ",
      supervisor: "ALBA GRICELDA CABAÑAS PALACIOS",
      ci: "5619751",
      perfil: "Ventas",
      comercial: "GPON",
      pos: "12456456",
      tipo: "Alta",
      estado: "Bloqueado",
      accion: "Acciones",
    },
    {
      banco: "05 - Enero - 2020",
      date: "Foto",
      num: "ARTURO ALEJANDRO LÓPEZ MARTÍNEZ",
      update: "ARTURO ALEJANDRO LÓPEZ MARTÍNEZ",
      supervisor: "ALBA GRICELDA CABAÑAS PALACIOS",
      ci: "5619751",
      perfil: "Ventas",
      comercial: "GPON",
      pos: "12456456",
      tipo: "Alta",
      estado: "Bloqueado",
      accion: "Acciones",
    },
    {
      banco: "05 - Enero - 2020",
      date: "Foto",
      num: "ARTURO ALEJANDRO LÓPEZ MARTÍNEZ",
      update: "ARTURO ALEJANDRO LÓPEZ MARTÍNEZ",
      supervisor: "ALBA GRICELDA CABAÑAS PALACIOS",
      ci: "5619751",
      perfil: "Ventas",
      comercial: "GPON",
      pos: "12456456",
      tipo: "Alta",
      estado: "Bloqueado",
      accion: "Acciones",
    },
    {
      banco: "05 - Enero - 2020",
      date: "Foto",
      num: "ARTURO ALEJANDRO LÓPEZ MARTÍNEZ",
      update: "ARTURO ALEJANDRO LÓPEZ MARTÍNEZ",
      supervisor: "ALBA GRICELDA CABAÑAS PALACIOS",
      ci: "5619751",
      perfil: "Ventas",
      comercial: "GPON",
      pos: "12456456",
      tipo: "Baja",
      estado: "Activo",
      accion: "Acciones",
    },
    {
      banco: "05 - Enero - 2020",
      date: "Foto",
      num: "ARTURO ALEJANDRO LÓPEZ MARTÍNEZ",
      update: "ARTURO ALEJANDRO LÓPEZ MARTÍNEZ",
      supervisor: "ALBA GRICELDA CABAÑAS PALACIOS",
      ci: "5619751",
      perfil: "Ventas",
      comercial: "GPON",
      pos: "12456456",
      tipo: "Alta",
      estado: "Activo",
      accion: "Acciones",
    },
    {
      banco: "05 - Enero - 2020",
      date: "Foto",
      num: "ARTURO ALEJANDRO LÓPEZ MARTÍNEZ",
      update: "ARTURO ALEJANDRO LÓPEZ MARTÍNEZ",
      supervisor: "ALBA GRICELDA CABAÑAS PALACIOS",
      ci: "5619751",
      perfil: "Ventas",
      comercial: "GPON",
      pos: "12456456",
      tipo: "Alta",
      estado: "Bloqueado",
      accion: "Acciones",
    },
    {
      banco: "05 - Enero - 2020",
      date: "Foto",
      num: "ARTURO ALEJANDRO LÓPEZ MARTÍNEZ",
      update: "ARTURO ALEJANDRO LÓPEZ MARTÍNEZ",
      supervisor: "ALBA GRICELDA CABAÑAS PALACIOS",
      ci: "5619751",
      perfil: "Ventas",
      comercial: "GPON",
      pos: "12456456",
      tipo: "Alta",
      estado: "Inactivo",
      accion: "Acciones",
    },
    {
      banco: "05 - Enero - 2020",
      date: "Foto",
      num: "ARTURO ALEJANDRO LÓPEZ MARTÍNEZ",
      update: "ARTURO ALEJANDRO LÓPEZ MARTÍNEZ",
      supervisor: "ALBA GRICELDA CABAÑAS PALACIOS",
      ci: "5619751",
      perfil: "Ventas",
      comercial: "GPON",
      pos: "12456456",
      tipo: "Alta",
      estado: "Activo",
      accion: "Acciones",
    },
    {
      banco: "05 - Enero - 2020",
      date: "Foto",
      num: "ARTURO ALEJANDRO LÓPEZ MARTÍNEZ",
      update: "ARTURO ALEJANDRO LÓPEZ MARTÍNEZ",
      supervisor: "ALBA GRICELDA CABAÑAS PALACIOS",
      ci: "5619751",
      perfil: "Ventas",
      comercial: "GPON",
      pos: "12456456",
      tipo: "Baja",
      estado: "Activo",
      accion: "Acciones",
    },
  ],
};

export const infoParameters = {
  tableInfo,
};
