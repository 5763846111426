import './UploadCI.scss'

const UploadCI = () => {
  return (
    
    <header className='printL__header mb-5'>
            <h3 className='printL__title'>Subir / Imprimir documentos </h3>
            <p className='printL__date '>Viernes 30 de Julio del 2022 | Semana: 18 | Cierre de mes: 1</p>
        </header>
  )
}

export default UploadCI