import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { classNames } from "primereact/utils";
import { Ripple } from "primereact/ripple";
import editIcon from "../../../../../../images/tableIcons/editIcon.png";
import deleteicon from "../../../../../../images/tableIcons/tashIcon.png";
import { Modal } from "antd";
import okicon from "../../../../../../images/tableIcons/okIcon.png";
import supr from "../../../../../../images/tableIcons/suprIcon.png";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

const TableNewReason = ({
  searchEnter,
  value,
  setDataParameters,
  setColumn,
  showModalDelete,
  setShowModalDelete,
  generarId,
}) => {
  const [data, setData] = useState([]);
  const deleteElements = (e) => {
    const resThen = (res) => {
      Swal.fire({
        icon: "success",
        title: "Tipo de activo eliminado",
        text: "Se eliminó la información de forma correcta",
        showConfirmButton: false,
        timer: 2000,
      });

      var newData = rows;
      newData.forEach((element, index) => {
        if (element.id == e.id) {
          newData.splice(index, 1);
        }
      });

      setData([...newData]);
    };
    MySwal.fire({
      title: `¿Está seguro que desea eliminar esta razón social?`,
      showDenyButton: true,
      icon: 'warning',
      showCancelButton: false,
      denyButtonText: `Cancelar`,
      confirmButtonText: "Eliminar",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title:'Se eliminó la razón social',
          icon: 'error',
          confirmButtonText: "Aceptar",
        }
        )
      }
    });
  };
  const defaultPaginatorTemplate = {
    layout: "PrevPageLink PageLinks NextPageLink",
    PrevPageLink: (options) => {
      return (
        <button
          type="button"
          className="paginator-before greenBtn "
          onClick={options.onClick}
          disabled={options.disabled}
        >
          Anterior
          <Ripple />
        </button>
      );
    },
    NextPageLink: (options) => {
      return (
        <button
          type="button"
          className="paginator-after greenBtn"
          onClick={options.onClick}
          disabled={options.disabled}
        >
          Siguiente
          <Ripple />
        </button>
      );
    },
    PageLinks: (options) => {
      if (
        (options.view.startPage === options.page &&
          options.view.startPage !== 0) ||
        (options.view.endPage === options.page &&
          options.page + 1 !== options.totalPages)
      ) {
        const className = classNames(options.className, { "p-disabled": true });

        return (
          <span className={className} style={{ userSelect: "none" }}>
            ...
          </span>
        );
      }

      return (
        <button
          type="button"
          className={options.className}
          onClick={options.onClick}
        >
          {options.page + 1}
          <Ripple />
        </button>
      );
    },
  };
  const accionTemplate = (e) => {
    return (
      <div className="centeredActions" style={{ justifyContent: "center" }}>
        <div className="sectionActions">
          <Link to="/editar-parameters" className="btnedit-table">
            <img src={editIcon} />
          </Link>
          <div className="btnedit-table">
            {e.accion == "Acciones" && (
              <img src={deleteicon} onClick={() => deleteElements(e)} />
            )}
          </div>
        </div>
      </div>
    );
  };

  const column = [
    {
      field: "cargo",
      header: "Cargo",
      order: false,
    },
    {
      field: "date",
      header: "Fecha de registro",
      order: false,
    },
    {
      field: "num",
      header: "Numero de Usuarios vinculados",
      order: false,
    },
    {
      field: "update",
      header: "Última actualización",
      order: false,
    },
    {
      field: "accion",
      header: "Acciones",
      order: false,
      body: accionTemplate,
    },
  ];

  const rows = [
    {
      id: generarId(),
      cargo: "InterTec S.A de C.V.",
      asignacion: "Suma",
      date: "21 - Abril - 2021",
      num: "34",
      update: "21 - Abril - 2021",
      accion: "Acciones",
    },
    {
      id: generarId(),
      cargo: "InterTec S.A de C.V.",
      asignacion: "Suma",
      date: "21 - Abril - 2021",
      num: "34",
      update: "21 - Abril - 2021",
      accion: "Acciones",
    },
    {
      id: generarId(),
      cargo: "InterTec S.A de C.V.",
      asignacion: "Suma",
      date: "21 - Abril - 2021",
      num: "34",
      update: "21 - Abril - 2021",
      accion: "Acciones",
    },
    {
      id: generarId(),
      cargo: "InterTec S.A de C.V.",
      asignacion: "Suma",
      date: "21 - Abril - 2021",
      num: "34",
      update: "21 - Abril - 2021",
      accion: "Acciones",
    },
    {
      id: generarId(),
      cargo: "InterTec S.A de C.V.",
      asignacion: "Suma",
      date: "21 - Abril - 2021",
      num: "34",
      update: "21 - Abril - 2021",
      accion: "Acciones",
    },
    {
      id: generarId(),
      cargo: "InterTec S.A de C.V.",
      asignacion: "Suma",
      date: "21 - Abril - 2021",
      num: "34",
      update: "21 - Abril - 2021",
      accion: "Acciones",
    },
    {
      id: generarId(),
      cargo: "InterTec S.A de C.V.",
      asignacion: "Suma",
      date: "21 - Abril - 2021",
      num: "34",
      update: "21 - Abril - 2021",
      accion: "Acciones",
    },
    {
      id: generarId(),
      cargo: "InterTec S.A de C.V.",
      asignacion: "Suma",
      date: "21 - Abril - 2021",
      num: "34",
      update: "21 - Abril - 2021",
      accion: "Acciones",
    },
    {
      id: generarId(),
      cargo: "InterTec S.A de C.V.",
      asignacion: "Suma",
      date: "21 - Abril - 2021",
      num: "34",
      update: "21 - Abril - 2021",
      accion: "Acciones",
    },
    {
      id: generarId(),
      cargo: "InterTec S.A de C.V.",
      asignacion: "Suma",
      date: "21 - Abril - 2021",
      num: "34",
      update: "21 - Abril - 2021",
      accion: "Acciones",
    },
    {
      id: generarId(),
      cargo: "InterTec S.A de C.V.",
      asignacion: "Suma",
      date: "21 - Abril - 2021",
      num: "34",
      update: "21 - Abril - 2021",
      accion: "Acciones",
    },
    {
      id: generarId(),
      cargo: "InterTec S.A de C.V.",
      asignacion: "Suma",
      date: "21 - Abril - 2021",
      num: "34",
      update: "21 - Abril - 2021",
      accion: "Acciones",
    },
    {
      id: generarId(),
      cargo: "InterTec S.A de C.V.",
      asignacion: "Suma",
      date: "21 - Abril - 2021",
      num: "34",
      update: "21 - Abril - 2021",
      accion: "Acciones",
    },
    {
      id: generarId(),
      cargo: "InterTec S.A de C.V.",
      asignacion: "Suma",
      date: "21 - Abril - 2021",
      num: "34",
      update: "21 - Abril - 2021",
      accion: "Acciones",
    },
  ];

  return (
    <div className="left-elements">
      <DataTable
        value={rows}
        paginator
        paginatorTemplate={defaultPaginatorTemplate}
        rows={10}
        className="parameters-table"
        globalFilter={searchEnter}
      >
        {column.map((e) => {
          return <Column field={e.field} header={e.header} body={e.body} />;
        })}
      </DataTable>
    </div>
  );
};
export default TableNewReason;
