import React, { useState } from "react";
import { Column } from "primereact/column";
import marcadorIcon from "../../../../../../images/tableIcons/icon-marcador.png";
import deleteIcon from "../../../../../../images/tableIcons/statusinactive.png";
import { DataTable } from "primereact/datatable";
import ModalAssign from "./ModalAssign";
import ModalDeliver from "./ModalDeliver";

const NewPersonnelThreeData1 = ({
  template,
  value,
  active,
  setSecondTable,
  setIdSelection,
  idSelection,
  secondTable,
}) => {
  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);

  const accionTemplate = ({ id }) => {
    return (
      <div
        className="centeredActions"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <div className="sectionActions">
          <div className="btnedit-table">
            <img
              onClick={() => {
                setModal(true);
                setIdSelection(id);
              }}
              src={marcadorIcon}
            />
          </div>
        </div>
      </div>
    );
  };

  const eliminarTemplate = ({ id }) => {
    return (
      <div
        className="centeredActions"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <div className="sectionActions">
          <div className="btnedit-table">
            <img
              className="iconEliminar"
              onClick={() => {
                setModal1(true);
                console.log(id);
                setIdSelection(id);
              }}
              src={deleteIcon}
            />
          </div>
        </div>
      </div>
    );
  };

  const column = [
    {
      field: "familia",
      header: "Familia",
      order: false,
    },
    {
      field: "seccion",
      header: "Sección",
      order: false,
    },
    {
      field: "producto",
      header: "Producto",
      order: false,
    },
    {
      field: "serie",
      header: "Nro de serie.",
      order: false,
    },
    {
      field: "tipo",
      header: "Tipo de recurso",
      order: false,
    },
    {
      field: "observaciones",
      header: "Observaciones",
      order: false,
    },
    {
      field: "accion",
      header: "Acciones",
      body: template == "accionTemplate" ? accionTemplate : eliminarTemplate,
      order: false,
    },
  ];
  return (
    <div className="datatable">
      <DataTable
        value={active && value}
        rows={10}
        className="parameters-table"
        // globalFilter={searchEnter}
      >
        {column.map((e, key) => {
          return (
            <Column
              style={{ fontSize: "12px" }}
              field={e.field}
              header={e.header}
              body={e.body ? e.body : null}
            />
          );
        })}
      </DataTable>
      {modal && (
        <ModalAssign
          secondTable={secondTable}
          idSelection={idSelection}
          setIdSelection={setIdSelection}
          value={value}
          setSecondTable={setSecondTable}
          setModal={setModal}
        />
      )}
      {modal1 && (
        <ModalDeliver
          secondTable={value}
          idSelection={idSelection}
          setIdSelection={setIdSelection}
          setSecondTable={setSecondTable}
          setModal1={setModal1}
        />
      )}
    </div>
  );
};

export default NewPersonnelThreeData1;
