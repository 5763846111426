import React from "react";
import Personnel from "../Personnel/Personnel";
import DataReports from "./DataReports";
import "./Reports.scss";

const Reports = () => {
  return (
    <div className="reports"
    style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      gap: "20px",
      minHeight: "calc(100vh - 40px)"
    }}
    >
      <div>
        <Personnel captionData="Reportes" />
        <div className="reports-select">
          <div className="selects">
            <div>
              <p>Sucursal</p>
              <select>
                <option disabled>Selecciona una opción</option>
                <option>Norte</option>
                <option>Sur</option>
                <option>Centrar</option>
                <option>Oeste</option>
                <option>Este</option>
              </select>
            </div>
            <div>
              <p>Cargo</p>
              <select>
                <option disabled>Selecciona una opción</option>
                <option>Norte</option>
                <option>Sur</option>
                <option>Centrar</option>
                <option>Oeste</option>
                <option>Este</option>
              </select>
            </div>
            <div>
              <p>Supervisor asig.</p>
              <select>
                <option disabled>Selecciona una opción</option>
                <option>Norte</option>
                <option>Sur</option>
                <option>Centrar</option>
                <option>Oeste</option>
                <option>Este</option>
              </select>
            </div>
            <div>
              <p>Departamento</p>
              <select>
                <option disabled>Selecciona una opción</option>
                <option>Norte</option>
                <option>Sur</option>
                <option>Centrar</option>
                <option>Oeste</option>
                <option>Este</option>
              </select>
            </div>
            <div>
              <p>Canal Comercial</p>
              <select>
                <option disabled>Selecciona una opción</option>
                <option>Norte</option>
                <option>Sur</option>
                <option>Centrar</option>
                <option>Oeste</option>
                <option>Este</option>
              </select>
            </div>
            <div>
              <p>TeamLeader</p>
              <select>
                <option disabled>Selecciona una opción</option>
                <option>Norte</option>
                <option>Sur</option>
                <option>Centrar</option>
                <option>Oeste</option>
                <option>Este</option>
              </select>
            </div>
          </div>
          <div className="btnPhone" style={{display:"flex", alignItems:"end", gap:"28px"}}>
            <button className="btn-reports" style={{backgroundColor:"#0271c1"}}>Limpiar</button>
            <button className="btn-reports" style={{backgroundColor:"#036402"}}>Aplicar</button>
          </div>
        </div>
        <div className="reportsContainer">
            <DataReports/>
        </div>
      </div>
      <div>
        <footer style={{ display: "flex", justifyContent: "center" }}>
          <p>2022 GestiClaro. Todos los derechos reservados</p>
        </footer>
      </div>
    </div>
  );
};

export default Reports;
