import React ,{useState} from "react";
import './RequestVacations.scss'
//componts
import HeadersSection from "../../../../components/book/headersSection/HeadersSection";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import { Ripple } from 'primereact/ripple';
import FooterSimple from "../../../../components/book/footerSimple/FooterSimple";
import PdfModal from "../../../../components/book/PdfModal/PdfModal";
import GeneralModal from "../../../../components/book/GeneralModal/GeneralModal";
import FooterTableW from "../../../../components/book/FooterTable/FooterTable";
//assets

import editIcon from '../../../../img/icons/edit.svg'
import profileImg from '../../../../img/jpg/profile.jpg'
import pdfIcon from '../../../../img/icons/pdf.svg'
import searchIcon from '../../../../img/icons/search-gray.svg'
import closeIcon from '../../../../img/icons/close.svg'
import copyIcon from '../../../../img/icons/copy.svg'
const RequestVacations = ()=>
{
    const [searchName, setSearchName] = useState();
    const [modalState, setModalState] = useState(false);
    const ModalTemplate=()=>
    {
        return(
            <>
                
                <div className="Modal-container-RequestVacations">
                    <div className="header-modal-container">
                        <div className="title-container">
                            <div className="title">Fecha de solicitud:</div>
                            <div className="sub-title">22 - Abril - 2021</div>
                        </div>
                        <div>
                            <img src={closeIcon} style={{cursor:"pointer"}} onClick={()=>{setModalState(false)}}></img>
                        </div>
                    </div>
                    <div className="profile-image">
                        
                        <img src={profileImg} ></img>
                        <div className="text-image">BIANCA GONZALES LARA</div>
                    </div>
                    <div className="title-container">
                        <div className="title">Tipo de solicitud:</div>
                        <div className="sub-title">Maternidad</div>
                    </div>
                    <div className="title-box">
                        <div className="title-container">
                                <div className="title">Fecha de inicio:</div>
                                <div className="sub-title">22 - Abril - 2021</div>
                        </div>
                        <div className="title-container">
                                <div className="title">Fecha de inicio:</div>
                                <div className="sub-title">22 - Abril - 2021</div>
                        </div>
                        <div className="title-container">
                                <div className="title">Supervisor:</div>
                                <div className="sub-title">ALEJANDRO MARTINEZ LÓPEZ</div>
                        </div>
                        <div className="title-container">
                                <div className="title">Aprobado:</div>
                                <div className="sub-title" style={{color:"#969696",fontWeight:"bold"}}>Pendiente</div>
                        </div>
                        <div className="title-container">
                                <div className="title">Descuento:</div>
                                <div className="sub-title">$0.00</div>
                        </div>
                        <div className="title-container">
                                <div className="title">Documento:</div>
                                <div className="sub-title" style={{color:"#0600a8"}}>vacacionnes.pdf</div>
                        </div>
                        <div className="ta-container">
                                <div className="title">Observaciones:</div>
                                <textarea></textarea>
                        </div>
                    </div>
                    <div className="button-container">
                        <button className="btn" style={{background:"#ff2631"}} onClick={()=>{setModalState(false)}}>Recchazar</button>
                        <button className="btn" style={{background:"#036402"}}onClick={()=>{setModalState(false)}}>Aprobar</button>
                    </div>
                    
                </div>
            </>
        )
        
    }
    //table templates
   
    const stateTemplate = (RowData) => {
        
        if (RowData.field10 === "Pendiente") {
            return (
                <div className="btn" style={{ background: "#969696" }}><span>Pendiente</span></div>
            );
        }
      

    }
    const actionsTemplate = () => {
        return (
            <>
                <img src={editIcon} style={{ paddingRight: "20px",cursor:"pointer" }} onClick={()=>setModalState(true)}></img>
            </>
        );
    }
   
    const template1 = {
        layout: 'PrevPageLink PageLinks NextPageLink',
        'PrevPageLink': (options) => {
            return (
                <button type="button" className={"btnGreen"} onClick={options.onClick} disabled={options.disabled}>
                    <span>Anterior</span>
                    <Ripple />
                </button>
            )
        },
        'NextPageLink': (options) => {
            return (
                <button type="button" className={"btnGreen"} onClick={options.onClick} disabled={options.disabled}>
                    <span>Siguiente</span>
                    <Ripple />
                </button>
            )
        },
    };

    var arrayHeader = [
        { field: "field1" },
        { field: "field2" },
        { field: "field3" },
        { field: "field4" },
        { field: "field5" },
        { field: "field6" },
        { field: "field7" },
        { field: "field8" },
        { field: "field9" },
        { field: "field10" },
        { field: "field11" },
        
    ]
    var arrayItems = [
        {
            field1: "05 - Enero - 2020",
            field2: "Bianca Gonzales Lara",
            field3: "05 - Enero - 2020",
            field4: "05 - Enero - 2020",
            field5: (<div style={{color:"#036402"}}>VACACIONES</div>),
            field6: "05 - Enero - 2020",
            field7: "ALEJANDRO MARTÍNEZ LÓPEZ",
            field8: "$0.00",
            field9: (<div style={{color:"#0600a8"}}>solicitud.pdf</div>),
            field10: "Pendiente",
            field11: "",
            

        },
    ]
    let headerGroup =
        <ColumnGroup>
            <Row>


                <Column header="Fecha de solicitud" colSpan={1} className="dth-child border-radius-left header-table" />
                <Column header="Solicitante" colSpan={1} className="dth-child header-table" />
                <Column header="Fecha de inicio" colSpan={1} className="dth-child header-table" />
                <Column header="Fecha de fin" colSpan={1} className="dth-child header-table" />
                <Column header="Motivo" colSpan={1} className="dth-child header-table" />
                <Column header="Fecha de aprobación" colSpan={1} className="dth-child header-table" />
                <Column header="Aprobado por" colSpan={1} className="dth-child header-table" />
                <Column header="Monto" colSpan={1} className="dth-child header-table" />
                <Column header="Archivo" colSpan={1} className="dth-child header-table" />
                <Column header="Estado" colSpan={1} className="dth-child header-table" />
                <Column header="Acciones" colSpan={1} className="dth-child border-radius-right header-table" />
               
            </Row>
        </ColumnGroup>

    return (
        <>
            <div className="cj-people-managment MetaNotAssigned">
                <div className="container-activations">
                    <HeadersSection
                        title={<div>Solicitud de vacaciones</div>}
                        exeptions={{}}
                    />
                    <div className="div-selects-activations">
                        <div className="div-container-stand">
                            <div className="search-container-cj">
                                <div className="db">
                                    <div className="search">
                                        <input
                                            type="search"
                                            value={searchName}
                                            onChange={(e) => setSearchName(e.target.value)}
                                            placeholder="Buscar por nombre"
                                            className="input-search"
                                        />
                                        <img src={searchIcon} className="input-icon"></img>
                                    </div>
                                </div>
                            </div>

                            <DataTable value={arrayItems} headerColumnGroup={headerGroup} responsiveLayout="scroll" globalFilter={searchName} className="table-activations" paginator rows={10} paginatorTemplate={template1} >

                                {/* <ColumnGroup> */}
                                {/* <Row> */}
                                {arrayHeader.map((item, key) => {
                                    switch (key) {
                                        case 9:
                                            return (

                                                <Column body={stateTemplate} colSpan={item.colspan ? item.colspan : 1} className="rowdata" key={key} />
                                            )
                                            break;
                                        case 10:
                                            return (

                                                <Column body={actionsTemplate} colSpan={item.colspan ? item.colspan : 1} className="rowdata" key={key} />
                                            )
                                            break;
                                        default:
                                            return (
                                                <Column field={item.field} colSpan={item.colspan ? item.colspan : 1} className="rowdata" key={key} />

                                            );
                                            break;
                                    }

                                })}

                            </DataTable>
                        <FooterTableW></FooterTableW>

                        </div>
                    </div>
                </div>
            </div>
            <FooterSimple></FooterSimple>
            {modalState&&(<GeneralModal modalState={modalState} state = {setModalState} content={ModalTemplate()}/>)}
        </>
    );
}
export default RequestVacations;