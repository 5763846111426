import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import deleteicon from "../../../../../../images/tableIcons/statusinactive.png";
import { set, sum } from "lodash";
import { number } from "echarts";
import Item from "antd/lib/list/Item";

const Registros = ({
  searchEnter,
  value,
  eliminarRegistro,
  actualizarEstado,
  suma,
}) => {
  const [activar, setActivar] = useState(true);
  const [estados, setEstados] = useState([]);

  const handleEliminar = (id, descripcion) => {
    eliminarRegistro(id);
  };
  const handleActivar = (id, estado) => {
    actualizarEstado(id);
  };

  const accionTemplate = ({ id, descripcion }) => {
    return (
      <div
        className="centeredActions"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <div className="sectionActions">
          <div className="btnedit-table">
            <img
              className="iconEliminar"
              src={deleteicon}
              onClick={() => handleEliminar(id, descripcion)}
            />
          </div>
        </div>
      </div>
    );
  };

  const btnVerdeTemplate = ({ id, estado }) => {
    return (
      <div
        className="centeredActions"
        style={{ display: "flex", gap: "10px", justifyContent: "center" }}
        onClick={() => handleActivar(id)}
      >
        {estado === "Activo" ? (
          <>
            <div className="btonverde">
              <div className="btnblanco"></div>
            </div>
            <div>
              <p
                style={{
                  margin: "auto 0 auto 15px",
                  color: "#036402",
                  fontSize: "12px",
                  with: "48px",
                }}
              >
                Activo
              </p>
            </div>
          </>
        ) : (
          <>
            <div className="btongris">
              <div className="btnmalva"></div>
            </div>
            <div>
              <p
                style={{
                  margin: "auto 2px",
                  color: "#000000",
                  fontSize: "12px",
                  with: "48px",
                }}
              >
                Inactivo
              </p>
            </div>
          </>
        )}
      </div>
    );
  };

  const column = [
    {
      field: "id",
      header: "ID",
      order: false,
    },
    {
      field: "tipo",
      header: "Tipo de rubro",
      order: false,
    },
    {
      field: "comision",
      header: "Comisiones de asignación",
      order: false,
    },
    {
      field: "descripcion",
      header: "Descripción",
      order: false,
    },
    {
      field: "asignacion",
      header: "Tipo de asignación",
      order: false,
    },
    {
      field: "estado",
      header: "Estado",
      body: btnVerdeTemplate,
      order: false,
    },
    {
      field: "accion",
      header: "Acciones",
      body: accionTemplate,
      order: false,
    },
  ];
  return (
    <div>
      <DataTable
        value={value}
        rows={10}
        className="parameters-table"
        globalFilter={searchEnter}
      >
        {column.map((e, key) => {
          return (
            <Column
              style={{ fontSize: "12px", color: "#036402" }}
              field={e.field}
              header={e.header}
              body={e.body ? e.body : null}
            />
          );
        })}
      </DataTable>
      <div className="total">
        <p>
          Total:{" "}
          <span>
            {suma.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
          </span>
        </p>
      </div>
    </div>
  );
};

export default Registros;
