import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Link, useNavigate } from 'react-router-dom';
// import validator from 'validator';

import 'primeflex/primeflex.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

import './Login.scss';

import img1 from "../../../img/log/intertec.png";
import img2 from "../../../img/log/image.png";

// import Swal from 'sweetalert2'
// import withReactContent from 'sweetalert2-react-content';
// const MySwal = withReactContent(Swal)
// import axios from "axios"

const Login = ({setAuth}) => {
    useEffect(() => {
        setAuth(0);
    }, [])

    const [email, setEmail] = useState('')
    const [errorEmail, setErrorEmail] = useState(false)

    const [password, setpassword] = useState('')
    const [errorPassword, setErrorPassword] = useState(false)
    
    const navigate = useNavigate(0);

    const formValid = (  ) => {
        let valid = true
        if( email.length<4 ){
            setErrorEmail(true);
            valid = false
        }
        if(password.length <=5){
            setErrorPassword(true)
            valid = false
        }
        return valid
    } 


    const handleSubmit = (e) => {
        e.preventDefault();
        if( !formValid()){
            return
        }
        setAuth(1);
        navigate('/management_and_reporting')
        
    }

    return (
        <div>
            <div className="login__login-box">
                <img src={img1} className="login__imgInter" alt=""/>
                <div className="login__title-log">Inicio de sesión</div>
                <form onSubmit={ (e) => handleSubmit(e) }>

                    <div>
                        <div  
                             className="login__label">
                                Correo electrónico
                        </div>

                        <input type="email" 
                               name="" 
                               value={email}
                               id="emailInput" 
                               className={`login__input-login ${errorEmail && 'input-error'}`} 
                               onChange={(e)=>{
                                    setErrorEmail(false); 
                                    setEmail(e.target.value)}
                                } 
                               title="" 
                               placeholder="Ingresar tu correo electrónico"
                        />
                        
                        { errorEmail && (<h4 className="msg-error">El email no es válido</h4>) }
                    </div>

                    <div>
                        <div  
                             className="login__label mt-3">
                                Contraseña
                        </div>
                        <input type="password" 
                               value={password}
                               name="" 
                               id="inputPassword" 
                               className={`login__input-login  ${errorPassword && 'input-error'}`} 
                               onChange={(e)=>{
                                    setErrorPassword(false)
                                    setpassword(e.target.value)
                                }} 
                               placeholder="Ingresa tu contraseña"/>
                        { errorPassword && (<h4 className="msg-error ">Debe contener al menos 6 caracteres</h4>) }
                    </div>

                    <div className="d-flex px-18px mb-2 mt-3">
                        <input type="checkbox" name="" id="input"value="" title="" style={{position:'static'}}/>
                        <span htmlFor="input" className="login__remember mb-0 ml-1">Recuerdame</span>
                        <div className="m-a"></div>
                        <div className="login__forget"><span onClick={()=>  navigate('forgot-password')}> Olvidé mi contraseña</span></div>
                    </div>
                        <button  
                            className="login__btn-log" type="submit" 
                        // onClick={login}
                        >Ingresar</button>
                    
                </form>
                <img src={img2} className="login__imgbottom" alt=""/>
            </div>
        </div>
    );
};

export default Login;
