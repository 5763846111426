import React, { useState,useEffect } from 'react'
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import Column from 'antd/lib/table/Column';
import { Row } from 'primereact/row';
import ColumnGroup from 'antd/lib/table/ColumnGroup';
import Xicon from '../../../../../img/icons/xicon.svg'
import { DataTable } from 'primereact/datatable';
import Modal2 from '../modal2/Modal2';

import './Modal1.scss'



const Modal1 = ({modalState,setOpenModalEdit}) => {
    useEffect(()=>{
        const bloked = document.getElementById('body_class')
        if(modalState === true)
        {
            bloked.style.overflow="hidden";
        }
        return()=>{bloked.style.overflow="scroll";} 
        
    },[modalState])
    const imgTemplate = () =>
    {
        return(
            <img src={Xicon} style={{cursor:"pointer"}} onClick={()=> {modal2Function();document.getElementById('fondo').style.display="none";document.getElementById('contenido').style.display="none"}}></img>
        )
    }
    const [modal2, setModal2] = useState(false);
    const modal2Function=()=>
    {
        setModal2(true)
    }
    var arrayItems = [
        {
            field1: "Automoviles",
            field2: "34",
            field3: "Nissan Sentra 2021",
            field4: "ewqe4321ewqe231",
            field5: "Vehículo",
            field6: "Lorem ipsum dolor sit amet, fweaw",
            field7: "",


        },
        {
            field1: "Automoviles",
            field2: "34",
            field3: "Nissan Sentra 2021",
            field4: "ewqe4321ewqe231",
            field5: "Vehículo",
            field6: "Lorem ipsum dolor sit amet, fweaw",
            field7: "",
        },
        {
            field1: "Automoviles",
            field2: "34",
            field3: "Nissan Sentra 2021",
            field4: "ewqe4321ewqe231",
            field5: "Vehículo",
            field6: "Lorem ipsum dolor sit amet, fweaw",
            field7: "",
           
        },
    ]
    var arrayHeader = [
        { field: "field1" },
        { field: "field2" },
        { field: "field3" },
        { field: "field4" },
        { field: "field5" },
        { field: "field6" },
        { field: "field7" },
    ]

    let headerGroup =
    <ColumnGroup>
        <Row>
            <Column header="Familia" colSpan={1} className="header-color border-radius-left header-table" />
            <Column header="Sección" colSpan={1} className="header-color header-table" />
            <Column header="Producto" colSpan={1} className="header-color header-table" />
            <Column header="Nro de serie." colSpan={1} className="header-color header-table" />
            <Column header="Tipo de recurso" colSpan={1} className="header-color header-table" />
            <Column header="Observaciones" colSpan={1} className="header-color header-table" />
            <Column header="Acciones" colSpan={1} className="header-color header-table border-radius-right" />
        </Row>
    </ColumnGroup>

  return (
    <>
    <div className='Modal_container' id='contenido'> 
        <div className='title-container' >
            <div className='title'>Recursos asignados</div>
        </div>
            <div className='dt-modal1'>
                <div></div>
                <div>
                    <DataTable value={arrayItems} headerColumnGroup={headerGroup} responsiveLayout="scroll"  className="table-activations" >

                    {arrayHeader.map((item, key) => {
                        switch (key) {
                            case 6:
                                return (
                                    <Column body={imgTemplate} colSpan={item.colspan ? item.colspan : 1} className="rowdata" key={key} />
                                )
                                break;
                            default:
                                return (
                                    <Column field={item.field} colSpan={item.colspan ? item.colspan : 1} className="rowdata" key={key} />

                                );
                                break;
                        }

                    })}
                    </DataTable>
                </div>
            </div>
           
    </div>  
    <div className='Modal__background' id='fondo' onClick={()=>{setOpenModalEdit(false)}}></div>
    {modal2 && (<Modal2 setOpenModalEdit= {setModal2} />)}
    </>
)}

export default Modal1