import React, { useState } from 'react'
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import { Dropdown } from 'primereact/dropdown';



import './ModalGenerate.scss'


const ModalEdit = ({setOpenModalGenerate}) => {


  return (
    <>
    <div className='Modal_container'>   
        <h3 className='mt-4'>Generar Rendición</h3>
       
       <div className='fila mt-4'>
       <p>NRO Rendición:</p>
       <p>5</p>
       </div>
       <div className='fila'>
       <p>CANTIDAD DE LEGAJOS DE LA RENDICIÓN:</p>
       <p>5</p>
       </div>
        <div className='mt-5'>
            
            <button className='btn mr-5 pl-5 pr-5' onClick={()=>setOpenModalGenerate(false)}>Cancelar</button>
            <button className='btn btn-primary pl-5 pr-5' onClick={()=>setOpenModalGenerate(false)}>Guardar</button>
        </div>
    </div>
    <div className='Modal__background'></div>
    </>
)}

export default ModalEdit