import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import 'primeflex/primeflex.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import Swal from 'sweetalert2'

import './RecoverPassword.scss'


import img1 from "../../../img/log/intertec.png";
import img2 from "../../../img/log/image.png";




const RecoverPassword = ({setAuth}) => {

    const navigate = useNavigate(0);

    // Constraseña uno
    const [password, setPassword] = useState('')
    const [Errorpassword, setErrorPassword] = useState(false)
    // Contraseña dos
    const [password2, setPassword2] = useState('')
    const [Errorpassword2, setErrorPassword2] = useState(false)

    useEffect(() => {
        setAuth((a)=> {
            a=0
        });
    }, [])


    const validForm = () => {
        var valid = true;
        if (password.length<=5){
            setErrorPassword('La contraseña debe contener al menos 6 caracteres')
            valid = false;
        }
        if (password2.length<=5){
            setErrorPassword2('La contraseña debe contener al menos 6 caracteres')
            valid = false;
        }
        if (password != password2){
            setErrorPassword2('Las contraseñas no coinciden')
            valid = false;
        }
        return valid
    }
    

    const handleSubmit = (e) => {
        e.preventDefault();
        const button = document.getElementsByClassName('swalBtnColor')
        button.value = 'Simon'
           


        if(validForm()) {

            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                  confirmButton: 'btn btn-success',
                  cancelButton: 'btn btn-danger'
                },
                buttonsStyling: false
              })
    
              swalWithBootstrapButtons.fire({
                title: 'Se actualizó la contraseña correctamente',
                text: "",
                icon: 'success',
                showCancelButton: false,
                confirmButtonText: 'Aceptar',
                reverseButtons: true
              })
              setPassword('');
              setPassword2('')
        }
    }


    return (
        <div>
            <div className="login__login-box">
                <img src={img1} className="login__imgInter" alt=""/>
                <div className="login__title-log">Recuperar contraseña</div>
                <form onSubmit={(e) => handleSubmit(e)} >
                    
                    <div>
                        <div htmlFor="" className="login__label ">Contraseña</div>
                        <input type="password" 
                               value={password}
                               className={`login__input-login ${Errorpassword && 'input-error'}`} 
                               onChange={(e)=>{setErrorPassword(false); setPassword(e.target.value) }} 
                               placeholder="Ingresa tu contraseña"/>
                        {Errorpassword && (<h4 className="msg-error text-right">{Errorpassword}</h4>)}
                    </div>

                    <div>
                        <div htmlFor="" className="login__label">Confirmar contraseña</div>
                        <input type="password" 
                               name="" 
                               value={password2}
                               className={`login__input-login ${Errorpassword2 && 'input-error'}`}
                               onChange={(e)=>{setErrorPassword2(false); setPassword2(e.target.value)}} 
                               placeholder="Confirmar contraseña"/>
                        {Errorpassword2 && (<h4 className="msg-error text-right">{Errorpassword2}</h4>)}
                    </div>

                    <div className="d-flex px-18px mb-2">
                        <div className="m-a"></div>
                    </div>
                        <button className="login__btn-log text" type="submit" 
                        // onClick={login}
                        >Actualizar</button>
                    
                </form>
                <img src={img2} className="login__imgbottom" alt=""/>
            </div>
        </div>
    );
};

export default RecoverPassword;
