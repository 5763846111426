import React from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import { Ripple } from 'primereact/ripple';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faFileWord } from '@fortawesome/free-solid-svg-icons';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import './Table.scss';

const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

const Table = ({ arrayHeader, arrayItems, dates }) => {

    let headerGroup = <ColumnGroup>
        <Row>


            <Column header="Antigüedad" colSpan={1} className="dth-child border-radius-left" />
            <Column header="Fecha activación" colSpan={1} className="dth-child" />
            <Column header="SDS" colSpan={1} className="dth-child" />
            <Column header="Tipo POS" colSpan={1} className="dth-child" />
            <Column header="POS" colSpan={1} className="dth-child" />
            <Column header="IMPULSADORA" colSpan={1} className="dth-child" />
            <Column header="NIM" colSpan={2} className="dth-child" />
            <Column header="SIMCARD" colSpan={1} className="dth-child" />
            <Column header="Bodega SIMCARD" colSpan={1} className="dth-child" />
            <Column header="Región" colSpan={1} className="dth-child" />
            <Column header="Sub Canal" colSpan={1} className="dth-child" />
            <Column header="Supervisor" colSpan={1} className="dth-child" />
            <Column header="Estado Rendición Legajos" colSpan={1} className="dth-child border-radius-right" />

        </Row>


        {/* {dates.map((item, key) => {
            return (
                <Row key={key}>
                    <Column header={item} colSpan={2} className="date-child-activations" />
                </Row>
            );
        })} */}

    </ColumnGroup>;

    const dateBodyTemplate = (rowData) => {
        console.log("rowdata", rowData)
        return (
            <td rowSpan={2} colSpan={2} className="date-child-activations">{rowData.date+"511"}</td>
        );
    };


    const exportExcel = () => {
        const ws = XLSX.utils.json_to_sheet(arrayItems);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, 'table_' + new Date().getTime() + fileExtension);
    }
    const template1 = {
        layout: 'PrevPageLink PageLinks NextPageLink',
        'PrevPageLink': (options) => {
            return (
                <button type="button" className={"btnGreen"} onClick={options.onClick} disabled={options.disabled}>
                    <span>Anterior</span>
                    <Ripple />
                </button>
            )
        },
        'NextPageLink': (options) => {
            return (
                <button type="button" className={"btnGreen"} onClick={options.onClick} disabled={options.disabled}>
                    <span>Siguiente</span>
                    <Ripple />
                </button>
            )
        },
        
        
        
    };
    return (
        <div>

            <DataTable value={arrayItems} headerColumnGroup={headerGroup} paginator rows={10} responsiveLayout="scroll" className="table-activations"  paginatorTemplate={template1}>
                
                {/* <ColumnGroup> */}
                    {/* <Row> */}
                    {arrayHeader.map((item, key) => {
                        console.log(item)
                        return (
                            <Column field={item.field} colSpan={item.colspan ? item.colspan : 1}  className={item.className ? item.className : 1} key={key} />
                        );
                    })}
                    {/* </Row> */}
                {/* </ColumnGroup> */}

            </DataTable>

            {/* <div className="div-downloads-dashboard">
                <span className="text-download-dashboard">Descargar como</span>
                <FontAwesomeIcon onClick={exportExcel} icon={faFileWord} className="icon-word-dashboard" />
                <FontAwesomeIcon onClick={exportExcel} icon={faFileExcel} className="icon-excel-dashboard" />
            </div> */}
        </div>
    );
};

export default Table;
