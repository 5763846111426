import React, { useState, useEffect, useRef } from 'react';


import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';

import { DataTable } from 'primereact/datatable';
import Modal from '../Modal/Modal'
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { ToggleButton } from 'primereact/togglebutton';

import './Table.scss'




  const Table = ({data, color, title}) => {
  const [open, setOpen] = useState();

  const img = (item)=> {
    return (
      <div className='Table__file'>
          <img src={item.file} />
      </div>
  )}

  const action = (item) => {
      return(
        <button type='button' className='Table__btnAction' onClick={()=> setOpen(true)} >{item.action}</button>
    )}

  return (
    <div className='Table__container'>
    <h2 className={`Table__title-${color}`}>{title}</h2>

    <DataTable className={`Table__table Table__body-${color}`} value={data} stripedRows='false' scrollable scrollHeight="160px" >
      <Column field={img}   align='center' alignHeader='center' header="TIPO DE ARCHIVO" style={{ maxWidth: '150px' }}></Column>
      <Column field="desc"   header="DESCRIPCIÓN" style={{ maxWidth: '1350px' }}></Column>
      <Column field={action} header="ACCIÓN" style={{ minWidth: '150px',maxWidth: '160px' }}></Column>
    </DataTable>
      {open && (<Modal setOpen={setOpen} />)}

     </div>
  )
}

export default Table