
const tableInfo = {
    headerList: [
        {
            field: 'num',
            header: '#',
            order: false
        },
        {
            field: 'date',
            header: 'FECHA DE INSERCIÓN',
            order: false
        },
        {
            field: 'plan',
            header: 'PLAN',
            order: false
        },
        {
            field: 'prom',
            header: 'PROMOCIÓN',
            order: false
        },
        {
            field: 'descprom',
            header: 'DESCRIPCIÓN PROMO',
            order: false
        },
        {
            field: 'registers',
            header: 'REGISTROS',
            order: false
        },
        {
            field: 'asig',
            header: 'ASIGNAR',
            order: false
        }
    ],
    rows: [
        {
            num: 1,
            date: '21/12/21',
            plan: 'MPP22',
            prom: '468',
            descprom: 'PP SIM PREACT HOMO PROPIO',
            registers: '23',
            asig: '',
        },
        {
            num: 2,
            date: '21/12/21',
            plan: 'MPP22',
            prom: '468',
            descprom: 'PP SIM PREACT HOMO PROPIO',
            registers: '23',
            asig: '',
        },
        {
            num: 3,
            date: '01/12/21',
            plan: 'MPP15',
            prom: '468',
            descprom: 'PP SIM PREACT HOMO PROPIO',
            registers: '23',
            asig: '',
        },
        {
            num: 4,
            date: '02/12/21',
            plan: 'MPP16',
            prom: '468',
            descprom: 'PP SIM PREACT HOMO PROPIO',
            registers: '23',
            asig: '',
        },
        {
            num: 5,
            date: '03/12/21',
            plan: 'MPP17',
            prom: '468',
            descprom: 'PP SIM PREACT HOMO PROPIO',
            registers: '23',
            asig: '',
        },
        {
            num: 6,
            date: '04/12/21',
            plan: 'MPP18',
            prom: '468',
            descprom: 'PP SIM PREACT HOMO PROPIO',
            registers: '23',
            asig: '',
        },
        {
            num: 7,
            date: '05/12/21',
            plan: 'MPP19',
            prom: '468',
            descprom: 'PP SIM PREACT HOMO PROPIO',
            registers: '23',
            asig: '',
        },
        {
            num: 8,
            date: '06/12/21',
            plan: 'MPP20',
            prom: '468',
            descprom: 'PP SIM PREACT HOMO PROPIO',
            registers: '23',
            asig: '',
        },
        {
            num: 9,
            date: '07/12/21',
            plan: 'MPP21',
            prom: '468',
            descprom: 'PP SIM PREACT HOMO PROPIO',
            registers: '23',
            asig: '',
        },
        {
            num: 10,
            date: '07/12/21',
            plan: 'MPP21',
            prom: '468',
            descprom: 'PP SIM PREACT HOMO PROPIO',
            registers: '23',
            asig: '',
        },
        {
            num: 11,
            date: '07/12/21',
            plan: 'MPP21',
            prom: '468',
            descprom: 'PP SIM PREACT HOMO PROPIO',
            registers: '23',
            asig: '',
        }    

    ]
}

export const infoPlans = {
    tableInfo
};