import React , {useState} from "react"
import './cardProgressBar.scss'
import { ProgressBar } from 'primereact/progressbar';

const CardProgressBar=()=>{

    const [value1, setValue1] = useState(0);

    return(
        <div className="cardProgressBar">
            <p className="textActivate">A ACTIVAR</p>

            <div className="flexText2">
                <p className="titleProgress">PREPAGO</p>
                <p className='boldText2'>245</p>
            </div>
            <ProgressBar showValue={false} style={{height:10, borderRadius:10}} value={50} color={'#ff2631'}/>
            <div className="flexText2">
                <p className="titleProgress">POSPAGO</p>
                <p className='boldText2'>245</p>
            </div>
            <ProgressBar showValue={false} style={{height:10, borderRadius:10}} value={65} color={'#036402'}/>
            <div className="flexText2">
                <p className="titleProgress">DTH</p>
                <p className='boldText2'>245/<span className='colorRed'>15</span></p>
            </div>
            <ProgressBar showValue={false} style={{height:10, borderRadius:10}} value={65} color={'#0271c1'}/>
            <div className="flexText2">
                <p className="titleProgress">GPON</p>
                <p className='boldText2'>245/<span className='colorRed'>60</span></p>
            </div>
            <ProgressBar showValue={false} style={{height:10, borderRadius:10}} value={70} color={'#f8a100'}/>
        </div>
    )
}
export default CardProgressBar
