import React, { useState, useEffect } from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import data from '../../../../mockups/ActivationRisk.json'
import './Tables.scss';
const Tables = () => {
    const [results, setResults] = useState(data)
    return (
        <div>
            <div className="primeReactNav">
                <div>
                    <h6 className="subtitle">Registros pendientes</h6>
                </div>
                <div>


                </div>

            </div>

            <DataTable
                value={results}
                className="table-dashboard"
            >

                <Column field="Nro" header="Nro" sortable />
                <Column field="fechaSolicitud" header="Fecha de solicitud" sortable />
                <Column field="ci" header="CI" sortable />
                <Column field="cliente" header="Cliente" sortable />
                <Column field="edad" header="Edad" sortable />
                <Column field="tiporiesgo" header="Tipo de riesgo" sortable />
                <Column field="cliente" header="Solicitado por" sortable />
                <Column field="supervisor" header="Supervisor" sortable />
                <Column field="subcanal" header="Sub canal" sortable />

            </DataTable>
        </div>
    );
};
export default Tables
