import React, { useState, useEffect } from "react";
import "../Occupation/Occupation.scss";
import TableOccupation from "../Occupation/TableOccupation";
import imgExel from "../../../../../img/jpg/exel.png";
import imgCsv from "../../../../../img/jpg/csv.png";
import { Modal } from "antd";
import { DataTable } from "primereact/datatable";
import { infoParameters } from "./bankData";

const Bank = () => {
  const [searchEnter, setSearchEnter] = useState();
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [dataParameters, setDataParameters] = useState([]);
  const [column, setColumn] = useState([]);

  useEffect(() => {
    const getData = async () => {
      setColumn(infoParameters.tableInfo.headerList);
      setDataParameters(infoParameters.tableInfo);
    };
    getData();
  }, []);
  return (
    <div
      className="parameters"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: "20px",
      }}
    >
      <div>
        <h4 className="parameters-title">
          Parámetros <span className="parameters-title-span">{" <"}Bancos</span>
        </h4>
        <div className="parameters-container">
          <div className="parameters-container-header">
            <div className="parameters-container-header-search">
              <label>Filtrar por:</label>
              <input
                value={searchEnter}
                onChange={(e) => {
                  setSearchEnter(e.target.value);
                }}
                type="search"
                placeholder="Buscar por banco"
              />
            </div>
          </div>
          <div className="parameters-table">
            <TableOccupation
              value={dataParameters.rows}
              column={column}
              setColumn={setColumn}
              showModalDelete={showModalDelete}
              setShowModalDelete={setShowModalDelete}
              searchEnter={searchEnter}
            />
          </div>
          <div className="parameters-footer">
          <div className="parameters-footer-download">
            <label>Descargar como</label>
            <button className="parameters-footer-download-button">
              <img src={imgExel} />
            </button>
            <button className="parameters-footer-download-button">
              <img src={imgCsv} />
            </button>
          </div>
          <div className="parameters-footer-pag">
            <button className="parameters-footer-pag-button">Anterior</button>
            <p className="parameters-footer-pag-text">1</p>
            <p className="parameters-footer-pag-text">2</p>
            <p className="parameters-footer-pag-text">3</p>
            <p className="parameters-footer-pag-text">4</p>
            <button className="parameters-footer-pag-button">Siguiente</button>
          </div>
        </div>
        </div>
        
      </div>
      <div>
        <footer style={{ display: "flex", justifyContent: "center" }}>
          <p>2022 GestiClaro. Todos los derechos reservados</p>
        </footer>
      </div>
    </div>
  );
};
export default Bank;
