import React, { useEffect } from "react";
import HeadersSection from "../../../../components/book/headersSection/HeadersSection";
import Form from "./Form";
import FooterSimple from "../../../../components/book/footerSimple/FooterSimple";
import { info } from '../../../../../mockups/dashboard';
import './EditPlan.scss';
import 'primeflex/primeflex.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { useParams } from "react-router";


const EditPlan = ({closeModule}) => {
    const {id} = useParams()
    
    return (
        <div className="container-createplan">
            <HeadersSection
                title={id ? 'Editar plan' : 'Crear plan'}
                exeptions={""}
                date={info.headerInfo.date}
            />
            <div className="form-createplan">
                <div className="div-container-stand" style={{ padding: 32 }}>
                    <Form closeModule={closeModule} />
                </div>
                <FooterSimple />
            </div>
        </div>
    );
};

export default EditPlan;

