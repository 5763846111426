import React, { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Modal } from "antd";
import "./Table.scss";
import { Link } from "react-router-dom";
import { Ripple } from 'primereact/ripple';
import { classNames } from 'primereact/utils';
import editIcon from "../../../../../images/tableIcons/blueEditIcon.svg";
import statusinactiveicon from "../../../../../images/tableIcons/statusinactive.png";
import statusactiveicon from "../../../../../images/tableIcons/statusactive.png";
import deleteicon from "../../../../../images/tableIcons/delete.png";

const Table = ({ arrayHeader, arrayItems, setShowCreatePlan }) => {
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  
  const defaultPaginatorTemplate = {
    layout: 'PrevPageLink PageLinks NextPageLink',
    'PrevPageLink': (options) => {
        return (
            <button type="button" className='paginator-before greenBtn ' onClick={options.onClick} disabled={options.disabled}>
                Anterior
                <Ripple />
            </button>
        )
    },
    'NextPageLink': (options) => {
        return (
            <button type="button" className='paginator-after greenBtn' onClick={options.onClick} disabled={options.disabled}>
                Siguiente
                <Ripple />
            </button>
        )
    },
    'PageLinks': (options) => {
        if ((options.view.startPage === options.page && options.view.startPage !== 0) || (options.view.endPage === options.page && options.page + 1 !== options.totalPages)) {
            const className = classNames(options.className, { 'p-disabled': true });

            return <span className={className} style={{ userSelect: 'none' }}>...</span>;
        }

        return (
            <button type="button" className={options.className} onClick={options.onClick}>
                {options.page + 1}
                <Ripple />
            </button>
        )
    },
};

  const asingTemplate = (rowData) => {
    return (
      
      <div className="centeredActions">
        <div className="sectionActions">
          <Link to="/plan-edit=1" className="btnedit-table"  >
            <img src={editIcon} />
          </Link>
          <div className="btnedit-table" onClick={() => {setShowModalEdit(true)}}>
            <img src={statusactiveicon} />
          </div>
          <div className="btnedit-table" onClick={() => setShowModalEdit(true)}>
            <img src={statusinactiveicon} />
          </div>
          <div
            className="btnedit-table"
            onClick={() => setShowModalDelete(true)}
          >
            
            <img src={deleteicon} />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <DataTable
        value={arrayItems}
        rows={10}
       
        paginator
        paginatorTemplate={defaultPaginatorTemplate}
        stripedRows
        responsiveLayout="scroll"
        className="table-plans"
      >
        {arrayHeader.map((item, key) => {
          return (
            <Column
              field={item.field}
              header={item.header}
              key={key}
              body={item.field === "asig" ? asingTemplate : null}
            />
          );
        })}
      </DataTable>

      <Modal
        visible={showModalEdit}
        centered={true}
        onOk={() => setShowModalEdit(false)}
        onCancel={() => setShowModalEdit(false)}
        footer={[
          <button
            className="btnModalCancel"
            onClick={() => setShowModalEdit(false)}
          >
            Cancelar
          </button>,
          <button className="btnModalDo">Desactivar</button>,
        ]}
      >
        <div className="contentModal">
          {/* <img src={warning}/> */}

          <p className="titleModal">¿Quieres desactivar este Plan?</p>
          <p>"MPP22"</p>
        </div>
      </Modal>

      <Modal
        visible={showModalDelete}
        onOk={() => setShowModalDelete(false)}
        onCancel={() => setShowModalDelete(false)}
        centered={true}
        footer={[
          <button
            className="btnModalCancel"
            onClick={() => setShowModalDelete(false)}
          >
            Cancelar
          </button>,
          <button className="btnModalDo">Eliminar</button>,
        ]}
      >
        <div className="contentModal">
          {/* <img src={warning}/> */}

          <p className="titleModal">Se eliminará este Plan</p>
          <p>"MPP22"</p>
        </div>
      </Modal>
    </div>
  );
};

export default Table;
