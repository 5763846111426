import React from "react"
import './activationCard.scss'


const ActivationCard=({name, number, color, image})=>{

    return(

        <div className="cardActivation" style={{border:`1px solid ${color}`}}>
            <p className="titleCard" style={{color:color}}>{name}</p>

            <div className="flexCard">
                <p className="boldText">{number}</p>
                <img src={image}/>
            </div>

        </div>
    )

}
export default ActivationCard
