import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import deleteicon from "../../../../../../images/tableIcons/eyeIcon.png";
import StaffDetail from "./StaffDetail";
import csv from "../../../../../../images/tableIcons/csv.png";
import excel from "../../../../../../images/tableIcons/excel.png";
const Penalties = () => {
  const tableInfo = {
    rows: [
      {
        movimiento: "001",
        fecha: "05 - Enero - 2020",
        observaciones: "solicitud.pdf",
        usuario: "$-120.00",
      },
    ],
  };

  const column = [
    {
      field: "movimiento",
      header: "Nro.de movimiento",
      order: false,
    },
    {
      field: "fecha",
      header: "Fecha demovimiento",
      order: false,
    },
    {
      field: "usuario",
      header: "Usuario",
      order: false,
    },
    {
      field: "observaciones",
      header: "Observaciones",
      order: false,
    },
    {
      field: "seccion",
      header: "Sección",
      order: false,
    },
    {
      field: "tipo",
      header: "Tipo",
      order: false,
    },
  ];
  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <div>
        <StaffDetail
          opcion="bitacora"
          className="bitacora"
          styles={{ padding: "50px 20px 10px" }}
          contenido={
            <div className="dataGeneral">
              <div className="dataGeneralBtn">
                <input type="search" placeholder="Buscar por nombre" />
              </div>

              <div className="tableDownload">
                <DataTable
                  value={tableInfo.rows}
                  rows={10}
                  className="parameters-table"
                  paginator={true}
                >
                  {column.map((e, key) => {
                    return (
                      <Column
                        field={e.field}
                        header={e.header}
                        body={e.body ? e.body : null}
                      />
                    );
                  })}
                </DataTable>
              </div>
              <div
                style={{ display: "flex", gap: "11px", alignItems: "center" }}
              >
                <p style={{ fontSize: "12px", margin: "0" }}>Descargar como</p>
                <div style={{ display: "flex", gap: "22px" }}>
                  <div
                    style={{
                      width: "40px",
                      height: "40px",
                      display: "grid",
                      placeItems: "center",
                      borderRadius: "5px",
                      backgroundColor: "#ffffff",
                      border: "1px solid  #e3e3e3",
                    }}
                  >
                    <img style={{ width: "20px", height: "20px" }} src={csv} />
                  </div>
                  <div
                    style={{
                      width: "40px",
                      height: "40px",
                      display: "grid",
                      placeItems: "center",
                      borderRadius: "5px",
                      backgroundColor: "#ffffff",
                      border: "1px solid  #e3e3e3",
                    }}
                  >
                    <img
                      style={{ width: "20px", height: "20px" }}
                      src={excel}
                    />
                  </div>
                </div>
              </div>
            </div>
          }
        />
      </div>
      <div>
        <footer style={{ display: "flex", justifyContent: "center" }}>
          <p>2022 GestiClaro. Todos los derechos reservados</p>
        </footer>
      </div>
    </div>
  );
};

export default Penalties;
