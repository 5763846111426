import React, { useState } from "react";
import "./SearchFiles.scss";
import HeadersSection from "../../../../components/book/headersSection/HeadersSection";
import InputSearch from "../../../../components/InputSearch/InputSearch";
import searchIcon from "../../../../img/icons/search.svg";
import PrePaid from "./Prepaid";
import Observations from "./Observations";
import TableSearchFiles from "./Table";
import Table2SearchFiles from "./Table2";
import data1 from "./users.json";
import data2 from "./users2.json";

export default function FilesSearch() {
  const [search, setSearch] = useState(false);
  const [change, setChange] = useState(true);
  const [change1, setChange1] = useState(false);

  return (
    <div>
      <HeadersSection
        title={"Buscar Legajos"}
        exeptions={{}}
        date={"Viernes 30 de Julio del 2021 | Semana: 18 | Cierre de mes: 1"}
      />
      <div className="files-search-contain">
        <div className="input-search">
          <InputSearch />
          <div className="button-container" onClick={(e) => setSearch(!search)}>
            <button className="btn-search">
              <img src={searchIcon}></img>
            </button>
          </div>
        </div>
      </div>
      {search === true ? (
        <>
          <PrePaid />
          <Observations />
          <div className="observations-contain-table cambio-table">
            <div className="observations-contain-table-title">
              <div
                onClick={() => {
                  setChange(true);
                  setChange1(false);
                }}
                className={change ? "title-blue" : "title-black"}
              >
                HISTORIAL DE OBSERVACIONES LEGAJOS AGENTE
              </div>
              <div
                onClick={() => {
                  setChange(false);
                  setChange1(true);
                }}
                className={change1 ? "title-blue" : "title-black"}
              >
                HISTORIAL DE OBSERVACIONES LEGAJOS CLARO
              </div>
            </div>
            {change ? (
              <TableSearchFiles data={data1} />
            ) : (
              <TableSearchFiles data={data2} />
            )}
          </div>
          <div className="observations-contain-table">
            <div className="datatable2-title">Servicios Asociados</div>
            <Table2SearchFiles />
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
}
