
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faSearch } from '@fortawesome/free-solid-svg-icons';
import { largeWord } from './functions';
//import { InputText } from 'primereact/inputtext';
import './AbsoluteSelect.scss';

const AbsoluteSelect = ({ inputName, list, setOpenSelect, openSelect,setIdSelect }) => {
    const [selected, changeSelect] = useState('');
    const [showList, setShowList] = useState(false);
    const [heightCard, setHeightList] = useState(0);
    const [listp,setList] = useState(list);

    useEffect(() => {
        if (openSelect) {
            setHeightList(0);
            setShowList(false);
        }
    }, [openSelect]);

    const viewCardList = () => {
        setOpenSelect(!showList);
        setTimeout(() => {
            triggerOpen();
            setOpenSelect(false);
        }, 100);
    };

    const triggerOpen = () => {
        setHeightList(!showList ? 218 : 0);
        if (!showList) {
            setTimeout(() => {
                setShowList(!showList);
            }, 300);
        } else setShowList(!showList);
    };

    const choiceList = (value) => {
        changeSelect(value);
        setIdSelect(value.id);
        console.log('valor', value)
        viewCardList();
    };
    console.log("list",listp)
    return (
        <div className="container-select-absolute">
            <div className="card-select-absolute" style={{zIndex: showList ? 2 : 0}}>
                <div className="grid-container-as">
                    <div className="input-name" style={{
                        paddingLeft: largeWord(inputName, 'flag') ? '0px' : '8px',
                        paddingRight: largeWord(inputName, 'flag') ? '0px' : '8px'
                    }}>
                        <p className={`inputName ${largeWord(inputName, 'className')}`} >{inputName}</p>
                    </div>
                    <div className="select">
                        <div className="inputSelected-relativeSelect">
                            <p
                                style={{
                                    color: selected ? '#000000' : '#e3e3e3'
                                }}
                            >{selected || 'Selección'}</p>
                        </div>
                    </div>
                    <div className="open-select div-iconCheronDown-rs">
                        <a onClick={viewCardList}><FontAwesomeIcon icon={showList ? faChevronUp : faChevronDown} /></a>
                    </div>
                </div>
            </div>
            <div className="card-list-select-absolute" style={{ height: heightCard }}>
                {showList &&
                    <div style={{ padding: 30 }}>
                        <div className="search-container">
                            <div className="sub-search-container">
                                <input type="text" placeholder="Buscar" />
                                <div><FontAwesomeIcon icon={faSearch} /></div>
                            </div>
                        </div>
                        <div className="list-container-rs">
                            {listp.map((item,key) => {
                                return (
                                    <div key={key} className="item-list-rs" onClick={() =>{ choiceList(item); console.log(item)}}>{item}</div>
                                );
                            })}

                        </div>
                    </div>
                }
            </div>
        </div>

    );
};

export default AbsoluteSelect;
