import React, { useState } from "react";
import './PeopleManagment.scss';

//componts
import HeadersSection from "../headersSection/HeadersSection";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import { Ripple } from 'primereact/ripple';
import FooterSimple from "../footerSimple/FooterSimple";
//assets
import calendarIcon from '../../../img/icons/calendar.png'
import searchIcon from '../../../img/icons/searchIcon.svg'
import redEyeIcon from '../../../img/icons/red-eye.svg'
import profileImg from '../../../img/jpg/profile.jpg'
import pdfIcon from '../../../img/icons/pdf.svg'

const PeopleManagment = () => {
    //table templates
    const imgTemplate = () => {
        return (
            <div className="profile-image">
                <img src={profileImg} ></img>
            </div>
        );
    }
    const stateTemplate = (RowData) => {
        console.log("RowData", RowData.field11)
        if (RowData.field11 === "Activo") {
            return (
                <div className="btn" style={{ background: "#036402" }}><span>Activo</span></div>
            );
        }
        else if (RowData.field11 === "Inactivo") {
            return (
                <div className="btn" style={{ background: "#000000" }}>Inactivo</div>
            );
        }
        else {
            return (
                <div className="btn" style={{ background: "#ff2631" }}>Bloqueado</div>
            );
        }

    }
    const actionsTemplate = () => {
        return (
            <>
                <img src={redEyeIcon} style={{ paddingRight: "20px" }}></img>
                <img src={pdfIcon} style={{ paddingRight: "20px" }}></img>
            </>
        );
    }
    const typeTemplate = (RowData) => {
        switch (RowData.field10) {
            case "alta":
                return (
                    <div style={{ color: "#0ec927" }}>ALTA</div>
                )
                break;
            case "baja":
                return (
                    <div style={{ color: "#ff2631" }}>BAJA</div>
                )
                break;
        }
        return (<>

        </>);
    }
    const template1 = {
        layout: 'PrevPageLink PageLinks NextPageLink',
        'PrevPageLink': (options) => {
            return (
                <button type="button" className={"btnGreen"} onClick={options.onClick} disabled={options.disabled}>
                    <span>Anterior</span>
                    <Ripple />
                </button>
            )
        },
        'NextPageLink': (options) => {
            return (
                <button type="button" className={"btnGreen"} onClick={options.onClick} disabled={options.disabled}>
                    <span>Siguiente</span>
                    <Ripple />
                </button>
            )
        },
    };

    var arrayHeader = [
        { field: "field1" },
        { field: "field2" },
        { field: "field3" },
        { field: "field4" },
        { field: "field5" },
        { field: "field6" },
        { field: "field7" },
        { field: "field8" },
        { field: "field9" },
        { field: "field10" },
        { field: "field11" },
        { field: "field12" },
    ]
    var arrayItems = [
        {
            field1: "05 - Enero - 2020",
            field2: "",
            field3: "ARTURO ALEJANDRO LÓPEZ MARTÍNEZ",
            field4: "MATRIZ CDE",
            field5: "ALBA GRICELDA CABAÑAS PALACIOS",
            field6: "5619751",
            field7: "Ventas",
            field8: "GPON",
            field9: "12456456",
            field10: "baja",
            field11: "Inactivo",
            field12: "",

        },
        {
            field1: "05 - Enero - 2020",
            field2: "",
            field3: "ARTURO ALEJANDRO LÓPEZ MARTÍNEZ",
            field4: "MATRIZ CDE",
            field5: "ALBA GRICELDA CABAÑAS PALACIOS",
            field6: "5619751",
            field7: "Ventas",
            field8: "GPON",
            field9: "12456456",
            field10: "alta",
            field11: "Activo",
            field12: "",
        },
        {
            field1: "05 - Enero - 2020",
            field2: "",
            field3: "ARTURO ALEJANDRO LÓPEZ MARTÍNEZ",
            field4: "MATRIZ CDE",
            field5: "ALBA GRICELDA CABAÑAS PALACIOS",
            field6: "5619751",
            field7: "Ventas",
            field8: "GPON",
            field9: "12456456",
            field10: "alta",
            field11: "Bloqueado",
            field12: "",
        },
        {
            field1: "05 - Enero - 2020",
            field2: "",
            field3: "ARTURO ALEJANDRO LÓPEZ MARTÍNEZ",
            field4: "MATRIZ CDE",
            field5: "ALBA GRICELDA CABAÑAS PALACIOS",
            field6: "5619751",
            field7: "Ventas",
            field8: "GPON",
            field9: "12456456",
            field10: "baja",
            field11: "Inactivo",
            field12: "",

        },
        {
            field1: "05 - Enero - 2020",
            field2: "",
            field3: "ARTURO ALEJANDRO LÓPEZ MARTÍNEZ",
            field4: "MATRIZ CDE",
            field5: "ALBA GRICELDA CABAÑAS PALACIOS",
            field6: "5619751",
            field7: "Ventas",
            field8: "GPON",
            field9: "12456456",
            field10: "alta",
            field11: "Activo",
            field12: "",
        },
        {
            field1: "05 - Enero - 2020",
            field2: "",
            field3: "ARTURO ALEJANDRO LÓPEZ MARTÍNEZ",
            field4: "MATRIZ CDE",
            field5: "ALBA GRICELDA CABAÑAS PALACIOS",
            field6: "5619751",
            field7: "Ventas",
            field8: "GPON",
            field9: "12456456",
            field10: "alta",
            field11: "Bloqueado",
            field12: "",
        },
        {
            field1: "05 - Enero - 2020",
            field2: "",
            field3: "ARTURO ALEJANDRO LÓPEZ MARTÍNEZ",
            field4: "MATRIZ CDE",
            field5: "ALBA GRICELDA CABAÑAS PALACIOS",
            field6: "5619751",
            field7: "Ventas",
            field8: "GPON",
            field9: "12456456",
            field10: "baja",
            field11: "Inactivo",
            field12: "",

        },
        {
            field1: "05 - Enero - 2020",
            field2: "",
            field3: "ARTURO ALEJANDRO LÓPEZ MARTÍNEZ",
            field4: "MATRIZ CDE",
            field5: "ALBA GRICELDA CABAÑAS PALACIOS",
            field6: "5619751",
            field7: "Ventas",
            field8: "GPON",
            field9: "12456456",
            field10: "alta",
            field11: "Activo",
            field12: "",
        },
        {
            field1: "05 - Enero - 2020",
            field2: "",
            field3: "ARTURO ALEJANDRO LÓPEZ MARTÍNEZ",
            field4: "MATRIZ CDE",
            field5: "ALBA GRICELDA CABAÑAS PALACIOS",
            field6: "5619751",
            field7: "Ventas",
            field8: "GPON",
            field9: "12456456",
            field10: "alta",
            field11: "Bloqueado",
            field12: "",
        },
        {
            field1: "05 - Enero - 2020",
            field2: "",
            field3: "ARTURO ALEJANDRO LÓPEZ MARTÍNEZ",
            field4: "MATRIZ CDE",
            field5: "ALBA GRICELDA CABAÑAS PALACIOS",
            field6: "5619751",
            field7: "Ventas",
            field8: "GPON",
            field9: "12456456",
            field10: "baja",
            field11: "Inactivo",
            field12: "",

        },
        {
            field1: "05 - Enero - 2020",
            field2: "",
            field3: "ARTURO ALEJANDRO LÓPEZ MARTÍNEZ",
            field4: "MATRIZ CDE",
            field5: "ALBA GRICELDA CABAÑAS PALACIOS",
            field6: "5619751",
            field7: "Ventas",
            field8: "GPON",
            field9: "12456456",
            field10: "alta",
            field11: "Activo",
            field12: "",
        },
        {
            field1: "05 - Enero - 2020",
            field2: "",
            field3: "ARTURO ALEJANDRO LÓPEZ MARTÍNEZ",
            field4: "MATRIZ CDE",
            field5: "ALBA GRICELDA CABAÑAS PALACIOS",
            field6: "5619751",
            field7: "Ventas",
            field8: "GPON",
            field9: "12456456",
            field10: "alta",
            field11: "Bloqueado",
            field12: "",
        },
    ]
    let headerGroup =
        <ColumnGroup>
            <Row>


                <Column header="Fecha de regístro" colSpan={1} className="dth-child border-radius-left header-table" />
                <Column header="Foto" colSpan={1} className="dth-child header-table" />
                <Column header="Nombre" colSpan={1} className="dth-child header-table" />
                <Column header="Sucursal" colSpan={1} className="dth-child header-table" />
                <Column header="Supervisor" colSpan={1} className="dth-child header-table" />
                <Column header="CI" colSpan={1} className="dth-child header-table" />
                <Column header="Perfil" colSpan={1} className="dth-child header-table" />
                <Column header="Canal comercial" colSpan={1} className="dth-child header-table" />
                <Column header="POS" colSpan={1} className="dth-child header-table" />
                <Column header="Tipo" colSpan={1} className="dth-child header-table" />
                <Column header="Estado" colSpan={1} className="dth-child header-table" />
                <Column header="Acciones" colSpan={1} className="dth-child border-radius-right header-table" />
            </Row>
        </ColumnGroup>

    return (
        <>
            <div className="cj-people-managment">
                <div className="container-activations">
                    <HeadersSection
                        title={<div>Gestión personas</div>}
                        exeptions={{}}
                    />
                    <div className="div-selects-activations">
                        <div className="div-container-stand">
                            <div className="top-box">

                                <div className="date">
                                    <div className="title">Filtrar por:</div>
                                    <div className="card">
                                        <div className="date-container">
                                            <div className="calendar-container">
                                                <div className="text">De</div>
                                                <div className="calendar">
                                                    <input type="date" style={{ marginRight: "31px" }}></input>
                                                </div>
                                            </div>
                                            <div className="calendar-container">
                                                <div className="text">Al</div>
                                                <input type="date" style={{ marginRight: "20px" }}></input>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="container-flex">
                                    <div className="selects-container">

                                        {/* input search */}
                                        <div class="input-wrapper">
                                            <input type="search" class="input" placeholder="Search" />
                                            <img src={searchIcon} className="input-icon"></img>
                                        </div>
                                        <select>
                                            <option value="Seleccionar">Seleccionar tipo</option>
                                            <option value="Alta">Alta</option>
                                            <option value="Baja">Baja</option>
                                        </select>
                                        <select>
                                            <option value="nuevo">Más nuevo a mas viejo</option>
                                            <option value="viejo">Más viejo a mas nuevo</option>
                                        </select>
                                        <button style={{ background: "#308ac9" }}>Imprimir agenda</button>
                                        <button style={{ background: "#036402" }}>Nuevo personal</button>
                                    </div>
                                    <div className="selects-container">

                                        {/* input search */}
                                        <select>
                                            <option value="sucursal">Seleccionar sucursal</option>
                                        </select>
                                        <select>
                                            <option value="Seleccionar">Seleccionar supervisor</option>
                                        </select>
                                        <select>
                                            <option value="nuevo">Seleccionar razón social</option>

                                        </select>
                                        <select>
                                            <option value="Seleccionar">Seleccionar estado</option>
                                            <option value=" Activo"> Activo</option>
                                            <option value="Inactivo">Inactivo</option>
                                        </select>
                                        <select>
                                            <option value="Seleccionar">Seleccionar canal</option>
                                        </select>
                                    </div>

                                </div>


                            </div>

                            <DataTable value={arrayItems} headerColumnGroup={headerGroup} responsiveLayout="scroll" className="table-activations" paginator rows={10} paginatorTemplate={template1} >

                                {/* <ColumnGroup> */}
                                {/* <Row> */}
                                {arrayHeader.map((item, key) => {
                                    switch (key) {
                                        case 1:
                                            return (
                                                <Column body={imgTemplate} colSpan={item.colspan ? item.colspan : 1} className="rowdata" key={key} />
                                            )
                                            break;
                                        case 9:
                                            return (

                                                <Column body={typeTemplate} colSpan={item.colspan ? item.colspan : 1} className="rowdata" key={key} />
                                            )
                                            break;
                                        case 10:
                                            return (

                                                <Column body={stateTemplate} colSpan={item.colspan ? item.colspan : 1} className="rowdata" key={key} />
                                            )
                                            break;
                                        case 11:
                                            return (

                                                <Column body={actionsTemplate} colSpan={item.colspan ? item.colspan : 1} className="rowdata" key={key} />
                                            )
                                            break;
                                        default:
                                            return (
                                                <Column field={item.field} colSpan={item.colspan ? item.colspan : 1} className="rowdata" key={key} />

                                            );
                                            break;
                                    }

                                })}

                            </DataTable>
                        

                        </div>
                    </div>
                </div>
            </div>
            <FooterSimple></FooterSimple>
        </>
    );
}
export default PeopleManagment;