import React, { useEffect, useState } from "react";
import './AttendanceRh.scss'

//componts
import HeadersSection from "../../../../components/book/headersSection/HeadersSection";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import { Ripple } from 'primereact/ripple';
import FooterSimple from "../../../../components/book/footerSimple/FooterSimple";
import GeneralModal from "../../../../components/book/GeneralModal/GeneralModal";
//table modal
//assets

import editIcon from '../../../../img/icons/edit.svg'
import profileImg from '../../../../img/jpg/profile.jpg'
import pdfIcon from '../../../../img/icons/pdf.svg'
import searchIcon from '../../../../img/icons/search-gray.svg'
import closeIcon from '../../../../img/icons/close.svg'
import FooterTableW from "../../../../components/book/FooterTable/FooterTable";
import { VirtualScroller } from "primereact/virtualscroller";
const AttendanceRh =()=>
{
    const [searchName, setSearchName] = useState();
    const [modalState, setModalState] = useState(false);
    const [days,setDays] = useState();
    useEffect(()=>{
        
        const fecha = new Date();
        const mont = fecha.getMonth();
        const year = fecha.getFullYear();
        getDaysFromMonth(year,mont+1)
        document.getElementById("selectYear").value = year;
        document.getElementById("selectMonth").value = mont+1;
        
    },[])
    const ModalTemplate=()=>
    {
       
        return(
            <>
                <div className="Modal-container-contract">
                    <div className="title-container-contractExpiration">
                    <div className="title-contractExpiration">Vencimiento de contrato</div>
                    <div>
                        <img src={closeIcon } onClick={()=>{setModalState(false)}} style={{cursor:"pointer"}}></img>
                    </div>
                    </div>
                    <div className="profile-image">
                        <img src={profileImg} ></img>
                        <div className="text-image">BIANCA GONZALES LARA</div>
                    </div>
                    <div className="contaier-inputs">
                        <div className="input-label-modal">
                            <div className="text-top-input">Nro. de documento:</div>
                            <input className="input-blocked" value="4545646546514" disabled></input>
                        </div>
                        <div className="input-label-modal">
                            <div className="text-top-input">Fin de contrato:</div>
                            <input type="date" className="input-blocked" value="4545646546514" disabled></input>
                        </div>
                        <div className="input-label-modal">
                            <div className="text-top-input">Antigüedad del agente:</div>
                            <input type="date" className="input-blocked" value="4545646546514" disabled></input>
                        </div>
                        
                    </div>
                    <div className="contaier-inputs">
                        <div className="input-label-modal">
                            <div className="text-top-input">Nro. de documento:</div>
                            <input type="date" className="input-clasic"  ></input>
                        </div>
                        <div className="input-label-modal">
                            <div className="text-top-input">Fin de contrato:</div>
                            <input type="date" className="input-clasic" ></input>
                        </div>
                        
                        
                    </div>
                    <div className="contaier-inputs">
                        <div className="input-label-modal">
                            <div className="text-top-input">Tipo de puesto:</div>
                            <select>
                                <option>Selecciona una opción</option>
                                <option>Administrativo</option>
                                <option>Comercial</option>
                                <option>BackOffice</option>
                                <option>Servicio técnico</option>
                                <option>Tercerizado</option>
                                <option>Courier</option>
                            </select>
                        </div>
                        
                    </div>
                    <div className="container-btn-modal-meta">
                        <button className="btn-cancel" onClick={()=>{setModalState(false)}}>Cancelar</button>
                        <button className="btn-save" onClick={()=>{setModalState(false)}}>Guardar cambios</button>
                    </div>
                </div>
            </>
        )
        
    }
    //table templates
    
    const imgTemplate = () => {
        return (
            <div className="profile-image">
                <img src={profileImg} ></img>
            </div>
        );
    }
    
    const contentTableTemplate = (status)=>
    {
        if(status === "")
        {
                return(
                    <>
                        <div className="select-umbloked"></div>
                    </>
                ) 
        }
        else
        {
            return(
                <>
                    <div>{status}</div>
                </>
            )
        }
        
        
    }
    const template1 = {
        layout: 'PrevPageLink PageLinks NextPageLink',
        'PrevPageLink': (options) => {
            return (
                <button type="button" className={"btnGreen"} onClick={options.onClick} disabled={options.disabled}>
                    <span>Anterior</span>
                    <Ripple />
                </button>
            )
        },
        'NextPageLink': (options) => {
            return (
                <button type="button" className={"btnGreen"} onClick={options.onClick} disabled={options.disabled}>
                    <span>Siguiente</span>
                    <Ripple />
                </button>
            )
        },
    };
    const getDaysFromMonth = (year,month)=>
    {
        if( (((year % 4 == 0) && (year % 100 != 0 )) || (year % 400 == 0)))
        {   
                if(month === 2)
                {
                    console.log("entro")
                    setDays(29);
                }
                else
                {
                    setDays(new Date(year,month,0).getDate()); 
                }
        }
        else 
        {
            setDays(new Date(year,month,0).getDate());

        }
       

    }
    const SetDaysFromMonth = () =>
    {
        let year = document.getElementById("selectYear").value
        let month = document.getElementById("selectMonth").value
        console.log("year ",year,"   month",month)
        getDaysFromMonth(year,month);
    }

    //supervisores
    var supervisors = [
        "Seleccionar supervisor",
        "JORGE ANTONIO FUERTE DIAZ",
        "JORGE ANTONIO FUERTE DIAZ",
        "JORGE ANTONIO FUERTE DIAZ",
        "JORGE ANTONIO FUERTE DIAZ",
        "JORGE ANTONIO FUERTE DIAZ",
    ]
   //fechaas
    var monts = [
        "Seleccionar mes",
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
    ]
    //esto es para sacar los años del actual a 20 atras 2022 - 2002
    var years = [];
        const max = new Date().getFullYear()
        const min = max - 20;
        years.push("Seleccionar año")
        for (let i = max; i >= min; i--) {
            years.push(i)
        }
    //fecha actual
    const actualDate = new Date();
    const d = actualDate.getDate()
    const m = actualDate.getMonth()
    const y = actualDate.getFullYear();
   
    var arrayHeader = [
        { field: "field1" },
        { field: "field2" },
        { field: "field3" },
        { field: "field4" },
        { field: "field5" },
        { field: "field6" },
        { field: "field7" },
        { field: "field8" },
        { field: "field9" },
        { field: "field10" },
        { field: "field11" },
        { field: "field12" },
        { field: "field13" },
        { field: "field14" },
        { field: "field15" },
        { field: "field16" },
        { field: "field17" },
        { field: "field18" },
        { field: "field19" },
        { field: "field20'" },
        { field: "field21" },
        { field: "field22" },
        { field: "field23" },
        { field: "field24" },
        { field: "field25" },
        { field: "field26" },
        { field: "field27" },
        { field: "field28" },
        { field: "field29" },
        { field: "field30" },
        { field: "field31" },
        { field: "field32" },
        { field: "field33" },
    ]
    var arrayItems = [
        {
            
            field1: "",
            field2: "JORGE ANTONIO FUERTE DIAZ",
            field3:contentTableTemplate(""),
            field4:contentTableTemplate(""),
            field5:contentTableTemplate(""),
            field6:contentTableTemplate(""),
            field7:contentTableTemplate(""),
            field8:contentTableTemplate(""),
            field9:contentTableTemplate(""),
            field10:contentTableTemplate(""),
            field11:contentTableTemplate(""),
            field12:contentTableTemplate(""),
            field13:contentTableTemplate(""),
            field14:contentTableTemplate(""),
            field15:contentTableTemplate(""),
            field16:contentTableTemplate(""),
            field17:contentTableTemplate(""),
            field18:contentTableTemplate(""),
            field19:contentTableTemplate(""),
            field20:contentTableTemplate(""),
            field21:contentTableTemplate(""),
            field22:contentTableTemplate(""),
            field23:contentTableTemplate(""),
            field24:contentTableTemplate(""),
            field25:contentTableTemplate(""),
            field26:contentTableTemplate(""),
            field27:contentTableTemplate(""),
            field28:contentTableTemplate(""),
            field29:contentTableTemplate(""),
            field30:contentTableTemplate(""),
            field31:contentTableTemplate(""),
            field32:contentTableTemplate(""),
            field33:contentTableTemplate(""),
            // fieldcontentTableTemplate(""),"",
        },
        {
            field1: "",
            field2: "JORGE ANTONIO FUERTE DIAZ",

        },
        {
            field1: "",
            field2: "JORGE ANTONIO FUERTE DIAZ",

        },
        {
            field1: "",
            field2: "JORGE ANTONIO FUERTE DIAZ",

        },
        {
            field1: "",
            field2: "ALBERTO ANTONIO FUERTE DIAZ",

        },
    ]
   
    let headerGroup =
    
        <ColumnGroup>
            <Row showGridlines>
                <Column header="Foto" frozen style={{width:"100px"}} colSpan={1}  className="dth-child header-table border-radius-left" />
                <Column header="Nombre" frozen style={{width:"288px"}} colSpan={1} className="dth-child header-table" />
                <Column header="1"  style={{width:"41px"}} colSpan={1}  className="header-table color" />
                <Column header="2"  style={{width:"41px"}} colSpan={1} className="header-table color" />
                <Column header="3"  style={{width:"41px"}} colSpan={1} className="header-table color" />
                <Column header="4"  style={{width:"41px"}} colSpan={1} className="header-table color" />
                <Column header="5"  style={{width:"41px"}} colSpan={1} className="header-table color" />
                <Column header="6"  style={{width:"41px"}} colSpan={1} className="header-table color" />
                <Column header="7"  style={{width:"41px"}} colSpan={1} className="header-table color" />
                <Column header="8"  style={{width:"41px"}} colSpan={1} className="header-table color" />
                <Column header="9"  style={{width:"41px"}} colSpan={1} className="header-table color" />
                <Column header="10" style={{width:"41px"}}  colSpan={1} className="header-table color" />
                <Column header="11" style={{width:"41px"}}  colSpan={1} className="header-table color" />
                <Column header="12" style={{width:"41px"}}  colSpan={1} className="header-table color" />
                <Column header="13" style={{width:"41px"}}  colSpan={1} className="header-table color" />
                <Column header="14" style={{width:"41px"}}  colSpan={1} className="header-table color" />
                <Column header="15" style={{width:"41px"}}  colSpan={1} className="header-table color" />
                <Column header="16" style={{width:"41px"}}  colSpan={1} className="header-table color" />
                <Column header="17" style={{width:"41px"}}  colSpan={1} className="header-table color" />
                <Column header="18" style={{width:"41px"}}  colSpan={1} className="header-table color" />
                <Column header="19" style={{width:"41px"}}  colSpan={1} className="header-table color" />
                <Column header="20" style={{width:"41px"}}  colSpan={1} className="header-table color" />
                <Column header="21" style={{width:"41px"}}  colSpan={1} className="header-table color" />
                <Column header="22" style={{width:"41px"}}  colSpan={1} className="header-table color" />
                <Column header="23" style={{width:"41px"}}  colSpan={1} className="header-table color" />
                <Column header="24" style={{width:"41px"}}  colSpan={1} className="header-table color" />
                <Column header="25" style={{width:"41px"}}  colSpan={1} className="header-table color" />
                <Column header="26" style={{width:"41px"}}  colSpan={1} className="header-table color" />
                <Column header="27" style={{width:"41px"}}  colSpan={1} className="header-table color" />
                {
                    days<=28?
                        <Column header="28" style={{width:"41px"}}  colSpan={1} className="header-table color" />:
                    days<=29?
                        <Column header="29" style={{width:"41px"}}  colSpan={1} className="header-table color" />:
                    days<=30?
                        <Column header="30" style={{width:"41px"}}  colSpan={1} className="header-table color" />:
                    days<=31?
                        <Column header="31" style={{width:"41px"}}  colSpan={1} className="header-table color" />:
                    null
                }
            </Row>
        </ColumnGroup>
        console.log("new Date(year,month,0).getDate()",new Date(2022,2,0).getDate())
    return (
        <>
        <div className=" cj-AttendanceRh">
            <div className="cj-people-managment MetaNotAssigned">
                <div className="container-activations">
                    <HeadersSection
                        title={<div>Vencimiento de contrato</div>}
                        exeptions={{}}
                    />
                    <div className="div-selects-activations">
                        <div className="div-container-stand">
                            <div className="search-container-cj">
                                <div className="header">
                                    <div className="db">
                                        <div className="search">
                                            <input
                                                type="search"
                                                value={searchName}
                                                onChange={(e) => setSearchName(e.target.value)}
                                                placeholder="Buscar por nombre"
                                                className="input-search"
                                            />
                                            <img src={searchIcon} className="input-icon"></img>
                                        </div>
                                        <select className="select" >
                                            {supervisors.map((item,key)=>{return(<option value={key}>{item}</option>)})}
                                        </select>
                                        <select className="select" id="selectMonth" onChange={()=>{SetDaysFromMonth()}}>
                                            {monts.map((item,key)=>{return(<option value={key}>{item}</option>)})}
                                        </select>
                                        <select className="select" id="selectYear"onChange={()=>{SetDaysFromMonth()}}>
                                            {years.map((item,key)=>{return(<option value={item}>{item} </option>)})}
                                        </select>
                                        
                                    </div>
                                    <div className="actual-date">
                                        <div className="text">Fecha de hoy:</div>
                                        <div className="date">{d}-{monts[m+1]}-{y}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="table-ARH">
                            
                           
                                <DataTable value={arrayItems} showGridlines headerColumnGroup={headerGroup} scrollDirection="both"  scrollable scrollHeight="400px" globalFilter={searchName} className="table-activations" paginator rows={10} paginatorTemplate={template1} >

                                    
                                    {arrayHeader.map((item, key) => {
                                        switch (key) {
                                            case 0:
                                                return (
                                                    <Column body={imgTemplate} style={{width:"100px",justifyContent:'center'}} frozen colSpan={item.colspan ? item.colspan : 1} className="rowdata" key={key} />
                                                    )
                                                break;
                                            case 1:
                                            return (
                                                <Column style={{textAlign:"center",width:"305px",justifyContent:'center'}} frozen field={item.field} colSpan={item.colspan ? item.colspan : 1} className="rowdata" key={key} />
                                                )
                                            break;
                                            case 19:
                                                console.log("entro")
                                            break;
                                            default:
                                                return (
                                                    // <VirtualScroller items={item.field} itemSize={50}  orientation="horizontal" />
                                                    <Column field={item.field} style={{width:"40px",textAlign:"center"}}  colSpan={item.colspan ? item.colspan : 1} className="rowdata" key={key}/>
                                                );
                                                break;
                                        }

                                    })}

                                </DataTable>
                                <FooterTableW></FooterTableW>
                            </div>
                        

                        </div>
                    </div>
                </div>
            </div>
            </div>
            <FooterSimple></FooterSimple>
            {modalState&&(<GeneralModal modalState={modalState} state = {setModalState} content={ModalTemplate()}/>)}
        </>
    );
}
export default AttendanceRh;