import React from "react";
import './FooterSimple.scss';

const FooterSimple = () => {

    return (
        <div className="footer-dashboard">
            <p>2021 GestiClaro. Todos los derechos reservdos</p>
        </div>
    );
};

export default FooterSimple;
