import React, { useEffect, useState } from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

const NewPersonnelFourData = ({ rows }) => {
  const [activar1, setActivar1] = useState(false);
  const [activar2, setActivar2] = useState(false);
  const [activar3, setActivar3] = useState(false);

  const selectionAccion = (element, id) => {
    const row = rows.filter((e)=>e.id == id)
    const selectcionInput = document.getElementById(element)

    if (row[0].espectador === selectcionInput.id){
      document.getElementById(row[0].edicion).checked = false
    }
    if (row[0].edicion === selectcionInput.id){
      document.getElementById(row[0].espectador).checked = false
    }
    
    
   
  };
  const accionTemplate1 = ({ id, espectador, edicion, reporteria }) => {
    return (
      <div className="template">
      <div
        className="centeredActions"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <div className="sectionActions">
          <div className="btnedit-table">
            <input
              type="checkbox"
              id={espectador}
              onClick={(e) => {
                selectionAccion(espectador, id);
              }}
            />
            <label for={espectador}></label>
          </div>
        </div>
      </div>
      </div>
    );
  };
  const accionTemplate2 = ({ id, espectador, edicion, reporteria }) => {
    return (
      <div
        className="centeredActions"
        style={{ display: "flex" }}
      >
        <div className="sectionActions">
          <div className="btnedit-table">
            <input
              type="checkbox"
              id={edicion}
              onClick={() => {
                selectionAccion(edicion, id);
              }}
            />
            <label for={edicion}></label>
          </div>
        </div>
      </div>
    );
  };
  const accionTemplate3 = ({ id, espectador, edicion, reporteria }) => {
    return (
      <div
        className="centeredActions"
        style={{ display: "flex" }}
      >
        <div className="sectionActions">
          <div className="btnedit-table">
            <input
              type="checkbox"
              id={reporteria}
              onClick={() => {
                selectionAccion(reporteria, id);
              }}
            />
            <label for={reporteria}></label>
          </div>
        </div>
      </div>
    );
  };
  const column = [
    {
      field: "seccion",
      header: "Sección en la plataforma",
      order: false,
    },
    {
      field: "espectador",
      header: "Espectador",
      body: accionTemplate1,
      order: false,
    },
    {
      field: "edicion",
      header: "Edición",
      body: accionTemplate2,
      order: false,
    },
    {
      field: "reporteria",
      header: "Reportería",
      body: accionTemplate3,
      order: false,
    },
  ];
  return (
    <div>
      <DataTable
        value={rows}
        rows={10}
        className="parameters-table table-four"
        // globalFilter={searchEnter}
      >
        {column.map((e, key) => {
          return (
            <Column
            className="column-four"
              style={{
                fontSize: "14px",
                background: "white",
                color: "#308ac9",
                fontWeight: "bold",
              }}
              field={e.field}
              header={e.header}
              body={e.body ? e.body : null}
            />
          );
        })}
      </DataTable>
    </div>
    
  );
};

export default NewPersonnelFourData;
