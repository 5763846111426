import React from "react";
import "./Reports.scss";
import HeadersSection from "../../../../components/book/headersSection/HeadersSection";
import { Dropdown } from "primereact/dropdown";
import "primeicons/primeicons.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import data2 from "./data.json";
import excel from "../../../../img/icons/excel.png";
import csv from "../../../../../images/FileManagement/FilesForm/CSVicon.png";
import { Ripple } from "primereact/ripple";
import { classNames } from "primereact/utils";

export default function FilesReports() {
  const data = [
    { name: "201902" },
    { name: "201903" },
    { name: "201904" },
    { name: "201905" },
    { name: "201906" },
  ];

  const defaultPaginatorTemplate = {
    layout: "PrevPageLink PageLinks NextPageLink",
    PrevPageLink: (options) => {
      return (
        <button
          type="button"
          className="paginator-before greenBtn "
          onClick={options.onClick}
          disabled={options.disabled}
        >
          Anterior
          <Ripple />
        </button>
      );
    },
    NextPageLink: (options) => {
      return (
        <button
          type="button"
          className="paginator-after greenBtn"
          onClick={options.onClick}
          disabled={options.disabled}
        >
          Siguiente
          <Ripple />
        </button>
      );
    },
    PageLinks: (options) => {
      if (
        (options.view.startPage === options.page &&
          options.view.startPage !== 0) ||
        (options.view.endPage === options.page &&
          options.page + 1 !== options.totalPages)
      ) {
        const className = classNames(options.className, { "p-disabled": true });

        return (
          <span className={className} style={{ userSelect: "none" }}>
            ...
          </span>
        );
      }

      return (
        <button
          type="button"
          className={options.className}
          onClick={options.onClick}
        >
          {options.page + 1}
          <Ripple />
        </button>
      );
    },
  };

  return (
    <div>
      <HeadersSection
        title={"Reportes Legajos"}
        exeptions={{}}
        date={"Viernes 30 de Julio del 2021 | Semana: 18 | Cierre de mes: 1"}
      />
      <div className="reports-contain-selects">
        <div className="contain-selects-1">
          <div className="contain-select">
            <span className="title2">Estado Rendición Legajos Agente</span>
            <Dropdown
              className="select2"
              //   value={selectedCountry}
              options={data}
              //   onChange={onCountryChange}
              optionLabel="name"
              filter
              showClear
              filterBy="name"
              placeholder="Selección"
              //   valueTemplate={selectedCountryTemplate}
              //   itemTemplate={countryOptionTemplate}
            />
          </div>
          <SelectReports name={"Región"} className={"title"} />
          <SelectReports name={"Supervisor"} className={"title"} />
        </div>
        <div className="contain-selects-1">
          <div className="contain-select">
            <span className="title2">NIM, SDS o SIMCARD</span>
            <Dropdown
              className="select2"
              //   value={selectedCountry}
              options={data}
              //   onChange={onCountryChange}
              optionLabel="name"
              filter
              showClear
              filterBy="name"
              placeholder="Selección"
              //   valueTemplate={selectedCountryTemplate}
              //   itemTemplate={countryOptionTemplate}
            />
          </div>
          <SelectReports name={"Sucursal"} className={"title"} />
          <SelectReports name={"Vendedor"} className={"title"} />
        </div>
        <div className="contain-selects-1">
          <div className="contain-select">
            <span className="title2">NIM, SDS o SIMCARD</span>
            <Dropdown
              className="select2"
              //   value={selectedCountry}
              options={data}
              //   onChange={onCountryChange}
              optionLabel="name"
              filter
              showClear
              filterBy="name"
              placeholder="Selección"
              //   valueTemplate={selectedCountryTemplate}
              //   itemTemplate={countryOptionTemplate}
            />
          </div>
          <SelectReports name={"Canal"} className={"title"} />
          <SelectReports name={"POS"} className={"title"} />
        </div>
        <div className="contain-selects-1">
          <SelectReports name={"Sub canal comercial"} className={"title2"} />
          <div className="contain-buttons">
            <button className="button-blue">Limpiar</button>
            <button className="button-green">Aplicar</button>
          </div>
        </div>
      </div>
      <div className="reports-datatable">
        <div className="contain-datatable-header">
          <div>Descargar como</div>
          <div className="icon">
            <img src={excel} alt="" />
          </div>
          <div className="icon">
            <img src={csv} alt="" />
          </div>
        </div>
        <DataTable
          value={data2}
          rows={10}
          paginator
          paginatorTemplate={defaultPaginatorTemplate}
        >
          <Column field="antiguedad" header="Antiguedad"></Column>
          <Column field="dateactivation" header="Fecha activación"></Column>
          <Column field="dateprovition" header="Fecha provisión"></Column>
          <Column field="sds" header="SDS"></Column>
          <Column field="typepos" header="Tipo POS"></Column>
          <Column field="pos" header="POS"></Column>
          <Column field="simcard" header="SIMCARD"></Column>
          <Column field="cellarsimcard" header="Bodega SIMCARD"></Column>
          <Column field="installer" header="Instalador"></Column>
          <Column field="assistant" header="Ayudante"></Column>
          <Column field="region" header="Región"></Column>
          <Column field="subchannel" header="Sub Canal"></Column>
          <Column field="supervisor" header="Supervisor"></Column>
          <Column field="statusmanagement" header="Estado Gestión"></Column>
        </DataTable>
      </div>
    </div>
  );
}

const SelectReports = ({ name, className }) => {
  return (
    <div className="contain-select">
      <span className={className}>{name}</span>
      <select className="select">
        <option>Seleccion</option>
      </select>
    </div>
  );
};
