import React from 'react'
import Sheet from '../../../../images/cardIcons/Sheet.svg'
import Checking from '../../../../images/cardIcons/Checking.svg'
// import Checked from '../../../../images/cardIcons/Checked.svg'
import './CardRisk.scss'

const CardRisk = () => {
    return (
        <div>
            <div className='cardSpace'>
                <div className='card frist__card__one'>
                    <div className='card-title__one'>
                        <p>Solicitar riesgo</p>
                    </div>
                    <div className='card-info'>
                        <div className='card-number'>

                        </div>
                        <div className='card-image'>
                            <img src={Sheet} />
                        </div>
                    </div>
                    <div className='card__footer'>

                    </div>
                </div>

                <div className='card second__card'>
                    <div className='card-title__two'>
                        <p>Riesgo pendiente de verificar</p>
                    </div>
                    <div className='card-info'>
                        <div className='card-number'>
                            <p>245</p>
                        </div>
                        <div className='card-image'>
                            {/* <img src={Checking} /> */}
                        </div>
                    </div>
                    <div className='card__footer'>

                    </div>
                </div>
                <div className='card three__card'>
                    <div className='card-title__three'>
                        <p>Riesgos verificados</p>
                    </div>
                    <div className='card-info'>
                        <div className='card-number'>
                            <p>245</p>
                        </div>
                        <div className='card-image'>
                            {/* <img src={Checked} /> */}
                        </div>
                    </div>
                    <div className='card__footer'>

                    </div>
                </div>
            </div>
            <div className="card__ultimate">
                <p>Última carga pedido: <span>2021-08-08 12:00:00</span></p>
                <p>Último riesgo verificado: <span>2021-08-08 12:00:00</span></p>
            </div>

        </div>
    )
}

export default CardRisk
