import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import "./CreatePlan.scss";
import "primeflex/primeflex.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

const Form = () => {
  const { id } = useParams();
  const [date, setDate] = useState("21/12/21");
  const [plan, setPlan] = useState("MPP22");
  const [prom, setProm] = useState("468");
  const [typeProduct, setTypeProduct] = useState("pospago");
  const [typePlan, setTypePlan] = useState("adicional");
  const [descPlan, setDescPlan] = useState("PP SIM PREACT HOMO PROPIO");
  const [descCamp, setDescCamp] = useState("PP SIM PREACT HOMO PROPIO");
  const [descProm, setDescProm] = useState("PP SIM PREACT HOMO PROPIO");
  return (
    <div className="p-grid">
      <div className="p-col-12 p-md-6 p-lg-3">
        <div className="group-label">
          <label className="input-label">FECHA INSERSIÓN:</label>
          <input
            value={id ? date : ""}
            onChange={(e) => setDate(e.target.value)}
            type="text"
            className="input"
          />
        </div>
        <div className="group-label">
          <label className="input-label">PLAN:</label>
          <input
            value={id ? plan : ""}
            onChange={(e) => setPlan(e.target.value)}
            type="text"
            className="input"
          />
        </div>
        <div className="group-label">
          <label className="input-label">PROMOCIÓN</label>
          <input
            type="text"
            value={id ? prom : ""}
            onChange={(e) => setProm(e.target.value)}
            className="input"
          />
        </div>
      </div>
      <div className="p-col-12 p-md-6 p-lg-3">
        <div className="group-label">
          <label className="input-label">TIPO DE PRODUCTO:</label>
          <select
            className="select-basic"
            value={id ? typeProduct : ""}
            onChange={(e) => setTypeProduct(e.target.value)}
          >
            <option>Seleccionar</option>
            <option value="pospago">PosPago</option>
            <option value="dht">DHT</option>
            <option value="gpon">GPON</option>
          </select>
        </div>
        <div className="group-label">
          <label className="input-label">TIPO DE PLAN:</label>
          <select
            className="select-basic"
            value={id ? typePlan : ""}
            onChange={(e) => setTypePlan(e.target.value)}
          >
            <option>Seleccionar</option>
            <option value="adicional">Adicional</option>
            <option value="recuperado">Recuperado</option>
            <option value="corpo">Corpo</option>
            <option value="bam">BAM</option>
            <option value="portabilidad">Portabilidad</option>
            <option value="portabilidadfibra">Portabilidad Fibra</option>
          </select>
        </div>
        <div className="group-label">
          <label className="input-label">DESCRIPCIÓN PLAN:</label>
          <input
            type="text"
            value={id ? descPlan : ""}
            onChange={(e) => setDescPlan(e.target.value)}
            className="input"
          />
        </div>
        <div className="group-label">
          <label className="input-label">DESCRIPCIÓN CAMPAÑA:</label>
          <input
            type="text"
            className="input"
            value={id ? descCamp : ""}
            onChange={(e) => setDescCamp(e.target.value)}
          />
        </div>
        <div className="group-label">
          <label className="input-label">DESCRIPCIÓN PROMOCIÓN:</label>
          <input
            type="text"
            value={id ? descProm : ''}
            onChange={(e) => setDescProm(e.target.value)}
            className="input"
          />
        </div>
        <div className="contain-buttons">
          <Link to="/plan">
            <button className="buttons btn-red">Cancelar</button>
          </Link>
          <button className="buttons btn-green">{id ? 'Guardar' : 'Guardar'}</button>
        </div>
      </div>
    </div>
  );
};

export default Form;
