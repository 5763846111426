import React from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faFileWord } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import './Table.scss';

const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

const Table = ({ arrayHeader, arrayItems, dates,state }) => {
    const navigate = useNavigate();
    let headerGroup = <ColumnGroup>
        <Row>
            <Column header="Region" rowSpan={2} colSpan={1} className="date-main" style={{ width: "200px" }} />
            {/* nuevas columnas */}
            <Column header="Region" rowSpan={2} colSpan={1} className="date-main" style={{ width: "200px" }} />
            <Column header="Region" rowSpan={2} colSpan={1} className="date-main" style={{ width: "200px" }} />
            {/* ************+* */}
            <Column header="PREPAGO" colSpan={3} className="prep-father" />
            <Column header="POSTPAGO" colSpan={3} className="pos-father" />
            <Column header="DTH" colSpan={3} className="dth-father" />
            <Column header="GPON" colSpan={3} className="gpon-father" />
        </Row>
        <Row>

            <Column header="Recibir" colSpan={1} className="prep-child" />
            <Column header="A vencer" colSpan={1} className="prep-child" />
            <Column header="Vencido" colSpan={1} className="prep-child" />

            <Column header="Recibir" colSpan={1} className="pos-child" />
            <Column header="A vencer" colSpan={1} className="pos-child" />
            <Column header="Vencido" colSpan={1} className="pos-child" />

            <Column header="Recibir" colSpan={1} className="dth-child" />
            <Column header="A vencer" colSpan={1} className="dth-child" />
            <Column header="Vencido" colSpan={1} className="dth-child" />

            <Column header="Recibir" colSpan={1} className="gpon-child" />
            <Column header="A vencer" colSpan={1} className="gpon-child" />
            <Column header="Vencido" colSpan={1} className="gpon-child" />

        </Row>
        <Row>
            {/* comprimida */}
            {/* <Column header="Total a la fecha" colSpan={1} className="main-bot" /> */}

            {/* descomprimida */}
            <Column header="Total a la fecha" colSpan={3} className="main-bot" />

            <Column header="0" colSpan={1} className="prep-bot" />
            <Column header="0" colSpan={1} className="prep-bot" />
            <Column header="0" colSpan={1} className="prep-bot" />


            <Column header="0" colSpan={1} className="pos-bot" />
            <Column header="0" colSpan={1} className="pos-bot" />
            <Column header="0" colSpan={1} className="pos-bot" />

            <Column header="0" colSpan={1} className="dth-bot" />
            <Column header="0" colSpan={1} className="dth-bot" />
            <Column header="0" colSpan={1} className="dth-bot" />

            <Column header="0" colSpan={1} className="gpon-bot" />
            <Column header="0" colSpan={1} className="gpon-bot" />
            <Column header="0" colSpan={1} className="gpon-bot" />

        </Row>



        {/* {dates.map((item, key) => {
            return (
                <Row key={key}>
                    <Column header={item} colSpan={2} className="date-child-activations" />
                </Row>
            );
        })} */}

    </ColumnGroup>;

    const dateBodyTemplate = (rowData) => {

        return (
            <td rowSpan={2} colSpan={2} className="date-child-activations">{rowData.date + "511"}</td>
        );
    };


    const exportExcel = () => {
        const ws = XLSX.utils.json_to_sheet(arrayItems);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, 'table_' + new Date().getTime() + fileExtension);
    }
    return (
        <div className="table-conatiner">

            <div className="table">
                <div className="header">
                    {/* cambio en la tabla dependiendo el estado del boton */}
                    
                        <div className="header1-minimized">
                        <div className="title-container">
                            <div className="title">Región</div>
                        </div>
                        <div className="subtitle">Total a la fecha</div>
                    </div>
                    
                    {/* ------------------------------------------------------------------------prepago */}
                    <div className="header2">
                        <div className="title-container" style={{ background: "#ff2631", opacity: "60%" }}>
                            <div className="title">PREPAGO</div>
                        </div>
                        <div className="subtitle-container" style={{ background: "#ff2631" }}>
                            <div className="subtitle">Recibir</div>
                            <div className="subtitle">A vencer</div>
                            <div className="subtitle">Vencidos</div>
                        </div>
                        <div className="header-value" style={{ background: "#FFDDDF" }}>
                            <div className="value">0</div>
                            <div className="value">0</div>
                            <div className="value">0</div>
                        </div>
                    </div>
                    {/* ------------------------------------------------------------------------ postpago */}
                    <div className="header2">
                        <div className="title-container" style={{ background: "#11ab77", opacity: "60%" }}>
                            <div className="title">POSPAGO</div>
                        </div>
                        <div className="subtitle-container" style={{ background: "#11ab77" }}>
                            <div className="subtitle">Recibir</div>
                            <div className="subtitle">A vencer</div>
                            <div className="subtitle">Vencidos</div>
                        </div>
                        <div className="header-value" style={{ background: "#DBF3EB" }}>
                            <div className="value">0</div>
                            <div className="value">0</div>
                            <div className="value">0</div>
                        </div>
                    </div>
                    {/* ------------------------------------------------------------------------ DTH */}
                    <div className="header2">
                        <div className="title-container" style={{ background: "#308ac9", opacity: "60%" }}>
                            <div className="title">DTH</div>
                        </div>
                        <div className="subtitle-container" style={{ background: "#308ac9" }}>
                            <div className="subtitle">Recibir</div>
                            <div className="subtitle">A vencer</div>
                            <div className="subtitle">Vencidos</div>
                        </div>
                        <div className="header-value" style={{ background: "#E0EEF7" }}>
                            <div className="value">0</div>
                            <div className="value">0</div>
                            <div className="value">0</div>
                        </div>
                    </div>
                    {/* ------------------------------------------------------------------------ GPON*/}
                    <div className="header2">
                        <div className="title-container-border" style={{ background: "#f5a72a", opacity: "60%" }}>
                            <div className="title">GPON</div>
                        </div>
                        <div className="subtitle-container" style={{ background: "#f5a72a" }}>
                            <div className="subtitle">Recibir</div>
                            <div className="subtitle">A vencer</div>
                            <div className="subtitle">Vencidos</div>
                        </div>
                        <div className="header-value" style={{ background: "#FEF2DF" }}>
                            <div className="value">0</div>
                            <div className="value">0</div>
                            <div className="value">0</div>
                        </div>
                    </div>
                </div>
              
                {/* ------------------------------------------------------------------------ data-table*/}
                <div className="body-table">
                    {arrayItems.map((item, key) => {
                        return (
                            <>
                                <div className="table-container">
                                    <table>
                                        <tr>
        
                                        {/* titulo de columnas cuando es minimizado ------------------------- */}
                                            <td rowspan="2" className="center-y td-minimized">{item.position.title}</td>
                                            {item.position.sumatory.map((item,key) => {
                                                return (
                                                        
                                                    <td className="td">{item}</td>
                                                );
                                                    
                                                })}
                                            
                                        </tr>
                                    </table>
                                </div>
                            </>
                        );
                        {/* finish data 1 */ }
                    })}
                </div>
            </div>
        </div>
    );
};
export default Table;
