import { preventDefault } from "@fullcalendar/react";
import React from "react";
import Personnel from "../Personnel";
import { useEffect, useState } from "react";
import { margin } from "@mui/system";
import Documents from "./Documents";
import Contactos from "./Contactos";
import ListaRegistro from "./ListaRegistro";

const NewPersonnel = () => {
  const [add, setAdd] = useState(false);
  const [add2, setAdd2] = useState(false);
  const [addContact, setAddContact] = useState(false);
  const [registro, setRegistro] = useState([]);
  const [registro2, setRegistro2] = useState([]);
  const [valide, setValide] = useState(false);
  const [valide2, setValide2] = useState(false);
  const eliminarContacto = (id) => {
    const registroActualizado = registro.filter(
      (contacto) => contacto.id !== id
    );
    setRegistro(registroActualizado);
  };
  const eliminarContacto2 = (id) => {
    const registroActualizado2 = registro2.filter(
      (contacto) => contacto.id !== id
    );
    setRegistro2(registroActualizado2);
  };
  return (
    <>
      <Personnel caption="Personal" captionData="Nuevo personal" />

      <div className="personnel-container">
        <h4 className="personnel-container-text">Datos básicos</h4>
        <div className="personnel-container-quest">
          <p>Datos personales</p>
        </div>
        <div className="personnel-data">
          <div className="personnel-data-doc-main">
            <div className="personnel-data-doc">
              <div className="personnel-data-doc-style">
                <label>Nro. de documento</label>
                <input
                  className="input-style"
                  type="text"
                  placeholder="Ingresa número de documento"
                />
              </div>
              <div className="personnel-data-doc-style">
                <label>Vencimiento del documento</label>
                <input className="input-style" type="date" />
              </div>
            </div>
          </div>

          <div className="personnel-data-doc-main">
            <div className="personnel-data-doc">
              <div className="personnel-data-doc-style">
                <label>Nombre (s)</label>
                <input
                  className="input-style"
                  type="text"
                  placeholder="Ingresa el Nombre (s)"
                />
              </div>
              <div className="personnel-data-doc-style">
                <label>Apellidos</label>
                <input
                  className="input-style"
                  type="text"
                  placeholder="Ingresa los Apellidos"
                />
              </div>
              <div className="personnel-data-doc-style">
                <label>Fecha de nacimiento</label>
                <input className="input-style" type="date" />
              </div>
              <div className="personnel-data-doc-age">
                <label>Edad</label>
                <input className="input-style" type="number" />
              </div>
            </div>

            <div className="personnel-data-doc">
              <div className="personnel-data-doc-style">
                <label>Nacionalidad</label>
                <select className="input-style">
                  <option disabled>Selecciona una opción</option>
                  <option>México</option>
                  <option>Argentina</option>
                  <option>Colombia</option>
                </select>
              </div>
              <div className="personnel-data-doc-style">
                <label>Ciudad de nacimiento</label>
                <input
                  className="input-style"
                  type="text"
                  placeholder="Ingresa los Apellidos"
                />
              </div>
              <div className="personnel-data-doc-style">
                <label>Sexo</label>
                <select className="input-style">
                  <option disabled>Selecciona una opción</option>
                  <option>Mujer</option>
                  <option>hombre</option>
                </select>
              </div>
              <div className="personnel-data-doc-age">
                <label>Género</label>
                <input
                  className="input-style"
                  type="text"
                  placeholder="Escribe el género"
                />
              </div>
            </div>
          </div>

          <div className="personnel-data-doc-main">
            <div className="personnel-data-doc">
              <div className="personnel-data-doc-age">
                <label>Estado civil</label>
                <select className="input-style">
                  <option>Soltero</option>
                  <option>Casado</option>
                  <option>Divorciado</option>
                  <option>Unión Libre</option>
                </select>
              </div>
              <div className="personnel-data-doc-style">
                <label>Nombre de la pareja o conyugue</label>
                <input
                  className="input-style"
                  type="text"
                  placeholder="Ingresa el Nombre (s)"
                />
              </div>
              <div className="personnel-data-doc-style">
                <label>CI del pareja</label>
                <input
                  className="input-style"
                  type="text"
                  placeholder="Ingresar Identificación"
                />
              </div>
              <div className="personnel-data-doc-age">
                <label>Nro. de hijos</label>
                <input className="input-style" type="number" min={0} />
              </div>
            </div>
          </div>

          <div className="personnel-data-doc-main">
            <div className="personnel-data-doc">
              <div className="personnel-data-doc-age">
                <label>CI de la madre</label>
                <input
                  className="input-style"
                  type="text"
                  placeholder="Ingresa Identificación"
                />
              </div>
              <div className="personnel-data-doc-style">
                <label>Nombre de la madre</label>
                <input
                  className="input-style"
                  type="text"
                  placeholder="Ingresa el Nombre (s)"
                />
              </div>
              <div className="personnel-data-doc-style">
                <label>CI del padre</label>
                <input
                  className="input-style"
                  type="text"
                  placeholder="Ingresar Identificación"
                />
              </div>
              <div className="personnel-data-doc-style">
                <label>Nombre del padre</label>
                <input
                  className="input-style"
                  type="text"
                  placeholder="Ingresar el nombre (s)"
                />
              </div>
            </div>
          </div>

          <div className="personnel-data-doc-main">
          {add && (
                    <p
                      style={{
                        textTransform: "uppercase",
                        color: "red",
                        position: "absolute",
                      }}
                    >
                      *Todos los campos son obligatorios
                    </p>
                  )}
                  {valide && (
                    <p
                      style={{
                        textTransform: "uppercase",
                        color: "red",
                        position: "absolute",
                      }}
                    >
                      *El contacto debe tener al menos 10 dígitos
                    </p>
                  )}
            <div className="personnel-data-doc">
              <div className="personnel-add-button">
                <div className="personnel-data-doc-style-emergency">
                  
                  <label>Teléfonos de contacto</label>
                  <Contactos setRegistro={setRegistro2} registro={registro2} setAdd={setAdd} setValide={setValide} />
                  <ListaRegistro
                    registro={registro2}
                    eliminarContacto={eliminarContacto2}
                  />
                </div>
              </div>
              <div className="personnel-data-doc-style">
                <label>Nombre del Contacto</label>
                <input
                  className="input-style"
                  type="text"
                  placeholder="Ingresa el Nombre (s)"
                />
              </div>
            </div>
          </div>

          <div className="personnel-data-doc-main">
            <div className="personnel-data-doc">
              <div className="personnel-data-doc-style">
                <label>Registro de nro. de conducir</label>
                <select className="input-style">
                  <option>Tipo</option>
                </select>
              </div>
              <div className="personnel-data-doc-age">
                <label>Selecciona la Ciudad</label>
                <select className="input-style">
                  <option>Selecciona la Ciudad</option>
                </select>
              </div>
              <div className="personnel-data-doc-style">
                <label>Número de Registro</label>
                <input
                  className="input-style"
                  type="text"
                  placeholder="Número de Registro de conducir"
                />
              </div>
              <div className="personnel-data-doc-age">
                <label>Fecha de vencimiento</label>
                <input className="input-style" type="date" />
              </div>
            </div>
          </div>
        </div>
        <div className="personnel-container-quest">
          <p>Dirección</p>
        </div>
        <div className="button-direction">
          <button>
            <p className="button-direction-text">+</p>
          </button>
        </div>
        <div className="personnel-container-direction">
          <div className="container-direction">
            <p>Fecha de modificación</p>
            <p>Dirección</p>
            <p>Acciones</p>
          </div>
          <div className="container-direction-text">
            <p>Aún no hay direcciones agregadas</p>
          </div>
        </div>
        <div className="personnel-container-quest">
          <p>Contacto</p>
          <div className="personnel-container-quest-checkbox">
            <input type="checkbox" id="contact" name="contact" />
            <label for="contact">Mostrar agenda</label>
          </div>
        </div>
        <div className="personnel-data-doc-main">
        {add2 && (
                    <p
                      style={{
                        textTransform: "uppercase",
                        color: "red",
                        position: "absolute",
                      }}
                    >
                      *Todos los campos son obligatorios
                    </p>
                  )}
                  {valide2 && (
                    <p
                      style={{
                        textTransform: "uppercase",
                        color: "red",
                        position: "absolute",
                      }}
                    >
                      *El contacto debe tener al menos 10 dígitos
                    </p>
                  )}
          <div className="personnel-data-doc">
            <div className="personnel-add-button">
              <div className="personnel-data-doc-style-emergency">
                <label>Teléfonos de contacto</label>
                <Contactos setRegistro={setRegistro} registro={registro} setAdd={setAdd2} setValide={setValide2} />
                <ListaRegistro
                  registro={registro}
                  eliminarContacto={eliminarContacto}
                />
              </div>
            </div>
            <div className="personnel-data-doc-style">
              <label>Correo electrónico personal</label>
              <input
                className="input-style"
                type="email"
                placeholder="Ingresa correo electrónico"
              />
            </div>
          </div>
        </div>
        <Documents />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "20px",
        }}
      >
        <button style={{ backgroundColor: "#e3e3e3", color: "black" }}>
          Anterior
        </button>
        <button style={{ backgroundColor: "#308ac9", color: "white" }}>
          Siguiente
        </button>
      </div>
      <footer style={{ display: "flex", justifyContent: "center" }}>
        <p>2022 GestiClaro. Todos los derechos reservados</p>
      </footer>
    </>
  );
};

export default NewPersonnel;
