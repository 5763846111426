import check   from './icons/check.png'
import dollar  from './icons/dollar.png'
import foco    from './icons/foco.png'
import phone   from './icons/phone.png'
import hand    from './icons/hand.png'
import file    from './icons/file.png'
import graphic from './icons/graphic.png'
import billete from './icons/billete.png'
import cube    from './icons/cube.png'
import calculator    from './icons/calculator.png'
import msg    from './icons/msg.png'
import voice    from './icons/voice.png'
import folder    from './icons/folder.png'
import settings    from './icons/settings.png'

// Ventas
const sales = {
  title: 'Ventas',
  icon: dollar,
  menu:[
    {
      title: 'Riesgo',
      link: '/dashboard',
      submenu:[]
    },
    {
      title: 'Formulario de ventas',
      link: '/form-ventas',
      submenu:[]
    },
    {
      title: 'Consulta de estado',
      link: '/consulta',
      submenu:[]
    },
    {
      title: 'Supervisores',
      link: '/supervisores',
      submenu:[]
    },
    {
      title: 'Reportes',
      link: '/reportes',
      submenu:[]
    },
    
  ]
}

// Verificaciones
const verifications = {
  title: 'Verificaciones',
  icon: check,
  menu:[
    {
      title: 'Home verificaciones',
      link: '/dashboard1',
      submenu:[]
    }
  ]
}

// Activaciones
const activations = {
    title:'Activaciones',
    icon: foco,
    menu: [
      {
        title:'Home activaciones',
        link: '/activaciones',
        submenu:[]
      },
      {
        title:'Gestión de riesgo',
        link: '',
        submenu:[{ title:'Riesgos pendientes a verificar', link:'/riesgos'},{ title:'Reportes',link:'/reports' }]
      },
      {
        title:'Consultar cajas a GPON',
        link: '/activaciones',
        submenu:[{title:'Cajas pendientes a verificar', link:'/cjas'}, {title:'Reportes', link:'/reports'}]
      },
      {
        title:'Gestión de Planes',
        link: '/plan',
        submenu:[]
      },
      {
        title:'Gestión POS',
        link: '/pos',
        submenu:[]
      },
      {
        title:'Subir actualizaciones',
        link: '/activation-upload',
        submenu:[]
      },
      {
        title:'Gestión de cancelación',
        link: '/activaciones',
        submenu:[{title:'Pendientes a gestionar', link:'/penidenresas'}, {title:'Gestionadas', link:'/gestionadas'}]
      },
      {
        title:'Reportes',
        link: '/activation-reports',
        submenu:[]
      },
      
    ]
}

//Logistica de instalación
const LogisticInstalations =  {
    title: 'Logística de Instalación',
    icon: phone,
    menu:[
      {
        title: 'Home instalación',
        link: '/asdfsdfgt',
        submenu:[]
      },
      {
        title: 'Asignación',
        link: '/csd-venrftas',
        submenu:[]
      },
      {
        title: 'Home de legajos',
        link: '/home',
        submenu:[]
      },
      {
        title: 'Supervisores',
        link: '/supervisores',
        submenu:[]
      },
      {
        title: 'Reportes',
        link: '/reportes',
        submenu:[{title:'Recibir legajos instalación', link: '/recibir-legajos'},{title:'Procesar legajos', link: '/procesar-lfsd'},{title:'Reportes', link: '/reportesd'}]
      },
]}

// Logistica de entrega
const LogisticDelivery =  {
    title: 'Logística de Entrega',
    icon: hand,
    menu:[
      {
        title: 'Prueba',
        link: '/asdfsdasfgt',
        submenu:[]
      },
]}

// Gestión de Legajos
const fileManagement =  {
    title: 'Gestión de Legajos',
    icon: file,
    menu:[
      {
        title: 'Home Legajos',
        link: '/home-files',
        submenu:[]
      },
      {
        title: 'Legajos Agencia',
        link: '',
        submenu:[{title:'Recibir Legajos', link: 'files-receive'},{title:'Buscar/Procesar Legajos', link: 'asdasasdcxz'}]
      },
      {
        title: 'Legajos Claro',
        link: '',
        submenu:[
            {title:'Home Claro', link: '/letargy-management'},
            {title:'Generar Rendición Claro', link: 'home-claro-letarges-generate-claro-surrender'},
            {title:'Subir Legajos Escaneados', link: '/upload-letarges-scanned'},
            {title:'Confirmar Recepción Claro', link: '/confirm-reception'},
            {title:'Subir Legajos STEALTH', link: '/upload-updates'}]
      },
      {
        title: 'Subir / Imprimir documentos',
        link: '/upload-documents',
        submenu:[]
      },
      {
        title: 'Imprimir Etiquetas',
        link: '/print-labels',
        submenu:[]
      },
      {
        title: 'Imprimir Anexo y/o Formularios',
        link: '/reasaaspasdodarteass',
        submenu:[]
      },
      {
        title: 'Reportes',
        link: '/files-reports',
        submenu:[]
      },
]}

// Distribución
const distribution =  {
    title: 'Distribución',
    icon: graphic,
    menu:[
      {
        title: 'Gestión de pubntos',
        link: '/asdfsdasasdafgt',
        submenu:[]
      },
]}

// Post venta
const postSell =  {
    title: 'POS Venta',
    icon: billete,
    menu:[
      {
        title: 'Prueba 1',
        link: '/asdfsdasasdasdafgt',
        submenu:[]
      },
      {
        title: 'Prueba 2',
        link: '/asdfsdassasdasdafgt',
        submenu:[]
      }
]}

// Gestión de Stock
const managerStock ={
    title: 'Gestión de Stock',
    icon: cube,
    menu:[
      {
        title: 'Home',
        link: '/asdfsdasaa<xsdasdafgt',
        submenu:[]
      },
      {
        title: 'Stock',
        link: '/asdfsdasaa<xsdasdafgt',
        submenu:[]
    },
    {
        title: 'Parámetros',
        link: '/parameters',
        submenu:[]
    },
      {
        title: 'Reportes',
        link: '/asdfsdassasdasszszxdafgt',
        submenu:[]
      }
]}

// Caja Tesorería
const boxTes =  {
    title: 'Caja Tesorería',
    icon: calculator,
    menu:[
      {
        title: 'Prueba 1',
        link: '/asdfsasasadasasdasdafgt',
        submenu:[]
      }
]}

//RRHH
const rrhh =  {
    title: 'RRHH',
    icon: msg,
    menu:[
      {
        title: 'Home RRHH',
        link: '/dashboard-rrhh',
        submenu:[]
      },
      {
        title: 'Asistencias',
        link: '/asdfsdassaassdasdafgt',
        submenu:[]
      },
      {
        title: 'Gestión Personal',
        link: '/asdfsdasasdasdaasfgt',
        submenu:[]
      },
      {
        title: 'Comercial',
        link: '/asdfsdassaassdabvcbsdafgt',
        submenu:[]
      },
      {
        title: 'Trabajadores',
        link: '/asdfsdasasdasdaafgvcbnt',
        submenu:[]
      },
      {
        title: 'Comisiones',
        link: '/asasasddfsdassaassdasdafgt',
        submenu:[]
      },
      {
        title: 'Liquidaciones',
        link: '/asdfsdassaassdabvcbgfhsdafgt',
        submenu:[]
      },
      {
        title: 'Gestión de recursos',
        link: '/home-files-rrhh-people-managment',
        submenu:[]
      },
      {
        title: 'reportes',
        link: '/asasasddfsdassaassdasdafgtsdfer',
        submenu:[]
      },
      {
        title: 'Parámetros',
        link: '/parameters',
        submenu:[]
      }
]}

// Auditoría
const audit =  {
  title: 'Auditoria',
  icon: voice,
  menu:[
    {
      title: 'Auditoría de legajos',
      link: '/asdfsdasasdasdafgta',
      submenu:[]
    }
]}

// Gerencia / Reportería
const managementRoprting =  {
  title: 'Gerencia / Reportería',
  icon: folder,
  menu:[
    {
      title: 'Dashboard',
      link: '/management_and_reporting',
      submenu:[]
    }
]}

// Panel de control
const controlPanel =  {
  title: 'Panel de control',
  icon: settings,
  menu:[
    {
      title: 'Prueba',
      link: '/abvafgta',
      submenu:[]
    }
]}


const menuList = [
    sales,
    verifications,
    activations,
    LogisticInstalations,
    LogisticDelivery,
    fileManagement,
    distribution,
    postSell,
    managerStock,
    boxTes,
    rrhh,
    audit,
    managementRoprting,
    controlPanel
]

export default menuList;