import React, { useState } from 'react'

import LabelCard from './LabelCard/LabelCard'
import Search from '../../../../../img/icons/search.svg'
import './PrintLabels.scss'

const PrintLabels = () => {
    const [ isActive1, setIsActive1 ] = useState(false)
    const [ isActive2, setIsActive2 ] = useState(false)
    const [labels, setLabels] = useState(null)

   
    const handleChange = (valor) => {
        if (valor === 1){
         setIsActive1(true) 
         setIsActive2(false) 
        }else{
            setIsActive1(false) 
            setIsActive2(true) 
        }
    }
    const handleSearch = () => {
        isActive1
            ? setLabels([1])
            : setLabels([1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1]);
    }
    const handleReset = () => {
        setLabels(null)
    }
  return (
    <div clasName=''>
        <header className='printL__header'>
            <h3 className='printL__title'>Imprimir etiquetas</h3>
            <p className='printL__date'>Viernes 30 de Julio del 2022 | Semana: 18 | Cierre de mes: 1</p>
        </header>

        <div className='printL__container'>
            <div className='printL__container-inputs'>
                <div className={`printL__input-container`} onClick={()=>handleChange(1)}>
                    <button className={`printL__btn ${isActive1 &&'active'}`}>Buscar:</button>
                    <input className='printL__input' type='text' placeholder='NIM, SDS o SIMCARD' />
                </div>

                <div className={`printL__input-container`} onClick={()=>handleChange(2)}>
                    <button className={`printL__btn  ${isActive2 &&'active'}`}>Buscar:</button>
                    <input className='printL__input' type='text' placeholder='POS' />
                </div>

                <div className={`printL__input-container1`}>
                    <button 
                        className='printL__btn-search'
                        onClick={handleSearch}
                        >
                            <img src={Search} />
                    </button>
                </div>

                <div className={`printL__input-container1`}>
                    <button 
                        className='printL__btn-reset' 
                        onClick={handleReset}
                        >Limpiar búsqueda</button>
                </div>
                
            </div>

            <div className='printL__containerCard'>
            
            {
                labels &&
                    labels.map((label, index) => 
                        (<LabelCard key={index} check={(labels.length >1)? true: false} />)
                    )
            }
            

            </div>
            { labels &&
                (<button className='printL__button-print'>Imprimir {(labels?.length>1) && 'etiquetas seleccionadas'} </button>)

            }


        </div>
    </div>
  )
}

export default PrintLabels