import React from "react";
import jpg from "../../../../../../images/FileManagement/FilesForm/JPGicon.svg";
import excel from "../../../../../../images/FileManagement/FilesForm/EXCELicon.png";
import pdf from "../../../../../../images/FileManagement/FilesForm/PDFicon.jpeg";
import txt from "../../../../../../images/FileManagement/FilesForm/TXTicon.jpg";
import "./FilesForm.scss";

export default function FilesForm() {
  return (
    <div>
      <div className="files-form-line">
        <h1 className="files-form-title">Imprimir Anexo y/o Formularios</h1>
        <h2 className="files-form-date">
          Viernes 30 de Julio del 2021 | Semana: 18 | Cierre de mes: 1
        </h2>
      </div>
      <div className="files-form-contain">
        <FilesFormCard
          text="PREPAGO"
          colorHeader={"prepaid"}
          iconColor="icon-red"
          color="color-red"
        />
        <FilesFormCard
          text="POSPAGO"
          colorHeader={"pospaid"}
          iconColor="icon-green"
          color="color-green"
        />
        <FilesFormCard
          text="DTH"
          colorHeader={"dth"}
          iconColor="icon-blue"
          color="color-blue"
        />
        <FilesFormCard
          text="GPON"
          colorHeader={"gpon"}
          iconColor="icon-yellow"
          color="color-yellow"
        />
      </div>
    </div>
  );
}

const FilesFormCard = ({ text, colorHeader, iconColor, color }) => {
  return (
    <div className="filesForm-contain-card">
      <div className={colorHeader}>{text}</div>
      <div className="contain">
        <div className="contain-icon">
          <img src={jpg} alt="jpg icon" className={iconColor} />
          <div className={color}>pdf_prepago_.jpg</div>
        </div>
        <div className="contain-icon">
          <img src={pdf} alt="pdf icon" className={iconColor} />
          <div className={color}>prepago_.pdf</div>
        </div>
        <div className="contain-icon">
          <img src={txt} alt="txt icon" className={iconColor} />
          <div className={color}>prepago_.txt</div>
        </div>
        <div className="contain-icon">
          <img src={excel} alt="excel icon" className={iconColor} />
          <div className={color}>prepago_.xls</div>
        </div>
      </div>
    </div>
  );
};
