import React, { useState, useEffect } from "react";
import "./PayAllowances.scss";
import imgExel from "../../../../../img/jpg/exel.png";
import imgCsv from "../../../../../img/jpg/csv.png";
import { Modal } from "antd";
import { DataTable } from "primereact/datatable";
import { infoParameters } from "./payAllowancesData";
import TablePayAllowances from "./TablePayAllowances"

const PayAllowances = () => {
  const [searchEnter, setSearchEnter] = useState();
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [dataParameters, setDataParameters] = useState([]);
  const [column, setColumn] = useState([]);
  const [modal, setModal] = useState(false)

 
 
  return (
    <div
      className="parameters"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: "20px",
      }}
    >
      <div>
        <h4 className="parameters-title">
          Parámetros <span className="parameters-title-span">{" <"}Asignación de pagos </span>
        </h4>
        <div className="parameters-container">
          <div className="parameters-container-header">
            <div className="parameters-container-header-search">
              <label>Filtrar por:</label>
              <input
                value={searchEnter}
                onChange={(e) => {
                  setSearchEnter(e.target.value);
                }}
                type="search"
                placeholder="Buscar por cargo"
              />
            </div>

            <div className="parameters-container-header-button">
              <button onClick={()=>{
                setModal(true)
              }}>Nueva asignación</button>
            </div>
          </div>

          <div className="parameters-table">
            <TablePayAllowances
              value={dataParameters}
              column={column}
              setColumn={setColumn}
              showModalDelete={showModalDelete}
              setShowModalDelete={setShowModalDelete}
              searchEnter={searchEnter}
            />
          </div>
          <div className="parameters-footer">
            <div className="parameters-footer-download">
              <label>Descargar como</label>
              <button className="parameters-footer-download-button">
                <img src={imgExel} />
              </button>
              <button className="parameters-footer-download-button">
                <img src={imgCsv} />
              </button>
            </div>
            <div className="parameters-footer-pag">
            </div>
          </div>
        </div>
      </div>
      <div>
        <footer style={{ display: "flex", justifyContent: "center" }}>
          <p>2022 GestiClaro. Todos los derechos reservados</p>
        </footer>
      </div>
      <Modal
      className="modalPayAllowances"
        visible={modal}
        onOk={() => setModal(false)}
        onCancel={() => setModal(false)}
        centered={true}
        footer={[
          <div style={{display:"flex", padding:"0 16px 16px"}}>
          <button
            className="ModalCancel"
            onClick={() => setModal(false)}
          >
            Cancelar
          </button>
          <button onClick={()=>setModal(false)} className="btnModalDo">Agregar</button>
          </div>,
        ]}
      >
        <div className="modal-container" style={{padding:"0 16px"}}>
          <p className="textModal">Nueva asignación</p>
          <div>
            <label>Nombre de la asignación</label>
            <input type="text" placeholder="Ingresa nombre de la asignación"/>
          </div>
          <div style={{marginTop:"20px"}}>
            <label>Tipo de asignación</label>
            <select>
              <option>Selecciona una opcion</option>
              <option>opcion 1</option>
              <option>opcion 2</option>
              <option>opcion 3</option>
            </select>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default PayAllowances;
//
