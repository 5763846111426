import React, { useState } from "react";
// import ReactDOM from "react-dom";
import HeadersSection from "../../../components/book/headersSection/HeadersSection";
import FooterSimple from "../../../components/book/footerSimple/FooterSimple";
import RelativeSelect from "../../../components/book/relativeSelect/RelativeSelect";
import Table from "../../../components/book/table/Table";
import { info } from '../../../../mockups/dashboard';

import './Board.scss';
import 'primeflex/primeflex.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.css';

const Dashboard = ({setActive, setAuth}) => {
    const [openSelect, setOpenSelect] = useState(false);
    return (

        <div className="container-dashboard">
            <HeadersSection
                title={info.headerInfo.title}
                exeptions={info.headerInfo.exeptions}
                date={info.headerInfo.date}
            />

            <div className="div-selects-dashboard">
                <div className="p-grid">
                    <div className="p-col-3 p-md-6 p-lg-3 p-sm-12 p-sm-12">
                        <RelativeSelect
                            inputName={info.selectRangoInfo.inputName}
                            list={info.selectRangoInfo.list}
                            setOpenSelect={setOpenSelect}
                            openSelect={openSelect}
                        />

                        <RelativeSelect
                            inputName={info.selectSupervisorInfo.inputName}
                            list={info.selectSupervisorInfo.list}
                            setOpenSelect={setOpenSelect}
                            openSelect={openSelect}
                        />
                    </div>
                    <div className="p-col-3 p-md-6 p-lg-3 p-sm-12">
                        <RelativeSelect
                            inputName={info.selectBranchInfo.inputName}
                            list={info.selectBranchInfo.list}
                            setOpenSelect={setOpenSelect}
                            openSelect={openSelect}
                        />

                        <RelativeSelect
                            inputName={info.selectSellerInfo.inputName}
                            list={info.selectSellerInfo.list}
                            setOpenSelect={setOpenSelect}
                            openSelect={openSelect}
                        />
                    </div>
                    <div className="p-col-3 p-md-6 p-lg-3 p-sm-12">
                        <RelativeSelect
                            inputName={info.selectChannelInfo.inputName}
                            list={info.selectChannelInfo.list}
                            setOpenSelect={setOpenSelect}
                            openSelect={openSelect}
                        />

                        <RelativeSelect
                            inputName={info.selectPOSInfo.inputName}
                            list={info.selectPOSInfo.list}
                            setOpenSelect={setOpenSelect}
                            openSelect={openSelect}
                        />
                    </div>
                    <div className="p-col-3 p-md-6 p-lg-3 p-sm-12">
                        <RelativeSelect
                            inputName={info.selectSubChannelInfo.inputName}
                            list={info.selectSubChannelInfo.list}
                            setOpenSelect={setOpenSelect}
                            openSelect={openSelect}
                        />

                        <RelativeSelect
                            inputName={info.selectAgentsInfo.inputName}
                            list={info.selectAgentsInfo.list}
                            setOpenSelect={setOpenSelect}
                            openSelect={openSelect}
                        />

                        <div className="div-buttons-dashboard">
                            <button className="buttons btn-blue">Limpiar</button>
                            <button className="buttons btn-green">Aceptar</button>
                        </div>

                    </div>

                    <div className="p-col-12 p-md-12 p-lg-12 p-sm-12">
                        <div className="div-container-stand">
                            <Table
                                arrayHeader={info.tableInfo.headerList}
                                arrayItems={info.tableInfo.rows}
                            />
                        </div>
                    </div>

                </div>
            </div>

            <FooterSimple />
        </div>
    );
};

export default Dashboard;

// if (document.getElementById("reactDashbaord")) {
//     ReactDOM.render(<Dashboard />, document.getElementById("dashboard"));
// }
