
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faSearch } from '@fortawesome/free-solid-svg-icons';
import { largeWord } from './functions';
//import { InputText } from 'primereact/inputtext';
import './RelativeSelect.scss';

const RelativeSelect = ({ inputName, list, setOpenSelect, openSelect }) => {
    const [selected, changeSelect] = useState('');
    const [showList, setShowList] = useState(false);
    const [heightCard, setHeightList] = useState(0);

    useEffect(() => {
        if (openSelect) {
            setHeightList(0);
            setShowList(false);
        }
    }, [openSelect]);

    const viewCardList = () => {
        setOpenSelect(!showList);
        setTimeout(() => {
            triggerOpen();
            setOpenSelect(false);
        }, 100);
    };

    const triggerOpen = () => {
        setHeightList(!showList ? 218 : 0);
        if (!showList) {
            setTimeout(() => {
                setShowList(!showList);
            }, 300);
        } else setShowList(!showList);
    };

    const choiceList = (value) => {
        changeSelect(value);
        viewCardList();
    };

    return (
        <div>
            <div className="card-select">
                <div className="p-grid">
                    <div className="p-col-4" style={{
                        paddingLeft: largeWord(inputName, 'flag') ? '0px' : '8px',
                        paddingRight: largeWord(inputName, 'flag') ? '0px' : '8px'
                    }}>
                        <p className={`inputName ${largeWord(inputName, 'className')}`}>{inputName}</p>
                    </div>
                    <div className="p-col-7">
                        <div className="inputSelected-relativeSelect">
                            <p
                                style={{
                                    color: selected ? '#000000' : '#e3e3e3' 
                                }}
                            >{selected || 'Selección'}</p>
                        </div>
                    </div>
                    <div className="p-col-1 div-iconCheronDown-rs">
                        <a onClick={viewCardList}><FontAwesomeIcon icon={showList ? faChevronUp : faChevronDown} /></a>
                    </div>
                </div>
            </div>
            <div className="card-list-select" style={{ height: heightCard }}>
                {showList &&
                    <div style={{ padding: 30 }}>
                        <div className="search-container">
                            <div className="sub-search-container">
                                <input type="text" placeholder="Buscar" />
                                <div><FontAwesomeIcon icon={faSearch} /></div>
                            </div>
                        </div>
                        <div className="list-container-rs">
                            {list.map((item) => {
                                return (
                                    <div className="item-list-rs" onClick={() => choiceList(item)}>{item}</div>
                                );
                            })}

                        </div>
                    </div>
                }
            </div>
        </div>

    );
};

export default RelativeSelect;
