import React from "react";
import Personnel from "../Personnel";
import "../Personnel.scss";
import "./StaffDetail.scss";
import "./General";
import General from "./General";
import Permisos from "./Permisos";
import pdf from "../../../../../../images/tableIcons/iconPdf.svg";

const StaffDetail = ({ opcion, contenido, button, className, barra, styles }) => {
  return (
    <div className={className}>
      <Personnel caption="Personal" captionData="Detalle del personal" />
      <div className="personnel-container container-height" style={styles}>
        <div className="staff-detail-header">
          <nav className="staff-detail-header-nav">
            <a
              className={
                opcion == "general" ? "a-l select-green" : "a-l not-select"
              }
              href="/personnel-staffdetail/general"
            >
              General
            </a>
            <a
              className={
                opcion == "solicitudes" ? " select-green" : "not-select"
              }
              href="/personnel-staffdetail/request"
            >
              Solicitudes
            </a>
            <a
              className={
                opcion == "penalizaciones" ? " select-green" : "not-select"
              }
              href="/personnel-staffdetail/penalties"
            >
              Penalizaciones
            </a>
            <a
              className={opcion == "permisos" ? " select-green" : "not-select"}
              href="/personnel-staffdetail/permissions"
            >
              Permisos
            </a>
            <a
              className={
                opcion == "bitacora" ? "a-r select-green" : "a-r not-select"
              }
              href="/personnel-staffdetail/binnacle"
            >
              Bitácora de acciones
            </a>
          </nav>
          {button && (
            <div className="staff-detail-header-button">
              <button
                className="button-white"
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <img src={pdf} />
                <p>Imprimir ficha</p>
              </button>
              <button className="button-red">
                <p>Desvincular</p>
              </button>
            </div>
          )}
        </div>
        {barra &&(
          <div className="personnel-container-quest" style={{justifyContent:"space-between"}}>
          <p>Datos personales</p>
          <button className="update-button">
            <p style={{margin:"0"}}>Actualizar</p>
          </button>
        </div>
        )}
        {contenido}
      </div>
    </div>
  );
};

export default StaffDetail;
