import React from "react";
import './ReciveLetarge.scss'

// components
import HeadersSection from "../headersSection/HeadersSection";
import AbsoluteSelect from "../absoluteSelect/AbsoluteSelect";
//assets
import searchIcon from "../../../img/icons/search.svg";
import showIcon from "../../../img/icons/eye.png";
import pencilIcon from "../../../img/icons/pencil.svg";
import trashIcon from "../../../img/icons/trash.svg";
//libraries
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import FooterSimple from "../footerSimple/FooterSimple";


var arrayHeader = [
    { field: "field1", colspan: 2, className: "header-table" },
    { field: "field2" },
    { field: "field3" },
    { field: "field4" },
    { field: "field5" },
    { field: "field6" },
    { field: "field7" },
    { field: "field8" },
    { field: "field9" },
    { field: "field10" },
    { field: "field11" },
    { field: "field12" },
    { field: "field13" },
    { field: "field14" },
    { field: "field15" },
    { field: "field16" },
    { field: "field17" },
    { field: "field18" },
    { field: "field19" },
]
var arrayItems = [
    {
        field1: "50",
        field2: "1244536334",
        field3: "PREPAGO",
        field4: "256332",
        field5: "",
        field6: (<div className="colorGreen">PORTA</div>),
        field7: "",
        field8: "",
        field9: "",
        field10: "",
        field11: "",
        field12: "",
        field13: "",
        field14: (<><input type="checkbox" id="vehicle1"></input><label for="vehicle1"></label></>),
        field15: "",
        field16: "",
        field17: (<><input type="checkbox" id="vehicle1"></input><label for="vehicle1"></label></>),
        field18: "Firma no coi...",
    },
    {
        field1: "50",
        field2: "1244536334",
        field3: "PREPAGO",
        field4: "256332",
        field5: "",
        field6: (<div className="colorGreen">PORTA</div>),
        field7: "",
        field8: "",
        field9: "",
        field10: "",
        field11: "",
        field12: "",
        field13: "",
        field14: (<><input type="checkbox" id="vehicle1"></input><label for="vehicle1"></label></>),
        field15: "",
        field16: "",
        field17: (<><input type="checkbox" id="vehicle1"></input><label for="vehicle1"></label></>),
        field18: "Firma no coi...",
    },
    {
        field1: "50",
        field2: "1244536334",
        field3: "PREPAGO",
        field4: "256332",
        field5: "",
        field6: (<div className="colorGreen">PORTA</div>),
        field7: "",
        field8: "",
        field9: "",
        field10: "",
        field11: "",
        field12: "",
        field13: "",
        field14: (<><input type="checkbox" id="vehicle1"></input><label for="vehicle1"></label></>),
        field15: "",
        field16: "",
        field17: (<><input type="checkbox" id="vehicle1"></input><label for="vehicle1"></label></>),
        field18: "Firma no coi...",
    },
    {
        field1: "50",
        field2: "1244536334",
        field3: "PREPAGO",
        field4: "256332",
        field5: "",
        field6: (<div className="colorGreen">PORTA</div>),
        field7: "",
        field8: "",
        field9: "",
        field10: "",
        field11: "",
        field12: "",
        field13: "",
        field14: (<><input type="checkbox" id="vehicle1"></input><label for="vehicle1"></label></>),
        field15: "",
        field16: "",
        field17: (<><input type="checkbox" id="vehicle1"></input><label for="vehicle1"></label></>),
        field18: "Firma no coi...",
    },
    {
        field1: "50",
        field2: "1244536334",
        field3: "PREPAGO",
        field4: "256332",
        field5: "",
        field6: (<div className="colorGreen">PORTA</div>),
        field7: "",
        field8: "",
        field9: "",
        field10: "",
        field11: "",
        field12: "",
        field13: "",
        field14: (<><input type="checkbox" id="vehicle1"></input><label for="vehicle1"></label></>),
        field15: "",
        field16: "",
        field17: (<><input type="checkbox" id="vehicle1"></input><label for="vehicle1"></label></>),
        field18: "Firma no coi...",
    },
    {
        field1: "50",
        field2: "1244536334",
        field3: "PREPAGO",
        field4: "256332",
        field5: "",
        field6: (<div className="colorGreen">PORTA</div>),
        field7: "",
        field8: "",
        field9: "",
        field10: "",
        field11: "",
        field12: "",
        field13: "",
        field14: (<><input type="checkbox" id="vehicle1"></input><label for="vehicle1"></label></>),
        field15: "",
        field16: "",
        field17: (<><input type="checkbox" id="vehicle1"></input><label for="vehicle1"></label></>),
        field18: "Firma no coi...",
    },
    {
        field1: "50",
        field2: "1244536334",
        field3: "PREPAGO",
        field4: "256332",
        field5: "",
        field6: (<div className="colorGreen">PORTA</div>),
        field7: "",
        field8: "",
        field9: "",
        field10: "",
        field11: "",
        field12: "",
        field13: "",
        field14: (<><input type="checkbox" id="vehicle1"></input><label for="vehicle1"></label></>),
        field15: "",
        field16: "",
        field17: (<><input type="checkbox" id="vehicle1"></input><label for="vehicle1"></label></>),
        field18: "Firma no coi...",
    },


]

const templateActions = () => {
    return (
        <div className="actions-container">
            <img className="icons-action" src={showIcon}></img>
            <img className="icons-action" width="25" height="25" src={pencilIcon}></img>
            <img className="icons-action" width="25" height="25" src={trashIcon}></img>
        </div>
    )
}
const ReciveLetarge = () => {
    // header
    let headerGroup =
        <ColumnGroup>
            <Row>


                <Column header="NRO LEGAJO" colSpan={1} className="dth-child border-radius-left" />
                <Column header="SDS" colSpan={1} className="dth-child" />
                <Column header="CANAL" colSpan={1} className="dth-child" />
                <Column header="POS" colSpan={1} className="dth-child" />
                <Column header="NIM" colSpan={1} className="dth-child" />
                <Column header="PLAN" colSpan={1} className="dth-child" />
                <Column header="SIMCARD" colSpan={2} className="dth-child" />
                <Column header="DOCUMENTO" colSpan={1} className="dth-child" />
                <Column header="TITULAR" colSpan={1} className="dth-child" />
                <Column header="FECHA DE ACTIVACIÓN" colSpan={1} className="dth-child" />
                <Column header="ESTADO" colSpan={1} className="dth-child" />
                <Column header="ANTIGÜEDAD LEGAJO" colSpan={1} className="dth-child" />
                <Column header="CI" colSpan={1} className="dth-child" />
                <Column header="RIESGO" colSpan={1} className="dth-child" />
                <Column header="MAVERICK" colSpan={1} className="dth-child" />
                <Column header="ANEXOS" colSpan={1} className="dth-child" />
                <Column header="OBSERVACIONES" colSpan={1} className="dth-child" />
                <Column header="ACCIONES" colSpan={2} className="dth-child border-radius-right" />

            </Row>
        </ColumnGroup>

    return (
        <>
            <div className="cj-recive-letarge">
                {/* header */}
                <div className="container-activations">
                    <HeadersSection
                        title={<div>Recibir Legajos</div>}
                        exeptions={{}}
                    />
                    {/* selects sections  */}
                    <div className="div-selects-activations homeFiles">
                        <div className="div-container-stand grid-container">
                            <div className="p-grid">
                                <div className="p-col-4 p-md-6 p-lg-3 p-sm-12">
                                    <AbsoluteSelect
                                        inputName={'RECIBE'}
                                    //list={info.selectRangoInfo.list}
                                    // list={listJsonTest}
                                    // setOpenSelect={setOpenSelect}
                                    // openSelect={openSelect}
                                    // setIdSelect={setIdSelect}
                                    />
                                </div>
                                <div className="p-col-4 p-md-6 p-lg-3 p-sm-12 center-absolute-select">
                                    <AbsoluteSelect
                                        inputName={'POS/ RESPONSABLE/ ENTREGA'}
                                    //list={info.selectRangoInfo.list}
                                    // list={listJsonTest}
                                    // setOpenSelect={setOpenSelect}
                                    // openSelect={openSelect}
                                    // setIdSelect={setIdSelect}
                                    />
                                </div>
                                <div className="p-col-4 p-md-6 p-lg-3 p-sm-12 center-absolute-select">
                                    <div className="btn-duplex">
                                        <button className="btn-duplex-push">SUPERVISOR</button>
                                        <input type="text" placeholder="XXXXX"></input>
                                    </div>
                                </div>
                                <div className="p-col-4 p-md-6 p-lg-3 p-sm-12 center-absolute-select"></div>
                                <div className="p-col-4 p-md-6 p-lg-3 p-sm-12 center-absolute-select">
                                    <div className="btn-duplex">
                                        <button className="btn-duplex-push">BUSCAR:</button>
                                        <input type="text" placeholder="NIM, SDS O SIMCARD"></input>
                                    </div>
                                </div>
                                <div className="p-col-4 p-md-6 p-lg-3 p-sm-12 center-absolute-select">
                                    <div className="btn-search-container">
                                        <button className="btn-search"><img src={searchIcon}></img></button>
                                    </div>

                                </div>
                            </div>
                            {/* DATA TABLE */}
                            <DataTable value={arrayItems} headerColumnGroup={headerGroup} responsiveLayout="scroll" className="table-activations"  >

                                {/* <ColumnGroup> */}
                                {/* <Row> */}
                                {arrayHeader.map((item, key) => {
                                    console.log(item)
                                    if (key === 18) {
                                        return (
                                            <Column body={templateActions()} colSpan={item.colspan ? item.colspan : 1} className={item.className ? item.className : 1} key={key} />
                                        )

                                    }
                                    else {
                                        return (
                                            <Column field={item.field} colSpan={item.colspan ? item.colspan : 1} className={item.className ? item.className : 1} key={key} />

                                        );
                                    }

                                })}
                                {/* </Row> */}
                                {/* </ColumnGroup> */}

                            </DataTable>
                            {/* btn end- limpiar y recepcionar */}
                            <div className="buttons-container">
                                <button className="btn-clean">Limpiar</button>
                                <button className="btn-reseption">Recepcionar</button>
                            </div>
                        </div>

                    </div>
                </div>
                <FooterSimple />
            </div>

        </>
    );
}
export default ReciveLetarge;