import React from "react"
import './card.scss'

const Card = ({ colorClass, text, number, icon }) => {
    
    return (
        <div className="cardInfo">
            <div className='contentCard'>
                <div className='sectionText'>
                    <p className={`colorText ${colorClass}`} style={{ color: colorClass }}>{text}</p>
                    <p className="boldText">{number}</p>
                </div>

                <div className='imgSpace'>
                    <img src={icon} alt={icon} />
                </div>
            </div>

            <div className={`footerCard ${colorClass}`} style={{ backgroundColor: colorClass }}>

            </div>
        </div>
    )

}
export default Card
