import React from "react";

const Error = () => {
  return (
    <div
      style={{
        display: "block",
        textAlign: "center",
        margin: "10px auto",
        color: "red",
        fontWeight: "900",
        padding: "20px",
        textTransform: "uppercase",
      }}
    >
      {" "}
      Todos los campos son obligatorios
    </div>
  );
};

export default Error;
