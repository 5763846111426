
const tableInfo = {
    headerList: [
        {
            field: 'num',
            header: '#',
            order: false
        },
        {
            field: 'number',
            header: 'Nro. POS',
            order: false
        },
        {
            field: 'name',
            header: 'Nombre POS',
            order: false
        },
        {
            field: 'city',
            header: 'Ciudad POS',
            order: false
        },
        {
            field: 'area',
            header: 'Dpto. POS',
            order: false
        },
        {
            field: 'action',
            header: 'ACCIONES',
            order: false
        },

    ],
    rows: [
        {
            num: 1,
            number: '21/12/21',
            name: 'MPP22',
            city:'PP BH TPS - SMS',
            area:'Portabilidad',
            action:'',


        },
        {
            num: 2,
            number: '21/12/21',
            name: 'MPP22',
            city:'PP BH TPS - SMS',
            area:'Portabilidad',
            action:'',


        },
        {
            num: 3,
            number: '21/12/21',
            name: 'MPP22',
            city:'PP BH TPS - SMS',
            area:'Portabilidad',
            action:'',


        },
        {
            num: 4,
            number: '21/12/21',
            name: 'MPP22',
            city:'PP BH TPS - SMS',
            area:'Portabilidad',
            action:'',


        },
        {
            num: 5,
            number: '21/12/21',
            name: 'MPP22',
            city:'PP BH TPS - SMS',
            area:'Portabilidad',
            action:'',


        },
        {
            num: 6,
            number: '21/12/21',
            name: 'MPP22',
            city:'PP BH TPS - SMS',
            area:'Portabilidad',
            action:'',


        },
        {
            num: 7,
            number: '21/12/21',
            name: 'MPP22',
            city:'PP BH TPS - SMS',
            area:'Portabilidad',
            action:'',


        },
        {
            num: 8,
            number: '21/12/21',
            name: 'MPP22',
            city:'PP BH TPS - SMS',
            area:'Portabilidad',
            action:'',


        },
        {
            num: 9,
            number: '21/12/21',
            name: 'MPP22',
            city:'PP BH TPS - SMS',
            area:'Portabilidad',
            action:'',


        },
        {
            num: 10,
            number: '21/12/21',
            name: 'MPP22',
            city:'PP BH TPS - SMS',
            area:'Portabilidad',
            action:'',


        },
        {
            num: 11,
            number: '21/12/21',
            name: 'MPP22',
            city:'PP BH TPS - SMS',
            area:'Portabilidad',
            action:'',


        },
        {
            num: 12,
            number: '21/12/21',
            name: 'MPP22',
            city:'PP BH TPS - SMS',
            area:'Portabilidad',
            action:'',


        },
        {
            num: 13,
            number: '21/12/21',
            name: 'MPP22',
            city:'PP BH TPS - SMS',
            area:'Portabilidad',
            action:'',


        },
        {
            num: 14,
            number: '21/12/21',
            name: 'MPP22',
            city:'PP BH TPS - SMS',
            area:'Portabilidad',
            action:'',


        },
        {
            num: 15,
            number: '21/12/21',
            name: 'MPP22',
            city:'PP BH TPS - SMS',
            area:'Portabilidad',
            action:'',


        },
        {
            num: 16,
            number: '21/12/21',
            name: 'MPP22',
            city:'PP BH TPS - SMS',
            area:'Portabilidad',
            action:'',


        },

    ]
}

export const infoPos = {
    tableInfo
};
