import React, { useState, useEffect } from "react";
import "./NewReason.scss";
import imgExel from "../../../../../img/jpg/exel.png";
import imgCsv from "../../../../../img/jpg/csv.png";
import { Modal } from "antd";
import { DataTable } from "primereact/datatable";
import { infoParameters } from "./newReasonData";
import TableNewReason from "./TableNewReason"



const NewReason = () => {
  const [searchEnter, setSearchEnter] = useState();
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [dataParameters, setDataParameters] = useState([]);
  const [column, setColumn] = useState([]);
  const [modal, setModal] = useState(false)

  const generarId = () => {
    const random = Math.random().toString(36).substr(2);
    const fecha = Date.now().toString(36);
    return fecha + random;
  };
  
  // infoParameters.tableInfo.rows.map(e=>{
  //   const parameters = {
  //     id: generarId(),
  //     cargo: e.cargo,
  //     asignacion: e.asignacion,
  //     date: e.date,
  //     num: e.num,
  //     update: e.update,
  //     accion: e.accion,
  //   }
  //   setDataParameters([...dataParameters, parameters])
  // })
  // console.log(dataParameters)
  return (
      <div
        className="parameters"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: "20px",
        }}
      >
        <div>
          <h4 className="parameters-title">
            Parámetros <span className="parameters-title-span">{" < "}Razones sociales </span>
          </h4>
          <div className="parameters-container">
            <div className="parameters-container-header">
              <div className="parameters-container-header-search">
                <label>Filtrar por:</label>
                <input
                  value={searchEnter}
                  onChange={(e) => {
                    setSearchEnter(e.target.value);
                  }}
                  type="search"
                  placeholder="Buscar por nombre"
                />
              </div>

              <div className="parameters-container-header-button">
                <button onClick={() => {
                  setModal(true)
                }}>Nueva razón social</button>
              </div>
            </div>

            <div className="parameters-table">
              <TableNewReason
              generarId={generarId}
                value={dataParameters}
                column={column}
                setColumn={setColumn}
                showModalDelete={showModalDelete}
                setShowModalDelete={setShowModalDelete}
                searchEnter={searchEnter}
              />
            </div>
            <div className="parameters-footer">
              <div className="parameters-footer-download">
                <label>Descargar como</label>
                <button className="parameters-footer-download-button">
                  <img src={imgExel} />
                </button>
                <button className="parameters-footer-download-button">
                  <img src={imgCsv} />
                </button>
              </div>
              <div className="parameters-footer-pag">
                
              </div>
            </div>
          </div>
        </div>
        <div>
        <footer style={{ display: "flex", justifyContent: "center" }}>
          <p>2022 GestiClaro. Todos los derechos reservados</p>
        </footer>
      </div>
        <Modal
        className="modalNewReason"
          visible={modal}
          onOk={() => setModal(false)}
          onCancel={() => setModal(false)}
          centered={true}
          footer={[
            <div style={{ display: "flex", padding: "0 16px 16px" }}>
              <button
                className="ModalCancel"
                onClick={() => setModal(false)}
              >
                Cancelar
              </button>
              <button className="btnModalDo">Agregar</button>
            </div>,
          ]}
        >
          <div className="modal-container" style={{ padding: "0 16px", height: "200px" }}>
            <p className="textModal">Nueva razón social</p>
            <div>
              <label>Razón social</label>
              <input type="text" placeholder="Ingresa nombre de la asignación" />
            </div>
          </div>
        </Modal>
      </div>
      

  );
};
export default NewReason;
//
