import { flexbox, style } from "@mui/system";
import { left } from "@popperjs/core";
import React, { useEffect, useState } from "react";
import DocumentElements from "../NewPersonnel/DocumentElements";
import Error from "./Error";
const ModalAdd = ({
  cerrarModal,
  registro,
  setRegistro,
  direccion,
  newModal,
}) => {
  const [activarNumber, setActivarNumber] = useState("");
  const [activar, setActivar] = useState(false);
  const [asignacion, setAsignacion] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [valor, setValor] = useState("");
  const [error, setError] = useState(false);
  console.log(direccion);

  useEffect(() => {
    if (activarNumber === "Fijo" || activarNumber === "Variable") {
      setActivar(true);
    } else {
      setActivar(false);
    }
  }, [activarNumber]);

  const generarId = () => {
    const random = Math.random().toString(36).substr(2);
    const fecha = Date.now().toString(36);
    return fecha + random;
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    // validar formulario
    if ([asignacion, descripcion, valor].includes("")) {
      setError(true);

      setTimeout(() => {
        setError(false);
      }, 2500);
      return;
    }

    const objetoData = {
      id: generarId(),
      tipo: asignacion,
      comision: "Comisión",
      descripcion: Number(valor).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      }),
      asignacion: descripcion,
      estado: "Activo",
      accion: "Acciones",
    };

    setRegistro([...registro, objetoData]);
    cerrarModal();
  };
  const handleSubmit1 = (e) => {
    e.preventDefault();
    const objetoData = {
      id: generarId(),
      fecha: "12 - Abril - 2021",
      direccion:
        "Federico Ibarra 1055, entre Barcelona y Santa Mónica, Santa Elena Alcalde, Guadalajara, Jalisco",
      accion: "Acciones",
    };

    setRegistro([...registro, objetoData]);
    cerrarModal();
  };
  const handleSubmit2 = (e) => {
    e.preventDefault();
    cerrarModal();
  };
  return (
    <>
      {direccion ? (
        <form onSubmit={handleSubmit1} className="modal" id="modal">
          <div className="modal-container-dir" style={{ height: "90%" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p style={{ fontWeight: "700", fontSize: "15px" }}>
                Nueva dirección
              </p>

              <p
                className="btn-cerrarModal"
                onClick={cerrarModal}
                style={{
                  color: "#a80038",
                  fontSize: "15px",
                  fontWeight: "700",
                }}
              >
                X
              </p>
            </div>
            <div className="display-phone" style={{ display: "flex" }}>
              <div className="select-modal">
                <label>Selecciona un departamento</label>
                <select style={{ width: "250px" }}>
                  <option selected>Selecciona una opción</option>
                  <option>Bono segunda recarga</option>
                  <option>Bonos</option>
                  <option>Comisión activaciones</option>
                  <option>POS</option>
                  <option>Días trabajados</option>
                  <option>Porth</option>
                  <option>Out</option>
                  <option>Portabilidad</option>
                  <option>PosPago</option>
                  <option>Prepago Residuales</option>
                  <option>Stock</option>
                  <option>Volumen cumplimiento</option>
                  <option>Zafra</option>
                </select>
              </div>
              <div className="select-modal">
                <label>Ciudad</label>
                <select style={{ width: "250px" }}>
                  <option selected>Selecciona una opción</option>
                  <option>Argentina</option>
                  <option>Chile</option>
                  <option>Colombia</option>
                  <option>Uruguay</option>
                  <option>Brasil</option>
                  <option>Ecuador</option>
                  <option>Paraguay</option>
                  <option>Bolivia</option>
                  <option>Venezuela</option>
                  <option>Guyana</option>
                  <option>Guayana Francesa</option>
                  <option>Surinam</option>
                  <option>Panamá</option>
                  <option>Costa Rica</option>
                  <option>El Salvador</option>
                  <option>Honduras</option>
                  <option>Guatemala</option>
                  <option>Belice</option>
                  <option>México</option>
                </select>
              </div>
            </div>
            <fieldset>
              <p>Tipo de vivienda:</p>
              <div style={{ display: "flex", gap: "20px", marginLeft: "50px" }}>
                <div>
                  <input
                    style={{ left: "30px" }}
                    type="radio"
                    id="casa"
                    name="casa"
                    value="casa"
                    checked
                  />
                  <label style={{ position: "absolute" }} for="casa">
                    Casa
                  </label>
                </div>

                <div>
                  <input
                    style={{ left: "100px" }}
                    type="radio"
                    id="edificio"
                    name="casa"
                    value="edificio"
                  />
                  <label
                    style={{ marginLeft: "50px", position: "absolute" }}
                    for="edificio"
                  >
                    Edificio
                  </label>
                </div>
              </div>
            </fieldset>
            <div className="inputs-modal modal-asign">
              <div className="inputs-column">
                <label>Calle 1</label>
                <input
                  className="input-style"
                  type="text"
                  placeholder="Ingresa una calle"
                />
              </div>
              <div className="inputs-column input-number">
                <label>Nro. exterior</label>
                <input
                  className="input-style"
                  type="text"
                  placeholder="Ingresa número"
                />
              </div>
            </div>
            <div className="inputs-modal modal-asign">
              <div className="inputs-column">
                <label>Calle 2</label>
                <input
                  className="input-style"
                  type="text"
                  placeholder="Ingresa una calle"
                />
              </div>
              <div className="inputs-column">
                <label>Calle 3</label>
                <input
                  className="input-style"
                  type="text"
                  placeholder="Ingresa número"
                />
              </div>
            </div>
            <div className="inputs-modal modal-asign">
              <div className="inputs-column">
                <label>Barriolle 2</label>
                <input
                  className="input-style"
                  type="text"
                  placeholder="Ingresa un barrio"
                />
              </div>
            </div>
            <div className="inputs-modal modal-asign">
              <div className="inputs-column">
                <label>Edificio</label>
                <input
                  disabled
                  style={{ backgroundColor: "#F8F8FC" }}
                  className="input-style"
                  type="text"
                  placeholder="Nro. del Edificio"
                />
              </div>
              <div className="inputs-column">
                <label>Piso</label>
                <input
                  disabled
                  style={{ backgroundColor: "#F8F8FC" }}
                  className="input-style"
                  type="text"
                  placeholder="Nro. de piso"
                />
              </div>
            </div>
            <div className="inputs-modal modal-asign">
              <div className="inputs-column">
                <label>Nro. de departamento</label>
                <input
                  disabled
                  style={{ backgroundColor: "#F8F8FC" }}
                  className="input-style"
                  type="text"
                  placeholder="Nro. de departamento"
                />
              </div>
            </div>
            <div className="inputs-modal modal-asign">
              <div className="inputs-column">
                <label>Código GPS</label>
                <input
                  className="input-style"
                  type="text"
                  placeholder="Ingresa Código GPS"
                />
              </div>
              <div className="inputs-column">
                <label>Google Plus</label>
                <input
                  className="input-style"
                  type="text"
                  placeholder="Ingresa Google Plus"
                />
              </div>
            </div>
            <div className="inputs-modal modal-asign">
              <div className="inputs-column textarea2">
                <label>Edificio</label>
                <textarea placeholder="Ingresa una calle"></textarea>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "right",
                marginRight: "20px",
                marginBottom:"20px"
              }}
            >
              <button
                className="btn-modal"
                style={{ backgroundColor: "#308ac9" }}
              >
                Guardar
              </button>
            </div>
          </div>
        </form>
      ) : !newModal ? (
        <form onSubmit={handleSubmit} className="modal" id="modal">
          <div className="modal-container">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p style={{ fontWeight: "700", fontSize: "15px" }}>
                Nueva asignación
              </p>

              <p
                className="btn-cerrarModal"
                onClick={cerrarModal}
                style={{
                  color: "#a80038",
                  fontSize: "15px",
                  fontWeight: "700",
                }}
              >
                X
              </p>
            </div>
            <div className="select-modal">
              <label>Tipo de Asignación</label>
              <select
                onChange={(e) => {
                  setAsignacion(e.target.value);
                }}
              >
                <option selected>Selecciona una opción</option>
                <option>Bono segunda recarga</option>
                <option>Bonos</option>
                <option>Comisión activaciones</option>
                <option>POS</option>
                <option>Días trabajados</option>
                <option>Porth</option>
                <option>Out</option>
                <option>Portabilidad</option>
                <option>PosPago</option>
                <option>Prepago Residuales</option>
                <option>Stock</option>
                <option>Volumen cumplimiento</option>
                <option>Zafra</option>
              </select>
            </div>
            <div className="select-modal-description">
              <div className="select-modal">
                <label>Descripción</label>
                <select onChange={(e) => setDescripcion(e.target.value)}>
                  <option selected>Tipo de rubro</option>
                  <option>Variable</option>
                  <option>Fijo</option>
                </select>
              </div>
              <div id="select-number">
                {descripcion && (
                  <input
                    value={valor}
                    onChange={(e) => {
                      setValor(Number(e.target.value));
                    }}
                    className="input-style size"
                    id="id"
                    type="number"
                    step="0.01"
                    min={0}
                  />
                )}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "20px",
              }}
            >
              <button
                className="btn-modal"
                style={{ backgroundColor: "#ff2631" }}
              >
                Guardar
              </button>
            </div>
            {error && <Error />}
          </div>
        </form>
      ) : (
        <form onSubmit={handleSubmit2} className="modal" id="modal">
          <div
            className="modal-container"
            style={{ height: "700px", paddingLeft: "46px" }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p style={{ fontWeight: "700", fontSize: "15px" }}>
                Nueva solicitud
              </p>

              <p
                className="btn-cerrarModal"
                onClick={cerrarModal}
                style={{
                  color: "#a80038",
                  fontSize: "15px",
                  fontWeight: "700",
                }}
              >
                X
              </p>
            </div>
            <div className="select-modal">
              <label>Fecha de solicitud</label>
              <input
                className="select-modal--request"
                type="text"
                placeholder="15/Enero/2021"
                disabled
              />
            </div>
            <div className="select-modal">
              <label>Tipo de solicitud</label>
              <select>
                <option disabled>Seleciona una opción</option>
                <option>Seleciona opción 1</option>
                <option>Seleciona opción 2</option>
                <option>Seleciona opción 3</option>
                <option>Seleciona opción 4</option>
              </select>
              <div style={{ display: "flex", marginTop: "20px" }}>
                <div className="select-modal" style={{ marginLeft: "0" }}>
                  <label>Fecha de inicio</label>
                  <input className="input-style-request" type="date" />
                </div>
                <div className="select-modal">
                  <label>Fecha de finalización</label>
                  <input className="input-style-request" type="date" />
                </div>
              </div>
              <div className="upload" style={{ display: "flex" }}>
                <div className="select-modal" style={{ marginLeft: "0" }}>
                  <label>Fecha de inicio</label>
                  <input
                    className="input-style-request"
                    type="number"
                    placeholder="0"
                  />
                </div>
                <DocumentElements
                  id="imageLogo1"
                  id2="input-file1"
                  title="Subir documento"
                  ajustes={true}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginRight: "39px",
                }}
              >
                <label>Observaciones</label>
                <textarea style={{ height: "100px" }}></textarea>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "20px",
              }}
            >
              <button
                className="btn-modal"
                style={{ backgroundColor: "#e3e3e3", color: "black" }}
              >
                Cancelar
              </button>
              <button
                onClick={cerrarModal}
                className="btn-modal"
                style={{ backgroundColor: "#308ac9", marginRight: "39px" }}
              >
                Entregar
              </button>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export default ModalAdd;
