const dataOne = [
  {
    total: "Total a la fecha",
    rows: [
      {
        row: [3568, 3568, 3568, 3568],
        color: 'rgba(255, 38, 49, 0.15)'
      },
      {
        row: [0, 0, 0, 0, 0],
        color: 'rgba(17, 171, 119, 0.15)'
      },
      {
        row: [0, 0, 0, 0],
        color: 'rgba(48, 138, 201, 0.15)'
      },
      {
        row: [0, 0, 0, 0, 0, 0, 0],
        color: 'rgba(245, 167, 42, 0.15)'
      },
      {
        row: [0],
        color: 'rgba(74, 79, 84, 0.15)'
      },
    ],
  },
];

const dataDateNow = [
  {
    total: "Total a la fecha",
    rows: [
      {
        row: [0, 0, 0, 0],
        color: 'rgba(255, 38, 49, 0.15)'
      },
      {
        row: [0, 0, 0, 0, 0],
        color: 'rgba(17, 171, 119, 0.15)'
      },
      {
        row: [0, 0, 0, 0],
        color: 'rgba(48, 138, 201, 0.15)'
      },
      {
        row: [0, 0, 0, 0, 0, 0, 0],
        color: 'rgba(245, 167, 42, 0.15)'
      },
      {
        row: [0],
        color: 'rgba(74, 79, 84, 0.15)'
      },
    ],
  },
];

const dataTotal = [
  {
    total: "A la fecha",
    total2: 'Total mes',
    rows: [
      {
        row: [0, 0, 0, 0],
        color: 'rgba(255, 38, 49, 0.15)'
      },
      {
        row: [0, 0, 0, 0, 0],
        color: 'rgba(17, 171, 119, 0.15)'
      },
      {
        row: [0, 0, 0, 0],
        color: 'rgba(48, 138, 201, 0.15)'
      },
      {
        row: [0, 0, 0, 0, 0, 0, 0],
        color: 'rgba(245, 167, 42, 0.15)'
      },
      {
        row: [0],
        color: 'rgba(74, 79, 84, 0.15)'
      },
    ],
  },
];

const dataDates = [
  {
    date: "29/07/2021",
    rows: [
      {
        row: [0, 0, 0, 0],
      },
      {
        row: [0, 0, 0, 0, 0],
      },
      {
        row: [0, 0, 0, 0],
      },
      {
        row: [0, 0, 0, 0, 0, 0, 0],
      },
      {
        row: [0],
      },
    ],
  },
  {
    date: "29/07/2021",
    rows: [
      {
        row: [0, 0, 0, 0],
      },
      {
        row: [0, 0, 0, 0, 0],
      },
      {
        row: [0, 0, 0, 0],
      },
      {
        row: [0, 0, 0, 0, 0, 0, 0],
      },
      {
        row: [0],
      },
    ],
  },
  {
    date: "29/07/2021",
    rows: [
      {
        row: [0, 0, 0, 0],
      },
      {
        row: [0, 0, 0, 0, 0],
      },
      {
        row: [0, 0, 0, 0],
      },
      {
        row: [0, 0, 0, 0, 0, 0, 0],
      },
      {
        row: [0],
      },
    ],
  },
  {
    date: "29/07/2021",
    rows: [
      {
        row: [0, 0, 0, 0],
      },
      {
        row: [0, 0, 0, 0, 0],
      },
      {
        row: [0, 0, 0, 0],
      },
      {
        row: [0, 0, 0, 0, 0, 0, 0],
      },
      {
        row: [0],
      },
    ],
  },
  {
    date: "29/07/2021",
    gray: true,
    rows: [
      {
        row: [0, 0, 0, 0],
      },
      {
        row: [0, 0, 0, 0, 0],
      },
      {
        row: [0, 0, 0, 0],
      },
      {
        row: [0, 0, 0, 0, 0, 0, 0],
      },
      {
        row: [0],
      },
    ],
  },
];

const datesOne = ["Al día de hoy", "30/07/2021", "30/07/2021"];

const tableInfoOne = {
  headers: [
    {
      field: "pp",
    },
    {
      field: "porta1",
    },
    {
      field: "bam1",
    },
    {
      field: "tgnral1",
    },
    {
      field: "pospago",
    },
    {
      field: "porta2",
    },
    {
      field: "bam2",
    },
    {
      field: "tgnral2",
    },

    {
      field: "pospago2",
    },
    {
      field: "porta3",
    },
    {
      field: "bam3",
    },
    {
      field: "tgnral3",
    },

    {
      field: "GPON",
    },
    {
      field: "GCORPO",
    },
    {
      field: "IPTV",
    },
    {
      field: "Cancelados",
    },
    {
      field: "DosP",
    },
    {
      field: "TresP",
    },
    {
      field: "Provisionados",
    },
    {
      field: "TresP2",
    },
  ],
  dates: datesOne,
  data: dataOne,
  dataDateNow,
  dataDates,
};

export const infoAct = {
  tableInfoOne,
  dataOne,
  dataDateNow,
  dataTotal,
};
