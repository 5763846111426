import React, { useState } from "react";
import './UploasLetargeScaned.scss'

//components
import HeadersSection from "../../../components/book/headersSection/HeadersSection";
import InputSearch from "../../../components/InputSearch/InputSearch";
import AbsoluteSelect from "../../../components/book/absoluteSelect/AbsoluteSelect";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import { Ripple } from 'primereact/ripple';

//assets 
import searchIcon from '../../../img/icons/search.svg'
import uploadIcon from '../../../img/icons/upload.svg'
import PalomGreen from '../../../img/icons/paloma-green.svg'
const UploasLetargeScaned = () => {
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [openModalEdit, setOpenModalEdit] = useState(false);
    const [viewTable, setViewTable] = useState(false)
    const handleDelete = () => {
        setOpenModalDelete(true)
    }

    const handleEdit = () => {
        setOpenModalEdit(true)
    }
    const templateButonsNB = {
        layout: 'PrevPageLink PageLinks NextPageLink',
        'PrevPageLink': (options) => {
            return (
                <button type="button" className={"btnGreen"} onClick={options.onClick} disabled={options.disabled}>
                    <span>Anterior</span>
                    <Ripple />
                </button>
            )
        },
        'NextPageLink': (options) => {
            return (
                <button type="button" className={"btnGreen"} onClick={options.onClick} disabled={options.disabled}>
                    <span>Siguiente</span>
                    <Ripple />
                </button>
            )
        },
    };
    const actions=()=>
    {
        
            return(
                <>
                    <img src={uploadIcon}></img>
                </>
            );
    }
    let headerGroup =
        <ColumnGroup>
            <Row>
                <Column header="#" colSpan={1} className="dth-child border-radius-left" />
                <Column header="NRO RENDICIÓN" colSpan={1} className="dth-child" />
                <Column header="FECHA GENERACIÓN" colSpan={1} className="dth-child" />
                <Column header="CANAL" colSpan={1} className="dth-child" />
                <Column header="CANTIDAD DE LEGAJOS" colSpan={1} className="dth-child" />
                <Column header="FECHA RECEPCIÓN CLARO" colSpan={1} className="dth-child" />
                <Column header="ACCIÓN" colSpan={1} className="dth-child border-radius-right" />

            </Row>
        </ColumnGroup>
        var arrayHeader = [
            { field: "field1", colspan: 2, className: "header-table" },
            { field: "field2" },
            { field: "field3" },
            { field: "field4" },
            { field: "field5" },
            { field: "field6" },
            { field: "field7" },
            
            
        ]
        var arrayItems = [
            {
                field1: "1",
                field2: "51",
                field3: "08/08/21",
                field4: "PREPAGO",
                field5: "20",
                field6: "08/08/21",
                field7: "",
             
                numberElement:"1"
                
            },
            {
                field1: "1",
                field2: "51",
                field3: "08/08/21",
                field4: "PREPAGO",
                field5: "20",
                field6: "08/08/21",
                field7: "",
                
                numberElement:"2"
                
            },
            {
                field1: "1",
                field2: "51",
                field3: "08/08/21",
                field4: "PREPAGO",
                field5: "20",
                field6: "08/08/21",
                field7: "",
                
                numberElement:"3"
                
            },
            {
                field1: "1",
                field2: "51",
                field3: "08/08/21",
                field4: "PREPAGO",
                field5: "20",
                field6: "08/08/21",
                field7: "",
                
                numberElement:"4"
                
            },
            {
                field1: "1",
                field2: "51",
                field3: "08/08/21",
                field4: "PREPAGO",
                field5: "20",
                field6: "08/08/21",
                field7: "",
               
                numberElement:"5"
                
            },
            {
                field1: "1",
                field2: "51",
                field3: "08/08/21",
                field4: "PREPAGO",
                field5: "20",
                field6: "08/08/21",
                field7: "",
               
                numberElement:"6"
                
            },
            {
                field1: "1",
                field2: "51",
                field3: "08/08/21",
                field4: "PREPAGO",
                field5: "20",
                field6: "08/08/21",
                field7: "",
              
                numberElement:"7"
                
            },
            {
                field1: "1",
                field2: "51",
                field3: "08/08/21",
                field4: "PREPAGO",
                field5: "20",
                field6: "08/08/21",
                field7: "",
              
                numberElement:"7"
                
            },
        
            {
                field1: "1",
                field2: "51",
                field3: "08/08/21",
                field4: "PREPAGO",
                field5: "20",
                field6: "08/08/21",
                field7: "",
              
                numberElement:"7"
                
            },
        
            {
                field1: "1",
                field2: "51",
                field3: "08/08/21",
                field4: "PREPAGO",
                field5: "20",
                field6: "08/08/21",
                field7: "",
              
                numberElement:"7"
                
            },
        
            {
                field1: "1",
                field2: "51",
                field3: "08/08/21",
                field4: "PREPAGO",
                field5: "20",
                field6: "08/08/21",
                field7: "",
              
                numberElement:"7"
                
            },
        
            {
                field1: "1",
                field2: "51",
                field3: "08/08/21",
                field4: "PREPAGO",
                field5: "20",
                field6: "08/08/21",
                field7: "",
              
                numberElement:"7"
                
            },
        
            {
                field1: "1",
                field2: "51",
                field3: "08/08/21",
                field4: "PREPAGO",
                field5: "20",
                field6: "08/08/21",
                field7: "",
              
                numberElement:"7"
                
            },
        
            {
                field1: "1",
                field2: "51",
                field3: "08/08/21",
                field4: "PREPAGO",
                field5: "20",
                field6: "08/08/21",
                field7: "",
              
                numberElement:"7"
                
            },
        
        
        
        ]
    return (
        <>
            {/* {openModalEdit && (<ModalEdit setOpenModalEdit= {setOpenModalEdit} />)}
                {openModalDelete && (<ModalDelete setOpenModalDelete= {setOpenModalDelete} />)} */}
            <div className="UploasLetargeScaned">
                <HeadersSection
                    title={<div>Subir Legajos Escaneados</div>}
                    exeptions={{}}
                />

                <div className="div-container-stand">
                    <div className="input-search">
                        <InputSearch placeholder ={"NRO RENDICIÓN"} ></InputSearch>
                        <div className="button-container">
                            <button className="btn-search" onClick={() => setViewTable(true)}><img src={searchIcon}></img></button>
                        </div>
                    </div>
                    <div className="data-table mt-5">
                        { viewTable &&
                            (<DataTable value={arrayItems} headerColumnGroup={headerGroup} responsiveLayout="scroll" paginator rows={10} paginatorTemplate={templateButonsNB} className="table-activations">
                                {arrayHeader.map((item, key) => {
                                    if (key === 6) {
                                        return (
                                            <Column body={actions} colSpan={item.colspan ? item.colspan : 1} className="column" key={key} />
                                        )
                                    }
                                    else {
                                        return (
                                            <Column field={item.field} colSpan={item.colspan ? item.colspan : 1} className="column" key={key} />
                                        );
                                    }

                                })}
                            </DataTable>)}
                        {/* { viewTable &&
                            (<div className="group-buttons">
                                <button className=" btn1 " onClick={()=> setViewTable(false)}>Limpiar</button>
                                <button className="btn2 ">Generar rendición</button>
                            </div>)} */}
                    </div>

                </div>

            </div>
        </>
    );
}
export default UploasLetargeScaned;