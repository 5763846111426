import React from "react";
import { useEffect, useState } from "react";
import "./ManagementPersons.scss";
import TableManagementPersons from "./TableManagementPersons";
import { infoParameters } from "./managementPersonsData";
import { faL } from "@fortawesome/free-solid-svg-icons";
import { green, red } from "@mui/material/colors";
import { style } from "@mui/system";
import { Link } from "@mui/material";

const ManagementPersons = () => {
  const [data, setData] = useState([]);
  const [column, setColumn] = useState([]);
  const [rows, setRows] = useState([]);
  const [searchEnter, setSearchEnter] = useState();
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [dataParameters, setDataParameters] = useState([]);

  useEffect(() => {
    const getData = async () => {
      setColumn(infoParameters.tableInfo.headerList);
      setRows(infoParameters.tableInfo.rows);
      setData(infoParameters.tableInfo);
    };
    getData();
  }, []);

  return (
    <div className="managementPersons" style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      gap: "20px",
    }}>
      <div>
        <p className="managementPersons-title">Gestión personas</p>
        <div className="managementPersons-container">
          <div className="managementPersons-header-principal">
            <div className="managementPersons-input-select">
              <input
                type="search"
                placeholder="Buscar por nombre"
                value={searchEnter}
                onChange={(e) => {
                  setSearchEnter(e.target.value);
                }}
              />
              <select className="">
                <option disabled>Seleccionar tipo</option>
                <option>Alta</option>
                <option>Baja</option>
              </select>
              <select>
                <option>Más nuevo a más viejo </option>
                <option>Más viejo a más nuevo</option>
              </select>
            </div>
            <div className="managementPersons-header-principal-button">
              <button className="managementPersons-button-blue">
                Imprimir agenda
              </button>
              <a href="/personnel-newpersonnel">
                <button className="managementPersons-button-green">
                  Nuevo personal
                </button>
              </a>
            </div>
          </div>

          <div className="managementPersons-header-secundario">
            <div className="header-secundario-title">
              <div className="managementPersons-header-secundario-title">
                <label>Filtrar por:</label>
              </div>

              <div className="managementPersons-header-secundario-date">
                <label>De</label>
                <input type="date" min="2022-09-01" />
                <label>Al</label>
                <input type="date" min="2022-09-01" />
              </div>
            </div>

            <div className="managementPersons-header-secundario-select">
              <select className="">
                <option>Seleccionar sucursal</option>
              </select>
              <select className="">
                <option>Seleccionar supervisor</option>
              </select>
              <select className="">
                <option>Seleccionar razón social</option>
              </select>
              <select className="">
                <option>Seleccionar canal</option>
              </select>
              <select className="">
                <option>Seleccionar sucursal</option>
              </select>
            </div>
          </div>

          <TableManagementPersons
            value={data.rows}
            rows={rows}
            column={column}
            setColumn={setColumn}
            showModalDelete={showModalDelete}
            setShowModalDelete={setShowModalDelete}
            searchEnter={searchEnter}
          />
        </div>
      </div>
      <div>
        <footer style={{ display: "flex", justifyContent: "center" }}>
          <p>2022 GestiClaro. Todos los derechos reservados</p>
        </footer>
      </div>
    </div>
  );
};

export default ManagementPersons;
