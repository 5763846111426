import React from "react";
import StaffDetail from "./StaffDetail";
import NewPersonnelFourData from "../NewPersonnelFour/NewPersonnelFourData";
import "./StaffDetail.scss";

const Permisos = () => {
  const generarId = () => {
    const random = Math.random().toString(36).substr(2);
    const fecha = Date.now().toString(36);
    return fecha + random;
  };

  const tableInfo = {
    rows: [
      {
        id: generarId(),
        seccion: "Ventas",
        espectador: "Espectador1",
        edicion: "Edición1",
        reporteria: "Reportería1",
      },
      {
        id: generarId(),
        seccion: "Verificaciones",
        espectador: "Espectador2",
        edicion: "Edición2",
        reporteria: "Reportería2",
      },
      {
        id: generarId(),
        seccion: "Activaciones",
        espectador: "Espectador3",
        edicion: "Edición3",
        reporteria: "Reportería3",
      },
      {
        id: generarId(),
        seccion: "Logística de instalación",
        espectador: "Espectador4",
        edicion: "Edición4",
        reporteria: "Reportería4",
      },
      {
        id: generarId(),
        seccion: "Logística de entrega",
        espectador: "Espectador5",
        edicion: "Edición5",
        reporteria: "Reportería5",
      },
      {
        id: generarId(),
        seccion: "Gestión de legajos",
        espectador: "Espectador6",
        edicion: "Edición6",
        reporteria: "Reportería6",
      },
      {
        id: generarId(),
        seccion: "Distribución",
        espectador: "Espectador7",
        edicion: "Edición7",
        reporteria: "Reportería7",
      },
      {
        id: generarId(),
        seccion: "POS Venta",
        espectador: "Espectador8",
        edicion: "Edición8",
        reporteria: "Reportería8",
      },
      {
        id: generarId(),
        seccion: "Gestión de stock",
        espectador: "Espectador9",
        edicion: "Edición9",
        reporteria: "Reportería9",
      },
      {
        id: generarId(),
        seccion: "Caja/Tesorería",
        espectador: "Espectador10",
        edicion: "Edición10",
        reporteria: "Reportería10",
      },
      {
        id: generarId(),
        seccion: "RRHH",
        espectador: "Espectador11",
        edicion: "Edición11",
        reporteria: "Reportería11",
      },
      {
        id: generarId(),
        seccion: "Auditoría",
        espectador: "Espectador12",
        edicion: "Edición12",
        reporteria: "Reportería12",
      },
      {
        id: generarId(),
        seccion: "Gerencia / Reportería",
        espectador: "Espectador13",
        edicion: "Edición13",
        reporteria: "Reportería13",
      },
      {
        id: generarId(),
        seccion: "Panel de control",
        espectador: "Espectador14",
        edicion: "Edición14",
        reporteria: "Reportería14",
      },
    ],
  };
  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <StaffDetail
        opcion="permisos"
        contenido={
          <NewPersonnelFourData rows={tableInfo.rows} button={false} />
        }
      />

      <div>
        <footer style={{ display: "flex", justifyContent: "center" }}>
          <p>2022 GestiClaro. Todos los derechos reservados</p>
        </footer>
      </div>
    </div>
  );
};

export default Permisos;
