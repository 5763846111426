import React, { useState } from "react";
import './Comercial.scss'
import json from "./TreeTableNodes.json"



//componts

import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import HeadersSection from "../../../../components/book/headersSection/HeadersSection";
import FooterSimple from "../../../../components/book/footerSimple/FooterSimple";
//assets
import logo from "../../../../img/log/image.png"
import TableIcon from "../../../../img/icons/alert.svg"
import ColumnGroup from "antd/lib/table/ColumnGroup";
import { Row } from "react-bootstrap";
const Comercial = () => {
    const [searchName, setSearchName] = useState();
    const [showData, setShowData] = useState(false)
    console.log("jsonxxx",json.root)
    const headerTable = 
    <ColumnGroup style={{width:"100%"}}>
            <Row >
                <Column header="Etiquetas de fila" style={{width:"250px"}} colSpan={1} className="  border-radius-left header-table  treetable"  />
                <Column header="Fecha ingreso" colSpan={1} className=" header-table  treetable"  />
                <Column header="Antigüiedad" colSpan={1} className=" header-table treetable"  />
                <Column header="Alcance %" colSpan={1} className=" header-table treetable"  />
                <Column header="Meta mes anterior" colSpan={1} className=" header-table treetable"  />
                <Column header="Activaciones logradas mes anterior" colSpan={1} className=" header-table treetable"  />
                <Column header="Ventas" colSpan={1} className=" header-table treetable"  />
                <Column header="Alcance %" colSpan={1} className=" header-table treetable"  />
                <Column header="Metas" colSpan={1} className=" header-table  treetable"  />
                <Column header="Proyección al cierre" colSpan={1} className=" header-table  treetable"  />
                <Column header="Proyección %" colSpan={1} className=" header-table  treetable"  />
                <Column header="Acciones" colSpan={1} className=" header-table  treetable border-radius-right"  />
                
            </Row>
        </ColumnGroup>
 const rowClassName = (node) => {
    console.log("node",node)
    return { 'table-tree-style': (node.children) };
}
const inputTemplate = ()=>
{
    return(<><input type="text" className="tableInput" placeholder="Cargar valor"></input></>)
}
    return (
        <>
       
            <div className="cj-people-managment MetaNotAssigned">
                <div className="container-activations">
                    <HeadersSection
                        title={<><div className="d-flex " style={{alignItems:"center"}}><div>Generar planillas</div><div className="ml-4" style={{fontSize:"15px",fontWeight:"400"}}>{"< Asignación de metas"}</div></div></>}
                        exeptions={{}}
                    />
                    <div className="div-selects-activations">
                        <div className="div-container-stand">
                            {/* header */}
                            <div className="top-container-template-generate">
                                <div className="container-flex ">
                                    <img src={logo} className={"logo-commercial"}></img>
                                 <div className="selects-container">
                                        {/* input search */}
                                        <div className="filter">
                                            Filtrar por:
                                        </div>
                                        <select className="select-commercial">
                                            <option value="sucursal">Seleccionar Persona</option>
                                            <option value="sucursal">Jorge Antonio Fuerte</option>
                                            <option value="sucursal">Ave Maria</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            {/* table */}
                            <div className="treetable-scroll">
                                <div className="treetable-container">
                                    <TreeTable value={json.root} headerColumnGroup={headerTable} stripedRows rowClassName={rowClassName}>
                                        <Column field="field1" className="treetable-column" expander ></Column>
                                        <Column field="field2" className="treetable-column" ></Column>
                                        <Column field="field3" className="treetable-column" ></Column>
                                        <Column field="field4" className="treetable-column" ></Column>
                                        <Column field="field5" className="treetable-column" ></Column>
                                        <Column field="field6" className="treetable-column" ></Column>
                                        <Column field="field7" className="treetable-column" ></Column>
                                        <Column field="field8" className="treetable-column" ></Column>
                                        <Column field="field9" className="treetable-column" ></Column>
                                        <Column field="field10" className="treetable-column" ></Column>
                                        <Column field="field11" className="treetable-column" ></Column>
                                        <Column body={inputTemplate} className="treetable-column" ></Column>
                                    </TreeTable>
                                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterSimple></FooterSimple>

        </>
    );
}
export default Comercial;