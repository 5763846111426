import React, { useState } from "react";
import './ExpiredPassword.scss'

//componts
import HeadersSection from "../../../../components/book/headersSection/HeadersSection";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import { Ripple } from 'primereact/ripple';
import FooterSimple from "../../../../components/book/footerSimple/FooterSimple";
import PdfModal from "../../../../components/book/PdfModal/PdfModal";
import GeneralModal from "../../../../components/book/GeneralModal/GeneralModal";
import FooterTableW from "../../../../components/book/FooterTable/FooterTable";
//assets

import eyeIcon from '../../../../img/icons/eye.svg'
import profileImg from '../../../../img/jpg/profile.jpg'
import pdfIcon from '../../../../img/icons/pdf.svg'
import searchIcon from '../../../../img/icons/search-gray.svg'
import PdfImg from '../../../../img/jpg/pdf.png'
import closeIcon from '../../../../img/icons/close.svg'
import copyIcon from '../../../../img/icons/copy.svg'
const ExpiredPassword = () => {
    const [searchName, setSearchName] = useState();
    const [modalPdfState, setModalPdfState] = useState(false);
    const [modalState, setModalState] = useState(false);
    const ModalTemplate=()=>
    {
        return(
            <>
                <div className="Modal-container">
                    <div className="headerUsersBlokedFiles">
                    < div className="left">
                            <div>
                                <div className="title-container">
                                    <div className="title-">POS del vendedor:</div>
                                    <div className="text-">432432423432</div>
                                </div>
                                <div className="title-container">
                                    <div className="title-">Supervisor:</div>
                                    <div className="text-">Alejandro Arreola Martínez</div>
                                </div>
                                <div className="title-container">
                                    <div className="title-">Nro. de teléfono del vendedor:</div>
                                    <div className="text-">33 3333 3333</div>
                                </div>
                                <div className="title-container">
                                    <div className="title-">Accesos para cambio de contraseña</div>
                                    
                                </div>
                                <div className="title-container">
                                    <a className="link-expiredpasswod">https://web.whatsapp.com/</a>
                                    <img src={copyIcon} style={{cursor:"pointer"}}></img>
                                </div>
                            </div>
                            <div>
                                <div className="title-container">
                                    <div className="title-">Estado del vendedor:</div>
                                    <div className="text-" style={{color:"#036402"}}>Activo</div>
                                </div>
                                <div className="title-container">
                                    <div className="title-">POS del vendedor:</div>
                                    <div className="text-">432432423432</div>
                            </div>
                            
                            </div>
                    </div>
                    <div className="right">
                            <div className="close" onClick={()=>setModalState(false)} >
                                <img src={closeIcon} style={{cursor:"pointer"}}></img>
                            </div>
                            <FooterTableW/>
                    </div>
                    </div>
                    
                </div>
            </>
        )
        
    }
    //table templates
    const PdfModalTemplate = () =>
    {
        return(<>
            <div className="img-pdf-modal">
                <img  src={PdfImg}></img>
            </div>
        </>)
       
    }
    const imgTemplate = () => {
        return (
            <div className="profile-image">
                <img src={profileImg} ></img>
            </div>
        );
    }
    const stateTemplate = (RowData) => {
        console.log("RowData", RowData.field11)
        if (RowData.field11 === "Activo") {
            return (
                <div className="btn" style={{ background: "#036402" }}><span>Activo</span></div>
            );
        }
        else if (RowData.field11 === "Inactivo") {
            return (
                <div className="btn" style={{ background: "#000000" }}>Inactivo</div>
            );
        }
        else {
            return (
                <div className="btn" style={{ background: "#ff2631" }}>Bloqueado</div>
            );
        }

    }
    const actionsTemplate = () => {
        return (
            <>
                <img src={eyeIcon} style={{ paddingRight: "20px",cursor:"pointer" }} onClick={()=>setModalState(true)}></img>
                <img src={pdfIcon} onClick={()=>setModalPdfState(true)} style={{ paddingRight: "20px",cursor:"pointer" }}></img>
            </>
        );
    }
    const typeTemplate = (RowData) => {
        switch (RowData.field10) {
            case "alta":
                return (
                    <div style={{ color: "#0ec927" }}>ALTA</div>
                )
                break;
            case "baja":
                return (
                    <div style={{ color: "#ff2631" }}>BAJA</div>
                )
                break;
        }
        return (<>

        </>);
    }
    const template1 = {
        layout: 'PrevPageLink PageLinks NextPageLink',
        'PrevPageLink': (options) => {
            return (
                <button type="button" className={"btnGreen"} onClick={options.onClick} disabled={options.disabled}>
                    <span>Anterior</span>
                    <Ripple />
                </button>
            )
        },
        'NextPageLink': (options) => {
            return (
                <button type="button" className={"btnGreen"} onClick={options.onClick} disabled={options.disabled}>
                    <span>Siguiente</span>
                    <Ripple />
                </button>
            )
        },
    };

    var arrayHeader = [
        { field: "field1" },
        { field: "field2" },
        { field: "field3" },
        { field: "field4" },
        { field: "field5" },
        { field: "field6" },
        { field: "field7" },
        { field: "field8" },
        { field: "field9" },
        { field: "field10" },
        { field: "field11" },
        { field: "field12" },
    ]
    var arrayItems = [
        {
            field1: "05 - Enero - 2020",
            field2: "",
            field3: "JORGE ANTONIO FUERTE DIAZ",
            field4: "MATRIZ CDE",
            field5: "ALBA GRICELDA CABAÑAS PALACIOS",
            field6: "5619751",
            field7: "Ventas",
            field8: "GPON",
            field9: "12456456",
            field10: "baja",
            field11: "Inactivo",
            field12: "",

        },
        {
            field1: "05 - Enero - 2020",
            field2: "",
            field3: "ARTURO ALEJANDRO LÓPEZ MARTÍNEZ",
            field4: "MATRIZ CDE",
            field5: "ALBA GRICELDA CABAÑAS PALACIOS",
            field6: "5619751",
            field7: "Ventas",
            field8: "GPON",
            field9: "12456456",
            field10: "alta",
            field11: "Activo",
            field12: "",
        },
        {
            field1: "05 - Enero - 2020",
            field2: "",
            field3: "ARTURO ALEJANDRO LÓPEZ MARTÍNEZ",
            field4: "MATRIZ CDE",
            field5: "ALBA GRICELDA CABAÑAS PALACIOS",
            field6: "5619751",
            field7: "Ventas",
            field8: "GPON",
            field9: "12456456",
            field10: "alta",
            field11: "Bloqueado",
            field12: "",
        },
        {
            field1: "05 - Enero - 2020",
            field2: "",
            field3: "ARTURO ALEJANDRO LÓPEZ MARTÍNEZ",
            field4: "MATRIZ CDE",
            field5: "ALBA GRICELDA CABAÑAS PALACIOS",
            field6: "5619751",
            field7: "Ventas",
            field8: "GPON",
            field9: "12456456",
            field10: "baja",
            field11: "Inactivo",
            field12: "",

        },
        {
            field1: "05 - Enero - 2020",
            field2: "",
            field3: "ARTURO ALEJANDRO LÓPEZ MARTÍNEZ",
            field4: "MATRIZ CDE",
            field5: "ALBA GRICELDA CABAÑAS PALACIOS",
            field6: "5619751",
            field7: "Ventas",
            field8: "GPON",
            field9: "12456456",
            field10: "alta",
            field11: "Activo",
            field12: "",
        },
        {
            field1: "05 - Enero - 2020",
            field2: "",
            field3: "ARTURO ALEJANDRO LÓPEZ MARTÍNEZ",
            field4: "MATRIZ CDE",
            field5: "ALBA GRICELDA CABAÑAS PALACIOS",
            field6: "5619751",
            field7: "Ventas",
            field8: "GPON",
            field9: "12456456",
            field10: "alta",
            field11: "Bloqueado",
            field12: "",
        },
        {
            field1: "05 - Enero - 2020",
            field2: "",
            field3: "ARTURO ALEJANDRO LÓPEZ MARTÍNEZ",
            field4: "MATRIZ CDE",
            field5: "ALBA GRICELDA CABAÑAS PALACIOS",
            field6: "5619751",
            field7: "Ventas",
            field8: "GPON",
            field9: "12456456",
            field10: "baja",
            field11: "Inactivo",
            field12: "",

        },
        {
            field1: "05 - Enero - 2020",
            field2: "",
            field3: "ARTURO ALEJANDRO LÓPEZ MARTÍNEZ",
            field4: "MATRIZ CDE",
            field5: "ALBA GRICELDA CABAÑAS PALACIOS",
            field6: "5619751",
            field7: "Ventas",
            field8: "GPON",
            field9: "12456456",
            field10: "alta",
            field11: "Activo",
            field12: "",
        },
        {
            field1: "05 - Enero - 2020",
            field2: "",
            field3: "ARTURO ALEJANDRO LÓPEZ MARTÍNEZ",
            field4: "MATRIZ CDE",
            field5: "ALBA GRICELDA CABAÑAS PALACIOS",
            field6: "5619751",
            field7: "Ventas",
            field8: "GPON",
            field9: "12456456",
            field10: "alta",
            field11: "Bloqueado",
            field12: "",
        },
        {
            field1: "05 - Enero - 2020",
            field2: "",
            field3: "ARTURO ALEJANDRO LÓPEZ MARTÍNEZ",
            field4: "MATRIZ CDE",
            field5: "ALBA GRICELDA CABAÑAS PALACIOS",
            field6: "5619751",
            field7: "Ventas",
            field8: "GPON",
            field9: "12456456",
            field10: "baja",
            field11: "Inactivo",
            field12: "",

        },
        {
            field1: "05 - Enero - 2020",
            field2: "",
            field3: "ARTURO ALEJANDRO LÓPEZ MARTÍNEZ",
            field4: "MATRIZ CDE",
            field5: "ALBA GRICELDA CABAÑAS PALACIOS",
            field6: "5619751",
            field7: "Ventas",
            field8: "GPON",
            field9: "12456456",
            field10: "alta",
            field11: "Activo",
            field12: "",
        },
        {
            field1: "05 - Enero - 2020",
            field2: "",
            field3: "ARTURO ALEJANDRO LÓPEZ MARTÍNEZ",
            field4: "MATRIZ CDE",
            field5: "ALBA GRICELDA CABAÑAS PALACIOS",
            field6: "5619751",
            field7: "Ventas",
            field8: "GPON",
            field9: "12456456",
            field10: "alta",
            field11: "Bloqueado",
            field12: "",
        },
    ]
    let headerGroup =
        <ColumnGroup>
            <Row>


                <Column header="Fecha de regístro" colSpan={1} className="dth-child border-radius-left header-table" />
                <Column header="Foto" colSpan={1} className="dth-child header-table" />
                <Column header="Nombre" colSpan={1} className="dth-child header-table" />
                <Column header="Sucursal" colSpan={1} className="dth-child header-table" />
                <Column header="Supervisor" colSpan={1} className="dth-child header-table" />
                <Column header="CI" colSpan={1} className="dth-child header-table" />
                <Column header="Perfil" colSpan={1} className="dth-child header-table" />
                <Column header="Canal comercial" colSpan={1} className="dth-child header-table" />
                <Column header="POS" colSpan={1} className="dth-child header-table" />
                <Column header="Tipo" colSpan={1} className="dth-child header-table" />
                <Column header="Estado" colSpan={1} className="dth-child header-table" />
                <Column header="Acciones" colSpan={1} className="dth-child border-radius-right header-table" />
            </Row>
        </ColumnGroup>

    return (
        <>
            <div className="cj-people-managment MetaNotAssigned">
                <div className="container-activations">
                    <HeadersSection
                        title={<div>Usuarios con contraseña vencida</div>}
                        exeptions={{}}
                    />
                    <div className="div-selects-activations">
                        <div className="div-container-stand">
                            <div className="search-container-cj">
                                <div className="db">
                                    <div className="search">
                                        <input
                                            type="search"
                                            value={searchName}
                                            onChange={(e) => setSearchName(e.target.value)}
                                            placeholder="Buscar por nombre"
                                            className="input-search"
                                        />
                                        <img src={searchIcon} className="input-icon"></img>
                                    </div>
                                </div>
                            </div>

                            <DataTable value={arrayItems} headerColumnGroup={headerGroup} responsiveLayout="scroll" globalFilter={searchName} className="table-activations" paginator rows={10} paginatorTemplate={template1} >

                                {/* <ColumnGroup> */}
                                {/* <Row> */}
                                {arrayHeader.map((item, key) => {
                                    switch (key) {
                                        case 1:
                                            return (
                                                <Column body={imgTemplate} colSpan={item.colspan ? item.colspan : 1} className="rowdata" key={key} />
                                            )
                                            break;
                                        case 9:
                                            return (

                                                <Column body={typeTemplate} colSpan={item.colspan ? item.colspan : 1} className="rowdata" key={key} />
                                            )
                                            break;
                                        case 10:
                                            return (

                                                <Column body={stateTemplate} colSpan={item.colspan ? item.colspan : 1} className="rowdata" key={key} />
                                            )
                                            break;
                                        case 11:
                                            return (

                                                <Column body={actionsTemplate} colSpan={item.colspan ? item.colspan : 1} className="rowdata" key={key} />
                                            )
                                            break;
                                        default:
                                            return (
                                                <Column field={item.field} colSpan={item.colspan ? item.colspan : 1} className="rowdata" key={key} />

                                            );
                                            break;
                                    }

                                })}

                            </DataTable>
                        

                        </div>
                    </div>
                </div>
            </div>
            <FooterSimple></FooterSimple>
            {modalPdfState&&(<PdfModal modalState={modalPdfState} state = {setModalPdfState} content={PdfModalTemplate()}/>)}
            {modalState&&(<GeneralModal modalState={modalState} state = {setModalState} content={ModalTemplate()}/>)}
        </>
    );
}
export default ExpiredPassword;