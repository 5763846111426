const tableInfo = {
  headerList: [
    {
      field: "cargo",
      header: "Cargo",
      order: false,
    },
    {
      field: "date",
      header: "Fecha de registro",
      order: false,
    },
    {
      field: "num",
      header: "Numero de Usuarios vinculados",
      order: false,
    },
    {
      field: "update",
      header: "Última actualización",
      order: false,
    },
    {
      field: "accion",
      header: "Acciones",
      order: false,
    },
  ],
  rows: [
    {
      cargo: "Ejecutivo",
      date: "21 - Abril - 2021",
      num: "34",
      update: "21 - Abril - 2021",
      accion: "Acciones",
    },
    {
      cargo: "Administrativo",
      date: "21 - Abril - 2021",
      num: "34",
      update: "21 - Abril - 2021",
      accion: "Acciones",
    },
    {
      cargo: "Administrativo",
      date: "21 - Abril - 2021",
      num: "34",
      update: "21 - Abril - 2021",
      accion: "Acciones",
    },
    {
      cargo: "Administrativo",
      date: "21 - Abril - 2021",
      num: "34",
      update: "21 - Abril - 2021",
      accion: "Acciones",
    },
    {
      cargo: "Cajero",
      date: "21 - Abril - 2021",
      num: "34",
      update: "21 - Abril - 2021",
      accion: "Acciones",
    },
    {
      cargo: "Administrativo",
      date: "21 - Abril - 2021",
      num: "34",
      update: "21 - Abril - 2021",
      accion: "Acciones",
    },
    {
      cargo: "Administrativo",
      date: "21 - Abril - 2021",
      num: "34",
      update: "21 - Abril - 2021",
      accion: "Acciones",
    },
    {
      cargo: "Administrativo",
      date: "21 - Abril - 2021",
      num: "34",
      update: "21 - Abril - 2021",
      accion: "Acciones",
    },
    {
      cargo: "Administrativo",
      date: "21 - Abril - 2021",
      num: "34",
      update: "21 - Abril - 2021",
      accion: "Acciones",
    },
    {
      cargo: "Administrativo",
      date: "21 - Abril - 2021",
      num: "34",
      update: "21 - Abril - 2021",
      accion: "Acciones",
    },
  ],
};

export const infoParameters = {
  tableInfo,
};
