import React, { useEffect, useState } from 'react'

import bell    from './icons/bell.png'
import profile from './icons/profile.png'
import logo1    from '../../../img/Main/brand.png'
import logo2 from '../../../img/sideBar/Image.png'

import './SidebarNew.scss'
import menuList from './listMenu'



import { NavLink } from 'react-router-dom'

const SidebarNew = () => {

  const [ showMenu, setShowMenu ] = useState([])
  const [ showSubMenu, setShowSubMenu ] = useState(false)

  useEffect(() => {
    const menuLocal =localStorage.getItem('menu')
    const index = localStorage.getItem('index')
    const i = localStorage.getItem('i')
    menuLocal 
      ? setShowMenu([JSON.parse(menuLocal)])
      : setShowMenu([menuList[0]])
    // index && showSubmenu(index, i)
  }, [])
  
  const handleHoverEnter = (e) => {
    let iconImg = document.getElementById('bar-white-'+e.target.id).classList.add('line-white')
  }  
  const handleHoverLeave = (e) => {
    let iconImg = document.getElementById('bar-white-'+e.target.id).classList.remove('line-white')
  }

  const handleClick = (e,item) => {
    setShowMenu([item])
    localStorage.setItem('menu', JSON.stringify(item))
    let iconImg = document.getElementById('bar-white-'+e.target.id).classList.add('line-white')
  }


  const showSubmenu = ( index, i) => {
    
    let submenuDiv = document.getElementById('submenu-'+index+i)?.classList
    if(submenuDiv.contains('show-submenu')){
      submenuDiv.remove('show-submenu')
      }else{
        submenuDiv.add('show-submenu')
      }
      // localStorage.setItem('index', index)
      // localStorage.setItem('i', i);
    }

  return (
    <div className='Sidebar__container'>
        <div className='Sidebar__redbar'>
        {
          menuList.map( (item, index) => 
            (<div 
                key={index} 
                onClick={(e)=> handleClick(e,item)}
                className='bar-item'>
              <div id={'bar-white-'+index} className=''></div>
                  <img 
                    id={index}
                    onMouseEnter={handleHoverEnter}
                    onMouseLeave={handleHoverLeave}
                    className='imglogo'
                    onClick={(e)=> handleClick(e,item)}
                    onFocus={()=>""}
                    
                    src={ item.icon } 
                    />
                </div>)
            )
          }

          <div className='bar-items-end'>
            <div className='bar-item'>
                <div id='bar-white-122' className=''></div>
                <img 
                    className='imglogo' 
                    id='122'
                    onMouseEnter={handleHoverEnter}
                    onMouseLeave={handleHoverLeave}
                    src={bell} />
            </div>

            <div className='bar-item'>
                <div id='bar-white-123' className=''></div>
                <img 
                    className='imglogo' 
                    id='123'
                    onMouseEnter={handleHoverEnter}
                    onMouseLeave={handleHoverLeave}
                    src={profile} />
            </div>
          </div>

        </div>

        <div className='menu-body'>
          <div className='group-logo'>
              <img className='img1' src = {logo1} />            
              <img className='img2' src = {logo2} />                
          </div>

        {
          showMenu.map( ({title,menu }, index) => 
            (
              <div key={index}>
                <h2 className='menu-body-title'>{title}</h2>
                {

                  menu.map( ({ title, link, submenu }, i) => 
                      (<div key={i}>
                        {
                          (submenu && submenu.length > 0)
                            ?(
                              <>
                                <span onClick = {() => showSubmenu(i,index)} className='menu-item'>{title}</span>
                                <div className='submenu-group ' id={`submenu-${i}${index}`}>
                                  {
                                    submenu.map( (sub, index) => (
                                      <NavLink   key={index} className= {`submenu-items `}  to= {sub.link}> {sub.title} </NavLink>

                                    ))
                                  }
                                </div>
                              </>
                              )

                            :(<NavLink className = 'menu-item' to={link}> { title } </NavLink>)
                        }
                      </div>)
                  )
                }

              </div>
            )
            )
          }
          
        </div>

    </div>
  )
}

export default SidebarNew