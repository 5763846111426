import React from "react";
import './Main.scss';

//assets
import logo from '../../img/Main/logo.png';
import brand from '../../img/Main/brand.png';
const Main =()=>
{
    return(
    <>
    <div className="position-main">
        <div className="main-email">
            <div className="content">
                <div className="header">
                        <img src={logo}  className="logo"></img>
                        <img src={brand} className="brand"></img>
                </div>
                <hr></hr>
                <div className="body">
                    <div>
                        <div className="title">Hola, Carlos Mauricio</div>
                        <div className="text">Podras reestablecer tu contraseña ingresando al siguiente link:</div>
                        <div className="url">https://Gesticlaro.com/8c3c6af661be593186768abace3489665972dbf2#<br/>/pages/138835157/simulate/sitemap</div>
                        <div className="text">Atentamente: </div>
                        <div className="text">GESTIClaro</div>
                    </div>
                </div>
                
            </div>
            <div className="footer">
                <div className="footer-content"></div>
            </div>
        </div>
        </div>
    </>
    );
}
export default Main;