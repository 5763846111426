import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import editIcon from "../../../../../../images/tableIcons/editIcon.png";
import deleteicon from "../../../../../../images/tableIcons/tashIcon.png";
import { Modal } from "antd";
import { classNames } from 'primereact/utils'
import { Ripple } from 'primereact/ripple';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

const TableParameters = ({
  searchEnter,
  value,
  setDataParameters,
  column,
  setColumn,
  showModalDelete,
  setShowModalDelete,
}) => {
  const defaultPaginatorTemplate = {
    layout: 'PrevPageLink PageLinks NextPageLink',
    'PrevPageLink': (options) => {
        return (
            <button type="button" className='paginator-before greenBtn ' onClick={options.onClick} disabled={options.disabled}>
                Anterior
                <Ripple />
            </button>
        )
    },
    'NextPageLink': (options) => {
        return (
            <button type="button" className='paginator-after greenBtn' onClick={options.onClick} disabled={options.disabled}>
                Siguiente
                <Ripple />
            </button>
        )
    },
    'PageLinks': (options) => {
        if ((options.view.startPage === options.page && options.view.startPage !== 0) || (options.view.endPage === options.page && options.page + 1 !== options.totalPages)) {
            const className = classNames(options.className, { 'p-disabled': true });

            return <span className={className} style={{ userSelect: 'none' }}>...</span>;
        }

        return (
            <button type="button" className={options.className} onClick={options.onClick}>
                {options.page + 1}
                <Ripple />
            </button>
        )
    },
};
const [data, setData] = useState([]);
const deleteElements = (e) => {
  const resThen = (res) => {
    Swal.fire({
      icon: "success",
      title: "Tipo de activo eliminado",
      text: "Se eliminó la información de forma correcta",
      showConfirmButton: false,
      timer: 2000,
    });

    var newData = value;
    newData.forEach((element, index) => {
      if (element.id == e.id) {
        newData.splice(index, 1);
      }
    });

    setData([...newData]);
  };
  MySwal.fire({
    title: `¿Está seguro que desea eliminar este cargo?`,
    showDenyButton: true,
    icon: 'warning',
    showCancelButton: false,
    denyButtonText: `Cancelar`,
    confirmButtonText: "Eliminar",
  }).then((result) => {
    if (result.isConfirmed) {
      Swal.fire({
        title:'Se eliminó este cargo',
        icon: 'error',
        confirmButtonText: "Aceptar",
      }
      )
    }
  });
};
  const accionTemplate = (e) => {
    return (
      <div className="centeredActions centerActions">
        <div className="sectionActions">
          <Link to="/editar-parameters" className="btnedit-table">
            <img src={editIcon} />
          </Link>
          <div className="btnedit-table">
            <img src={deleteicon} onClick={() => deleteElements(e)} />
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <DataTable
        value={value}
        rows={10}
        paginator
        paginatorTemplate={defaultPaginatorTemplate}
        className="parameters-table"
        globalFilter={searchEnter}
      >
        {column.map((e) => {
          return (
            <Column
              field={e.field}
              header={e.header}
              body={e.field === "accion" ? accionTemplate : null}
            />
          );
        })}
      </DataTable>
      <Modal
        visible={showModalDelete}
        onOk={() => setShowModalDelete(false)}
        onCancel={() => setShowModalDelete(false)}
        centered={true}
        footer={[
          <button
            className="btnModalCancel"
            onClick={() => setShowModalDelete(false)}
          >
            Cancelar
          </button>,
          <button  className="btnModalDo">
            Eliminar
          </button>,
        ]}
      >
        <div className="contentModal">
          {/* <img src={warning}/> */}

          <p className="titleModal">Se eliminará este Parámetro</p>
        </div>
      </Modal>
    </>
  );
};
export default TableParameters;
