import { id } from "date-fns/locale";
import { before } from "lodash";
import React, { useEffect } from "react";
import { useState } from "react";
import $ from "jquery";

const InputFile = ({ id, id2, ajustes }) => {
  const [imgLogo, setImgLogo] = useState(false);
  const [fileSafe, setFileSafe] = useState(false);

  function changeFileLogo() {
    var fileInput = document.getElementById(id);

    var filePath = fileInput.value;
    if (filePath === "") {
      setImgLogo(false);
      return false;
    }
    if (fileInput.files && fileInput.files[0]) {
      var reader = new FileReader();
      reader.onload = function (e) {
        setImgLogo(fileInput.files[0].name);
      };
      reader.readAsDataURL(fileInput.files[0]);
    }
    console.log("reader", reader)
    const index = document.getElementById(id).value.split(`\\`).length - 1;
    setFileSafe(document.getElementById(id).value);

    if (fileSafe !== "") {
      const inputFile = document.getElementById(id2);
      inputFile.innerHTML = filePath.split(`\\`)[index];
      inputFile.style.width = ajustes ? "200px":"250px";
      inputFile.style.height = "30px";
      inputFile.style.textAlign = "center";
      inputFile.style.fontWeight = "700";
      inputFile.style.color = "#ff2631";
    }
  }
  const resetearCambios = () => {
    setFileSafe(false);
    setImgLogo(false);
    document.getElementById(id2).textContent = "Selecciona archivo";
  };
  return (
    <div className="container-file">
      <div className="upload-data">
        <div
          onClick={() => $("#" + id).trigger("click")}
          className="input-file"
          style={ajustes && {width:"200px"}}
          id={id2}
        >
          <input
            type="file"
            id={id}
            className="input-file"
            name="imageLogo"
            accept="image/*"
            placeholder="test"
            onChange={(e) => {
              changeFileLogo();
            }}
          />
          <div style={{ color: "#ff2631" }}>
            {!imgLogo ? "Selecciona archivo" : fileSafe}
          </div>
        </div>
        {
          <div className="file-name">
            {!imgLogo ? (
              <div>
                <div className="file-top" style={{ fontSize: "12px"}}>
                  Ningún archivo seleccionado
                </div>
              </div>
            ) : (
              <div className="file-top-submit">
                <input type="submit" value="Ver archivo" />
                <input
                  id="eliminar"
                  type="submit"
                  value="eliminar"
                  onClick={resetearCambios}
                />
              </div>
            )}
          </div>
        }
      </div>
    </div>
  );
};

export default InputFile;
