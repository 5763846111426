import React, { useState } from 'react'

import './ModalDelete.scss'
import alert from'../../../../img/icons/alert.png'


const ModalDelete = ({setOpenModalDelete}) => {


    const [selectedCity1, setSelectedCity1] = useState(null);

    const onCityChange = (e) => {
        setSelectedCity1(e.value);
    }

    const cities = [
        { name: 'Falta Firma', code: 'FF' },
        { name: 'Frima no coincide', code: 'NC' },
        { name: 'Tachaduras', code: 'TA' },
    ];

  return (
    <>
    <div className='Modal_container'>   
    <img className='icon-alert' src={alert}/>
        <h3 className='mt-4'>Se eliminará este legajo</h3>
        <p>"PREPAGO 98F0934854830"</p>
        <div className='mt-5'>
            <button className='btn mr-5 pl-5 pr-5' onClick={()=>setOpenModalDelete(false)}>Cancelar</button>
            <button className='btn btn-primary pl-5 pr-5' onClick={()=>setOpenModalDelete(false)}>Eliminar</button>
        </div>
    </div>
    <div className='Modal__background'></div>
    </>
)}

export default ModalDelete