import React from "react"
import './pieGraph.scss'
import ReactEChartsCore from "echarts-for-react/lib/core";
import * as echarts from "echarts/core";
import {LineChart, BarChart, PieChart} from "echarts/charts";
import {GridComponent, TooltipComponent, TitleComponent, DatasetComponent,  LegendComponent,} from 'echarts/components';
import {
    CanvasRenderer,
    // SVGRenderer,
} from 'echarts/renderers';

echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    LineChart,
    BarChart,
    PieChart,
    CanvasRenderer,
    LegendComponent,
]);

const PieGraph =()=>{

    const onChartReadyCallback = () => {
        // alert("OKOK")
    };

    const LegendCard=(params)=>{
        return(
            <div className='legendCard'>
                <div className="contentLegend">
                    <div className="iconCircle" style={{backgroundColor:params.color}}/>
                    <p>{params.number}</p>
                </div>
                <p className="nameLegend">{params.name}</p>
            </div>
        )
    }

    const options = {
        tooltip: {
            trigger: 'item'
        },
        // legend: {
        //     //top: '5%',
        //     //left: 'center'
        //     icon:'circle',
        //     orient:'horizontal',
        // },
        series: [
            {
                name: 'Title',
                type: 'pie',
                radius: ['50%', '70%'],
                avoidLabelOverlap: false,
                label: {
                    show: true,
                    position: 'center'
                },
                emphasis: {
                    label: {
                        show: false,
                        fontSize: '15',
                        fontWeight: 'bold'
                    }
                },
                labelLine: {
                    show: false
                },
                data: [
                    {value:157, name: '257' ,itemStyle:{ color: '#308ac9'}},
                    { value: 360, name: '256' ,itemStyle:{ color: '#036402'}},
                ]
            }
        ]
    };

    return(
        <div className='graphPieContainer'>
            <p>GESTIÓN DE PROVISIÓN</p>


                <div className="flexLegend">
                    <LegendCard number={257} name={'A confirmar PROVISIÓN'} color={'#308ac9'}/>
                    <LegendCard number={257} name={'NIM generado pendiente PROVISIÓN'} color={'#036402'}/>
                </div>





                <ReactEChartsCore
                    echarts={echarts}
                    option={options}
                    notMerge={true}
                    lazyUpdate={true}
                    theme={"theme_name"}
                    onChartReady={onChartReadyCallback}
                    //onEvents={EventsDict}
                    //opts={}
                />


        </div>
    )
}
export default PieGraph
