import React, { useState, useEffect } from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import data from '../../../../mockups/pendingBoxs.json'
import './Tables.scss';
const Tables = () => {
    const [results, setResults] = useState(data)
    return (
        <div className="shadowbox">
            <div className="primeReactNav mb-0">
                <div>
                    <h6 className="subtitleTable"></h6>
                </div>
                <div>

                </div>
            </div>

            <DataTable
                value={results}
                className="table-dashboard gpon"
            >
                <Column field="Nro" header="Nro" sortable />
                <Column field="fechaSolicitud" header="Fecha de solicitud" sortable />
                <Column field="caja" header="Caja" sortable />
                <Column field="ultima" header="Utima actualizacion" sortable />
                <Column field="antiquity" header="Antigüedad" sortable />
                <Column field="puertosTotales" header="Puertos totales" sortable />
                <Column field="puertosLibres" header="Puertos libres" sortable />
                <Column field="puertosOcupados" header="Puertos ocupados" sortable />
                <Column field="cliente" header="Solicitado por" sortable />
                <Column field="supervisor" header="Supervisor" sortable />
                <Column field="subCanal" header="Sub canal" sortable />
                <Column field="verificado" header="Verificado por" sortable />
            </DataTable>

        </div>
    );
};
export default Tables
