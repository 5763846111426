import { positions } from "@mui/system";
import React from "react";
import { useState, useEffect } from "react";
import { Button } from "react-bootstrap";

const Contactos = ({ setRegistro, registro, setAdd, setValide }) => {
 
  const [numero, setNumero] = useState("");
  const [tipo, setTipo] = useState("");

  const generarId = () => {
    const random = Math.random().toString(36).substr(2);
    const fecha = Date.now().toString(36);
    return fecha + random;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(tipo)
    if ((tipo=="Selecciona tipo") || (numero=="") || (tipo=="")){
      setAdd(true)

      setTimeout(()=>{
        setAdd(false)
      }, 2000)
      return
    }
    if (numero.length < 10){
      setValide(true)

      setTimeout(()=>{
        setValide(false)
      }, 2500)
      return
    }


    
    const objetoData = {
      tipo,
      numero,
      id: generarId(),
    };
    setRegistro([...registro, objetoData]);
    setNumero("");
    setTipo("");
    setAdd(false)
  };

  return (
    <div>
      <form onSubmit={handleSubmit} className="contact-emergency color-blue">
        <select
          className="contact-emergency-select"
          value={tipo}
          onChange={(e) => {
            setTipo(e.target.value);
          }}
        >
          <option>Selecciona tipo</option>
          <option>Fijo</option>
          <option>Celular</option>
        </select>
        <input
          className="contact-emergency-input"
          value={numero}
          onChange={(e) => {
            setNumero(e.target.value);
          }}
          placeholder="Ingresa numero"
          type="number"
        />
        <button>
          <p className="button-add-cont">+</p>
        </button>
      </form>
    </div>
  );
};

export default Contactos;
