
import React , {useState} from "react"
import { Modal } from "antd";
import "antd/es/modal/style/css.js";
import './pos.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import HeadersSection from "../../../components/book/headersSection/HeadersSection";
import {info} from "../../../../mockups/dashboard";
import {infoPos} from "../../../../mockups/pos";
import FooterSimple from "../../../components/book/footerSimple/FooterSimple";
import { Link } from "react-router-dom"
import editIcon from "../../../../images/tableIcons/blueEditIcon.svg";
import statusinactiveicon from '../../../../images/tableIcons/statusinactive.png';
import statusactiveicon from '../../../../images/tableIcons/statusactive.png';
import deleteicon from '../../../../images/tableIcons/delete.png';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Ripple } from 'primereact/ripple';
// import trash from "../../../../images/tableIcons/trash.svg"
// import status from "../../../../images/tableIcons/closeRed.svg"
import {infoPlans} from "../../../../mockups/plans";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
const MySwal = withReactContent(Swal)
// import warning from "../../../../images/cardIcons/warning.svg"

const Pos =()=>{

    // const clickEdit = (values) => {
    //     //setShowCreatePlan(true);
    //     setItems(values)
    // };

    // const asingTemplate = (rowData) => {
    //     return (
    //         <Link to="plan/edit" className="btnedit-table" onClick={()=>clickEdit(rowData)}>
    //             <img src={editIcon} />
    //         </Link>
    //
    //     );
    // };




    const asingTemplate = (rowData) => {
        return (
            <div className="centeredActions">
                <div className="sectionActions">
                    <Link to="/pos-edit=1" className="btnedit-table" >
                         <img src={editIcon} />
                    </Link>
                    <div  className="btnedit-table" onClick={()=>setShowModalEdit(true)}>
                        <img src={statusactiveicon} />
                    </div>
                    <div  className="btnedit-table" onClick={()=>setShowModalEdit(true)}>
                        <img src={statusinactiveicon} />
                    </div>
                    <div  className="btnedit-table" onClick={()=>setShowModalDelete(true)}>
                        <img src={deleteicon} />
                    </div>
                </div>
            </div>
        );
    };

  
    const defaultPaginatorTemplate = {
        layout: 'PrevPageLink PageLinks NextPageLink',
        'PrevPageLink': (options) => {
            return (
                <button type="button" className='paginator-before greenBtn ' onClick={options.onClick} disabled={options.disabled}>
                    Anterior
                    <Ripple />
                </button>
            )
        },
        'NextPageLink': (options) => {
            return (
                <button type="button" className='paginator-after greenBtn' onClick={options.onClick} disabled={options.disabled}>
                    Siguiente
                    <Ripple />
                </button>
            )
        },
        'PageLinks': (options) => {
            if ((options.view.startPage === options.page && options.view.startPage !== 0) || (options.view.endPage === options.page && options.page + 1 !== options.totalPages)) {
                const className = classNames(options.className, { 'p-disabled': true });
    
                return <span className={className} style={{ userSelect: 'none' }}>...</span>;
            }
    
            return (
                <button type="button" className={options.className} onClick={options.onClick}>
                    {options.page + 1}
                    <Ripple />
                </button>
            )
        },
    };

    const [showModalEdit,setShowModalEdit] = useState(false)
    const [showModalDelete,setShowModalDelete] = useState(false)


    return(
        <div>

            <HeadersSection
                title={'Pos no registrados'}
                exeptions={""}
                date={info.headerInfo.date}
            />


            <div className="form-createplan">
                <div className="reac" style={{ padding: 32 }}>
                    {/* <div className="div-btns-plans">
                                <button onClick={() => setShowCreatePlan(!showCreatePlan)} className="buttons btn-big btn-green"><FontAwesomeIcon icon={faPlus} /> CREAR PLAN</button>
                            </div> */}
                    <div className="div-btns-plans">
                        <Link to="/pos-create">
                            <button  className="buttons btn-big btn-green"><FontAwesomeIcon icon={faPlus} /> CREAR POS</button>
                        </Link>

                    </div>


                    <DataTable
                        value={infoPos.tableInfo.rows}
                        rows={10}
                        paginator
                        paginatorTemplate={defaultPaginatorTemplate}
                        
                        stripedRows
                        className="table-pos"
                    
                        responsiveLayout="scroll"
                        
                        >
                        <Column field="num" header='#'></Column>
                        <Column field="number" header='Nro. POS'></Column>
                        <Column field="name" header='Nombre POS'></Column>
                        <Column field="city" header='Ciudad POS'></Column>
                        <Column field="area" header='Dpto. POS'></Column>
                        <Column field="action" header='ACCIONES' body={asingTemplate}></Column>
                    </DataTable>


                        <Modal
                            visible={showModalEdit}
                            centered={true}
                            onOk={() => setShowModalEdit(false)}
                            onCancel={() => setShowModalEdit(false)}
                            footer={[
                               <button className='btnModalCancel' onClick={()=>setShowModalEdit(false)}>Cancelar</button>,
                               <button className='btnModalDo'>Desactivar</button>
                            ]}
                        >
                            <div className='contentModal'>
                                {/* <img src={warning}/> */}

                                <p className='titleModal'>¿Quieres desactivar este POS?</p>
                                <p>"MPP22"</p>
                            </div>
                        </Modal>

                    <Modal
                        visible={showModalDelete}
                        onOk={() => setShowModalDelete(false)}
                        onCancel={() => setShowModalDelete(false)}
                        centered={true}
                        footer={[
                            <button className='btnModalCancel' onClick={()=>setShowModalDelete(false)}>Cancelar</button>,
                            <button className='btnModalDo'>Eliminar</button>
                        ]}
                    >
                        <div className='contentModal'>
                            {/* <img src={warning}/> */}

                            <p className='titleModal'>Se eliminará este POS</p>
                            <p>"MPP22"</p>
                        </div>
                    </Modal>


                </div>
                <div style={{marginTop:'50px'}}>
                    <FooterSimple />
                </div>

            </div>

        </div>
    )

}
export default Pos

