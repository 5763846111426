import React,{useState} from "react";
import './CardDowload.scss'
import Modal from "../../../pages/auth/FileManagement/HomeFiles/UploadUpdates/Modal/Modal";
//assets
import txt from '../../../img/jpg/txt.jpg'
import exel from '../../../img/jpg/exel.png'
const CardDowload = ({color,info,title})=>
{
    const [open, setOpen] = useState();
    return(<>
        <div className="CardDowload">
            <div className="title-container">
                <div className="title" style={{color:color}}>{title}</div>
            </div>
            
            <div className="body">
            <div className="header" style={{background:color}}>
                <div className="column1">TIPO DE ARCHIVO</div>
                <div className="column2">DESCRIPCIÓN</div>
                <div className="column3">ACCIÓN</div>
            </div>
                {info.map((item,key)=>{
                    
                        if(item.document === "exel")
                        {
                            return(<>
                                <div className={key %2 === 0 ?"content bg-gray":"content"}>
                                    <div className="document">
                                        <div className="btn-document">
                                            <div className="img-container">
                                                <img src={exel} className="img" ></img>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="description">
                                        {item.description}
                                    </div>
                                    <div className="action">
                                        <button className="btn" style={{background:"#036402"}} onClick={()=><Modal setOpen={setOpen(true)}></Modal>}>Subir</button>
                                    </div>
                                </div>
                            </>)
                        }
                        else
                        {
                            return(<>
                                <div className={key %2 === 0 ?"content bg-gray":"content"}>
                                    <div className="document">
                                        <div className="btn-document">
                                            <div className="img-container">
                                                <img src={txt} className="img" ></img>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="description">
                                    {item.description}
                                    </div>
                                    <div className="action">
                                        <button className="btn" style={{background:"red"}} onClick={()=><Modal setOpen={setOpen(true)}></Modal>}>Subir</button>
                                    </div>
                                </div>
                            </>)
                        }
                })}
            </div>
                    

                    
                
            
        </div>
        {open && (<Modal setOpen={setOpen} />)}
    </>);
}
export default CardDowload;