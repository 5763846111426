const tableInfo = {
  headerList: [
    {
      field: "id",
      header: "ID",
      order: false,
    },
    {
      field: "tipo",
      header: "Tipo de rubro",
      order: false,
    },
    {
      field: "comision",
      header: "Comisiones de asignación",
      order: false,
    },
    {
      field: "descripcion",
      header: "Descripción",
      order: false,
    },
    {
      field: "asignacion",
      header: "Tipo de asignación",
      order: false,
    },
    {
      field: "estado",
      header: "Estado",
      order: false,
    },
    {
      field: "accion",
      header: "Acciones",
      order: false,
    },
  ],
  // rows: [
  //     {
  //         id: '1',
  //         tipo: 'Fijo',
  //         comision: 'Comisión',
  //         descripcion: '$ 12,000.00',
  //         asignacion: 'Bono',
  //         estado: 'Activo',
  //         accion: 'Acciones',
  //     }

  // ]
};

export const infoParameters = {
  tableInfo,
};
