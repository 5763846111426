import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import editIcon from "../../../../../../images/tableIcons/blueEditIcon.svg";
import deleteicon from "../../../../../../images/tableIcons/delete.png";

import { Modal } from "antd";

const TablePaymentMethods = ({
  searchEnter,
  value,
  column,
  showModalDelete,
  setShowModalDelete,
}) => {
  const accionTemplate = () => {
    return (
      <div className="centeredActions">
        <div className="sectionActions">
          <Link to="/editar-parameters" className="btnedit-table">
            <img src={editIcon} />
          </Link>
          <div className="btnedit-table">
            <img src={deleteicon} onClick={() => setShowModalDelete(true)} />
          </div>
        </div>
      </div>
    );
  };

  const eliminarParameters = () => {
    //   setShowModalDelete(false)
    //   const body = document.querySelector('.parameters')
    //   const eliminar = document.createElement('div')
    //   eliminar.classList.add('eliminarParameters')
    //   eliminar.textContent = 'El parámetro ha sido eliminado'
    //   body.appendChild(eliminar)
    //  setTimeout(()=>{
    //   eliminar.remove()
    //  }, 3000)
  };

  return (
    <>
      <DataTable
        value={value}
        rows={10}
        className="parameters-table"
        globalFilter={searchEnter}
      >
        {column.map((e) => {
          return (
            <Column
              field={e.field}
              header={e.header}
              body={e.field === "accion" ? accionTemplate : null}
            />
          );
        })}
      </DataTable>
      <Modal
        visible={showModalDelete}
        onOk={() => setShowModalDelete(false)}
        onCancel={() => setShowModalDelete(false)}
        centered={true}
        footer={[
          <button
            className="btnModalCancel"
            onClick={() => setShowModalDelete(false)}
          >
            Cancelar
          </button>,
          <button className="btnModalDo">Eliminar</button>,
        ]}
      >
        <div className="contentModal">
          {/* <img src={warning}/> */}

          <p className="titleModal">Se eliminará este Parámetro</p>
        </div>
      </Modal>
    </>
  );
};
export default TablePaymentMethods;
