import React, { useState } from "react";
import './ResourcesGestion.scss'

//componts
import HeadersSection from "../../../../components/book/headersSection/HeadersSection";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import { Ripple } from 'primereact/ripple';
import FooterSimple from "../../../../components/book/footerSimple/FooterSimple";
import GeneralModal from "../../../../components/book/GeneralModal/GeneralModal";
import FooterTableW from "../../../../components/book/FooterTable/FooterTable";
import PdfModal from "../../../../components/book/PdfModal/PdfModal";
//assets

import eyeIcon from '../../../../../images/blueIcon.svg'
import profileImg from '../../../../img/jpg/profile.jpg'
import pdfIcon from '../../../../img/icons/pdf.svg'
import PdfImg from '../../../../img/jpg/pdf.png'
import Xicon from '../../../../img/icons/xicon.svg'
import plusIcon from '../../../../img/icons/plusIcon.svg'
const ResourcesGestion = () => {
    const [searchName, setSearchName] = useState();
    const [showData,setShowData] = useState(false)
    const [modalPdfState, setModalPdfState] = useState(false);
    const [openModalAsign,setOpenModalAsign]= useState(false)
    const [openEntrar,setOpenEntrar]= useState(false)
    const imgTemplate = () => {
        return (
            <div className="profile-image">
                <img src={profileImg} ></img>
            </div>
        );
    }
    const imgTemplateX = () => {
        return (
            <div >
                <img src={Xicon} width="20" style={{cursor:"pointer"}} onClick={()=>{setOpenEntrar(true)}}></img>
            </div>
        );
    }
    const ActionsTemplate = (statuss) => {
        console.log("statusxxx",statuss)
        if(statuss === 0)
        {
            return (
                <div style={{color:"#ff2631"}}>
                    Entregado
                </div>
            );
        }
        else
        {
            return (
                <div style={{color:"#036402"}}>
                    Asignado
                </div>
            );
        }
        
    }
    const stateTemplate = (RowData) => {
        console.log("RowData", RowData.field11)
        if (RowData.field11 === "Activo") {
            return (
                <div className="btn" style={{ background: "#036402" }}><span>Activo</span></div>
            );
        }
        else if (RowData.field11 === "Inactivo") {
            return (
                <div className="btn" style={{ background: "#000000" }}>Inactivo</div>
            );
        }
        else {
            return (
                <div className="btn" style={{ background: "#ff2631" }}>Bloqueado</div>
            );
        }

    }
    const actionsTemplate = () => {
        return (
            <>
                <img src={eyeIcon} width="40" style={{ paddingRight: "20px", cursor: "pointer" }} onClick={()=>{setModalPdfState(true)}} ></img>
                
            </>
        );
    }
    const typeTemplate = (RowData) => {
        switch (RowData.field10) {
            case "alta":
                return (
                    <div style={{ color: "#0ec927" }}>ALTA</div>
                )
                break;
            case "baja":
                return (
                    <div style={{ color: "#ff2631" }}>BAJA</div>
                )
                break;
        }
        return (<>

        </>);
    }
    const showInfo=()=>
    {
        setShowData(true)
    }
    const PdfModalTemplate = () =>
{
    return(<>
        <div className="img-pdf-modal">
            <img  src={PdfImg}></img>
        </div>
    </>)
   
}
    const ModalTemplateAsign = () =>
    {
        return(<>
            <div className="modal-asign-container">
                <div className="title">Asignar recurso</div>
                <div className="input-container">
                    <div className="sub-title">Recurso a asignar</div>
                    <input type={"text"} className="input"></input>
                </div>
                <div className="input-container">
                    <div className="sub-title">Fecha de entrega</div>
                    <input type={"date"} className="input"disabled></input>
                </div>
                <div className="input-container">
                    <div className="sub-title">Fecha prevista de devolución</div>
                    <input type={"date"} className="input"></input>
                </div>
                <div className="input-container">
                    <div className="sub-title">Observaciones</div>
                    <textarea type={"text"}></textarea>
                </div>
                <div className="btns-container-resources-gestion">
                    <button className="btn-s" style={{color:"black",background:"#e3e3e3"}} onClick={()=>{setOpenModalAsign(false)}}>Cancelar</button>
                    <button className="btn-s" style={{background:"#16de9a"}}onClick={()=>{setOpenModalAsign(false)}} >Asignar</button>
                </div>
            </div>
        </>)
        
        
    }
    const ModalTemplateEntregar = () =>
    {
        return(<>
            <div className="modal-asign-container">
                <div className="title">Entregar recurso</div>
                <div className="input-container">
                    <div className="sub-title">Recurso a entregar</div>
                    <input type={"text"} className="input"></input>
                </div>
                <div className="input-container">
                    <div className="sub-title">Fecha de entrega</div>
                    <input type={"date"} className="input"disabled></input>
                </div>
                <div className="input-container">
                    <div className="sub-title">Fecha prevista de devolución</div>
                    <input type={"date"} className="input"></input>
                </div>
                <div className="input-container">
                    <div className="sub-title">Observaciones</div>
                    <textarea type={"text"}></textarea>
                </div>
                <div className="btns-container-resources-gestion">
                    <button className="btn-s" style={{color:"black",background:"#e3e3e3"}} onClick={()=>{setOpenEntrar(false)}}>Cancelar</button>
                    <button className="btn-s" style={{background:"#308ac9"}}onClick={()=>{setOpenEntrar(false)}} >Entregar</button>
                </div>
            </div>
        </>)
        
        
    }

    var arrayHeader = [
        { field: "field1" },
        { field: "field2" },
        { field: "field3" },
        { field: "field4" },
        { field: "field5" },
        { field: "field6" },
        { field: "field7" },
        { field: "field8" },
        { field: "field9" },
        { field: "field10" },
        { field: "field11" },
        { field: "field12" },
    ]
    var arrayItems = [
        {
            field1: "05 - Enero - 2020",
            field2: "",
            field3: "JORGE ANTONIO FUERTE DIAZ",
            field4: "MATRIZ CDE",
            field5: "ALBA GRICELDA CABAÑAS PALACIOS",
            field6: "5619751",
            field7: "Ventas",
            field8: "GPON",
            field9: "12456456",
            field10: "baja",
            field11: "Inactivo",
            field12: "",

        },
        {
            field1: "05 - Enero - 2020",
            field2: "",
            field3: "ARTURO ALEJANDRO LÓPEZ MARTÍNEZ",
            field4: "MATRIZ CDE",
            field5: "ALBA GRICELDA CABAÑAS PALACIOS",
            field6: "5619751",
            field7: "Ventas",
            field8: "GPON",
            field9: "12456456",
            field10: "alta",
            field11: "Activo",
            field12: "",
        },
        {
            field1: "05 - Enero - 2020",
            field2: "",
            field3: "ARTURO ALEJANDRO LÓPEZ MARTÍNEZ",
            field4: "MATRIZ CDE",
            field5: "ALBA GRICELDA CABAÑAS PALACIOS",
            field6: "5619751",
            field7: "Ventas",
            field8: "GPON",
            field9: "12456456",
            field10: "alta",
            field11: "Bloqueado",
            field12: "",
        },
    ]
    let headerGroup =
        <ColumnGroup>
            <Row>


                <Column header="Fecha de regístro" colSpan={1} className="dth-child border-radius-left header-table" />
                <Column header="Foto" colSpan={1} className="dth-child header-table" />
                <Column header="Nombre" colSpan={1} className="dth-child header-table" />
                <Column header="Sucursal" colSpan={1} className="dth-child header-table" />
                <Column header="Supervisor" colSpan={1} className="dth-child header-table" />
                <Column header="CI" colSpan={1} className="dth-child header-table" />
                <Column header="Perfil" colSpan={1} className="dth-child header-table" />
                <Column header="Canal comercial" colSpan={1} className="dth-child header-table" />
                <Column header="POS" colSpan={1} className="dth-child header-table" />
                <Column header="Tipo" colSpan={1} className="dth-child header-table" />
                <Column header="Estado" colSpan={1} className="dth-child header-table" />
                <Column header="Acciones" colSpan={1} className="dth-child border-radius-right header-table" />
            </Row>
        </ColumnGroup>
        let headerGroupBlue =
        <ColumnGroup style={{width:"100%"}}>
            <Row >
                <Column header="Familia" colSpan={1} className="header-color border-radius-left header-table  dth-child"  />
                <Column header="Sección" colSpan={1} className="header-color header-table dth-child"  />
                <Column header="Producto" colSpan={1} className="header-color header-table dth-child"  />
                <Column header="Nro de serie." colSpan={1} className="header-color header-table dth-child"  />
                <Column header="Tipo de recurso" colSpan={1} className="header-color header-table dth-child"  />
                <Column header="Fecha de recepción" colSpan={1} className="header-color header-table dth-child"  />
                <Column header="Fecha prevista de devolución" colSpan={1} className="header-color header-table dth-child"  />
                <Column header="Observaciones" colSpan={1} className="header-color header-table  dth-child"  />
                <Column header="Acciones" colSpan={1} className="header-color header-table border-radius-right dth-child"  />
            </Row>
        </ColumnGroup>
            var arrayItemsBlue = [
                {
                    field1: "Automoviles",
                    field2: "34",
                    field3: "Nissan Sentra 2021",
                    field4: "ewqe4321ewqe231",
                    field5: "Vehículo",
                    field6: "05 - Enero - 2020",
                    field7: "05 - Enero - 2020",
                    field8: "Lorem ipsum dolor sit amet, fweaw",
                    field9: "",
        
                },
                {
                    field1: "Automoviles",
                    field2: "34",
                    field3: "Nissan Sentra 2021",
                    field4: "ewqe4321ewqe231",
                    field5: "Vehículo",
                    field6: "05 - Enero - 2020",
                    field7: "05 - Enero - 2020",
                    field8: "Lorem ipsum dolor sit amet, fweaw",
                    field9: "",
                },
                {
                    field1: "Automoviles",
                    field2: "34",
                    field3: "Nissan Sentra 2021",
                    field4: "ewqe4321ewqe231",
                    field5: "Vehículo",
                    field6: "05 - Enero - 2020",
                    field7: "05 - Enero - 2020",
                    field8: "Lorem ipsum dolor sit amet, fweaw",
                    field9: "",
                   
                },
            ]
            var arrayItemsBlue2 = [
                {
                    field1: "Automoviles",
                    field2: "34",
                    field3: "Nissan Sentra 2021",
                    field4: "ewqe4321ewqe231",
                    field5: "Vehículo",
                    field6: "05 - Enero - 2020",
                    field7: "05 - Enero - 2020",
                    field8: "Lorem ipsum dolor sit amet, fweaw",
                    field9: (<div style={{color:"#ff2631"}}>Entregado</div>),
        
                },
                {
                    field1: "Automoviles",
                    field2: "34",
                    field3: "Nissan Sentra 2021",
                    field4: "ewqe4321ewqe231",
                    field5: "Vehículo",
                    field6: "05 - Enero - 2020",
                    field7: "05 - Enero - 2020",
                    field8: "Lorem ipsum dolor sit amet, fweaw",
                    field9: (<div style={{color:"#ff2631"}}>Entregado</div>),
                },
                {
                    field1: "Automoviles",
                    field2: "34",
                    field3: "Nissan Sentra 2021",
                    field4: "ewqe4321ewqe231",
                    field5: "Vehículo",
                    field6: "05 - Enero - 2020",
                    field7: "05 - Enero - 2020",
                    field8: "Lorem ipsum dolor sit amet, fweaw",
                    field9: (<div style={{color:"#036402"}}>Asignado</div>),
                   
                },
            ]
            var arrayHeaderBlue = [
                { field: "field1" },
                { field: "field2" },
                { field: "field3" },
                { field: "field4" },
                { field: "field5" },
                { field: "field6" },
                { field: "field7" },
                { field: "field8" },
                { field: "field9" },
            ]
            //este contador va a intefactuar con la ultima tabla
            const template1 = {
                layout: 'PrevPageLink PageLinks NextPageLink',
                'PrevPageLink': (options) => {
                    return (
                        <button type="button" className={"btnGreen"} onClick={options.onClick} disabled={options.disabled}>
                            <span>Anterior</span>
                            <Ripple />
                        </button>
                    )
                },
                'NextPageLink': (options) => {
                    return (
                        <button type="button" className={"btnGreen"} onClick={options.onClick} disabled={options.disabled}>
                            <span>Siguiente</span>
                            <Ripple />
                        </button>
                    )
                },
            };
    return (
        <>
            <div className="cj-people-managment MetaNotAssigned">
                <div className="container-activations">
                    <HeadersSection
                        title={<div>Generar planillas</div>}
                        exeptions={{}}
                    />
                    <div className="div-selects-activations">
                        <div className="div-container-stand">
                            <div className="top-container-template-generate">
                                <div className="container-flex ">
                                    <div className="selects-container">

                                        {/* input search */}
                                        <div className="filter">
                                            Filtrar por:
                                        </div>
                                        <select>
                                            <option value="sucursal">Seleccionar Persona</option>
                                            <option value="sucursal">Jorge Antonio Fuerte</option>
                                            <option value="sucursal">Ave Maria</option>
                                        </select>
                                    </div>
                                </div>
                                <div>
                                    <button style={{ background: "#036402" }} onClick={()=>{showInfo()}}>Generar</button>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="div-container-stand" style={{marginBottom:"20px"}}>
                        <div className="container-flex">

                            <DataTable value={showData?arrayItems:null} headerColumnGroup={headerGroup} responsiveLayout="scroll" globalFilter={searchName} className="table-activations" >

                                {/* <ColumnGroup> */}
                                {/* <Row> */}
                                {arrayHeader.map((item, key) => {
                                    switch (key) {
                                        case 1:
                                            return (
                                                <Column body={imgTemplate} colSpan={item.colspan ? item.colspan : 1} className="rowdata" key={key} />
                                            )
                                            break;
                                        case 9:
                                            return (

                                                <Column body={typeTemplate} colSpan={item.colspan ? item.colspan : 1} className="rowdata" key={key} />
                                            )
                                            break;
                                        case 10:
                                            return (

                                                <Column body={stateTemplate} colSpan={item.colspan ? item.colspan : 1} className="rowdata" key={key} />
                                            )
                                            break;
                                        case 11:
                                            return (

                                                <Column body={actionsTemplate} colSpan={item.colspan ? item.colspan : 1} className="rowdata" key={key} />
                                            )
                                            break;
                                        default:
                                            return (
                                                <Column field={item.field} colSpan={item.colspan ? item.colspan : 1} className="rowdata" key={key} />

                                            );
                                            break;
                                    }

                                })}

                            </DataTable>
                        </div>
                    </div>
                    {
                    showData?
                    <>
                        <div className="div-container-stand" style={{marginBottom:"20px"}}>
                            <div className="container-flex">
                                {
                                    showData?
                                    <>
                                    <div className="sub-titles-resources-gestion-container">
                                    <div className="sub-titles-resources-gestion">Recursos asignados</div>
                                    <button className="btn-resources-gestion" onClick={()=>setOpenModalAsign(true)}><img src={plusIcon}></img></button>
                                    </div>
                                    <DataTable value={showData?arrayItemsBlue:null} headerColumnGroup={headerGroupBlue} responsiveLayout="scroll" globalFilter={searchName} className="table-activations"  >

                                        {/* <ColumnGroup> */}
                                        {/* <Row> */}
                                        {arrayHeaderBlue.map((item, key) => {
                                            if(key === 8)
                                            {
                                                return (
                                                    <Column body={imgTemplateX(item.field.value)} colSpan={item.colspan ? item.colspan : 1} className="rowdata" key={key} />
                                                )
                                            }
                                            else{
                                                return (
                                                    <Column field={item.field} colSpan={item.colspan ? item.colspan : 1} className="rowdata" key={key} />

                                                );
                                            }

                                        })}

                                    </DataTable>
                                    </>:
                                    null

                                }
                            </div>
                        </div> 
                        </>
                        :
                        null
                    }   
                    {
                    showData?
                    <>
                    <div className="div-container-stand" style={{marginBottom:"20px"}}>
                        <div className="container-flex">
                            {
                                showData?
                                <>
                                <div className="sub-titles-resources-gestion">Historial de asignaciones</div>
                                <DataTable value={showData?arrayItemsBlue2:null} headerColumnGroup={headerGroupBlue} responsiveLayout="scroll" globalFilter={searchName} paginator rows={10} paginatorTemplate={template1} className="table-activations" >
                                    {arrayHeaderBlue.map((item, key) => {
                                        
                                            return (
                                                <Column field={item.field} colSpan={item.colspan ? item.colspan : 1} className="rowdata" key={key} />

                                            );
                                    })}
                                </DataTable>
                                </>:
                                null

                            }
                            <FooterTableW />
                        </div>
                    </div>    
                    </>:
                    null
                    }
                </div>
            </div>
            <FooterSimple></FooterSimple>
            {modalPdfState&&(<PdfModal modalState={modalPdfState} state = {setModalPdfState} content={PdfModalTemplate()}/>)}
            {openModalAsign&&(<GeneralModal modalState={openModalAsign} state = {setOpenModalAsign} content={ModalTemplateAsign()}/>)}
            {openEntrar&&(<GeneralModal modalState={openEntrar} state = {setOpenEntrar} content={ModalTemplateEntregar()}/>)}
        </>
    );
}
export default ResourcesGestion;