import React, { useState } from "react";
import "./SearchFiles.scss";
import Data from "./data.json";
import check from "../../../../img/icons/check.svg";
import alertIcon from "../../../../img/icons/alertIcon.png";
import { data } from "jquery";

export default function PrePaid() {
  const [impul, setImpul] = useState(false);
  const [save, setSave] = useState(false)
  const [tipo, setTipo] = useState("")
  const [doc, setDoc] = useState("")
  const [nac, setNac] = useState("")
  const [nombre, setNombre] = useState("")
  const [apellido, setApellido] = useState("")
  const [age, setAge] = useState("")


  const saveOnClick = ()=>{
    setSave(!save)
    var hoy = new Date()
    var nacimiento = new Date(nac)
    var m = hoy.getMonth() - nacimiento.getMonth();
    setAge(hoy.getFullYear()-nacimiento.getFullYear())
    if (m < 0 || (m === 0 && hoy.getDate() < nacimiento.getDate())) {
      setAge(age--);
  }

  }


  return (
    <div>
      <div className="files-search-contain1">
        <div className="contain-title">
          <div>Ficha</div>
          <div>{Data.map((e) => e.Tipo)}</div>
        </div>
        <div className="contain-alert">
          <div className="alert-yellow">
            <img src={alertIcon} alt="" />
            NO RECIBIDO
          </div>
          <div className="alert-red">
            <img src={alertIcon} alt="" />
            NO PRESENTADO EN CLARO
          </div>
        </div>
        <div className="contain-data">
          <div className="contain-info">
            <div>
              <div>
                <span>ESTADO LEGAJO AGENTE: </span>
                <span className="bold">
                  {Data.map((e) => e.EstadoLegajoAgente)}
                </span>
              </div>
              <div>
                <span>FECHA RECEPCION AGENTE: </span>
                <span className="bold">
                  {Data.map((e) => e.FechaRecepcionAgente)}
                </span>
              </div>
            </div>
            <div>
              <div>
                <span>FECHA ACTIVACIÓN: </span>
                <span className="bold">
                  {Data.map((e) => e.FechaActivacion)}
                </span>
              </div>
              <div>
                <span className="bold">{Data.map((e) => e.Recarga)}</span>
              </div>
            </div>
            <div>
              <div className="contain">
                <div>
                  <span>NIM: </span>
                  <span className="bold">{Data.map((e) => e.NIM)}</span>
                </div>
                <div>
                  <span className="bold-green">
                    {Data.map((e) => e.Portabilidad)}
                  </span>
                </div>
              </div>
              <div>
                <span>SDS: </span>
                <span className="bold">{Data.map((e) => e.SDS)}</span>
              </div>
              <div>
                <span>SIMCARD: </span>
                <span className="bold">{Data.map((e) => e.SIMCARD)}</span>
              </div>
            </div>
            <div className="contain">
              <div>
                <span className="blueColor">PLAN: </span>
                <span className="bold-blue">{Data.map((e) => e.PLAN)}</span>
              </div>
              <div>
                <span className="blueColor">CAMPAÑA: </span>
                <span className="bold-blue">{Data.map((e) => e.Campaña)}</span>
              </div>
            </div>
            <div>
              <div>
                <span className="bold">DATOS STEALTH</span>
              </div>
              <div>
                <span className="bold">
                  DATOS DDI: <span className="xbold">TIPO TITULAR: </span>
                </span>
                <span className="bold">{Data.map((e) => e.DatosDDI)}</span>
              </div>
              <div>
                <span>TIPO DOC: </span>
                <span className="bold">{Data.map((e) => e.TipoDOC)}</span>
              </div>
            </div>
          </div>
          <div className="contain-info">
            <div>
              <div>
                <span>ESTADO LEGAJO CLARO: </span>
                <span className="bold">
                  {Data.map((e) => e.EstadoLegajoClaro)}
                </span>
              </div>
              <div>
                <span>FECHA RENDICIÓN CLARO: </span>
                <span className="bold">
                  {Data.map((e) => e.FechaRendicionClaro)}
                </span>
              </div>
            </div>
            <div>
              <div>
                <span>FECHA PROVISIÓN: </span>
                <span className="bold">
                  {Data.map((e) => e.FechaProvision)}
                </span>
              </div>
            </div>
            <div>
              <div>
                <div>
                  <span>OPERADOR ORÍGEN: </span>
                </div>
              </div>
              <div>
                <span>CUENTA: </span>
                <span className="bold">{Data.map((e) => e.Cuenta)}</span>
              </div>
              <div>
                <span>BODEGA SIMCARD: </span>
                <span className="bold">{Data.map((e) => e.BodegaSIMCARD)}</span>
              </div>
              <div>
                <span>PRIMERA RECARGA: </span>
                <span className="bold">
                  {Data.map((e) => e.PrimeraRecarga)}
                </span>
              </div>
            </div>
            <div className="contain">
              <div>
                <span className="blueColor">DESCRIPCIÓN PLAN: </span>
                <span className="bold-blue">
                  {Data.map((e) => e.DescripcionPlan)}
                </span>
              </div>
            </div>
            <div>
              <div className="top">
                <span>TITULAR:</span>
                <span className="bold">{Data.map((e) => e.Titular)}</span>
              </div>
              <div>
                <span>DOCUMENTO: </span>
                <span className="bold">{Data.map((e) => e.Documento)}</span>
              </div>
            </div>
          </div>
          <div className="contain-info">
            <div>
              <div>
                <span>ANTIGUEDAD ACTIVACIÓN: </span>
                <span className="bold">
                  {Data.map((e) => e.AntiguedadActivacion)}
                </span>
              </div>
              <div>
                <span>NRO DE RENDICIÓN CLARO: </span>
                <span className="bold">
                  {Data.map((e) => e.NroRendicionClaro)}
                </span>
              </div>
            </div>
            <div className="contain">
              <div>
                <span>POS: </span>
                <span className="bold">{Data.map((e) => e.Pos)}</span>
              </div>
              <div>
                <span>DESCRIPCIÓN POS: </span>
                <span className="bold">
                  {Data.map((e) => e.DescripcionPos)}
                </span>
              </div>
            </div>
            <div>
              <div>
                <span>FECHA: </span>
                <span className="bold">
                  {Data.map((e) => e.FechaNacimiento)}
                </span>
              </div>
              <div>
                <span>CICLO: </span>
                <span className="bold">{Data.map((e) => e.Ciclo)}</span>
              </div>
              <div className="flex gap-3">
                <span>IMPULSADORA: </span>
                <div className="check" onClick={(e) => setImpul(!impul)}>
                  {impul === true ? <img src={check} alt="" /> : ""}
                </div>
              </div>
            </div>
            <div>
              <div className="topplus">
                <span>LINEA DE ORIGEN:</span>
                <span className="bold">{Data.map((e) => e.LineaOrigen)}</span>
              </div>
              <div>
                <span>FECHA DE NACIMIENTO: </span>
                <span className="bold">
                  {Data.map((e) => e.FechaNacimiento)}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="linea-info"></div>
        {!save ? (
          <form className="files-search-contain2">
          <div className="title">DATOS RENDICIÓN LEGAJOS:</div>
          <div className="contain-input1">
            <InputDate name={"TIPO DE DOC"} holder={"CI, EXT o RUC"} data={tipo} setData={setTipo} />
            <InputDate name={"DOCUMENTO"} holder={"Documento"} data={doc} setData={setDoc}/>
            <div className="contain-input">
              <div className="title">FECHA DE NACIMIENTO</div>
              <input type={"date"} className="files-input" value={nac} onChange={(e)=>{setNac(e.target.value)}}/>
            </div>
            <div className="contain-input">
              <div className="date-result">
                EDAD: <span className="bold-green">{age} AÑOS</span>
              </div>
            </div>
          </div>
          <div className="contain-input1">
            <InputDate name="NOMBRE" holder="Nombre del titular" data={nombre} setData={setNombre}/>
            <InputDate name="APELLIDO" holder="Apellido del titular" data={apellido} setData={setApellido}/>
          </div>
          <div className="contain-button">
            <button onClick={saveOnClick} className="button-green">GUARDAR DATOS DE RENDICIÓN</button>
          </div>
        </form>
        ):(
          <div className="files-search-contain2">
          <div className="title">DATOS RENDICIÓN LEGAJOS:</div>
          <div className="contain-input1">
            <InputDate2 name={"TIPO DE DOC"} holder={"CI, EXT o RUC"} data={tipo} />
            <InputDate2 name={"DOCUMENTO"} holder={"Documento"} data={doc} />
            <div className="contain-input" style={{display:"flex", gap:"5px"}}>
              <div className="title" style={{fontWeight:"normal"}}>FECHA DE NACIMIENTO: </div>
              <p style={{fontWeight:"bold", textTransform:"uppercase"}}>{nac}</p>
            </div>
            <div className="contain-input">
              <div className="date-result">
                EDAD: <span className="bold-green">{age} AÑOS</span>
              </div>
            </div>
          </div>
          <div className="contain-input1">
            <InputDate2 name="NOMBRE" holder="Nombre del titular" data={nombre} />
            <InputDate2 name="APELLIDO" holder="Apellido del titular" data={apellido} />
          </div>
          <div className="contain-button">
            <button style={{backgroundColor:"#0271c1"}} onClick={saveOnClick} className="button-green">EDITAR DATOS DE RENDICIÓN</button>
          </div>
        </div>
        )}
      </div>
    </div>
  );
}

const InputDate = ({ name, holder, data, setData }) => {
  return (
    <div className="contain-input">
      <div className="title">{name}</div>
      <input value={data} onChange={(e)=>setData(e.target.value)} type={"text"} className="files-input" placeholder={holder} />
    </div>
  );
};
const InputDate2 = ({ name, holder, data}) => {
  return (
    <div className="contain-input" style={{display:"flex", gap:"5px"}}>
      <div style={{fontWeight:"normal"}} className="title">{name}:</div>
      <p style={{fontWeight:"bold", textTransform:"uppercase"}}>{data}</p>
    </div>
  );
};
