import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import deleteicon from "../../../../../../images/tableIcons/eyeIcon.png";
import StaffDetail from "./StaffDetail";
import csv from "../../../../../../images/tableIcons/csv.png";
import excel from "../../../../../../images/tableIcons/excel.png";
const Penalties = () => {
  const accionTemplate = ({ id, descripcion }) => {
    return (
      <div
        className="centeredActions"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <div className="sectionActions">
          <div className="btnedit-table">
            <img src={deleteicon} />
          </div>
        </div>
      </div>
    );
  };
  const statusTemplate = () => {
    return (
      <div className="centeredStatus">
        <p style={{ color: "white", margin: "0" }}>Finalizado</p>
      </div>
    );
  };
  const fileTemplate = () => {
    return (
      <div className="centeredFile">
        <p
          style={{
            textDecoration: "underline #0600a8",
            color: "#0600a8",
            margin: "auto 0",
            fontSize: "11px",
          }}
        >
          solicitud.pdf
        </p>
      </div>
    );
  };
  const reasonTemplate = () => {
    return (
      <div className="centeredReason">
        <p style={{ color: "#f8a100", margin: "auto 0", fontSize: "11px" }}>
          Amonestación
        </p>
      </div>
    );
  };
  const tableInfo = {
    rows: [
      {
        fecha: "05 - Enero - 2020",
        motivo: "Amonestación",
        descuento: "$-120.00",
      },
    ],
  };

  const column = [
    {
      field: "fecha",
      header: "Fecha demovimiento",
      order: false,
    },
    {
      field: "motivo",
      header: "Motivo",
      body: reasonTemplate,
      order: false,
    },
    {
      field: "descuento",
      header: "Descuento",
      order: false,
    },
    {
      field: "archivo",
      header: "Archivo",
      body: fileTemplate,
      order: false,
    },
    {
      field: "estado",
      header: "Estado",
      body: statusTemplate,
      order: false,
    },
    {
      field: "accion",
      header: "Acciones",
      body: accionTemplate,
      order: false,
    },
  ];
  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <div>
        <StaffDetail
          opcion="penalizaciones"
          className="penalizaciones"
          styles={{ padding: "50px 20px 10px" }}
          contenido={
            <div className="dataGeneral">
              <div className="tableDownload">
                <DataTable
                  value={tableInfo.rows}
                  rows={10}
                  className="parameters-table"
                  paginator={true}
                >
                  {column.map((e, key) => {
                    return (
                      <Column
                        field={e.field}
                        header={e.header}
                        body={e.body ? e.body : null}
                      />
                    );
                  })}
                </DataTable>
              </div>
              <div
                style={{ display: "flex", gap: "11px", alignItems: "center" }}
              >
                <p style={{ fontSize: "12px", margin: "0" }}>Descargar como</p>
                <div style={{ display: "flex", gap: "22px" }}>
                  <div
                    style={{
                      width: "40px",
                      height: "40px",
                      display: "grid",
                      placeItems: "center",
                      borderRadius: "5px",
                      backgroundColor: "#ffffff",
                      border: "1px solid  #e3e3e3",
                    }}
                  >
                    <img style={{ width: "20px", height: "20px" }} src={csv} />
                  </div>
                  <div
                    style={{
                      width: "40px",
                      height: "40px",
                      display: "grid",
                      placeItems: "center",
                      borderRadius: "5px",
                      backgroundColor: "#ffffff",
                      border: "1px solid  #e3e3e3",
                    }}
                  >
                    <img
                      style={{ width: "20px", height: "20px" }}
                      src={excel}
                    />
                  </div>
                </div>
              </div>
            </div>
          }
        />
      </div>
      <div>
        <footer style={{ display: "flex", justifyContent: "center" }}>
          <p>2022 GestiClaro. Todos los derechos reservados</p>
        </footer>
      </div>
    </div>
  );
};

export default Penalties;
