import React,{useState} from "react";

import './GenerateClaroSurrender.scss'
//componentes
import HeadersSection from "../headersSection/HeadersSection";
import AbsoluteSelect from "../absoluteSelect/AbsoluteSelect";
import InputSearch from "../../InputSearch/InputSearch";
import ModalEdit from "./ModalEdit/ModalEdit";
//assets
import searchIcon from '../../../img/icons/search.svg'
import pencilIcon from '../../../img/icons/pencil.svg'
import trashIcon from '../../../img/icons/trash.svg'
//libraries
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import FooterSimple from "../footerSimple/FooterSimple";
import ModalDelete from "./ModalDelete/ModalDelete";
import ModalGenerate from "./ModalGenerate/ModalGenerate";

const GenerateClaroSurrender = () => {
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [openModalEdit, setOpenModalEdit] = useState(false);
    const [openModalGenerate, setOpenModalGenerate] = useState(false);
    const [ viewTable, setViewTable ] = useState(false)

   


    const handleDelete = () => {
        setOpenModalDelete(true)
    }

    const handleEdit = () => {
        setOpenModalEdit(true)
    }

    const handleGenerate = () => {
        setOpenModalGenerate(true)
    }


    var listJsonTest = [
        "19090",
        "2324234",
        "34534",
        "56756",
        "3453",
        "4567567",
        "98797",
        "34556",
        "8966",
    ]
    const [openSelect, setOpenSelect] = useState(false);
    const [idSelect, setIdSelect] = useState('')
    const templateActions = (item) => {
        return (
            <div className="actions-container">
                <img className="cursor-pointer" onClick={handleEdit} src={pencilIcon} style={{paddingRight:"7px", justifyContent:"center"}}></img>
                <img className="cursor-pointer" onClick={handleDelete} src={trashIcon}></img>
            </div>
        )
    }
    const checkTemplate = (RowData)=>
    {
        if(RowData.field15)
        {
            return(<>
                <input type="checkbox" id={RowData.id}></input><label for={RowData.id}></label>
            </>);
        }
        // else{
            // return(<>
            //     <input type="checkbox" id={RowData.id}></input><label for={RowData.id}></label>
            // </>);
        // }
        
    }
    const checkTemplate2 = (RowData)=>
    {
        if(RowData.field17)
        {
            return(<>
                <input type="checkbox" id={RowData.id+1}></input><label for={RowData.id+1}></label>
            </>);
        }
        // else{
            // return(<>
            //     <input type="checkbox" id={RowData.id}></input><label for={RowData.id}></label>
            // </>);
        // }
        
    }
    let headerGroup =
        <ColumnGroup>
            <Row>
                <Column header="NRO LEGAJO" colSpan={1} className="dth-child border-radius-left" />
                <Column header="SDS" colSpan={1} className="dth-child" />
                <Column header="CANAL" colSpan={1} className="dth-child" />
                <Column header="POS" colSpan={1} className="dth-child" />
                <Column header="NIM" colSpan={1} className="dth-child" />
                <Column header="PLAN" colSpan={1} className="dth-child" />
                <Column header="SIMCARD" colSpan={2} className="dth-child" />
                <Column header="DOCUMENTO" colSpan={1} className="dth-child" />
                <Column header="TITULAR" colSpan={1} className="dth-child" />
                <Column header="FECHA DE ACTIVACIÓN" colSpan={1} className="dth-child" />
                <Column header="ESTADO" colSpan={1} className="dth-child" />
                <Column header="ANTIGÜEDAD LEGAJO" colSpan={1} className="dth-child" />
                <Column header="CI" colSpan={1} className="dth-child" />
                <Column header="RIESGO" colSpan={1} className="dth-child" />
                <Column header="MAVERICK" colSpan={1} className="dth-child" />
                <Column header="ANEXOS" colSpan={1} className="dth-child" />
                <Column header="OBSERVACIONES" colSpan={1} className="dth-child" />
                <Column header="ACCIONES" colSpan={1} className="dth-child border-radius-right" />

            </Row>
        </ColumnGroup>
        var arrayHeader = [
            { field: "field1", colspan: 2, className: "header-table" },
            { field: "field2" },
            { field: "field3" },
            { field: "field4" },
            { field: "field5" },
            { field: "field6" },
            { field: "field7" },
            { field: "field8" },
            { field: "field9" },
            { field: "field10" },
            { field: "field11" },
            { field: "field12" },
            { field: "field13" },
            { field: "field14" },
            { field: "field15" },
            { field: "field16" },
            { field: "field17" },
            { field: "field18" },
            { field: "field19" },
        ]
        var arrayItems = [
            {
                field1: "50",
                field2: "1244536334",
                field3: "PREPAGO",
                field4: "256332",
                field5: "",
                field6: (<div className="colorGreen">PORTA</div>),
                field7: "",
                field8: "",
                field9: "",
                field10: "",
                field11: "",
                field12: "",
                field13: "",
                field14: "",
                field15: "true",
                field16: "",
                field17: (<><input type="checkbox" id="vehicle2"></input><label for="vehicle2"></label></>),
                field18: "Firma no coi...",
                id:"1"
            },
            {
                field1: "50",
                field2: "1244536334",
                field3: "PREPAGO",
                field4: "256332",
                field5: "",
                field6: (<div className="colorGreen">PORTA</div>),
                field7: "",
                field8: "",
                field9: "",
                field10: "",
                field11: "",
                field12: "",
                field13: "",
                field14: "",
                field15: "false",
                field16: "",
                field17: (<><input type="checkbox" id="vehicle1"></input><label for="vehicle1"></label></>),
                field18: "Firma no coi...",
                id:"2"
            },
            {
                field1: "50",
                field2: "1244536334",
                field3: "PREPAGO",
                field4: "256332",
                field5: "",
                field6: (<div className="colorGreen">PORTA</div>),
                field7: "",
                field8: "",
                field9: "",
                field10: "",
                field11: "",
                field12: "",
                field13: "",
                field14: "",
                field15: "false",
                field16: "",
                field17: (<><input type="checkbox" id="vehicle1"></input><label for="vehicle1"></label></>),
                field18: "Firma no coi...",
                id:"3"
            },
            {
                field1: "50",
                field2: "1244536334",
                field3: "PREPAGO",
                field4: "256332",
                field5: "",
                field6: (<div className="colorGreen">PORTA</div>),
                field7: "",
                field8: "",
                field9: "",
                field10: "",
                field11: "",
                field12: "",
                field13: "",
                field14: "",
                field15: "true",
                field16: "",
                field17: (<><input type="checkbox" id="vehicle1"></input><label for="vehicle1"></label></>),
                field18: "Firma no coi...",
                id:"4"
            },
            {
                field1: "50",
                field2: "1244536334",
                field3: "PREPAGO",
                field4: "256332",
                field5: "",
                field6: (<div className="colorGreen">PORTA</div>),
                field7: "",
                field8: "",
                field9: "",
                field10: "",
                field11: "",
                field12: "",
                field13: "",
                field14: "",
                field15: "true",
                field16: "",
                field17: (<><input type="checkbox" id="vehicle1"></input><label for="vehicle1"></label></>),
                field18: "Firma no coi...",
                id:"5"
            },
            {
                field1: "50",
                field2: "1244536334",
                field3: "PREPAGO",
                field4: "256332",
                field5: "",
                field6: (<div className="colorGreen">PORTA</div>),
                field7: "",
                field8: "",
                field9: "",
                field10: "",
                field11: "",
                field12: "",
                field13: "",
                field14: "",
                field15: "true",
                field16: "",
                field17: (<><input type="checkbox" id="vehicle1"></input><label for="vehicle1"></label></>),
                field18: "Firma no coi...",
                id:"6"
            },
            {
                field1: "50",
                field2: "1244536334",
                field3: "PREPAGO",
                field4: "256332",
                field5: "",
                field6: (<div className="colorGreen">PORTA</div>),
                field7: "",
                field8: "",
                field9: "",
                field10: "",
                field11: "",
                field12: "",
                field13: "",
                field14: "",
                field15: "true",
                field16: "",
                field17: (<><input type="checkbox" id="vehicle1"></input><label for="vehicle1"></label></>),
                field18: "Firma no coi...",
                id:"7"
            },
        
        
        ]
        
        return(
            <>
                {openModalEdit && (<ModalEdit setOpenModalEdit= {setOpenModalEdit} />)}
                {openModalDelete && (<ModalDelete setOpenModalDelete= {setOpenModalDelete} />)}
                {openModalGenerate && (<ModalGenerate setOpenModalGenerate= {setOpenModalGenerate} />)}
                <div className="cj-generate-claro-surrender">
                    <HeadersSection
                    title={<div>Generar Rendición a Claro</div>}
                    exeptions={{}}
                    />

                    <div className="div-container-stand">
                        <div className="select-container">
                            <AbsoluteSelect
                                inputName={'Responsable'}
                                //list={info.selectRangoInfo.list}
                                // list={dataSelect}
                                list = {listJsonTest}//quitar cuando se ncorpore la api
                                setOpenSelect={setOpenSelect}
                                openSelect={openSelect}
                                setIdSelect={setIdSelect}
                            />
                        </div> 
                        <div className="input-search">
                            <InputSearch placeholder="NIM, SDS o SIMCARD"></InputSearch>
                            <div className="button-container">
                                <button className="btn-search" onClick={()=> setViewTable(true)}><img src={searchIcon}></img></button>
                            </div>
                        </div>
                        <div className="data-table mt-5">
                        { viewTable &&
                            (<DataTable value={arrayItems} headerColumnGroup={headerGroup} responsiveLayout="scroll" className="table-activations">
                                {arrayHeader.map((item, key) => {
                                    if (key === 18) {
                                        return (
                                            <Column body={templateActions} colSpan={item.colspan ? item.colspan : 1} className={item.className ? item.className : 1} key={key} />
                                        )
                                    }
                                    if (key === 14) {
                                        return (
                                            <Column body={checkTemplate} colSpan={item.colspan ? item.colspan : 1} className={item.className ? item.className : 1} key={key} />
                                        )
                                    }
                                    if (key === 16) {
                                        return (
                                            <Column body={checkTemplate2} colSpan={item.colspan ? item.colspan : 1} className={item.className ? item.className : 1} key={key} />
                                        )
                                    }
                                    else {
                                        return (
                                            <Column field={item.field} colSpan={item.colspan ? item.colspan : 1} className={item.className ? item.className : 1} key={key} />
                                        );
                                    }

                                })}
                            </DataTable>)}
                        { viewTable &&
                            (<div className="group-buttons">
                                <button className=" btn1 " onClick={()=> setViewTable(false)}>Limpiar</button>
                                <button className="btn2 " onClick={handleGenerate}>Generar rendición</button>
                            </div>)}
                    </div>
                        
                  </div>

                </div>
            </>
        );
}
export default GenerateClaroSurrender;