import React from "react";

const Registro = ({ numero, tipo, ID, eliminarContacto }) => {
  const handleEliminar = () => {
    eliminarContacto(ID);
  };
  return (
    <div className=" contact-emergency color-red">
      <input
        className="contact-emergency-input"
        style={{ width: 80, backgroundColor: "white" }}
        value={tipo}
        disabled
      />
      <input
        className="contact-emergency-input"
        style={{ backgroundColor: "white" }}
        value={numero}
        disabled
      />
      <button style={{ marginLeft: "20px" }} onClick={handleEliminar}>
        <p className="button-add-cont">-</p>
      </button>
    </div>
  );
};

export default Registro;
