import React from "react";
import "./Commissions.scss";

const Commissions = ({title}) => {
  return (
    <div className="commissions">
      <div>
        <p className="commissions-text">{title ? title:"Comisiones"}</p>

        <div className="commissionContainer">
          <div className="commissionsContext">
            <div className="commissionsSelect">
              <label>Filtrar por:</label>
              <select>
                <option>Enero</option>
                <option>Febrero</option>
                <option>Marzo</option>
                <option>Abril</option>
                <option>Mayo</option>
                <option>Junio</option>
                <option>Julio</option>
                <option>Agosto</option>
                <option>Septiembre</option>
                <option>Octubre</option>
                <option>Noviembre</option>
                <option>Diciembre</option>
              </select>
            </div>
            <div>
              <button className="btn-Green">Descargar Xls</button>
            </div>
          </div>
        </div>
      </div>
      <div>
        <footer style={{ display: "flex", justifyContent: "center" }}>
          <p>2022 GestiClaro. Todos los derechos reservados</p>
        </footer>
      </div>
    </div>
  );
};

export default Commissions;
