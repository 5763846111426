import React from "react";
import DocumentElements from "./DocumentElements";
import { useState } from "react";
import $ from "jquery";

const Documents = ({noImg, update}) => {
  function activateInput() {
    $("input[name='uploadImageS1']").trigger("click");
  }

  const [imgLogo, setImgLogo] = useState(false);
  const [imgSave, setImgSave] = useState(false);
  const [image, setImage] = useState("");

  function changeFileLogo() {
    var fileInput = document.getElementById("uploadImageS1");

    var filePath = fileInput.value;
    if (filePath === "") {
      setImgSave(false);
      return false;
    }
    var allowedExtensions = /(.png|.jpg|.jpeg)$/i;
    if (!allowedExtensions.exec(filePath)) {
      fileInput.value = "";
      return false;
    } else {
      if (fileInput.files && fileInput.files[0]) {
        var reader = new FileReader();
        reader.onload = function (e) {
          setImgLogo(fileInput.files[0]);
          setImgSave(URL.createObjectURL(fileInput.files[0]));
        };
        reader.readAsDataURL(fileInput.files[0]);
      }
    }
  }

  return (
    <div className="personnel-data-doc-main">
      <div className="personnel-container-quest" style={update && {justifyContent:"space-between"}}>
        {update ? (
          <>
          <p>Documentos relacionados</p>
          <button className="update-button">
          <p style={{margin:"0"}}>Actualizar</p>
        </button>
        </>
        ):(
          <p>Documentos relacionados</p>
        )}
        
      </div>
      <div className="personnel-picture">
        <input
          type="file"
          id="uploadImageS1"
          name="uploadImageS1"
          onChange={(e) => {
            changeFileLogo();
          }}
        />
        <div className="picture-ID">
          {!noImg && (
            <>
            <p>
            Foto<span> carnet</span>
          </p>
          <div
            className="picture-ID-img"
            style={{
              width: "100%",
              height: "100%",
              backgroundImage: `url(${imgSave ? imgSave : null})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          >
            {image === "" && (
              <button
                onClick={activateInput}
                className="aside-menu__brand-images__logo-wrapper__edit-icon"
                alt="Pencil icon png"
              >
                <p>+</p>
              </button>
            )}
          </div>
          </>
          )}
        </div>

        <div className="personnel-picture-container">
          <div className="personnel-picture-selection">
            <DocumentElements
              id="imageLogo1"
              id2="input-file1"
              title="Documento de identidad"
            />
            <DocumentElements
              id="imageLogo2"
              id2="input-file2"
              title="Curriculum"
            />
            <DocumentElements
              id="imageLogo3"
              id2="input-file3"
              title="Registro de conducir"
            />
            <DocumentElements
              id="imageLogo4"
              id2="input-file4"
              title="Vida y residencia"
            />
          </div>

          <div className="personnel-picture-selection">
            <DocumentElements
              id="imageLogo5"
              id2="input-file5"
              title="Antecedentes policiales"
            />
            <DocumentElements
              id="imageLogo6"
              id2="input-file6"
              title="Antecedentes judiciales"
            />
            <DocumentElements
              id="imageLogo7"
              id2="input-file7"
              title="INFORMCONF"
            />
            <DocumentElements
              id="imageLogo8"
              id2="input-file8"
              title="Ficha TE BUSCAMOS"
            />
            <DocumentElements
              id="imageLogo9"
              id2="input-file9"
              title="Certificaciones"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Documents;
