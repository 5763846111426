import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import editIcon from "../../../../../../images/tableIcons/editIcon.png";
import deleteicon from "../../../../../../images/tableIcons/tashIcon.png";
import { Modal } from "antd";
import okicon from "../../../../../../images/tableIcons/okIcon.png";
import supr from "../../../../../../images/tableIcons/suprIcon.png";
import { classNames } from 'primereact/utils'
import { Ripple } from 'primereact/ripple';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

const TablePayAllowances = ({
  searchEnter,
  value,
  setDataParameters,
  setColumn,
  showModalDelete,
  setShowModalDelete,
}) => {
  const defaultPaginatorTemplate = {
    layout: 'PrevPageLink PageLinks NextPageLink',
    'PrevPageLink': (options) => {
        return (
            <button type="button" className='paginator-before greenBtn ' onClick={options.onClick} disabled={options.disabled}>
                Anterior
                <Ripple />
            </button>
        )
    },
    'NextPageLink': (options) => {
        return (
            <button type="button" className='paginator-after greenBtn' onClick={options.onClick} disabled={options.disabled}>
                Siguiente
                <Ripple />
            </button>
        )
    },
    'PageLinks': (options) => {
        if ((options.view.startPage === options.page && options.view.startPage !== 0) || (options.view.endPage === options.page && options.page + 1 !== options.totalPages)) {
            const className = classNames(options.className, { 'p-disabled': true });

            return <span className={className} style={{ userSelect: 'none' }}>...</span>;
        }

        return (
            <button type="button" className={options.className} onClick={options.onClick}>
                {options.page + 1}
                <Ripple />
            </button>
        )
    },
};
const [data, setData] = useState([]);
const deleteElements = (e) => {
  const resThen = (res) => {
    Swal.fire({
      icon: "success",
      title: "Tipo de activo eliminado",
      text: "Se eliminó la información de forma correcta",
      showConfirmButton: false,
      timer: 2000,
    });

    var newData = rows;
    newData.forEach((element, index) => {
      if (element.id == e.id) {
        newData.splice(index, 1);
      }
    });

    setData([...newData]);
  };
  MySwal.fire({
    title: `¿Está seguro que desea eliminar esta asignación?`,
    showDenyButton: true,
    icon: 'warning',
    showCancelButton: false,
    denyButtonText: `Cancelar`,
    confirmButtonText: "Eliminar",
  }).then((result) => {
    if (result.isConfirmed) {
      Swal.fire({
        title:'Se eliminó esta asignación',
        icon: 'error',
        confirmButtonText: "Aceptar",
      }
      )
    }
  });
};
  const accionTemplate = (e) => {
    return (
      <div className="centeredActions">
        <div className="sectionActions">
          <Link to="/editar-parameters" className="btnedit-table">
            <img src={editIcon} />
          </Link>
          <div className="btnedit-table">
            {e.accion == "Acciones" && (
              <img src={deleteicon} onClick={() => deleteElements(e)}/>
            )}
            {e.accion == "Acciones2" && (
              <img src={okicon} />
            )}
            {e.accion == "Acciones3" && (
              <img src={supr}  />
            )}
          </div>
        </div>
      </div>
    );
  };

  const column = [
    {
      field: "cargo",
      header: "Cargo",
      order: false,
    },
    {
      field: "asignacion",
      header: "Tipo de Asignación",
      order: false,
    },
    {
      field: "date",
      header: "Fecha de registro",
      order: false,
    },
    {
      field: "num",
      header: "Numero de Usuarios vinculados",
      order: false,
    },
    {
      field: "update",
      header: "Última actualización",
      order: false,
    },
    {
      field: "accion",
      header: "Acciones",
      order: false,
      body: accionTemplate,
    },
  ];

  const rows = [
    {
      cargo: "Bono segunda recarga",
      asignacion: "Suma",
      date: "21 - Abril - 2021",
      num: "34",
      update: "21 - Abril - 2021",
      accion: "Acciones",
    },
    {
      cargo: "Bonos",
      asignacion: "Suma",
      date: "21 - Abril - 2021",
      num: "34",
      update: "21 - Abril - 2021",
      accion: "Acciones2",
    },
    {
      cargo: "Comisión activaciones POS",
      asignacion: "Suma",
      date: "21 - Abril - 2021",
      num: "34",
      update: "21 - Abril - 2021",
      accion: "Acciones3",
    },
    {
      cargo: "Días trabajados",
      asignacion: "Suma",
      date: "21 - Abril - 2021",
      num: "34",
      update: "21 - Abril - 2021",
      accion: "Acciones2",
    },
    {
      cargo: "PORTH OUT",
      asignacion: "Suma",
      date: "21 - Abril - 2021",
      num: "34",
      update: "21 - Abril - 2021",
      accion: "Acciones",
    },
    {
      cargo: "Portabilidad",
      asignacion: "Suma",
      date: "21 - Abril - 2021",
      num: "34",
      update: "21 - Abril - 2021",
      accion: "Acciones",
    },
    {
      cargo: "Portabilidad PosPago",
      asignacion: "Suma",
      date: "21 - Abril - 2021",
      num: "34",
      update: "21 - Abril - 2021",
      accion: "Acciones2",
    },
    {
      cargo: "Prepago",
      asignacion: "Suma",
      date: "21 - Abril - 2021",
      num: "34",
      update: "21 - Abril - 2021",
      accion: "Acciones3",
    },
    {
      cargo: "Residuales",
      asignacion: "Suma",
      date: "21 - Abril - 2021",
      num: "34",
      update: "21 - Abril - 2021",
      accion: "Acciones3",
    },
    {
      cargo: "Administrativo",
      asignacion: "Suma",
      date: "21 - Abril - 2021",
      num: "34",
      update: "21 - Abril - 2021",
      accion: "Acciones3",
    },
  ];

  return (
    <div  className="leftElements">
      <DataTable
        value={rows}
        rows={10}
        paginator
        paginatorTemplate={defaultPaginatorTemplate}
        className="parameters-table"
        globalFilter={searchEnter}
      >
        {column.map((e) => {
          return <Column field={e.field} header={e.header} body={e.body} />;
        })}
      </DataTable>
    </div>
  );
};
export default TablePayAllowances;
