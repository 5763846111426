import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Link, useNavigate } from 'react-router-dom';

import 'primeflex/primeflex.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

import './ForgotPassword.scss'

import img1 from "../../../img/log/intertec.png";
import img2 from "../../../img/log/image.png";

const ForgotPassword = ({setAuth}) => {
    useEffect(() => {
        setAuth(0);
    }, [])


    const [errorEmail, setErrorEmail] = useState(false)
    const [email, setEmail] = useState('')
    const navigate = useNavigate(0);
    
    const handleSubmit = ( e ) => {
        e.preventDefault();

        if (email.length <1 ){
            setErrorEmail(true)
            return
        }

        navigate('/recover-password')
    } 

    return (
        <div>
            <div className="login__login-box">
                <img src={img1} className="login__imgInter" alt=""/>
                <div className="login__title-log">Correo de recuperación</div>
                <p className="text">Enviar correo a:</p>
                <p className="text bold">{'*****ol@gmail.com'}</p>
                <form onSubmit={ (e) => handleSubmit(e) }>
                    <div className="mb-5">
                        <div htmlFor="" className="login__label text">Correo electrónico</div>
                        <input  type="email" 
                                name="" 
                                value={email}
                                id="emailInput" 
                                className={`login__input-login ${errorEmail && 'input-error'}`} 
                                onChange={(e)=>{
                                    setErrorEmail(false);
                                    setEmail(e.target.value)}
                                } 
                                placeholder="Ingresar tu correo electrónico"/>
                        {errorEmail && (<h4 className="msg-error text-right">El correo no es válido</h4>)}
                    </div>
                   
                   <div className="d-flex ">

                        <button onClick={() => navigate('/')} className="RP__btn RP__btn-cancel" type="button" 
                        // onClick={login}
                        >Cancelar</button>

                        <button className="RP__btn RP__btn-submit" type="submit" 
                        // onClick={login}
                        >Enviar</button>
                    </div>
                    
                </form>
                <img src={img2} className="login__imgbottom" alt=""/>
            </div>
        </div>
    );
};

export default ForgotPassword;
