import React from "react";
// import RouteReactJS from '../../../router/Router';
import RouterAuth from '../../../pages/auth/Router/RouterAuth';
import './HeadersSection.scss';
import { getTitle } from './functions';

const HeadersSection = ({ title, exeptions, date }) => {
    // const HeadersSection = ({ date }) => {
    return (
        <div className="div-headerSection">
            <div className="p-grid">
                <div className="p-col-12 p-lg-7">
                    {getTitle(title, exeptions)}
                </div>
                <p className="date-headerSection">{date}</p>
            </div>
            {/* <RouterAuth/> */}
        </div>
    );
};

export default HeadersSection;
