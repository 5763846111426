import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import editIcon from "../../../../../../images/tableIcons/editIcon.png";
import csv from "../../../../../../images/tableIcons/csv.png";
import excel from "../../../../../../images/tableIcons/excel.png";
import StaffDetail from "./StaffDetail";
import ModalAdd from "../NewPersonnelTwo/ModalAdd";

const Request = () => {
  const [modal, setModal] = useState(false);

  const cerrarModal = () => {
    const modal = document.getElementById("overlay");
    modal.remove();
    setModal(false);
  };
  const accionTemplate = ({ id, descripcion }) => {
    return (
      <div
        className="centeredActions"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <div className="sectionActions">
          <div className="btnedit-table">
            <img src={editIcon} />
          </div>
        </div>
      </div>
    );
  };
  const statusTemplate = () => {
    return (
      <div className="centeredStatusP">
        <p style={{ color: "white", margin: "0" }}>Pendiente</p>
      </div>
    );
  };
  const fileTemplate = () => {
    return (
      <div className="centeredFile">
        <p
          style={{
            textDecoration: "underline #0600a8",
            color: "#0600a8",
            margin: "auto 0",
            fontSize: "11px",
          }}
        >
          solicitud.pdf
        </p>
      </div>
    );
  };
  const reasonTemplate = () => {
    return (
      <div className="centeredReason">
        <p style={{ color: "#f8a100", margin: "auto 0", fontSize: "11px" }}>
          VACACIONES
        </p>
      </div>
    );
  };
  const tableInfo = {
    rows: [
      {
        solicitud: "05 - Enero - 2020",
        inicio: "05 - Enero - 2020",
        fin: "05 - Enero - 2020",
        fecha: "05 - Enero - 2020",
        monto: "$0.00",
      },
    ],
  };

  const column = [
    {
      field: "solicitud",
      header: "Fecha de solicitud",
      order: false,
    },
    {
      field: "inicio",
      header: "Fecha de inicio",
      order: false,
    },
    {
      field: "fin",
      header: "Fecha de fin",
      order: false,
    },
    {
      field: "motivo",
      header: "Motivo",
      body: reasonTemplate,
      order: false,
    },
    {
      field: "fecha",
      header: "Fecha de aprobación",
      order: false,
    },
    {
      field: "monto",
      header: "Monto",
      order: false,
    },
    {
      field: "archivo",
      header: "Archivo",
      body: fileTemplate,
      order: false,
    },
    {
      field: "estado",
      header: "Estado",
      body: statusTemplate,
      order: false,
    },
    {
      field: "accion",
      header: "Acciones",
      body: accionTemplate,
      order: false,
    },
  ];
  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <div>
        <StaffDetail
          opcion="solicitudes"
          className="solicitudes"
          styles={{ padding: "50px 20px 10px" }}
          contenido={
            <div className="dataGeneral">
              <div className="dataGeneralBtn">
                <input type="search" placeholder="Buscar por nombre" />
                <button
                  onClick={(e) => {
                    setModal(true);
                    const body = document.getElementById("root");
                    const overlay = document.createElement("div");
                    overlay.classList.add("overlay");
                    overlay.setAttribute("id", "overlay");
                    body.appendChild(overlay);
                  }}
                >
                  Nueva solicitud
                </button>
              </div>
              <div className="tableDownload">
                <DataTable
                  paginator={true}
                  value={tableInfo.rows}
                  rows={10}
                  className="parameters-table"
                >
                  {column.map((e, key) => {
                    return (
                      <Column
                        field={e.field}
                        header={e.header}
                        body={e.body ? e.body : null}
                      />
                    );
                  })}
                </DataTable>

                <div
                  style={{ display: "flex", gap: "11px", alignItems: "center" }}
                >
                  <p style={{ fontSize: "12px", margin: "0" }}>
                    Descargar como
                  </p>
                  <div style={{ display: "flex", gap: "22px" }}>
                    <div
                      style={{
                        width: "40px",
                        height: "40px",
                        display: "grid",
                        placeItems: "center",
                        borderRadius: "5px",
                        backgroundColor: "#ffffff",
                        border: "1px solid  #e3e3e3",
                      }}
                    >
                      <img
                        style={{ width: "20px", height: "20px" }}
                        src={csv}
                      />
                    </div>
                    <div
                      style={{
                        width: "40px",
                        height: "40px",
                        display: "grid",
                        placeItems: "center",
                        borderRadius: "5px",
                        backgroundColor: "#ffffff",
                        border: "1px solid  #e3e3e3",
                      }}
                    >
                      <img
                        style={{ width: "20px", height: "20px" }}
                        src={excel}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        />
        {modal && <ModalAdd newModal={true} cerrarModal={cerrarModal} />}
      </div>
      <div>
        <footer style={{ display: "flex", justifyContent: "center" }}>
          <p>2022 GestiClaro. Todos los derechos reservados</p>
        </footer>
      </div>
    </div>
  );
};

export default Request;
