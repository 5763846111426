import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import HeadersSection from "../../../components/book/headersSection/HeadersSection";
import FooterSimple from "../../../components/book/footerSimple/FooterSimple";
import Table from "./Table/Table";
import CreatePlan from "./CreatePlan/CreatePlan";
import { info } from '../../../../mockups/dashboard';
import { infoPlans } from '../../../../mockups/plans';
// import { linksList } from '../../router/linksList';

import './Plans.scss';
import 'primeflex/primeflex.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

const Plans = () => {
    const [showCreatePlan, setShowCreatePlan] = useState(false);
    console.log("entro a plans")
    return (
        <div>
            {showCreatePlan ?
                <CreatePlan closeModule={setShowCreatePlan} />
                :
                <div
                    // className="container-createplan"
                    >
                    <HeadersSection
                        title={'Planes no registrados'}
                        exeptions={""}
                        date={info.headerInfo.date}
                    />
                    <div className="form-createplan">
                        <div className="div-container-stand" style={{ padding: 32 }}>
                            {/* <div className="div-btns-plans">
                                <button onClick={() => setShowCreatePlan(!showCreatePlan)} className="buttons btn-big btn-green"><FontAwesomeIcon icon={faPlus} /> CREAR PLAN</button>
                            </div> */}
                            <div className="div-btns-plans">
                                <Link to="/plan-create">
                                    <button  className="buttons btn-big btn-green"><FontAwesomeIcon icon={faPlus} /> CREAR PLAN</button>
                                </Link>

                            </div>
                            <Table
                                arrayHeader={infoPlans.tableInfo.headerList}
                                arrayItems={infoPlans.tableInfo.rows}
                                setShowCreatePlan={setShowCreatePlan}
                            />

                        </div>
                        <FooterSimple />
                    </div>
                </div>
            }
        </div>

    );
};

export default Plans;
