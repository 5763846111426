import React, { useState } from "react";
import check from "../../../../img/icons/check.svg";

const  Anexo = () => {
    const [anexo, setAnexo] = useState(false); 
    const onChangue = () => {
      setAnexo(!anexo);
    };
    
    return (
      <div className="CI-check" onClick={onChangue}>
       {anexo === false ? "" : <img src={check} alt="check" />}
      </div>
    );
};

export default Anexo