import React from "react";
import Registros from "../NewPersonnelTwo/Registros";

const ListaRegistro = ({
  eliminarRegistro,
  registro,
  cambiarEstado,
  actualizarEstado,
  suma,
}) => {
  return (
    <div>
      {
        <Registros
          suma={suma}
          actualizarEstado={actualizarEstado}
          eliminarRegistro={eliminarRegistro}
          cambiarEstado={cambiarEstado}
          value={registro}
        />
      }
    </div>
  );
};

export default ListaRegistro;
