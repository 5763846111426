import React from "react";
import StaffDetail from "./StaffDetail";
import "./StaffDetail.scss";
import "../NewPersonnelTwo/BranchOffice.scss";
import { useEffect, useState } from "react";
import ListaRegistro from "../NewPersonnelTwo/ListaRegistro";
import ModalAdd from "../NewPersonnelTwo/ModalAdd";
import DataGeneral from "./DataGeneral";
import Documents from "../NewPersonnel/Documents";
import user from "../../../../../../images/tableIcons/userImage.png";
import whatsapp from "../../../../../../images/tableIcons/whatsapp.png";
import editicon from "../../../../../../images/tableIcons/Icon.png";

const General = () => {
  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [cargarTabla, setCargarTabla] = useState(false);
  const [registro, setRegistro] = useState([]);
  const [registro1, setRegistro1] = useState([]);
  const [estadoActualizado, setEstadoActualizado] = useState([]);
  const [suma, setSuma] = useState(0);

  useEffect(() => {
    let descripcion3 = 0;
    registro.forEach((e) => {
      const descripcion = e.descripcion.slice(1);
      const descripcion2 = parseFloat(descripcion.replaceAll(",", ""));
      descripcion3 = descripcion3 + parseFloat(descripcion2);
      setSuma(descripcion3);
    });
  }, [registro]);

  const eliminarRegistro = (id) => {
    const registroActualizado = registro.filter(
      (contacto) => contacto.id !== id
    );
    setRegistro(registroActualizado);
    setSuma(0);
  };

  const actualizarEstado = (id) => {
    registro.forEach((elemento) => {
      if (elemento.id === id && elemento.estado == "Activo") {
        elemento.estado = "Inactivo";
      } else if (elemento.id === id && elemento.estado == "Inactivo") {
        elemento.estado = "Activo";
      }
      setEstadoActualizado([...estadoActualizado, elemento]);
    });
  };

  const cerrarModal = () => {
    const modal = document.getElementById("overlay");
    modal.remove();
    setModal(false);
    setModal1(false);
    setCargarTabla(true);
  };
  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <StaffDetail
        opcion="general"
        button={true}
        className="general"
        barra={true}
        contenido={
          <>
            <div className="profile-container">
              <div className="profile-container-img">
                <div className="profile-data-information">
                  <p style={{ margin: "0", fontSize: "16px" }}>
                    Foto de perfil
                  </p>

                  <div
                    className="profile-data-information-img"
                    style={{
                      display: "flex",
                      justifyContent: "right",
                      backgroundImage: `url(${user})`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }}
                  >
                    <div
                      style={{
                        display: "grid",
                        placeItems: "center",
                        width: "20px",
                        height: "20px",
                        backgroundColor: "#a80038",
                        borderRadius: "50%",
                      }}
                    >
                      <img src={editicon} />
                    </div>
                  </div>
                </div>
                <div className="profile-data-information">
                  <p style={{ margin: "0", fontSize: "16px" }}>
                    Foto de CARNET
                  </p>
                  <div
                    className="profile-data-information-img"
                    style={{
                      display: "flex",
                      justifyContent: "right",
                      backgroundImage: `url(${user})`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }}
                  >
                    <div
                      style={{
                        display: "grid",
                        placeItems: "center",
                        width: "20px",
                        height: "20px",
                        backgroundColor: "#a80038",
                        borderRadius: "50%",
                      }}
                    >
                      <img src={editicon} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="container">
                <div className="data-information">
                  <div className="data-information-container">
                    <div className="data-information-text">
                      <p>
                        Nombre: <span>FATIMA JAZMIN FERREIRA RIVEROS</span>
                      </p>
                    </div>
                    <div className="data-information-text">
                      <p>
                        Cedula: <span>4.752.796</span>
                      </p>
                    </div>
                    <div className="data-information-text">
                      <p>
                        Fecha de expedición: <span>09 - Julio - 2021</span>
                      </p>
                    </div>
                  </div>

                  <div className="data-information-container">
                    <div className="data-information-text">
                      <p>
                        Fecha de nacimiento: <span>04 - Mayo - 1990</span>
                      </p>
                    </div>
                    <div className="data-information-text">
                      <p>
                        Edad: <span>57 años</span>
                      </p>
                    </div>
                    <div className="data-information-text">
                      <p>
                        Sexo: <span>Mujer</span>
                      </p>
                    </div>
                    <div className="data-information-text">
                      <p>
                        Nacionalidad: <span>México</span>
                      </p>
                    </div>
                    <div className="data-information-text">
                      <p>
                        Ciudad de nacimiento: <span>Guadalajara</span>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="data-information">
                  <div className="data-information-container">
                    <div className="data-information-text">
                      <p>
                        Estado civil: <span>Soltero</span>
                      </p>
                    </div>
                    <div className="data-information-text">
                      <p>
                        Nombre de la pareja o conyugue: <span></span>
                      </p>
                    </div>
                    <div className="data-information-text">
                      <p>
                        CI pareja: <span></span>
                      </p>
                    </div>
                    <div className="data-information-text">
                      <p>
                        CI pareja: <span>0</span>
                      </p>
                    </div>
                  </div>

                  <div className="data-information-container">
                    <div className="data-information-text">
                      <p>
                        Tipo de contacto: <span></span>
                      </p>
                    </div>
                    <div className="data-information-text">
                      <p>
                        Nro. de teléfono: <span></span>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="data-information">
                  <div className="data-information-container">
                    <div className="data-information-text">
                      <p>
                        Nombre de la madre: <span>Ana María Flores</span>
                      </p>
                    </div>
                    <div className="data-information-text">
                      <p>
                        CI de la madre: <span></span>
                      </p>
                    </div>
                    <div className="data-information-text">
                      <p>
                        Nombre del padre: <span>Domingo Riveros</span>
                      </p>
                    </div>
                    <div className="data-information-text">
                      <p>
                        Domingo Riveros: <span>0</span>
                      </p>
                    </div>
                  </div>

                  <div className="data-information-container">
                    <div className="data-information-text">
                      <p>
                        Contacto de emergencia: <span>Domingo Riveros</span>
                      </p>
                    </div>
                    <div
                      className="data-information-text"
                      style={{ display: "flex", gap: "10px" }}
                    >
                      <p>
                        Teléfono de emergencia:{" "}
                        <span>Celular (0986) 188 817</span>
                      </p>
                      <img
                        style={{
                          width: "18px",
                          height: "18px",
                          marginTop: "2px",
                        }}
                        src={whatsapp}
                      />
                    </div>
                  </div>
                </div>

                <div
                  className="data-information"
                  style={{ borderBottom: "none" }}
                >
                  <div className="data-information-container">
                    <div className="data-information-text">
                      <p>
                        Registro de conducir: <span>Particular</span>
                      </p>
                    </div>
                    <div className="data-information-text">
                      <p>
                        <span>Guadalajara</span>
                      </p>
                    </div>
                    <div className="data-information-text">
                      <p>
                        <span>54235rgfed534</span>
                      </p>
                    </div>
                    <div className="data-information-text">
                      <p>
                        <span>21 - Febrero - 2024</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="personnel-container-quest"
              style={{ justifyContent: "space-between" }}
            >
              <p>Contacto</p>
              <button className="update-button">
                <p style={{ margin: "0" }}>Actualizar</p>
              </button>
            </div>
            <div className="profile-container">
              <div className="noPicture">
                <div
                  className="profile-data-information"
                  style={{ height: "40px" }}
                ></div>
              </div>
              <div className="container">
                <div className="data-information" style={{ border: "none" }}>
                  <div className="data-information-container">
                    <div
                      className="data-information-text"
                      style={{ display: "flex", gap: "10px" }}
                    >
                      <p>
                        Teléfono de contacto:{" "}
                        <span>Celular (0986) 188 817</span>
                      </p>
                      <img
                        style={{
                          width: "18px",
                          height: "18px",
                          marginTop: "2px",
                        }}
                        src={whatsapp}
                      />
                    </div>
                    <div className="data-information-text">
                      <p>
                        Correo electróinico: <span>fatimafe@orion.com.py</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="personnel-container-quest"
              style={{ justifyContent: "space-between" }}
            >
              <p>Seguro social</p>
              <button className="update-button">
                <p style={{ margin: "0" }}>Actualizar</p>
              </button>
            </div>
            <div className="profile-container">
              <div className="noPicture">
                <div className="profile-data-information "></div>
                <div className="profile-data-information"></div>
              </div>
              <div className="container">
                <div className="data-information">
                  <div
                    className="data-information-container"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "20px",
                    }}
                  >
                    <div>
                      <div className="data-information-text">
                        <p>
                          IPS: <span>Si</span>
                        </p>
                      </div>
                    </div>
                    <div
                      className="socialSecurity"
                      style={{ display: "flex", gap: "70px", width: "1320px" }}
                    >
                      <div className="data-information-text">
                        <p>
                          Fecha de ingreso: <span>21 - Febrero - 2024</span>
                        </p>
                      </div>
                      <div className="data-information-text">
                        <p>
                          Tipo de asegurado: <span>IMSS</span>
                        </p>
                      </div>
                      <div className="data-information-text">
                        <p>
                          Número ASECOT (ID asegurado): <span>3213214324</span>
                        </p>
                      </div>
                      <div className="data-information-text">
                        <p>
                          Salario registrado IPS: <span>$ 12,000.00</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="data-information">
                  <div
                    className="data-information-container"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "20px",
                    }}
                  >
                    <div>
                      <div className="data-information-text">
                        <p
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "0px",
                          }}
                        >
                          Seguro médico privado: <span>Si</span>
                        </p>
                      </div>
                    </div>
                    <div style={{ display: "flex", gap: "70px" }}>
                      <div className="data-information-text">
                        <p className="text-social">
                          Fecha de ingreso: <span>21 - Febrero - 2024</span>
                        </p>
                      </div>
                      <div className="data-information-text">
                        <p className="text-social">
                          Tipo de asegurado: <span>IMSS</span>
                        </p>
                      </div>
                      <div className="data-information-text">
                        <p className="text-social">
                          Nro. de seguro médico: <span>3213214324</span>
                        </p>
                      </div>
                      <div className="data-information-text">
                        <p className="text-social">
                          Antigüedad IPS: <span>21 - Febrero - 2024</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="personnel-container-quest"
              style={{ justifyContent: "space-between" }}
            >
              <p>Datos de asignación</p>
              <button className="update-button">
                <p style={{ margin: "0" }}>Actualizar</p>
              </button>
            </div>
            <div className="profile-container">
              <div className="noPicture">
                <div className="profile-data-information"></div>
                <div className="profile-data-information"></div>
              </div>
              <div className="container">
                <div className="data-information">
                  <div className="data-information-container">
                    <div className="data-information-text">
                      <p className="text-social">
                        Fecha de inicio: <span>21 - Febrero - 2021</span>
                      </p>
                    </div>
                  </div>

                  <div className="data-information-container">
                    <div className="data-information-text">
                      <p className="text-social">
                        Tipo de relación: <span>Tercerizado</span>
                      </p>
                    </div>
                    <div className="data-information-text">
                      <p className="text-social">
                        Inicio de contrato: <span>21 - Febrero - 2021</span>
                      </p>
                    </div>
                    <div className="data-information-text">
                      <p className="text-social">
                        Fin de contrato: <span>21 - Febrero - 2021</span>
                      </p>
                    </div>
                    <div className="data-information-text">
                      <p className="text-social">
                        Antiguedad del agente:{" "}
                        <span>2 años, 3 meses, 14 días</span>
                      </p>
                    </div>
                  </div>

                  <div className="data-information-container">
                    <div className="data-information-text">
                      <p className="text-social">
                        Tipo de puesto: <span>Administrativo</span>
                      </p>
                    </div>
                    <div className="data-information-text">
                      <p className="text-social">
                        Correo electrónico asignado:{" "}
                        <span>fatimafe@orion.com.py</span>
                      </p>
                    </div>
                    <div className="data-information-text">
                      <p className="text-social">
                        Nro. de teléfono asignado: <span>33 0903 4567</span>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="data-information">
                  <div className="data-information-container">
                    <div className="data-information-text">
                      <p className="text-social">
                        Sucursal: <span>MATRIZ</span>
                      </p>
                    </div>
                    <div className="data-information-text">
                      <p className="text-social">
                        Departamento: <span>Administración</span>
                      </p>
                    </div>
                    <div className="data-information-text">
                      <p className="text-social">
                        Canal comercial: <span>Canal comercial:</span>
                      </p>
                    </div>
                  </div>

                  <div className="data-information-container">
                    <div className="data-information-text">
                      <p className="text-social">
                        Supervisor asignado:{" "}
                        <span>Saul Alejandro Pérez Gonzalez</span>
                      </p>
                    </div>
                    <div className="data-information-text">
                      <p className="text-social">
                        TeamLeader <span>Armando Carlos Herrera Loroña</span>
                      </p>
                    </div>
                    <div className="data-information-text">
                      <p className="text-social">
                        POS asignado: <span></span>
                      </p>
                    </div>
                  </div>

                  <div className="data-information-container">
                    <div className="data-information-text">
                      <p className="text-social">
                        Bodega virtual: <span>No</span>
                      </p>
                    </div>
                    <div className="data-information-text">
                      <p className="text-social">
                        Impulsadora: <span>Si</span>
                      </p>
                    </div>
                    <div className="data-information-text">
                      <p className="text-social">
                        Forma de asistencia: <span>Marcar reloj</span>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="data-information">
                  <div className="data-information-container">
                    <div className="data-information-text">
                      <p className="text-social">
                        Razón social asignada: <span>SA de CV</span>
                      </p>
                    </div>
                  </div>

                  <div className="data-information-container">
                    <div className="data-information-text">
                      <p className="text-social">
                        Emite RUC: <span>Si</span>
                      </p>
                    </div>
                    <div className="data-information-text">
                      <p className="text-social">
                        RUC: <span>423432432432</span>
                      </p>
                    </div>
                    <div className="data-information-text">
                      <p className="text-social">
                        Realiza pago por acreditación: <span>Si</span>
                      </p>
                    </div>
                    <div className="data-information-text">
                      <p className="text-social">
                        Banco: <span>Banco:</span>
                      </p>
                    </div>
                  </div>

                  <div className="data-information-container">
                    <div className="data-information-text">
                      <p className="text-social">
                        Periodo de liquedación: <span>Quincenal</span>
                      </p>
                    </div>
                    <div className="data-information-text">
                      <p className="text-social">
                        Forma de pago: <span>Trabajo</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="personnel-container-quest"
              style={{ justifyContent: "space-between" }}
            >
              <p>Datos de asignación</p>
              <button className="update-button">
                <p style={{ margin: "0" }}>Actualizar</p>
              </button>
            </div>
            <div className="profile-container">
              <div className="noPicture">
                <div className="profile-data-information"></div>
                <div className="profile-data-information"></div>
              </div>
              <div className="container">
                <div
                  className="data-information"
                  style={{ borderBottom: "none" }}
                >
                  <div
                    className="data-information-container"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0",
                    }}
                  >
                    <p>
                      Descripción y regístro de condiciones laborales,
                      comerciales y forma de pago
                    </p>
                    <textarea
                      className="textarea"
                      placeholder="Escribe aqui"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="personnel-container-quest"
              style={{ justifyContent: "space-between" }}
            >
              <p>Asignación de haberes</p>
              <button className="update-button">
                <p style={{ margin: "0" }}>Actualizar</p>
              </button>
            </div>
            {modal && (
              <ModalAdd
                cerrarModal={cerrarModal}
                registro={registro}
                setRegistro={setRegistro}
              />
            )}
            <div className="button-direction buttom-branch">
              <button
                onClick={(e) => {
                  setModal(true);
                  const body = document.getElementById("root");
                  const overlay = document.createElement("div");
                  overlay.classList.add("overlay");
                  overlay.setAttribute("id", "overlay");
                  body.appendChild(overlay);
                }}
              >
                <p className="button-direction-text buttom-branch-text">+</p>
              </button>
            </div>
            <ListaRegistro
              actualizarEstado={actualizarEstado}
              eliminarRegistro={eliminarRegistro}
              registro={registro}
              suma={suma}
            />
            <div className="personnel-container-quest">
              <p>Historial de direcciones</p>
            </div>
            {modal1 && (
              <ModalAdd
                direccion={true}
                cerrarModal={cerrarModal}
                registro={registro1}
                setRegistro={setRegistro1}
              />
            )}
            <div className="button-direction buttom-branch">
              <button
                style={{ backgroundColor: "#0271c1" }}
                onClick={(e) => {
                  setModal1(true);
                  const body = document.getElementById("root");
                  const overlay = document.createElement("div");
                  overlay.classList.add("overlay");
                  overlay.setAttribute("id", "overlay");
                  body.appendChild(overlay);
                }}
              >
                <p
                  className="button-direction-text buttom-branch-text"
                  style={{ color: "#0271c1" }}
                >
                  +
                </p>
              </button>
            </div>
            <DataGeneral value={registro1} />
            <Documents update={true} noImg={true} />
          </>
        }
      />
      <div>
        <footer style={{ display: "flex", justifyContent: "center" }}>
          <p>2022 GestiClaro. Todos los derechos reservados</p>
        </footer>
      </div>
    </div>
  );
};

export default General;
