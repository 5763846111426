import React from "react";
import "./SearchFiles.scss";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import show from '../../../../img/icons/eye.png';
import data2 from './data2.json';

export default function Table2SearchFiles() {
    const Nim = () => {
        return (
            <div style={{color: '#036402'}}>PORTA</div>
        )
    }

  return (
    <div className="search-files-datatable2">
      <DataTable value={data2}>
        <Column field="dateactivation" header="FECHA DE ACTIVACIÓN"></Column>
        <Column field="antiguedad" header="ANTIGUEDAD"></Column>
        <Column field="typeproduct" header="TIPO PRODUCTO"></Column>
        <Column field="nim" header="NIM" body={Nim}></Column>
        <Column field="plan" header="PLAN"></Column>
        <Column field="pos" header="POS"></Column>
        <Column field="seller" header="VENDEDOR"></Column>
        <Column field="stateactivation" header="ESTADO ACTIVACIÓN"></Column>
        <Column field="statefilesagent" header="ESTADO LEGAJO AGENTE"></Column>
        <Column field="statefilesure" header="ESTADO LEGAJO CLARO"></Column>
      </DataTable>
    </div>
  );
}