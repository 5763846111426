import React, { useState } from 'react'
import checkIcon from '../../../../../../img/icons/check.png'
import barCode from '../../../../../../../images/FileManagement/PrintLabels/Icon.png'
import './LabelCard.scss'


const LabelCard = ({check}) => {
  const[ isCheck, setIsCheck ] = useState()

  return (
    <div className='printL__card-content'>
        {check && 
          (<div className='printL__input-check' onClick={() => {setIsCheck(!isCheck)}} >
              {isCheck && (<img src={checkIcon}/>)}
           </div>)
        }
      <div className='printL__card' onClick={() => {setIsCheck(!isCheck)}}>
        <img src={barCode} />
        <h3>12784365PEP</h3>
        <p className='text '>12784365PEP</p>    
        <p className='text bold'>12784365PEP</p>    
        <p className='text '>12784365544851514</p>    
        <p className='text bold'>JUAN PÉREZ GARCÍA</p>    
        <p className='text '>03/08/2021 - XXXXXX</p>    
        <p className='text '>PREPAGO PORTABILIDAD</p>        
          
      </div>
    </div>
  )
}

export default LabelCard