export const largeWord = (word, type) => {
    const splitWord = word.split(' ');
    if (splitWord.length === 3) {
        switch (type) {
            case 'flag':
                return true;
            case 'className':
                return 'inputName-medium-word';
            default:
                break;
        }
        //return 12;
    } else if (splitWord.length > 3) {
        switch (type) {
            case 'flag':
                return true;
            case 'className':
                return 'inputName-large-word';
            default:
                break;
        }
        //return 10
    } else {
        //return 16
        switch (type) {
            case 'flag':
                return false;
            case 'className':
                return 'inputName-small-word';
            default:
                break;
        }
    }
};