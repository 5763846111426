import React, { useEffect, useRef, useState } from 'react'
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';

import { ProgressBar } from 'primereact/progressbar';
import { Toast } from 'primereact/toast';

import close from '../../../../../../img/icons/close.png'
import './Modal.scss'
import { DataTable } from 'primereact/datatable';
import Column from 'antd/lib/table/Column';

const Modal = ({setOpen}) => {

    const [updateFile, setUpdateFile] = useState(0);
    const toast = useRef(null);
    const interval = useRef(null);
    const [file, setFile] = useState('')
    const handleChange = (e) => {
        console.log(e.target.files)
        setFile(e.target.files)
    }

    const [ modal, setModal ]   = useState(true)
    const [ modal2, setModal2 ] = useState(false)
    const [ modal3, setModal3 ] = useState(false)
    const [fileError, setFileError] = useState(false)



    const handleUpload = () => {
        if(!file){
            setFileError(true)
            return
        }
        setModal(false);
        setModal2(true);
        for(let i = 0; i<=100; i++){
            setTimeout( ()=> {
                setUpdateFile(i)
            },1000 )     
        }
        setTimeout( ()=> {
            setModal2(false)
            setModal3(true)

        },3000 )




    }

    function changeFileLogo() {
        setFileError(false)
        var fileInput = document.getElementById("file");
        var filePath = fileInput.value;
        if (filePath === "") {
            setFile(false);
            return false;
        }
        if (fileInput.files && fileInput.files[0]) {
            var reader = new FileReader();
            reader.onload = function (e) {
                setFile(fileInput.files[0].name);
            };
            reader.readAsDataURL(fileInput.files[0]);
        }
    }
  return (
    <>
        <div className='Modal__modal'>
            { modal &&
                (<div>
                    <img className='Modal__close' onClick={()=>setOpen(false)} src={close}/>
                    <p className='modal-title'>Subir datos al sistema</p>
                    <input className='file-select' id='file'  type='file'onChange={changeFileLogo}  />
                    <label className={`${fileError&& 'msg-error'}`}>{file ? file : 'No ha seleccionado un archivo'}</label>
                    <button className='button' onClick={handleUpload}>Subir</button>
                </div>)
            }

            { modal2 && (<div className='modal2'>
                <p className='modal-title'>Cargando datos <small>{file}</small> </p>
                <ProgressBar className='progressBar' value={updateFile}></ProgressBar>
                {(updateFile===100) && (<p className='complete'> Carga completa</p>)}
            </div>)}

            { modal3 &&
                (<div className='modal3'>
                    <p className='modal-title'>Carga completa</p>
                    <div className='table_modal3'>
                        <div className='row_table'>
                            <p>xxx</p>
                            <p>REGISTROS INCERTADOS</p>
                        </div>
                        <div className='row_table'>
                            <p>xxx</p>
                            <p>REGISTROS ACTUALIZADOS</p>
                        </div>
                        <div className='row_table'>
                            <p>xxx</p>
                            <p>REGISTROS PROCESADOS</p>
                        </div>
                    </div>
                    <button  className='button_close' onClick={()=>setOpen(false)}>Cerrar</button>
                </div>)
            }

        </div>

        <div className='Modal__background' ></div>

    </>
  )
}

export default Modal