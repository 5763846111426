import React from "react";
import "./Personnel.scss";

const NewPersonnel = ({ caption, captionData, titleData }) => {
  return (
    <div className="personnel">
      <div className="personne-header">
        {caption && (
          <p>
          {"< "}
          {caption}
        </p>
        )}
        <h3>{captionData}</h3>
      </div>
    </div>
  );
};

export default NewPersonnel;
