import React, { useState } from 'react'
import Table from './Table/Table'


import excel from '../../../../../img/icons/excel.png'
import txt from '../../../../../img/icons/txt.jpg'


import './UploadUpdates.scss'
const UploadUpdates = () => {
  
    const data = [
        {file: excel, desc:'TXT Stealth - Legajos CLARO', action:'Subir'  },
        {file: excel, desc:'TXT Stealth - Legajos CLARO', action:'Subir'  },
        {file: txt, desc:'TXT Stealth - Legajos CLARO', action:'Subir'  },
        {file: excel, desc:'TXT Stealth - Legajos CLARO', action:'Subir'  },
        {file: txt, desc:'TXT Stealth - Legajos CLARO', action:'Subir'  },
        {file: excel, desc:'TXT Stealth - Legajos CLARO', action:'Subir'  },
        {file: txt, desc:'TXT Stealth - Legajos CLARO', action:'Subir'  }
    ]

  return (
    <div clasName=''>
        <header className='printL__header mb-5'>
            <h3 className='printL__title'>Subir Actualizaciones Legajos STEALTH</h3>
            <p className='printL__date '>Viernes 30 de Julio del 2022 | Semana: 18 | Cierre de mes: 1</p>
        </header>

        {/* El componente recibe como props la data, el titulo y el color de la tabla(green, blue, red y yellow) */}
        <Table data = {data} color='red' title='Prepago'  />
        <Table data = {data} color='green' title='Postpago' />
        <Table data = {data} color='blue' title='DTH' />
        <Table data = {data} color='yellow' title='GPON' />

        

     
    </div>
  )
}

export default UploadUpdates