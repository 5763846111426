import React, { useState } from "react";
import check from "../../../../img/icons/check.svg";

const Ci = (row) => {
    const [cis, setCis] = useState(false);
    const onChangue = () => {
      setCis(!cis);
    };
    return (
      <div className="CI-check"  onClick={onChangue}>
        {cis == false ? "" : <img src={check} alt="check" value={cis} />}
      </div>
    );
  };

  export default Ci