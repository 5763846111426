import React from "react";

const ModalAssign = ({
  setModal,
  setSecondTable,
  value,
  idSelection,
  secondTable,
}) => {
  const handleSubmit = (e) => {
    e.preventDefault();

    const elementSelection = value.filter(
      (element) => element.id === idSelection
    );

    setSecondTable([...secondTable, elementSelection[0]]);

    setModal(false);
  };
  return (
    <div className="modal">
      <form onSubmit={handleSubmit} className="modal-container">
        <p className="modal-container-text">Asignar recurso</p>
        <div className="contenido-modal">
          <label>Recurso a asignar</label>
          <input
            className="input-modal input-gray"
            type="text"
            disabled
            placeholder="Vehiculos, Autos, Nissan Sentra 2021, wq23fdsfs3"
          />
        </div>
        <div className="contenido-modal">
          <label>Fecha de entrega</label>
          <input
            className="input-modal input-gray"
            type="text"
            disabled
            placeholder="15/Enero/2021"
          />
        </div>
        <div className="contenido-modal">
          <label>Recurso a asignar</label>
          <input className="input-modal" type="date" />
        </div>
        <div className="contenido-modal">
          <label>Observaciones</label>
          <textarea placeholder=""></textarea>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "20px",
          }}
        >
          <button
            style={{ backgroundColor: "#e3e3e3", color: "black" }}
            onClick={() => {
              setModal(false);
            }}
          >
            Cancelar
          </button>
          <button style={{ backgroundColor: "#308ac9", color: "white" }}>
            Asignar
          </button>
        </div>
      </form>
    </div>
  );
};

export default ModalAssign;
