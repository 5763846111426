import React from "react";
import "./NewPersonnelThree.scss";
import NewPersonnelThreeData1 from "./NewPersonnelThreeData1";
import { useEffect, useState } from "react";
import Personnel from "../Personnel";
import { infoParameters } from "./newPersonnelThreeData";

const NewPersonnelThree = () => {
  const [active, setActive] = useState(false);
  const [value, setValue] = useState([]);
  const [secondTable, setSecondTable] = useState([]);
  const [idSelection, setIdSelection] = useState("");
  const template = ["accionTemplate", "eliminarTemplate"];

  const generarId = () => {
    const random = Math.random().toString(36).substr(2);
    const fecha = Date.now().toString(36);
    return fecha + random;
  };

  const tableInfo = {
    rows: [
      {
        id: generarId(),
        familia: "Automoviles",
        seccion: "34",
        producto: "Nissan Sentra 2021",
        serie: "ewqe",
        tipo: "Vehículo",
        observaciones: "Lorem ipsum dolor sit amet, fweaw",
        accion: "Acciones",
      },
      {
        id: generarId(),
        familia: "Automoviles",
        seccion: "34",
        producto: "Nissan Sentra 2021",
        serie: "ewqe4321ewqe231",
        tipo: "Vehículo",
        observaciones: "Lorem ipsum dolor sit amet, fweaw",
        accion: "Acciones",
      },
    ],
  };

  useEffect(() => {
    setValue(tableInfo.rows);
    console.log(value);
  }, []);
  return (
    <div
      className=" personnel"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: "20px",
      }}
    >
      <>
        <div>
          <Personnel caption="Personal" captionData="Nuevo Personal" />
        </div>
        <div className="personnel-container">
          <p className="personnel-container-text">Asignación de recursos</p>
          <div className="personnel-container-quest">
            <p>Recursos</p>
          </div>
          <div style={{ marginBottom: "20px" }}>
            <div className="personnel-container-means">
              <input type="checkbox" name="assign" id="assign" />
              <label for="assign">Asignar recursos</label>
            </div>
            <div className="personnel-container-search">
              <div className="personnel-container-search-lab-inp">
                <label>Buscar recurso por:</label>
                <input
                  className="input-style"
                  type="text"
                  placeholder="Buscar por palabra"
                />
              </div>
              <div className="personnel-container-search-button">
                <button
                  onClick={() => {
                    setActive(true);
                  }}
                  style={{ backgroundColor: "#036402", color: "white" }}
                >
                  Buscar recurso
                </button>
              </div>
            </div>
          </div>
          <NewPersonnelThreeData1
            secondTable={secondTable}
            setSecondTable={setSecondTable}
            value={value}
            active={active}
            setIdSelection={setIdSelection}
            idSelection={idSelection}
            template={template[0]}
          />
        </div>

        <div className="personnel-container">
          <NewPersonnelThreeData1
            value={secondTable}
            active={active}
            setSecondTable={setSecondTable}
            setIdSelection={setIdSelection}
            idSelection={idSelection}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "20px",
            marginTop: "20px",
          }}
        >
          <button style={{ backgroundColor: "#308ac9", color: "white" }}>
            Anterior
          </button>
          <button style={{ backgroundColor: "#036402", color: "white" }}>
            Guardar
          </button>
        </div>
      </>
      <div>
        <footer style={{ display: "flex", justifyContent: "center" }}>
          <p>2022 GestiClaro. Todos los derechos reservados</p>
        </footer>
      </div>
    </div>
  );
};

export default NewPersonnelThree;
