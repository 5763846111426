import React from 'react'
import HeadersSection from '../../../../components/book/headersSection/HeadersSection'
import { info } from '../../../../../mockups/dashboard';
import './PendingRecords.scss'
import CardRisk from '../../../../components/activations/cardsRisk/CardRisk';
import Tables from '../../../../components/PendingRecords/Tables/Tables';
import './PendingRecords.scss'

const PendingRecords = () => {
    return (
        <div>
            <HeadersSection
                title={'Gestión de riesgo'}
                exeptions={''}
                date={info.headerInfo.date}

            />

            <CardRisk />
            <Tables />

        </div>


    )
}

export default PendingRecords
