import React from "react";
import './UploadUpgrateStealth.scss'
//components
import HeadersSection from "../../../components/book/headersSection/HeadersSection";
import CardDowload from "../../../components/book/CardDowload/CardDowload";

const UploadUpgrateStealth = () => {
    const info = [
        {
            document: "exel",
            description: "DDI - Stealth - Legajos CLARO",
        },
        {
            document: "txt",
            description: "DDI - Stealth - Legajos CLARO red",
        },
        {
            document: "txt",
            description: "DDI - Stealth - Legajos CLARO red",
        },
        {
            document: "exel",
            description: "DDI - Stealth - Legajos CLARO",
        },
    ]
    return (
        <>
            <div className="UploadUpgrateStealth">
                <HeadersSection
                    title={<div>Subir Actualizaciones Legajos STEALTH</div>}
                    exeptions={{}}
                />
                <div className="div-selects-activations">
                    <div className="div-container-stand pt">
                        <CardDowload info={info} color="red" title="Prepago"></CardDowload>
                    </div>
                    <div className="div-container-stand pt">
                        <CardDowload info={info} color="#036402" title="Pospago"></CardDowload>
                    </div>
                    <div className="div-container-stand pt">
                        <CardDowload info={info} color="#0271c1" title="DHT"></CardDowload>
                    </div>
                    <div className="div-container-stand pt">
                        <CardDowload info={info} color="#f8a100" title="GPON"></CardDowload>
                    </div>
                </div>
            </div>
        </>
    );
}
export default UploadUpgrateStealth