import React from "react"
import './table.scss'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const Table =()=>{


    const items=[
        {f1:'Central', f2:'10000', f3:'10000', f4:'', f5:'',f6:'', f7:'1000'},
        {f1:'Centro', f2:'', f3:'', f4:'', f5:'',f6:'', f7:'1000'},
        {f1:'Este', f2:'', f3:'', f4:'1000', f5:'',f6:'', f7:''},
        {f1:'Norte', f2:'', f3:'', f4:'', f5:'',f6:'', f7:''},
        {f1:'Sur', f2:'', f3:'', f4:'', f5:'1000',f6:'', f7:''},
    ]

    return(
        <div className="containerActivationTable">
            <DataTable value={items} responsiveLayout="scroll">
                <Column field="f1" style={{textAlign:'center', fontWeight:'bold'}} header="Región" headerStyle={{ textAlign: 'center', backgroundColor:'#0600a8', color:'#ffffff' ,borderTopLeftRadius:'15px'}}/>
                <Column field="f2" style={{textAlign:'center'}} header="Riesgo" headerStyle={{ textAlign: 'center', backgroundColor:'#d500ff', color:'#ffffff'}}/>
                <Column field="f3" style={{textAlign:'center'}} header="Caja GPON" headerStyle={{ textAlign: 'center', backgroundColor:'#de9000', color:'#ffffff'}}/>
                <Column field="f4" style={{textAlign:'center'}} header="Prepago" headerStyle={{ textAlign: 'center', backgroundColor:'#ff2631', color:'#ffffff'}}/>
                <Column field="f5" style={{textAlign:'center'}} header="Pospago" headerStyle={{ textAlign: 'center', backgroundColor:'#036402', color:'#ffffff'}}/>
                <Column field="f6" style={{textAlign:'center'}} header="DTH" headerStyle={{ textAlign: 'center', backgroundColor:'#0271c1', color:'#ffffff'}}/>
                <Column field="f7" style={{textAlign:'center'}} header="GPON" headerStyle={{ textAlign: 'center', backgroundColor:'#de9000', color:'#ffffff',borderTopRightRadius:'15px'}}/>
            </DataTable>
        </div>
    )
}
export default Table
