import React from "react";
import { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Rows } from "primereact/row";
import { Link } from "react-router-dom";
import editIcon from "../../../../../../js/img/icons/eye.svg";
import deleteicon from "../../../../../../js/img/icons/pdf.svg";
import imgProfiler from "../../../../../../../resource/js/img/jpg/profile.jpg";
import { Modal, Row } from "antd";
import { dataTool } from "echarts";
import { style } from "@mui/system";

const TableManagementPersons = ({
  rows,
  color,
  value,
  setColumn,
  showModalDelete,
  setShowModalDelete,
  searchEnter,
}) => {
  const [position, setPosition] = useState(0);

  const accionTemplate = () => {
    return (
      <div className="centeredActions">
        <div className="sectionActions">
          <Link to="/editar-parameters" className="btnedit-table">
            <img src={editIcon} />
          </Link>
          <div className="btnedit-table">
            <img src={deleteicon} />
          </div>
        </div>
      </div>
    );
  };

  const typeTemplate = (e) => {
    return <p className={e.tipo === "Baja" ? "high" : "drop"}>{e.tipo}</p>;
  };
  const statusTemplate = (e) => {
    return <p className={`${e.estado}`}>{e.estado}</p>;
  };
  const imgTemplate = () => {
    return (
      <div className="imgTemplate">
        <img src={imgProfiler} />
      </div>
    );
  };

  const column = [
    {
      field: "banco",
      header: "Fecha de registro",
      order: false,
    },
    {
      field: "img",
      header: "Foto",
      order: false,
      body: imgTemplate,
    },
    {
      field: "num",
      header: "Nombre",
      order: false,
    },
    {
      field: "update",
      header: "Sucursal",
      order: false,
    },
    {
      field: "supervisor",
      header: "Supervisor",
      order: false,
    },
    {
      field: "ci",
      header: "CI",
      order: false,
    },
    {
      field: "perfil",
      header: "Perfil",
      order: false,
    },
    {
      field: "comercial",
      header: "Canal comercial",
      order: false,
    },
    {
      field: "pos",
      header: "POS",
      order: false,
    },
    {
      field: "tipo",
      header: "Tipo",
      order: false,
      body: typeTemplate,
    },
    {
      field: "estado",
      header: "Estado",
      order: false,
      body: statusTemplate,
    },
    {
      field: "accion",
      header: "Acciones",
      order: false,
      body: accionTemplate,
    },
  ];

  return (
    <>
      <DataTable
        value={value}
        rows={8}
        paginator={true}
        className="parameters-table"
        globalFilter={searchEnter}
      >
        {column.map((e) => {
          return <Column field={e.field} header={e.header} body={e.body} />;
        })}
      </DataTable>
      <Modal
        visible={showModalDelete}
        onOk={() => setShowModalDelete(false)}
        onCancel={() => setShowModalDelete(false)}
        centered={true}
        footer={[
          <button
            className="btnModalCancel"
            onClick={() => setShowModalDelete(false)}
          >
            Cancelar
          </button>,
          <button className="btnModalDo">Eliminar</button>,
        ]}
      >
        <div className="contentModal">
          {/* <img src={warning}/> */}

          <p className="titleModal">Se eliminará este Parámetro</p>
        </div>
      </Modal>
    </>
  );
};

export default TableManagementPersons;
