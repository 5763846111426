import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import deleteicon from "../../../../../../images/tableIcons/blueEditIcon.svg";

const DataGeneral = ({value}) => {

    const accionTemplate = ({ id, descripcion }) => {
        return (
          <div
            className="centeredActions"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div className="sectionActions">
              <div className="btnedit-table">
                <img 
                  src={deleteicon}
                />
              </div>
            </div>
          </div>
        );
      };

      const column = [
        {
          field: "fecha",
          header: "Fecha de modificación",
          order: false,
        },
        {
          field: "direccion",
          header: "Dirección",
          order: false,
        },
        {
          field: "accion",
          header: "Acciones",
          body:accionTemplate,
          order: false,
        },
      ];
  return (
    <div className="dataGeneral">
      {console.log(value)}
        <DataTable
        value={value}
        rows={10}
        className="parameters-table"
      >
        {column.map((e, key) => {
          return (
            <Column
              style={{ fontSize: "12px", fontWeight:"bold"}}
              field={e.field}
              header={e.header}
              body={e.body ? e.body : null}
            />
          );
        })}
      </DataTable>
    </div>
  )
}

export default DataGeneral