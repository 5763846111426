import React, {useState} from "react";
import "./SearchFiles.scss";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import show from '../../../../img/icons/eye.png';


export default function TableSearchFiles({data}) {
    const [isOpen, setIsOpen] = useState(false)
    const Actions = () => {
        return (
            <div>
                <img src={show} alt='' style={{width: '20px', height: '20px'}} onClick={e => setIsOpen(!isOpen)}/>
            </div>
        )
    }

  return (
    <div className="search-files-datatable">
      <DataTable value={data} scrollable scrollHeight="480px">
        <Column field="id" header="N°"></Column>
        <Column field="dateobservation" header="Fecha de observación"></Column>
        <Column field="users" header="Usuarios"></Column>
        <Column field="observation" header="Observaciones"></Column>
        <Column field="actions" header="Bitacora de estados" body={Actions}></Column>
      </DataTable>
      {isOpen === true ? (
        <>
          <div className="contain-modal"></div>
          <div className="modal">
            <div className="close" onClick={(e) => setIsOpen(!isOpen)}>
              X
            </div>
            <div className="title">
                Observaciones Legajo Agente
            </div>
            <div className="contain">
              <div className="contain-info">
                <span>Nro: <span className="bold">1</span></span>
                <span>Fecha de publicación: <span className="bold">12-08-21 12:08:02</span></span>
                <span>Usuario: <span className="bold">Oscar Guillermo</span></span>
                <span>Observaciones: </span>
                <span className="bold">Firma no coincide, Nro de cI adulterado</span>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
}
