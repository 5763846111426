import React from "react";
import { useEffect, useState } from "react";
import Personnel from "../Personnel";
import NewPersonnelFourData from "./NewPersonnelFourData";
import "./NewPersonnelFour.scss";

const NewPersonnelFour = () => {
  const [active, setActive] = useState(false);
  const [value, setValue] = useState([]);
  const [secondTable, setSecondTable] = useState([]);
  const [idSelection, setIdSelection] = useState("");
  const template = ["accionTemplate", "eliminarTemplate"];

  const generarId = () => {
    const random = Math.random().toString(36).substr(2);
    const fecha = Date.now().toString(36);
    return fecha + random;
  };

  const tableInfo = {
    rows: [
      {
        id: generarId(),
        seccion: "Ventas",
        espectador: "Espectador1",
        edicion: "Edición1",
        reporteria: "Reportería1",
      },
      {
        id: generarId(),
        seccion: "Verificaciones",
        espectador: "Espectador2",
        edicion: "Edición2",
        reporteria: "Reportería2",
      },
      {
        id: generarId(),
        seccion: "Activaciones",
        espectador: "Espectador3",
        edicion: "Edición3",
        reporteria: "Reportería3",
      },
      {
        id: generarId(),
        seccion: "Logística de instalación",
        espectador: "Espectador4",
        edicion: "Edición4",
        reporteria: "Reportería4",
      },
      {
        id: generarId(),
        seccion: "Logística de entrega",
        espectador: "Espectador5",
        edicion: "Edición5",
        reporteria: "Reportería5",
      },
      {
        id: generarId(),
        seccion: "Gestión de legajos",
        espectador: "Espectador6",
        edicion: "Edición6",
        reporteria: "Reportería6",
      },
      {
        id: generarId(),
        seccion: "Distribución",
        espectador: "Espectador7",
        edicion: "Edición7",
        reporteria: "Reportería7",
      },
      {
        id: generarId(),
        seccion: "POS Venta",
        espectador: "Espectador8",
        edicion: "Edición8",
        reporteria: "Reportería8",
      },
      {
        id: generarId(),
        seccion: "Gestión de stock",
        espectador: "Espectador9",
        edicion: "Edición9",
        reporteria: "Reportería9",
      },
      {
        id: generarId(),
        seccion: "Caja/Tesorería",
        espectador: "Espectador10",
        edicion: "Edición10",
        reporteria: "Reportería10",
      },
      {
        id: generarId(),
        seccion: "RRHH",
        espectador: "Espectador11",
        edicion: "Edición11",
        reporteria: "Reportería11",
      },
      {
        id: generarId(),
        seccion: "Auditoría",
        espectador: "Espectador12",
        edicion: "Edición12",
        reporteria: "Reportería12",
      },
      {
        id: generarId(),
        seccion: "Gerencia / Reportería",
        espectador: "Espectador13",
        edicion: "Edición13",
        reporteria: "Reportería13",
      },
      {
        id: generarId(),
        seccion: "Panel de control",
        espectador: "Espectador14",
        edicion: "Edición14",
        reporteria: "Reportería14",
      },
    ],
  };

  return (
    <div
      className=" personnel"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: "20px",
      }}
    >
      <>
        <div>
          <Personnel caption="Personal" captionData="Nuevo Personal" />
        </div>
        <div className="personnel-container">
          <p className="personnel-container-text">Asignación de usuario</p>
          <div className="personnel-data-doc">
            <div className="start-date">
              <label>Usuario</label>
              <input type="email" placeholder="Ingresa el correo electrónico" />
            </div>
            <div className="start-date">
              <label>Contraseña</label>
              <input type="text" placeholder="12323243423" disabled />
            </div>
          </div>

          <div className="personnel-data-doc">
            <div className="personnel-data-doc-age">
              <label>Tipo de usuario</label>
              <select className="input-style">
                <option>Selecciona una opción</option>
                <option>Activo</option>
                <option>Inactivo</option>
              </select>
            </div>

            <div className="personnel-data-doc-age">
              <label>Rol</label>
              <select className="input-style">
                <option>Selecciona una opción</option>
                <option>Vendedor</option>
                <option>Administrador</option>
                <option>Agente</option>
              </select>
            </div>

            <div className="personnel-data-doc-age">
              <label>Perfil</label>
              <select className="input-style">
                <option>Selecciona una opción</option>
                <option>Perfil 1</option>
                <option>Perfil 2</option>
                <option>Perfil 3</option>
              </select>
            </div>
          </div>
          <div
            className="personnel-container-quest"
            style={{ marginBottom: "20px" }}
          >
            <p>Asignación de permisos</p>
          </div>
          <NewPersonnelFourData rows={tableInfo.rows} />
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "20px",
            marginTop: "20px",
          }}
        >
          <button style={{ backgroundColor: "#308ac9", color: "white" }}>
            Anterior
          </button>
          <button style={{ backgroundColor: "#036402", color: "white" }}>
            Siguiente
          </button>
        </div>
      </>
      <div>
        <footer style={{ display: "flex", justifyContent: "center" }}>
          <p>2022 GestiClaro. Todos los derechos reservados</p>
        </footer>
      </div>
    </div>
  );
};

export default NewPersonnelFour;
