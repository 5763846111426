import React from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faFileWord } from '@fortawesome/free-solid-svg-icons';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import './Table.scss';

// import excel from '../../../../images/dashboard/excel.svg';
// import csv from '../../../../images/dashboard/csv.svg';

const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

const Table = ({ arrayHeader, arrayItems }) => {
    const exportExcel = () => {
        const ws = XLSX.utils.json_to_sheet(arrayItems);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, 'table_' + new Date().getTime() + fileExtension);
    }

    return (
        <div>
            <div className="div-downloads-dashboard">
                <span className="text-download-dashboard">Descargar como</span>
                <FontAwesomeIcon onClick={exportExcel} icon={faFileWord} className="icon-word-dashboard" />
                <FontAwesomeIcon onClick={exportExcel} icon={faFileExcel} className="icon-excel-dashboard" />
            </div>
            <DataTable
                value={arrayItems}
                paginator
                rows={8}
                exportable
                className="table-dashboard">
                {arrayHeader.map((item, key) => {
                    return (
                        <Column field={item.field} header={item.header} sortable={item.order} key={key} />
                    );
                })}
            </DataTable>
        </div>
    );
};

export default Table;
