import React, { useState , useEffect} from "react";
import ReactDOM from "react-dom";
import HeadersSection from "../../../components/book/headersSection/HeadersSection";
import AbsoluteSelect from "../../../components/book/absoluteSelect/AbsoluteSelect";
import Table from "../../../components/activations/table/Table";
import TableAgo from "../../../components/activations/table/TableAgo";
import FooterSimple from "../../../components/book/footerSimple/FooterSimple";
import ThirdTable from "../../../components/activations/table/ThirdTable"
import { info } from '../../../../mockups/dashboard';
import { infoAct } from '../../../../mockups/activations';

import './management.scss';
import 'primeflex/primeflex.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import axios from "axios";

const Management = ({routes}) => {
    const [openSelect, setOpenSelect] = useState(false);
    const [showSubSelects, setShowSubSelects] = useState(false);

    const [dataSelect, setDataSelect] = useState([{}])

    const [idSelect, setIdSelect] = useState('')
    const [firstTable, setFirstTable] = useState([{}])
    const [secondTable, setSecondTable] = useState([{}])
    const [thirdTable, setThirdTable] = useState([{}])

    var Routes
    useEffect(() => {

        const getSelect = async () => {

            let getData = Routes.getSelect;
            const result = await axios(getData,);
            setDataSelect(result.data.data.period)

        }

        const getTable = async () => {

            let getData = Routes.dataTable;
            const result = await axios(getData,);
            setFirstTable(result.data.data.first_table)
            setSecondTable(result.data.data.second_table)
            setThirdTable(result.data.data.third_table)

        }
        getTable()

        const getDataRelated  = async() => {
            let mainRoute = document.getElementsByName('routes')[0].getAttribute('content');
            const result = await axios(mainRoute,);
            Routes = result.data
            getSelect()
            getTable()
        }

        getDataRelated()



    },[]);

   //  useEffect(()=>{
   //
   //      //var Routes2
   //      if(idSelect !== ''){
   //          const getTable = async () => {
   //
   //              let getData = routes.dataTable;
   //              const result = await axios(getData,);
   //              setFirstTable(result.data.data.first_table)
   //              setSecondTable(result.data.data.second_table)
   //              setThirdTable(result.data.data.third_table)
   //
   //          }
   //          getTable()
   //
   //      }
   //
   // },[idSelect])
   //



    return (
        <div className="container-activations">
            <HeadersSection
                title={'Gerencía y reportería'}
                exeptions={{}}
                date={info.headerInfo.date}
            />


            <div className="div-selects-activations">
                <div className="div-container-stand">
                    <div className="p-grid">
                        <div className="p-col-3 p-md-6 p-lg-3 p-sm-12">
                            <AbsoluteSelect
                                inputName={'Periodo'}
                                //list={info.selectRangoInfo.list}
                                list={dataSelect}
                                setOpenSelect={setOpenSelect}
                                openSelect={openSelect}
                                setIdSelect={setIdSelect}
                            />
                        </div>
                        <div className="p-col-9 p-md-6 p-lg-9 p-sm-12">
                            <div className="div-details-activations">
                                <button className="buttons btn-big btn-blue" onClick={() => setShowSubSelects(!showSubSelects)}>Detalles</button>
                            </div>
                        </div>
                    </div>
                </div>
                {showSubSelects &&
                <div className="sub-selects-activations">
                    <div className="p-grid">
                        <div className="p-col-3 p-md-6 p-lg-3 p-sm-12 p-sm-12">
                            <AbsoluteSelect
                                inputName={info.selectRangoInfo.inputName}
                                list={info.selectRangoInfo.list}
                                setOpenSelect={setOpenSelect}
                                openSelect={openSelect}
                            />

                            <AbsoluteSelect
                                inputName={info.selectSupervisorInfo.inputName}
                                list={info.selectSupervisorInfo.list}
                                setOpenSelect={setOpenSelect}
                                openSelect={openSelect}
                            />
                        </div>
                        <div className="p-col-3 p-md-6 p-lg-3 p-sm-12">
                            <AbsoluteSelect
                                inputName={info.selectBranchInfo.inputName}
                                list={info.selectBranchInfo.list}
                                setOpenSelect={setOpenSelect}
                                openSelect={openSelect}
                            />

                            <AbsoluteSelect
                                inputName={info.selectSellerInfo.inputName}
                                list={info.selectSellerInfo.list}
                                setOpenSelect={setOpenSelect}
                                openSelect={openSelect}
                            />
                        </div>
                        <div className="p-col-3 p-md-6 p-lg-3 p-sm-12">
                            <AbsoluteSelect
                                inputName={info.selectChannelInfo.inputName}
                                list={info.selectChannelInfo.list}
                                setOpenSelect={setOpenSelect}
                                openSelect={openSelect}
                            />

                            <AbsoluteSelect
                                inputName={info.selectPOSInfo.inputName}
                                list={info.selectPOSInfo.list}
                                setOpenSelect={setOpenSelect}
                                openSelect={openSelect}
                            />
                        </div>
                        <div className="p-col-3 p-md-6 p-lg-3 p-sm-12" style={{ position: 'relative' }}>
                            <div className="div-buttons-activations">
                                <button className="buttons btn-blue">Limpiar</button>
                                <button className="buttons btn-green">Aceptar</button>
                            </div>

                        </div>
                    </div>
                </div>
                }
            </div>



                <Table
                    arrayHeader={infoAct.tableInfoOne.headers}
                    //arrayItems={infoAct.tableInfoOne.data}
                    arrayItems={infoAct.dataOne}
                    dataDates={infoAct.dataOne}
                    dataDateNow={infoAct.tableInfoOne.dataDateNow}
                    porcent={[]}
                />




                <TableAgo
                    //arrayItems={infoAct.tableInfoOne.data}
                    arrayItems={secondTable}
                    dataDateNow={infoAct.dataTotal}
                />




                <ThirdTable
                    arrayItems={infoAct.dataOne}
                    dataDateNow={infoAct.tableInfoOne.dataDateNow}
                    dataDates={infoAct.tableInfoOne.dataDates}
                    download={true}
                />


            <FooterSimple />
        </div>

    );
};

export default Management;

