import React,{useState} from "react";
import './ActivationsReports.scss';

//components
import AbsoluteSelect from "../absoluteSelect/AbsoluteSelect";
import HeadersSection from "../headersSection/HeadersSection";
import ThirdTable from "../../../components/activations/table/ThirdTable"
import FooterSimple from "../footerSimple/FooterSimple";
const ActivationsReports =()=>
{
    var listJsonTest = [
        "19090",
        "2324234",
        "34534",
        "56756",
        "3453",
        "4567567",
        "98797",
        "34556",
        "8966",
    ]
    const dataOne = [
        {
          total: "Total a la fecha",
          rows: [
            {
              row: [3568, 3568, 3568, 3568],
              color: 'rgba(255, 38, 49, 0.15)'
            },
            {
              row: [0, 0, 0, 0, 0],
              color: 'rgba(17, 171, 119, 0.15)'
            },
            {
              row: [0, 0, 0, 0],
              color: 'rgba(48, 138, 201, 0.15)'
            },
            {
              row: [0, 0, 0, 0, 0, 0, 0],
              color: 'rgba(245, 167, 42, 0.15)'
            },
            {
              row: [0],
              color: 'rgba(74, 79, 84, 0.15)'
            },
          ],
        },
      ];
      const dataDateNow = [
        {
          total: "Total a la fecha",
          rows: [
            {
              row: [0, 0, 0, 0],
              color: 'rgba(255, 38, 49, 0.15)'
            },
            {
              row: [0, 0, 0, 0, 0],
              color: 'rgba(17, 171, 119, 0.15)'
            },
            {
              row: [0, 0, 0, 0],
              color: 'rgba(48, 138, 201, 0.15)'
            },
            {
              row: [0, 0, 0, 0, 0, 0, 0],
              color: 'rgba(245, 167, 42, 0.15)'
            },
            {
              row: [0],
              color: 'rgba(74, 79, 84, 0.15)'
            },
          ],
        },
      ];
      const dataDates = [
        {
          date: "29/07/2021",
          rows: [
            {
              row: [0, 0, 0, 0],
            },
            {
              row: [0, 0, 0, 0, 0],
            },
            {
              row: [0, 0, 0, 0],
            },
            {
              row: [0, 0, 0, 0, 0, 0, 0],
            },
            {
              row: [0],
            },
          ],
        },
        {
          date: "29/07/2021",
          rows: [
            {
              row: [0, 0, 0, 0],
            },
            {
              row: [0, 0, 0, 0, 0],
            },
            {
              row: [0, 0, 0, 0],
            },
            {
              row: [0, 0, 0, 0, 0, 0, 0],
            },
            {
              row: [0],
            },
          ],
        },
        {
          date: "29/07/2021",
          rows: [
            {
              row: [0, 0, 0, 0],
            },
            {
              row: [0, 0, 0, 0, 0],
            },
            {
              row: [0, 0, 0, 0],
            },
            {
              row: [0, 0, 0, 0, 0, 0, 0],
            },
            {
              row: [0],
            },
          ],
        },
        {
          date: "29/07/2021",
          rows: [
            {
              row: [0, 0, 0, 0],
            },
            {
              row: [0, 0, 0, 0, 0],
            },
            {
              row: [0, 0, 0, 0],
            },
            {
              row: [0, 0, 0, 0, 0, 0, 0],
            },
            {
              row: [0],
            },
          ],
        },
        {
          date: "29/07/2021",
          gray: true,
          rows: [
            {
              row: [0, 0, 0, 0],
            },
            {
              row: [0, 0, 0, 0, 0],
            },
            {
              row: [0, 0, 0, 0],
            },
            {
              row: [0, 0, 0, 0, 0, 0, 0],
            },
            {
              row: [0],
            },
          ],
        },
      ];
      
    const [openSelect, setOpenSelect] = useState(false);
    const [showSubSelects, setShowSubSelects] = useState(false);
    const [idSelect, setIdSelect] = useState('')
    return(
        <>
            <div className="cj-activations-reports">
                <HeadersSection
                    title={<div><span>Reportes</span></div>}
                    exeptions={{}}
                />
                <div className="div-selects-activations homeFiles">
                    <div className="div-container-stand">
                    <div className="p-grid">
                        <div className="p-col-3 p-md-6 p-lg-3 p-sm-12">
                            <AbsoluteSelect
                                inputName={'Región'}
                                //list={info.selectRangoInfo.list}
                                list={listJsonTest}
                                setOpenSelect={setOpenSelect}
                                openSelect={openSelect}
                                setIdSelect={setIdSelect}
                            />
                        </div> 
                        <div className="p-col-3 p-md-6 p-lg-3 p-sm-12">
                            <AbsoluteSelect
                                inputName={'Sucursal'}
                                //list={info.selectRangoInfo.list}
                                list={listJsonTest}
                                setOpenSelect={setOpenSelect}
                                openSelect={openSelect}
                                setIdSelect={setIdSelect}
                            />
                        </div> 
                        <div className="p-col-3 p-md-6 p-lg-3 p-sm-12">
                            <AbsoluteSelect
                                inputName={'Canal'}
                                //list={info.selectRangoInfo.list}
                                list={listJsonTest}
                                setOpenSelect={setOpenSelect}
                                openSelect={openSelect}
                                setIdSelect={setIdSelect}
                            />
                        </div> 
                        <div className="p-col-3 p-md-6 p-lg-3 p-sm-12">
                            <AbsoluteSelect
                                inputName={'Sub canal comercial'}
                                //list={info.selectRangoInfo.list}
                                list={listJsonTest}
                                setOpenSelect={setOpenSelect}
                                openSelect={openSelect}
                                setIdSelect={setIdSelect}
                            />
                        </div> 
                        <div className="p-col-3 p-md-6 p-lg-3 p-sm-12">
                            <AbsoluteSelect
                                inputName={'Vendedor'}
                                //list={info.selectRangoInfo.list}
                                list={listJsonTest}
                                setOpenSelect={setOpenSelect}
                                openSelect={openSelect}
                                setIdSelect={setIdSelect}
                            />
                        </div> 
                        <div className="p-col-3 p-md-6 p-lg-3 p-sm-12">
                            <AbsoluteSelect
                                inputName={'POS'}
                                //list={info.selectRangoInfo.list}
                                list={listJsonTest}
                                setOpenSelect={setOpenSelect}
                                openSelect={openSelect}
                                setIdSelect={setIdSelect}
                            />
                        </div> 
                        <div className="p-col-3 p-md-6 p-lg-3 p-sm-12 fz-13">
                            <AbsoluteSelect
                                inputName={'Estado Venta'}
                                //list={info.selectRangoInfo.list}
                                list={listJsonTest}
                                setOpenSelect={setOpenSelect}
                                openSelect={openSelect}
                                setIdSelect={setIdSelect}
                            />
                        </div> 
                        <div className="p-col-3 p-md-6 p-lg-3 p-sm-12 fz-14">
                            <AbsoluteSelect
                                inputName={'Estado STEALTH'}
                                //list={info.selectRangoInfo.list}
                                list={listJsonTest}
                                setOpenSelect={setOpenSelect}
                                openSelect={openSelect}
                                setIdSelect={setIdSelect}
                            />
                        </div> 
                        <div className="p-col-3 p-md-6 p-lg-3 p-sm-12 fz-13 pb-2">
                            <AbsoluteSelect
                                inputName={'Estado DDI'}
                                //list={info.selectRangoInfo.list}
                                list={listJsonTest}
                                setOpenSelect={setOpenSelect}
                                openSelect={openSelect}
                                setIdSelect={setIdSelect}
                            />
                        </div> 
                    </div>
                </div>
            </div>
            <div className="buttons-container" style={{marginBottom:"30px"}}>
                    <div className="div-details-activations">
                        <button className="btn-blue btn-jf mr-3 " style={{width:"100px"}} onClick={() => setShowSubSelects(!showSubSelects)}>Limpiar</button>
                        <button className="btn-jf btn-green" onClick={() => setShowSubSelects(!showSubSelects)}>Aplicar</button>
                    </div>
                </div>

                <ThirdTable
                    arrayItems={dataOne}
                    dataDateNow={dataDateNow}
                    dataDates={dataDates}
                    download={false}
                />


            </div>
            <FooterSimple></FooterSimple>
        </>
    );
}
export default ActivationsReports;