
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import menuIcon from '../../img/icons/menuIcon.png'
import Login from "../guest/login/Login";
import RecoverPassword from "../guest/RecoverPassword/RecoverPassword";
import ForgotPassword from "../guest/ForgotPassword/ForgotPassword";
import Sidebar from "../book/Sidebar/Sidebar";
import SidebarNew from "../book/Sidebar/SidebarNew";
import MenuToggle from "../book/menuToggle/MenuToggle"
import Dashboard from "../../pages/auth/Dashboard/Dashboard"
import Plans from "../../pages/auth/Plans/Plans"
import CreatePlan from "../../pages/auth/Plans/CreatePlan/CreatePlan";
import Management from "../../pages/auth/Management/Management"
import PrintLabels from "../../pages/auth/FileManagement/HomeFiles/PrintLabels/PrintLabels";
import UploadUpdates from "../../pages/auth/FileManagement/HomeFiles/UploadUpdates/UploadUpdates";
import UploadCI from "../../pages/auth/FileManagement/HomeFiles/UploadCI/UploadCI";
import HomeFiles from "../../pages/auth/FileManagement/HomeFiles/HomeFiles"
import HomeFilesReceive from "../../pages/auth/FileManagement/HomeFiles/Receive/Receive"
import HomeFilesToFinish from "../../pages/auth/FileManagement/HomeFiles/ToFinish/ToFinish"
import HomeFilesExpired from "../../pages/auth/FileManagement/HomeFiles/Expired/Expired"
import HomeFilesReceivePos from "../../pages/auth/FileManagement/HomeFiles/ReceivePos/ReceivePos"
import HomeFilesToFinishPos from "../../pages/auth/FileManagement/HomeFiles/ToFinishPos/ToFinishPos"
import HomeFilesExpiredPos from "../../pages/auth/FileManagement/HomeFiles/ExpiredPos/ExpiredPos"
import HomeFilesReceiveDTH from "../../pages/auth/FileManagement/HomeFiles/ReceiveDTH/ReceiveDTH"
import HomeFilesToFinishDTH from "../../pages/auth/FileManagement/HomeFiles/ToFinishDTH/ToFinishDTH"
import HomeFilesExpiredDTH from "../../pages/auth/FileManagement/HomeFiles/ExpiredDTH/ExpiredDTH"
import HomeFilesReceiveGpon from "../../pages/auth/FileManagement/HomeFiles/ReciveGpon/ReciveGpon";
import HomeFilesToFinishGpon from "../../pages/auth/FileManagement/HomeFiles/ToFinishGpon/ToFinishGpon";
import HomeFilesExpiredGpon from "../../pages/auth/FileManagement/HomeFiles/ExpiredGpon/ExpiredGpon";
import ReciveLetarge from "../book/ReciveLetarge/ReciveLetarge";
import Main from '../Main/Main';
import UploadFile from "../../pages/auth/Activations/UploadFile/UploadFile"
import PendingRecords from "../../pages/auth/Activations/PendingRecords/PendingRecords"
import PendingBox from "../../pages/auth/Activations/PendingBox/PendingBox"
import Activations from '../../pages/auth/Activations/Activations'
import Pos from '../../pages/auth/Pos/Pos'
import CreatePos from '../../pages/auth/Pos/CreatePos/CreatePos'
import EditPlan from "../../pages/auth/Plans/EditPlan/EditPlan";
import lethargyAnOvercome from "../../pages/auth/lethargyAnOvercome/lethargyAnOvercome";
import VerificationsPeopleManagment from "../book/PeopleManagment/PeopleManagment";
import ActivationsReports from "../book/ActivationsReports/ActivationsReports";
import FilesReceive from "../../pages/auth/FileManagement/FilesReceive/FilesReceive";
import 'bootstrap/dist/css/bootstrap.min.css';

import './MainRoute.scss'
import FilesForm from "../../pages/auth/FileManagement/HomeFiles/FilesForm/FilesForm";
import FilesReports from "../../pages/auth/FileManagement/Reports/Reports";
import DashboardRRHH from "../../pages/auth/RRHH/DashboardRRHH/DashboardRRHH";
import PeopleManagment from "../book/PeopleManagment/PeopleManagment";
import GenerateClaroSurrender from "../book/GenerateClaroSurrender/GenerateClaroSurrender";
import FilesSearch from "../../pages/auth/FileManagement/SearchFiles/SearchFiles";

//letarges
import ConfirmReception from "../../pages/auth/ConfirmReception/ConfirmReception.js";
import LethargyManagement from "../LethargyManagement/LethargyManagement";
import UploasLetargeScaned from "../../pages/auth/UploasLetargeScaned/UploasLetargeScaned";
import UploadUpgrateStealth from "../../pages/auth/UploadUpgrateStealth/UploadUpgrateStealth";
import UploadDocuments from "../../pages/auth/UploadDocuments/UploadDocuments";
import UsersBlokedFiles from "../../pages/auth/UsersBlokedFiles/UsersBlokedFiles";
import UsersBlockedStock from "../../pages/auth/UsersBlockedStock/UsersBlockedStock";
import UnassignedPos from "../../pages/auth/RRHH/UnassignedPos/UnassignedPos";
import MetaNotAssigned from "../../pages/auth/RRHH/MetaNotAssigned/MetaNotAssigned";
import ExpiredPassword from "../../pages/auth/RRHH/ExpiredPassword/ExpiredPassword";
import NoSale from "../../pages/auth/RRHH/NoSale/NoSale";
import ReturnProducts from "../../pages/auth/RRHH/ReturnProducts/ReturnProducts";
import ContractEexpiration from "../../pages/auth/RRHH/ContractEexpiration/ContractEexpiration";
import RequestVacations from "../../pages/auth/RRHH/RequestVacations/RequestVacations";
import IpsRegister from "../../pages/auth/RRHH/IpsRegister/IpsRegister";
import SecureRegistration from "../../pages/auth/RRHH/SecureRegistration/SecureRegistration";
import AllocateResourses from "../../pages/auth/RRHH/AllocateResourses/AllocateResourses";
import AttendanceRh from "../../pages/auth/RRHH/AttendanceRh/AttendanceRh";
import TemplateGenerate from "../../pages/auth/RRHH/TemplateGenerate/TemplateGenerate";
import ResourcesGestion from "../../pages/auth/RRHH/ResourcesGestion/ResourcesGestion";
import Comercial from "../../pages/auth/RRHH/Comercial/Comercial";
import Parameters from "../../pages/auth/RRHH/Parameters/Occupation/Occupation";
import Bank from "../../pages/auth/RRHH/Parameters/Bank/Bank";
import PaymentMethods from "../../pages/auth/RRHH/Parameters/PaymentMethods/PaymentMethods";
import ManagementPersons from "../../pages/auth/RRHH/Parameters/ManagementPersons/ManagementPersons";
import NewPersonnel from "../../pages/auth/RRHH/Personnel/NewPersonnel/NewPersonnel";
import StaffDetail from "../../pages/auth/RRHH/Personnel/StaffDetail/StaffDetail";
import NewPersonnelTwo from "../../pages/auth/RRHH/Personnel/NewPersonnelTwo/NewPersonnelTwo";
import NewPersonnelThree from "../../pages/auth/RRHH/Personnel/NewPersonnelThree/NewPersonnelThree";
import NewPersonnelFour from "../../pages/auth/RRHH/Personnel/NewPersonnelFour/NewPersonnelFour";
import Permisos from "../../pages/auth/RRHH/Personnel/StaffDetail/Permisos";
import General from "../../pages/auth/RRHH/Personnel/StaffDetail/General";
import Penalties from "../../pages/auth/RRHH/Personnel/StaffDetail/Penalties";
import Request from "../../pages/auth/RRHH/Personnel/StaffDetail/Request";
import Binnacle from "../../pages/auth/RRHH/Personnel/StaffDetail/Binnacle"
import Commissions from "../../pages/auth/RRHH/Commissions/Commissions";
import HumanResources from "../../pages/auth/RRHH/Commissions/HumanResources";
import Reports from "../../pages/auth/RRHH/Reports/Reports";
import PayAllowances from "../../pages/auth/RRHH/Parameters/PayAllowances/PayAllowances"
import NewReason from "../../pages/auth/RRHH/Parameters/NewReason/NewReason";



const MainRoute = () => {

    const [active, setActive] = useState(0)
    const [auth, setAuth] = useState(0)
    const [path, setPath] = useState('')
    const [showMenu, setShowMenu] = useState(true)
    // console.log('active', active)

    // useEffect(() => {
    //     setPath(window.location.pathname);
    // })

    useEffect(() => {
        setAuth(1);
        if (window.location.pathname == '/'
            || window.location.pathname == '/recover-password'
            || window.location.pathname == '/forgot-password') {
            setAuth(0)
            // console.log(window.location.pathname)
        }
    })

    return (
        <>
            
            <Router>

                {window.location.pathname === '/mail' ?
                    <>
                        <div className="main-content-wrap d-flex flex-column">
                            <div className="main-content">
                                <Routes>
                                    <Route exact path="/mail" element={<Main setAuth={setAuth} />} />
                                </Routes>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div className={`app-admin-wrap layout-sidebar-compact sidebar-dark-purple ${showMenu && 'sidenav-open'} clearfix ${auth ? 'auth' : 'guest'}`}>
                            <SidebarNew></SidebarNew>

                            <div className="main-content-wrap d-flex flex-column">
                                {/* <MenuToggle /> */}
                                <div onClick={()=> setShowMenu(!showMenu) } className="btn-open-menu">
                                <img src={menuIcon}  />
                                </div>


                                <div className="main-content" id="main-content-id">

                                    <Routes>
                                        <Route exact path="/" element={<Login setAuth={setAuth} />} />
                                        <Route exact path="/recover-password" element={<RecoverPassword setAuth={setAuth} />} />
                                        <Route exact path="/forgot-password" element={<ForgotPassword setAuth={setAuth} />} />
                                        <Route exact path="/dashboard" element={<Dashboard setAuth={setAuth} />} />
                                        <Route exact path="/plan" element={<Plans setAuth={setAuth} />} />
                                        <Route exact path="/plan-create" element={<CreatePlan setAuth={setAuth} />} />
                                        <Route exact path="/plan-edit=:id" element={<EditPlan setAuth={setAuth} />} />
                                        <Route exact path="/management_and_reporting" element={<Management setAuth={setAuth} />} />
                                        <Route exact path="/activation-upload" element={<UploadFile setAuth={setAuth} />} />
                                        <Route exact path="/activation-records" element={<PendingRecords setAuth={setAuth} />} />
                                        <Route exact path="/activation-box" element={<PendingBox setAuth={setAuth} />} />
                                        <Route exact path="/activation" element={<Activations setAuth={setAuth} />} />
                                        <Route exact path="/activation-reports" element={<ActivationsReports setAuth={setAuth} />} />
                                        <Route exact path="/pos" element={<Pos setAuth={setAuth} />} />
                                        <Route exact path="/pos-create" element={<CreatePos setAuth={setAuth} />} />
                                        <Route exact path="/pos-edit=:id" element={<CreatePos setAuth={setAuth} />} />
                                        <Route exact path="/print-labels" element={<PrintLabels setAuth={setAuth} type={0} />}></Route>
                                        <Route exact path="/upload-updates" element={<UploadUpdates setAuth={setAuth} type={0} />}></Route>
                                        <Route exact path="/upload-ci" element={<UploadCI setAuth={setAuth} type={0} />}></Route>
                                        <Route exact path="/home-files" element={<HomeFiles setAuth={setAuth} type={0} />}></Route>
                                        <Route exact path="/home-files/receive" element={<HomeFilesReceive setAuth={setAuth} type={0} />}></Route>
                                        <Route exact path="/home-files/to-finish" element={<HomeFilesToFinish setAuth={setAuth} type={0} />}></Route>
                                        <Route exact path="/home-files/expired" element={<HomeFilesExpired setAuth={setAuth} type={0} />}></Route>
                                        <Route exact path="/home-files/receive-pos" element={<HomeFilesReceivePos setAuth={setAuth} type={0} />}></Route>
                                        <Route exact path="/home-files/to-finish-pos" element={<HomeFilesToFinishPos setAuth={setAuth} type={0} />}></Route>
                                        <Route exact path="/home-files/expired-pos" element={<HomeFilesExpiredPos setAuth={setAuth} type={0} />}></Route>
                                        <Route exact path="/home-files/receive-dth" element={<HomeFilesReceiveDTH setAuth={setAuth} type={0} />}></Route>
                                        <Route exact path="/home-files/to-finish-dth" element={<HomeFilesToFinishDTH setAuth={setAuth} type={0} />}></Route>
                                        <Route exact path="/home-files/expired-dth" element={<HomeFilesExpiredDTH setAuth={setAuth} type={0} />}></Route>
                                        <Route exact path="/home-files/receive-gpon" element={<HomeFilesReceiveGpon setAuth={setAuth} type={0} />}></Route>
                                        <Route exact path="/home-files/to-finish-gpon" element={<HomeFilesToFinishGpon setAuth={setAuth} type={0} />}></Route>
                                        <Route exact path="/home-files/expired-gpon" element={<HomeFilesExpiredGpon setAuth={setAuth} type={0} />}></Route>
                                        <Route exact path="/home-claro-letarges-generate-claro-surrender" element={<GenerateClaroSurrender setAuth={setAuth} type={0} />}></Route>
                                        <Route exact path="/files-receive" element={<FilesReceive setAuth={setAuth} type={0} />}></Route>
                                        <Route exact path="/files-form" element={<FilesForm setAuth={setAuth} type={0} />}></Route>
                                        <Route exact path="/files-reports" element={<FilesReports setAuth={setAuth} type={0} />}></Route>

                                        {/* nuevo - no borrar */}
                                        <Route exact path="/confirm-reception" element={<ConfirmReception setAuth={setAuth} type={0} />}></Route>
                                        <Route exact path="/letargy-management" element={<LethargyManagement setAuth={setAuth} type={0} />}></Route>
                                        <Route exact path="/upload-letarges-scanned" element={<UploasLetargeScaned setAuth={setAuth} type={0} />}></Route>
                                        <Route exact path="/upload-updates-stealth" element={<UploadUpgrateStealth setAuth={setAuth} type={0} />}></Route>
                                        
                                        <Route exact path="/upload-documents" element={<UploadDocuments setAuth={setAuth} type={0} />}></Route>

                                        <Route exact path="/files-search" element={<FilesSearch setAuth={setAuth} type={0} />}></Route>

                                        <Route exact path="/users-bloked" element={<UsersBlokedFiles setAuth={setAuth} type={0} />}></Route>
                                        <Route exact path="/users-bloked-stook" element={<UsersBlockedStock setAuth={setAuth} type={0} />}></Route>
                                        <Route exact path="/dashboard-rrhh" element={<DashboardRRHH setAuth={setAuth} type={0} />}></Route>
                                        <Route exact path="/home-files-rrhh-people-managment" element={<PeopleManagment setAuth={setAuth} type={0} />}></Route>
                                        {/* 22/07/2022 */}
                                        <Route exact path="/pos-unassigned" element={<UnassignedPos setAuth={setAuth} type={0} />}></Route>
                                        <Route exact path="/meta-unassigned" element={<MetaNotAssigned setAuth={setAuth} type={0} />}></Route>
                                        <Route exact path="/expired-password" element={<ExpiredPassword setAuth={setAuth} type={0} />}></Route>
                                        <Route exact path="/no-sales" element={<NoSale setAuth={setAuth} type={0} />}></Route>
                                        <Route exact path="/return-products" element={<ReturnProducts setAuth={setAuth} type={0} />}></Route>
                                         {/* 26/07/2022 */}
                                        <Route exact path="/contractExpiration" element={<ContractEexpiration setAuth={setAuth} type={0} />}></Route>
                                        <Route exact path="/request-permission" element={<RequestVacations setAuth={setAuth} type={0} />}></Route>
                                        <Route exact path="/ips-register" element={<IpsRegister setAuth={setAuth} type={0} />}></Route>
                                        <Route exact path="/secure-registration" element={<SecureRegistration setAuth={setAuth} type={0} />}></Route>
                                        <Route exact path="/allocateResourses" element={<AllocateResourses setAuth={setAuth} type={0} />}></Route>
                                        {/* 28/07/2022 */}
                                        <Route exact path="/attendance" element={<AttendanceRh setAuth={setAuth} type={0} />}></Route>
                                        {/* 26/08/2022 */}
                                        <Route exact path="/template-generate" element={<TemplateGenerate setAuth={setAuth} type={0} />}></Route>
                                        <Route exact path="/resources-gestion" element={<ResourcesGestion setAuth={setAuth} type={0} />}></Route>
                                        <Route exact path="/commercial" element={<Comercial setAuth={setAuth} type={0} />}></Route>

                                        {/* 08-09-2022  joe*/}
                                        <Route exact path="/parameters-occupation" element={<Parameters setAuth={setAuth} />} />
                                        <Route exact path="/parameters-bank" element={<Bank setAuth={setAuth} />} />
                                        <Route exact path="/parameters-payment-methods" element={<PaymentMethods setAuth={setAuth} />} />
                                        <Route exact path="/parameters-managementpersons" element={<ManagementPersons setAuth={setAuth} />} />
                                        <Route exact path="/parameters-pay-allowances" element={<PayAllowances setAuth={setAuth} />} />
                                        <Route exact path="/parameters-new-reason" element={<NewReason setAuth={setAuth} />} />
                                        <Route exact path="/personnel-newpersonnel" element={<NewPersonnel setAuth={setAuth} />} />
                                        <Route exact path="/personnel-newpersonneltwo" element={<NewPersonnelTwo setAuth={setAuth} />} />
                                        <Route exact path="/personnel-newpersonnelthree" element={<NewPersonnelThree setAuth={setAuth} />} />
                                        <Route exact path="/personnel-newpersonnelfour" element={<NewPersonnelFour setAuth={setAuth} />} />
                                        <Route exact path="/personnel-staffdetail/general" element={<General setAuth={setAuth} />} />
                                        <Route exact path="/personnel-staffdetail/permissions" element={<Permisos setAuth={setAuth} />} />
                                        <Route exact path="/personnel-staffdetail/penalties" element={<Penalties setAuth={setAuth} />} />
                                        <Route exact path="/personnel-staffdetail/request" element={<Request setAuth={setAuth} />} />
                                        <Route exact path="/personnel-staffdetail/binnacle" element={<Binnacle setAuth={setAuth} />} />
                                        <Route exact path="/commissions" element={<Commissions setAuth={setAuth} />} />
                                        <Route exact path="/humanresources" element={<HumanResources setAuth={setAuth} />} />
                                        <Route exact path="/reports" element={<Reports setAuth={setAuth} />} />
                                        
                                        {/* cambiar name-rpute por reques_vacation */}
                                    </Routes>
                                </div>
                            </div>
                        </div>

                    </>
                }


            </Router>
        </>
    )

}

export default MainRoute