import React, { useState, useEffect } from "react";
import "./Occupation.scss";
import TableOccupation from "./TableOccupation";
import imgExel from "../../../../../img/jpg/exel.png";
import imgCsv from "../../../../../img/jpg/csv.png";
import { Modal } from "antd";
import { DataTable } from "primereact/datatable";
import { infoParameters } from "./occupationData";

const Occupation = () => {
  const [searchEnter, setSearchEnter] = useState();
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [dataParameters, setDataParameters] = useState([]);
  const [column, setColumn] = useState([]);
  const [modal, setModal] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setColumn(infoParameters.tableInfo.headerList);
      setDataParameters(infoParameters.tableInfo);
    };
    getData();
  }, []);

  return (
    <div
      className="parameters"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: "20px",
      }}
    >
      <div>
        <h4 className="parameters-title">
          Parámetros <span className="parameters-title-span">{" <"}Cargos</span>
        </h4>
        <div className="parameters-container">
          <div className="parameters-container-header">
            <div className="parameters-container-header-search">
              <label>Filtrar por:</label>
              <input
                value={searchEnter}
                onChange={(e) => {
                  setSearchEnter(e.target.value);
                }}
                type="search"
                placeholder="Buscar por cargo"
              />
            </div>

            <div className="parameters-container-header-button">
              <button onClick={()=>setModal(true)}>Nuevo cargo</button>
            </div>
            <Modal
              className="modalNewReason"
              visible={modal}
              onOk={() => setModal(false)}
              onCancel={() => setModal(false)}
              centered={true}
              footer={[
                <div style={{ display: "flex", padding: "0 16px 16px" }}>
                  <button
                    className="ModalCancel"
                    onClick={() => setModal(false)}
                  >
                    Cancelar
                  </button>
                  <button onClick={()=>setModal(false)} className="btnModalDo">Agregar</button>
                </div>,
              ]}
            >
              <div
                className="modal-container"
                style={{ padding: "0 16px", height: "200px" }}
              >
                <p className="textModal">Nuevo cargo</p>
                <div>
                  <label>Nombre del cargo</label>
                  <input
                    type="text"
                    placeholder="Ingresa nombre del cargo"
                  />
                </div>
              </div>
            </Modal>
          </div>

          <div className="parameters-table">
            <TableOccupation
              value={dataParameters.rows}
              column={column}
              setColumn={setColumn}
              showModalDelete={showModalDelete}
              setShowModalDelete={setShowModalDelete}
              searchEnter={searchEnter}
            />
          </div>
          <div className="parameters-footer">
            <div className="parameters-footer-download">
              <label>Descargar como</label>
              <button className="parameters-footer-download-button">
                <img src={imgExel} />
              </button>
              <button className="parameters-footer-download-button">
                <img src={imgCsv} />
              </button>
            </div>
            <div className="parameters-footer-pag"></div>
          </div>
        </div>
      </div>
      <div>
        <footer style={{ display: "flex", justifyContent: "center" }}>
          <p>2022 GestiClaro. Todos los derechos reservados</p>
        </footer>
      </div>
    </div>
  );
};
export default Occupation;
//
