import React from "react"
import './cancellation.scss'
// import cancel from "../../../../../images/cardIcons/closePurple.svg"

const Cancellation=()=>{


    const Card=()=>{

    }


    return(
        <div className="containerCancellation">

            {/*<div style={{display:'flex'}}>*/}
                <div className='cancellationCard'>
                    {/* <img src={cancel}/> */}
                    <div className='textCancellation'>
                        <p className="titleCancellation">Cancelaciones</p>
                        <p className="boldCancellation">245</p>
                    </div>
                </div>

                <div style={{width:'100%', display:'flex', justifyContent:'center'}}>
                    <div className='labelText'>
                        <p>Cancelaciones GESTICLARO</p>
                        <p>Cancelaciones STELTH <span className='lightText'>Junio/19</span></p>
                    </div>
                </div>
            {/*</div>*/}




            <div className="progressBarCancellation">
                <div className="contentCancellation">

                    <div className="middleCard">
                        <div className="textCenter">
                            <div className="middleTextSection">
                                <div className='percentageWidth'>
                                    <div
                                        className="progressBarLeft"
                                        role="progressbar"
                                        style={{ width:'80%', backgroundColor:'#ff2631'}}
                                        aria-valuenow={80}
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                    >
                                        <p>275</p>
                                    </div>
                                </div>

                                <div className="textGroup">
                                    <strong>PP</strong>
                                    <p>CANCELACIONES</p>
                                </div>

                                <div className='percentageWidth'>
                                    <div
                                        className="progressBarRight"
                                        role="progressbar"
                                        style={{ width:'80%', backgroundColor:'#ff2631'}}
                                        aria-valuenow={80}
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                    >
                                        <p>275/15</p>
                                    </div>
                                </div>
                            </div>

                            <div className="middleTextSection">
                                <div className='percentageWidth'>
                                    <div
                                        className="progressBarLeft"
                                        role="progressbar"
                                        style={{ width:'70%', backgroundColor:'#036402'}}
                                        aria-valuenow={70}
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                    >
                                        <p>275</p>
                                    </div>
                                </div>

                                        <div className="textGroup">
                                            <strong>POSPAGO</strong>
                                            <p>CANCELACIONES</p>
                                        </div>
                                <div className='percentageWidth'>
                                    <div
                                        className="progressBarRight"
                                        role="progressbar"
                                        style={{ width:'70%', backgroundColor:'#036402'}}
                                        aria-valuenow={70}
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                    >
                                        <p>275/35</p>
                                    </div>
                                </div>
                            </div>

                            <div className="middleTextSection">
                                <div className='percentageWidth'>
                                    <div
                                        className="progressBarLeft"
                                        role="progressbar"
                                        style={{ width:'80%', backgroundColor:'#0271c1'}}
                                        aria-valuenow={80}
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                    >
                                        <p>275</p>
                                    </div>
                                </div>

                                        <div className="textGroup">
                                            <strong>DTH</strong>
                                            <p>CANCELACIONES</p>
                                        </div>

                                <div className='percentageWidth'>

                                    <div
                                        className="progressBarRight"
                                        role="progressbar"
                                        style={{ width:'70%', backgroundColor:'#0271c1'}}
                                        aria-valuenow={70}
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                    >
                                        <p>275/35</p>
                                    </div>

                                </div>
                            </div>


                            <div className="middleTextSection">
                                <div className='percentageWidth'>
                                    <div
                                        className="progressBarLeft"
                                        role="progressbar"
                                        style={{ width:'70%', backgroundColor:'#f8a100'}}
                                        aria-valuenow={70}
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                    >
                                        <p>275</p>
                                    </div>
                                </div>

                                        <div className="textGroup">
                                            <strong>GPON</strong>
                                            <p>CANCELACIONES</p>
                                        </div>
                                <div className='percentageWidth'>
                                    <div
                                        className="progressBarRight"
                                        role="progressbar"
                                        style={{ width:'60%', backgroundColor:'#f8a100'}}
                                        aria-valuenow={60}
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                    >
                                        <p>275/35</p>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>

                </div>
            </div>


        </div>
    )
}
export default Cancellation
