import { display, flexbox, style } from "@mui/system";
import React from "react";
import { useState } from "react";
import InputFile from "./InputFile";
const DocumentElements = ({ title, id, id2, ajustes }) => {
  return (
    <div className="each-input" style={ajustes && {marginLeft:"30px", marginTop:"-27px"}}>
      <p>{title}</p>
      {<InputFile id={id} id2={id2} ajustes={ajustes} />}
    </div>
  );
};

export default DocumentElements;
