import React, {useState, useEffect} from "react"
import { info } from '../../../../../mockups/dashboard';
import HeadersSection from "../../../../components/book/headersSection/HeadersSection";
// import CreatePlan from "../createplan/CreatePlan";
import Card from "../../../../components/book/card/Card";
// import greenIcon from "../../../../../images/cardIcons/greenHand.svg";
// import blueIcon from "../../../../../images/cardIcons/blueHand.svg";
// import blueIcon from '../../../../../images/cardIcons/blueHand.svg'
// import { ReactComponent as blueIcon } from '../../../../../images/cardIcons/blueHand.svg'
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import { Modal, Button } from "antd";
import ExcelLogo from "../../../../../images/cardIcons/excelLogo.png"
import TxtLogo from "../../../../../images/cardIcons/txtLogo.jpg"
import "antd/es/modal/style/css.js";
import { ProgressBar } from 'primereact/progressbar';
import 'primeflex/primeflex.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
//import '../../Dashboard/Dashboard.scss';
import FooterSimple from "../../../../components/book/footerSimple/FooterSimple";
import './uploadFile.scss';
import axios from "axios"
import handgreen from "../../../../../images/cardIcons/handgreen.png";
import handblue from "../../../../../images/cardIcons/handblue.png";


const UploadFile=({routes})=>{


    /* info modal third step*/
    const [plans, setPlans] = useState('')
    const [post, setPost] = useState('')

    const [records, setRecords] = useState(0)
    const [processed, setProcessed] = useState(0)
    const [updated, setUpdated] = useState(0)
    const [exit, setExit] =useState(true)

    useEffect(() => {

        var Routes
        const getHeader = async () => {
            let getData = Routes.uploadHeader;

            const result = await axios(getData,);
            setPlans(result.data.data.new_plans)
            setPost(result.data.data.new_post)
        }
        getHeader()

        const getRoutes = async() =>{
            let mainRoute = document.getElementsByName('routes')[0].getAttribute('content');
            const result = await axios(mainRoute,);
            Routes = result.data
            getHeader()
        }
        getRoutes();


    },[]);


    // Pusher.logToConsole = true;
    // var flag
    // useEffect(() => {

    //     let pusherKey = document.getElementsByName('pusher-app-secret')[0].getAttribute('content');
    //     //console.log()
    //     var pusher = new Pusher(pusherKey, {
    //         cluster: 'mt1',
    //         forceTLS: true
    //     });


    //     var channel = pusher.subscribe('upload-st-file-channel');
    //     console.log('info de channel',channel)
    //     channel.bind('upload-st-file-event', function(data) {
    //         console.log('info de pusher', data.message)

    //         flag = data.message.flag
    //         if(data.message.flag === 'progressBar'){
    //             setValue1(data.message.percentage)
    //         }
    //         if(data.message.flag === 'finished'){
    //             setThirdStep(true)
    //             setSecondStep(false)
    //             setPlans(data.message.header.new_plans)
    //             setPost(data.message.header.new_post)
    //             setRecords(data.message.inserted_records)
    //             setProcessed(data.message.records_processed)
    //             setUpdated(data.message.updated_records)
    //         }else{
    //             setThirdStep(false)
    //         }

    //         //alert(data.message);
    //         console.log('info de pusher',data.message)
    //     });

    // },);


    const [openSelect, setOpenSelect] = useState(false);
    const [showModal, setShowModal] = useState(false)
    const [uploadFile, setUploadFile] =useState(false)
    const [firstStep, setFirstStep] = useState(true)
    const [secondStep, setSecondStep] = useState(false)
    const [thirdStep, setThirdStep] =useState(false)
    const [ value1, setValue1] = useState(10)
    const [errorInput, setErrorInput] = useState(false)



    const fileType = (rowData) => {
        return (
            <React.Fragment>
                {rowData.file === '1' && (
                    <div className="divFile">
                        {/*<FontAwesomeIcon icon={faFileWord} className="icon-word-dashboard" />*/}
                        <img src={TxtLogo}/>
                    </div>
                )}

                {rowData.file === '2' && (
                    <div className="divFile">
                        <img src={ExcelLogo}/>
                        {/*<FontAwesomeIcon icon={faFileExcel} className="icon-excel-dashboard" />*/}
                    </div>
                )}



            </React.Fragment>
        );
    }

    const description = (rowData) => {
        return (
            <React.Fragment>
                {rowData.description}
            </React.Fragment>
        );
    }

    const actions = (rowData) => {
        return (
            <React.Fragment>
                {rowData.action === '1' && (
                    <button className='btn-green btnUpload' onClick={()=>{
                        setShowModal(true)
                        setUploadFile(false)
                        setFileName('')
                        setThirdStep(false)
                    }}>
                        Subir
                    </button>
                )}

                {rowData.action === '2' && (
                    <button className='btn-red btnUpload'>
                        Subir
                    </button>
                )}


            </React.Fragment>
        );
    }

    const fakeData = [
        {file: '1', description:'TXT Stealth - Activaciones CLARO', action:'1'},
        {file: '2', description:'DDI - Actualización estados activaciones', action:'2'},
        {file: '2', description:'DDI - Actualización estados activaciones', action:'2'},
        {file: '2', description:'DDI - Actualización estados activaciones', action:'2'},
        {file: '2', description:'DDI - Actualización estados activaciones', action:'2'},
        {file: '2', description:'DDI - Actualización estados activaciones', action:'2'},
        {file: '2', description:'DDI - Actualización estados activaciones', action:'2'},

    ]

    const [fileName, setFileName] = useState('')
    const [fileToSend, setFileToSend] = useState('')

    const showFileName=()=>{
        var name = document.getElementById('fileInput').files[0].name;
        document.getElementById('inputName').value = name
        setFileToSend(document.getElementById('fileInput').files[0])
        setFileName(name)
        setErrorInput(false)
    }


    function fileValidation(id){

        var formData = new FormData()
        var fileInput = document.getElementById('fileInput');
        console.log('archivo',fileInput.files[0]);
        var filePath = fileInput.value;
        var allowedExtensions = /(.txt)$/i;
        if(!allowedExtensions.exec(filePath)){
            // alert('Please upload file having extensions .pdf only.');
            fileInput.value = '';
            return false;
        }else{
            //Image preview
            if (fileInput.files && fileInput.files[0]) {
                var reader = new FileReader();
                reader.onload = function(e) {

                    formData.append('st_file', fileInput.files[0])

                    console.log('archivo', fileInput.files[0])

                    var data = routes.uploadFile

                    if(fileName === ''){
                        setErrorInput(true)
                    }else {
                        setErrorInput(false)
                        setFirstStep(false)
                        setSecondStep(true)
                        setThirdStep(false)
                    }


                    const saveFile = async () => {
                        await axios.post(data, formData)
                            .then((response) => {

                                if(value1 ===100){
                                    setThirdStep(true)
                                }

                            }).catch((error) => {


                            })
                    }

                    saveFile();

                };
                reader.readAsDataURL(fileInput.files[0]);
            }
        }
    }

    return(
        <div className="container-dashboard">
            <div className="container-dashboard">
                <HeadersSection
                    title={'Subir actualizaciones'}
                    exeptions={''}
                    date={info.headerInfo.date}
                />


                <div className='cardSpace'>
                    <Card
                        colorClass='#036402'
                        text={'NUEVOS PLANES'}
                        number={'245'}
                        icon={handgreen}
                    />

                    <Card
                        colorClass='#0271c1'
                        text={'NUEVOS POS'}
                        number={'245'}
                        icon={handblue}
                    />

                </div>


                <div className="tableShadow">
                    <DataTable
                        value={fakeData}
                        rows={5}
                        exportable
                        responsiveLayout="scroll"
                        className="borderRadiusTable"
                    >

                        <Column field="file_type" header="TIPO DE ARCHIVO" expander
                                body={fileType}
                                headerStyle={{ textAlign: 'center', marginLeft:'46px', width:'200px', borderTopLeftRadius:'10px'}}
                                bodyClassName="sizeFirstColumn p-text-center"

                        />
                        <Column field="description"header="DESCRIPCIÓN" expander
                                body={description}
                                headerStyle={{ textAlign: 'left'}}
                                bodyClassName="textBold"
                        />

                        <Column field="action"  header="ACCIÓN" expander
                                body={actions}
                                headerStyle={{ textAlign: 'center', width:'200px', borderTopRightRadius:'10px'}}
                                bodyClassName="p-text-center sizeFirstColumn"/>

                    </DataTable>
                </div>
            </div>


            <Modal
                style={{  borderRadius: '10px',  height: 270}}
                visible={showModal}
                onOk={() => setShowModal(false)}
                onCancel={() => {
                    setShowModal(false);
                    setFirstStep(true);
                    setThirdStep(false)
                    setSecondStep(false)
                }}
                centered

                footer={[
                    <>
                        {firstStep && (
                            <button className='btn-green btnUpload' onClick={()=>fileValidation('fileInput')}>
                                Subir
                            </button>
                        )}

                        {thirdStep && (
                            <button className='btn-green btnUpload' onClick={()=>{
                                setShowModal(false);
                                setFirstStep(true);
                                setThirdStep(false);
                                setSecondStep(false)
                                setFileName('')
                            }}>
                                Cerrar
                            </button>
                        )}

                    </>

                ]}
            >
                <div>
                    {firstStep && (
                        <>
                            <div style={{fontWeight:'bold'}} className="textUploadFile">

                                <>Subir datos al sistema</>

                            </div>
                            <div className="input-file">
                                <input type="file" id="fileInput" className="input-file-input" placeholder="No ha seleccionado un archivo" onChange={showFileName}/>
                                <div className="btnFile">

                                    <label htmlFor="fileInput" className="input-file-btn ">
                                        <div className='btnBlue' >
                                            Subir archivo
                                        </div>

                                    </label>

                                    <input value={fileName} type='text'id="inputName" placeholder="No ha seleccionado un archivo" className='inputUploadFile'/>
                                </div>
                            </div>

                            {errorInput && (<h4 style={{fontSize:'11px', color:'#FA0401'}}>Debe seleccionar un archivo</h4>)}


                        </>
                    )}

                    {secondStep && (
                        <div className="input-file">
                            <input type="file" id="fileInput" className="input-file-input" placeholder="No ha seleccionado un archivo"/>
                            <div className="btnFile">
                                <div style={{fontWeight:'bold'}} className="textUploadFile">
                                    Cargando datos
                                </div>

                                <input value={fileName} type='text' id="inputName" placeholder="No ha seleccionado un archivo" className='inputUploadFile'/>
                            </div>
                            <div className="sectionProgressBar">
                                <ProgressBar value={value1} color={"#036402"} style={{height:30}} showValue={false} />
                                {value1 === 100 && (<h4 className="textCompleted">Carga completa</h4>)}
                            </div>
                        </div>

                    )}

                    {thirdStep && (
                        <>
                            <div style={{fontWeight:'bold'}} className="textUploadFile">
                                Carga completa
                            </div>

                            {/*<table>*/}
                            {/*    <tbody>*/}
                            {/*        <tr>*/}
                            {/*            <td>xxx</td>*/}
                            {/*            <td>REGISTROS INSERTADOS</td>*/}
                            {/*        </tr>*/}

                            {/*        <tr>*/}
                            {/*            <td>xxx</td>*/}
                            {/*            <td>REGISTROS ACTUALIZADOS</td>*/}
                            {/*        </tr>*/}

                            {/*        <tr>*/}
                            {/*            <td>xxx</td>*/}
                            {/*            <td>REGISTROS PROCESADOS</td>*/}
                            {/*        </tr>*/}

                            {/*    </tbody>*/}
                            {/*</table>*/}
                            <div className="rowsInfo" style={{backgroundColor: '#f8f8fc'}}>
                                <p>{records}</p>
                                <p>REGISTROS INCERTADOS</p>
                            </div>

                            <div className="rowsInfo">
                                <p>{updated}</p>
                                <p>REGISTROS ACTUALIZADOS</p>
                            </div>
                            <div className="rowsInfo"  style={{backgroundColor: '#f8f8fc'}}>
                                <p>{processed}</p>
                                <p>REGISTROS PROCESADOS</p>
                            </div>


                        </>
                    )}

                </div>

            </Modal>

            <FooterSimple />

        </div>
    )

}
export default UploadFile;
